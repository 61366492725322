import { useMemo } from 'react';
import { useToggle } from './useToggle';

export const useDisclosure = (initialValue = false) => {
  const [value, toggle] = useToggle(initialValue);

  const handlers = useMemo(
    () => ({
      toggle,
      onOpen: () => toggle(true),
      onClose: () => toggle(false),
    }),
    [toggle]
  );

  return [value, handlers];
};
