import clsx from 'clsx';
interface Props {
  className?: string;
  variant?: 'default' | 'light';
}
export const LayoutIcon = ({ className, variant = 'default' }: Props) => {
  return (
    <>
      {variant === 'default' && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(className)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2H3C2.44772 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22H8C8.55228 22 9 21.5523 9 21V3C9 2.44772 8.55229 2 8 2ZM3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H8C9.65685 24 11 22.6569 11 21V3C11 1.34315 9.65685 0 8 0H3Z"
            fill="current"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 2H16C15.4477 2 15 2.44772 15 3V8C15 8.55229 15.4477 9 16 9H21C21.5523 9 22 8.55228 22 8V3C22 2.44772 21.5523 2 21 2ZM16 0C14.3431 0 13 1.34315 13 3V8C13 9.65685 14.3431 11 16 11H21C22.6569 11 24 9.65685 24 8V3C24 1.34315 22.6569 0 21 0H16Z"
            fill="current"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 15H16C15.4477 15 15 15.4477 15 16V21C15 21.5523 15.4477 22 16 22H21C21.5523 22 22 21.5523 22 21V16C22 15.4477 21.5523 15 21 15ZM16 13C14.3431 13 13 14.3431 13 16V21C13 22.6569 14.3431 24 16 24H21C22.6569 24 24 22.6569 24 21V16C24 14.3431 22.6569 13 21 13H16Z"
            fill="current"
          />
        </svg>
      )}
      {variant === 'light' && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(className)}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1H3C1.89543 1 1 1.89543 1 3V21C1 22.1046 1.89543 23 3 23H8C9.10457 23 10 22.1046 10 21V3C10 1.89543 9.10457 1 8 1ZM3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H8C9.65685 24 11 22.6569 11 21V3C11 1.34315 9.65685 0 8 0H3Z"
            fill="current"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 1H16C14.8954 1 14 1.89543 14 3V8C14 9.10457 14.8954 10 16 10H21C22.1046 10 23 9.10457 23 8V3C23 1.89543 22.1046 1 21 1ZM16 0C14.3431 0 13 1.34315 13 3V8C13 9.65685 14.3431 11 16 11H21C22.6569 11 24 9.65685 24 8V3C24 1.34315 22.6569 0 21 0H16Z"
            fill="current"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 14H16C14.8954 14 14 14.8954 14 16V21C14 22.1046 14.8954 23 16 23H21C22.1046 23 23 22.1046 23 21V16C23 14.8954 22.1046 14 21 14ZM16 13C14.3431 13 13 14.3431 13 16V21C13 22.6569 14.3431 24 16 24H21C22.6569 24 24 22.6569 24 21V16C24 14.3431 22.6569 13 21 13H16Z"
            fill="current"
          />
        </svg>
      )}
    </>
  );
};
