import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { Button } from '@hubql/hubqlkit';
import { useRouter } from 'next/router';
import { useWorkspace } from '../context/workspace/WorkspaceContext';

interface Props {
  variant?: string;
  label?: string;
  className?: string;
  size?: string;
  font?: string;
  textSize?: string;
  justify?: string;
}

export const CreateWorkspace = ({
  variant = 'shiny',
  size = 'md',
  label = 'Create',
  className,
  font,
  textSize,
  justify,
}: Props) => {
  const router = useRouter();
  const { workspaceUser } = useWorkspaceUser();
  const { createWorkspace, createWorkspaceState } = useWorkspace();
  const handleCreateWorkspace = async () => {
    const newId = await createWorkspace();
    if (newId) {
      router.push(`/w/${newId}`);
    }
  };
  return (
    <Button
      size={size}
      isLoading={createWorkspaceState?.loading}
      onClick={handleCreateWorkspace}
      loadingLabel=""
      isDisabled={!workspaceUser?.id}
      className={className}
      variant={variant}
      font={font}
      textSize={textSize}
      justify={justify}
    >
      {label}
    </Button>
  );
};
