import { SWRConfig } from 'swr';
import { GuestSignUpModal } from '../onboard/GuestSignUpModal';
import { Header } from '../header/Header';
import { useFileStore } from '../state/stores/file';
import Head from 'next/head';
import { useRouter } from 'next/router';

import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  useUser,
  useAuth,
} from '@clerk/nextjs';

import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@hubql/apollo';
import { WorkspaceProvider } from '../context/workspace/WorkspaceContext';
import {
  WorkspaceUserProvider,
  useWorkspaceUser,
} from '../context/user/WorkspaceUserContext';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { useMemo } from 'react';
import { Spinner } from '@hubql/shared-design-system';
import clsx from 'clsx';
import { isPublicPage } from '@hubql/util';
import { Loading } from '@hubql/hubqlkit';
import { UpgradeModal } from '../onboard/UpgradeModal';

type LayoutProps = {
  slugProp?: string;
  workspaceData?: any;
  children: React.ReactNode;
};
export const Layout = ({ children, slugProp, workspaceData }: LayoutProps) => {
  const { isLoaded, isSignedIn } = useUser();

  if (!isLoaded) return <Loading />;
  return (
    <WorkspaceUserProvider>
      <LayoutOuter
        isSignedIn={isSignedIn}
        slugProp={slugProp}
        workspaceData={workspaceData}
      >
        {children}
      </LayoutOuter>
    </WorkspaceUserProvider>
  );
};

const fetcher = (url: string) => fetch(url).then((r) => r.json());

type LayoutOuterProps = {
  isSignedIn: boolean;
  slugProp?: string;
  workspaceData?: any;
  children: React.ReactNode;
};
export const LayoutOuter = ({
  children,
  isSignedIn,
  slugProp,
  workspaceData,
}: LayoutOuterProps) => {
  const { workspaceUser } = useWorkspaceUser();
  const router = useRouter();
  const isPublic = isPublicPage(router.pathname);
  const guestId = isSignedIn
    ? null
    : workspaceUser?.isGuest
    ? workspaceUser?.id
    : undefined;
  const skipTokenCache =
    router.pathname === '/onboard' ||
    router.pathname === '/signed-in' ||
    router.pathname === '/home';

  const { getToken } = useAuth();
  const client = useMemo(() => {
    return createApolloClient({
      getToken,
      skipTokenCache,
      guestId,
    });
  }, [isSignedIn, guestId, skipTokenCache]);
  const showPublicComponent = isSignedIn ? false : guestId || isPublic;
  return (
    <ApolloProvider client={client}>
      <SWRConfig value={{ fetcher }}>
        <WorkspaceProvider slugProp={slugProp} workspaceData={workspaceData}>
          {showPublicComponent ? (
            <LayoutInner>{children}</LayoutInner>
          ) : (
            <>
              <Head>
                <title>Welcome to Schema Visualizer!</title>
              </Head>
              <SignedIn>
                <LayoutInner>{children}</LayoutInner>
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn redirectUrl="/signed-in" />
              </SignedOut>
            </>
          )}
        </WorkspaceProvider>
      </SWRConfig>
    </ApolloProvider>
  );
};
type LayoutInnerProps = {
  children: React.ReactNode;
};
export const LayoutInner = ({ children }: LayoutInnerProps) => {
  const router = useRouter();
  const isOnboarding = router.asPath === '/onboard';
  return (
    <div className="overflow-hidden  ">
      <div className="flex flex-col">
        <div className="w-full relative z-30">
          {!isOnboarding && <Header />}
        </div>

        <div className={clsx('flex flex-row w-screen')}>
          <div className="w-full h-full  relative z-0">{children}</div>
        </div>
      </div>
      <GuestSignUpModal />
      <UpgradeModal />
    </div>
  );
};
