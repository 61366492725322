import { create } from 'zustand';
import { UserBookmarksQuery } from '@hubql/codegen';

interface BookmarkState {
  bookmarks: UserBookmarksQuery['userBookmarks']['edges'][number]['node'][];
  setBookmarks: (
    bookmarks: UserBookmarksQuery['userBookmarks']['edges'][number]['node'][]
  ) => void;
  addBookmark: (
    bookmark: UserBookmarksQuery['userBookmarks']['edges'][number]['node']
  ) => void;
  removeBookmarkById: (bookmarkId: string) => void;
}

export const useBookmarkStore = create<BookmarkState>((set) => ({
  bookmarks: [],
  setBookmarks: (bookmarks) =>
    set((state) => ({
      ...state,
      bookmarks,
    })),
  addBookmark: (bookmark) =>
    set((state) => ({
      ...state,
      bookmarks: [...state.bookmarks, bookmark],
    })),
  removeBookmarkById: (bookmarkId) =>
    set((state) => ({
      ...state,
      bookmarks: state.bookmarks.filter((x) => x.id !== bookmarkId),
    })),
}));
