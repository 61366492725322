import { useMutation } from '@apollo/client';
import { CreateProjectFileDocument } from '@hubql/codegen';
import {
  Button,
  GraphqlIcon,
  JsonIcon,
  PrismaIcon,
  ProtoIcon,
  Skeleton,
  XmlIcon,
  OpenAPIIcon,
} from '@hubql/hubqlkit';
import router from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import {
  graphQLToFields,
  jsonToFields,
  prismaToFields,
  protobufToFields,
  xmlToFields,
  yamlToFields,
} from '@hubql/file-util';
import { uploadFile, IField } from '@hubql/file-util';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';
import { DocumentIcon } from '@heroicons/react/20/solid';
import { LiveField } from '../state/stores/live';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';

interface iCreateFile {
  fileContent: any;
  label: string;
  projectId: any;
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml';
  isLoading: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  workspaceId: string;
  workspaceSlug: string;
  fileName: string;
  variant?: string;
  size?: string;
  className?: string;
  id?: string;
  template?: boolean;
  img?: {
    src: string;
  };
  source?: string;
  fileUrl?: string;
}

export const createFileProcess = async ({
  fileContent,
  fileUrl,
  fullFileName,
  extension,
  workspaceId,
  projectId,
  setIsLoading,
  createProjectFile,
  fileName,
  posthog,
  source,
  showToast = true,
  isRedirect = true,
  workspaceUser,
  setShowGuestSignUp,
  setShowUpgradeModal,
}: {
  fileContent: any;
  fullFileName: string;
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml';
  workspaceId: string;
  projectId: string;
  setIsLoading?: (isLoading: boolean) => void;
  createProjectFile: any;
  fileName: string;
  posthog: any;
  workspaceUser: any;
  setShowGuestSignUp: any;
  setShowUpgradeModal: any;
  source?: string;
  showToast?: boolean;
  isRedirect?: boolean;
  fileUrl?: string;
}) => {
  try {
    setIsLoading && setIsLoading(true);

    toast.info('Creating file...', {
      autoClose: 3000,
    });
    let fileContentToCreate = fileContent;

    if (fileUrl) {
      const fileResponse = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'accept-encoding': 'gzip, deflate, br',
        },
      });
      fileContentToCreate = await fileResponse.text();
    }

    const uuid = uuidv4().replace(/-/gi, '');
    const fileId = 'fil_' + uuid;
    const projectFileId = 'prf_' + uuidv4().replace(/-/gi, '');

    const checkIfJSON = (content: string) => {
      try {
        return typeof content === 'string' ? JSON.parse(content) : content;
      } catch (error) {
        console.error('An error occurred:', error);
        return content;
      }
    };

    const checkIfYaml = (content: string) => {
      try {
        return typeof content === 'string' ? content : content;
      } catch (error) {
        console.error('An error occurred:', error);
        return content;
      }
    };

    const checkIfXML = (content: string) => {
      try {
        return typeof content === 'string' ? content : content;
      } catch (error) {
        console.error('An error occurred:', error);
        return content;
      }
    };

    let fields: IField[] = [];
    if (extension === 'json') {
      fields = jsonToFields({
        jsonData: checkIfJSON(fileContentToCreate),
        fileId: fileId,
        workspaceId,
        rawFields: [],
      });
    }

    if (extension === 'yaml') {
      fields = yamlToFields({
        yamlData: checkIfYaml(fileContentToCreate),
        fileId: fileId,
        workspaceId,
        rawFields: [],
      });
    }

    if (extension === 'xml') {
      fields = xmlToFields({
        xmlData: checkIfXML(fileContentToCreate),
        fileId: fileId,
        workspaceId,
        rawFields: [],
      });
    }

    if (extension === 'graphql') {
      fields = graphQLToFields({
        graphqlSchema: fileContentToCreate,
        fileId: fileId,
        workspaceId,
        rawFields: [],
      });
    }

    if (extension === 'proto') {
      fields = protobufToFields({
        protobufSchema: fileContentToCreate,
        fileId: fileId,
        workspaceId,
        rawFields: [],
      });
    }

    if (extension === 'prisma') {
      const formatResponse = await fetch('/rest/prisma-format', {
        method: 'POST',
        headers: {
          'accept-encoding': 'gzip, deflate, br',
          'content-type': 'application/json',
        },
        body: JSON.stringify({ prismaSchema: fileContentToCreate }),
      });
      const { prismaSchema } = await formatResponse.json();
      if (!prismaSchema) {
        if (showToast) {
          toast.error('Failed to format Prisma file');
          setIsLoading && setIsLoading(false);
          return;
        } else {
          return false;
        }
      }

      fileContentToCreate = prismaSchema;

      const [prismaFields, errors] = await prismaToFields({
        rawContent: prismaSchema,
        fileId: fileId,
        workspaceId,
        fieldData: [],
      });

      if (!prismaFields || errors) {
        console.error(errors);
        showToast && toast.error('Failed to convert Prisma file');
        setIsLoading && setIsLoading(false);
        return;
      }

      fields = prismaFields;
    }

    const file = new File([fileContentToCreate], fullFileName, {
      type: 'text/plain',
    });
    await uploadFile({
      workspaceId,
      fileId: fileId,
      fullFileName,
      file,
      commitHash: 'HEAD',
    });

    const newFields = fields?.map((field) => {
      const isArray = Array.isArray(field.exampleValue);
      const valueType = isArray ? 'array' : typeof field.exampleValue;
      const isObject = field.exampleValue && valueType === 'object';
      return {
        exampleValue: null,
        id: field.id,
        key: field.key,
        parentId: field.parentId,
        // TODO: we should extract this logic - fields to fieldinput
        type:
          extension === 'prisma' ||
          extension === 'graphql' ||
          extension === 'proto'
            ? field.type
            : valueType,
        workspaceId: workspaceId,
        commitHash: 'HEAD',
        line: field.line,
        meta: field.meta,
      };
    }) as unknown as LiveField[];

    const createFileResult = await createProjectFile({
      variables: {
        input: {
          id: projectFileId,
          projectId: projectId,
          workspaceId: workspaceId,
          fileId,
          file: {
            data: {
              id: fileId,
              extension,
              fileName: fileName ?? 'test',
              workspaceId: workspaceId,
              fields: {
                data: newFields.map((field) => {
                  return {
                    ...field,
                    fileId: fileId,
                    workspaceId: workspaceId,
                  };
                }),
              },
            },
          },
        },
      },
      refetchQueries: ['projectFileList'],
    });

    if (
      createFileResult.data?.createProjectFile?.__typename ===
      'CreateProjectFileSuccess'
    ) {
      showToast &&
        toast.success(
          'New file created, we are now redirecting you to your new file.'
        );
      try {
        posthog?.capture('FILE_CREATED', {
          workspaceId: workspaceId,
          projectId: projectId,
          source: source,
          fileType: extension,
        });
        isRedirect && router.push(`/?file=${fileId}`);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      if (createFileResult.data?.createProjectFile?.__typename === 'Error') {
        if (workspaceUser?.isGuest) {
          setShowGuestSignUp(true);
        } else {
          setShowUpgradeModal(true);
        }
        showToast &&
          toast.error(createFileResult.data?.createProjectFile.message);
      } else {
        showToast && toast.error('File creation failed!');
      }
      setIsLoading && setIsLoading(false);
      return false;
    }
  } catch (error) {
    console.error(error);
    showToast && toast.error('File creation failed!');
    setIsLoading && setIsLoading(false);
    return false;
  }
};

export const CreateFile = ({
  fileContent,
  fileUrl,
  label,
  projectId,
  workspaceId,
  fileName,
  extension,
  variant = 'regular',
  size = 'lg',
  className,
  template,
  img,
  id,
  source,
  isLoading,
  setIsLoading,
}: iCreateFile) => {
  const posthog = usePostHog();
  const { workspaceUser } = useWorkspaceUser();
  const setShowGuestSignUp = useUIUtilityStore(
    (state) => state.setShowGuestSignUp
  );
  const setShowUpgradeModal = useUIUtilityStore(
    (state) => state.setShowUpgradeModal
  );

  const [createProjectFile, createProjectFileState] = useMutation(
    CreateProjectFileDocument
  );
  const createNewFile = () => {
    createFileProcess({
      fileContent,
      fileUrl,
      fullFileName: fileName + '.' + extension,
      extension,
      workspaceId,
      projectId,
      setIsLoading,
      createProjectFile,
      fileName,
      posthog,
      source,
      workspaceUser,
      setShowGuestSignUp,
      setShowUpgradeModal,
    });
  };
  if (!template)
    return (
      <Button
        id={id}
        size={size}
        variant={variant}
        isDisabled={!fileContent && !fileUrl}
        isLoading={isLoading}
        onClick={createNewFile}
        className={className}
      >
        {label}
      </Button>
    );

  const extensionIcon = (extension: string, size?: string) => {
    switch (extension) {
      case 'json':
        return <JsonIcon className={size === 'lg' ? 'w-12 h-10' : 'w-7 h-5'} />;
      case 'graphql':
        return (
          <GraphqlIcon className={size === 'lg' ? 'w-10 h-10' : 'w-5 h-5'} />
        );
      case 'prisma':
        return (
          <PrismaIcon className={size === 'lg' ? 'w-10 h-10' : 'w-4 h-5'} />
        );
      case 'proto':
        return (
          <ProtoIcon className={size === 'lg' ? 'w-10 h-10' : 'w-9 h-5'} />
        );
      case 'xml':
        return <XmlIcon className={size === 'lg' ? 'w-10 h-10' : 'w-6 h-5'} />;
      case 'yaml':
        return (
          <OpenAPIIcon className={size === 'lg' ? 'w-10 h-10' : 'w-6 h-5'} />
        );
      default:
        return (
          <DocumentIcon className={size === 'lg' ? 'w-10 h-10' : 'w-5 h-5'} />
        );
    }
  };

  const isNotBlank = fileName !== 'blank';

  return (
    <div className="w-full overflow-hidden border rounded-sm cursor-pointer border-zinc-700 hover:bg-zinc-800">
      <div onClick={createNewFile}>
        {isLoading ? (
          <div className="flex flex-col w-full h-full overflow-hidden rounded-sm cursor-pointer hover:bg-zinc-900">
            <Skeleton className="w-full h-full rounded-none aspect-video" />
            <Skeleton className="w-full h-10 border-t rounded-none border-zinc-700" />
          </div>
        ) : (
          <div>
            {isNotBlank && (
              <>
                <img
                  src={img?.src}
                  className="w-full transition-all duration-200 ease-in-out border-b aspect-video border-zinc-700 hover:scale-105"
                  alt={img?.src ? label : ''}
                />
                <div className="flex items-center justify-between gap-2 p-3">
                  {extensionIcon(extension)}
                  <span className="w-full text-sm truncate">{label ?? ''}</span>
                </div>
              </>
            )}
            {!isNotBlank && (
              <div className="flex flex-col items-center justify-center gap-2 p-3 aspect-video">
                {extensionIcon(extension, (size = 'lg'))}
                {extension}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
