import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentIcon,
  FolderIcon,
  TableCellsIcon,
  TagIcon,
} from '@heroicons/react/20/solid';
import { useLiveStore } from '../state/stores/live';
import { useCommentStore } from '../state/stores/comments';
import { useFileStore } from '../state/stores/file';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Input } from '@hubql/hubqlkit';
import { useCenterView } from '../util/useCenterView';
import { CommentIndicator } from '../comments/CommentIndicator';
import { SuggestionIndicator } from '../files/SuggestionIndicator';

export const SidebarModels = () => {
  const fieldData = useLiveStore((state) => state.fields);
  const { handleCenterView } = useCenterView({
    centerWhenNotFound: false,
  });
  const [keyword, setKeyword] = useState('');
  // TODO - add functionality to each list item (show/hide, lock/unlock, set to viewport)
  //      - show model and table only in the list. No need to see the fields
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const fieldTags = useFileStore((state) => state.fieldTags);

  const handleFieldClick = (nodeId: string, parentId: string) => {
    setSelectedFieldId(nodeId);
    handleCenterView(nodeId, parentId);
  };

  const FieldList = ({ parentId, color }) => {
    const colorHover = `hover:bg-zinc-50/10`;
    const workMode = useFileStore((state) => state.workMode);
    const suggestions = useLiveStore((state) => state.suggestions);
    const canComment = workMode === 'VIEW' ? false : true;
    return (
      <div style={{ background: '#27272a40' }} className="rounded-b-md">
        {fieldData.map((item, index) => {
          const isActive = selectedFieldId === item.id;
          const fieldSuggestions =
            suggestions.filter(
              (suggest) => suggest.fileFieldId === item.id
            )?.[0]?.action ?? '';
          const hasComment = useCommentStore(
            useCallback(
              (state) => {
                if (!canComment) return false;
                return state.comments.find(
                  (comment) => comment.fieldId === item.id
                )
                  ? true
                  : false;
              },
              [canComment]
            )
          );

          return (
            item.parentId === parentId && (
              <div key={'model-list' + item.id + item.key + index}>
                <div
                  onClick={() => {
                    handleFieldClick(item.id, item.parentId ?? '');
                  }}
                  className={clsx(
                    'flex gap-2 items-center pl-7 pr-2 py-1.5 cursor-pointer',
                    colorHover,
                    isActive ? 'bg-zinc-50/20 text-zinc-50' : '',
                    keyword !== '' &&
                      item.key.toLowerCase().includes(keyword.toLowerCase())
                      ? 'bg-green-600/20'
                      : ''
                  )}
                >
                  <DocumentIcon className="w-4 h-4 ml-5 fill-zinc-400" />
                  <span id={`fieldList-${item.id}`} className="flex-1">
                    {item.key}
                  </span>
                  <div className="grid w-8 grid-cols-2 gap-2">
                    <CommentIndicator isActive={hasComment} />
                    <SuggestionIndicator
                      isActive={fieldSuggestions.length > 0}
                    />
                  </div>
                </div>
                <div className="pl-4">
                  <FieldList parentId={item.id} color={color} />
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };
  const ModelList = ({ model, color }) => {
    const color50 = color ? `${color}50` : '#27272a';
    const [open, setOpen] = useState(true);
    const handleClick = (model) => {
      handleFieldClick(model.id, model.parentId);
    };
    const Chevron = () => {
      const iconStyle = 'w-5 h-4 fill-zinc-50';
      return open ? (
        <ChevronUpIcon className={iconStyle} onClick={() => setOpen(false)} />
      ) : (
        <ChevronDownIcon className={iconStyle} onClick={() => setOpen(true)} />
      );
    };

    return (
      <div className="">
        <div
          className={clsx(
            'flex gap-2 items-center px-1 py-1.5 cursor-pointer hover:bg-zinc-800 select-none	'
          )}
          style={{ background: color50 }}
        >
          <FolderIcon
            className={clsx(
              'ml-5 w-4 h-4 stroke-zinc-200',
              open ? 'fill-none' : 'fill-zinc-200'
            )}
          />
          <span
            className={clsx(
              keyword !== '' &&
                model.key.toLowerCase().includes(keyword.toLowerCase())
                ? 'bg-zinc-600'
                : '',
              'flex-1'
            )}
            onClick={() => handleClick(model)}
          >
            {model.key}
          </span>
          {fieldData.filter((field) => field.parentId === model.id).length >
            0 && (
            <div className="cursor-pointer hover:bg-zinc-50/20">
              <Chevron />
            </div>
          )}
        </div>
        {open && <FieldList parentId={model.id} color={color} />}
      </div>
    );
  };

  const TagList = ({ models, tag }) => {
    const color = tag?.hexColor ?? '#27272a';
    const color25 = `${color}25`;
    const colorHover = `hover:bg-[${color}60]` ?? 'hover:bg-zinc-800';
    const [open, setOpen] = useState(true);
    const borderStyle = open ? 'rounded-b-0' : 'rounded-md';
    const handleClick = () => {
      setOpen(!open);
    };

    const Chevron = () => {
      const iconStyle = 'w-5 h-4 fill-zinc-50';
      return open ? (
        <ChevronUpIcon className={iconStyle} />
      ) : (
        <ChevronDownIcon className={iconStyle} />
      );
    };
    return (
      <div
        className={clsx('mb-2 rounded-b-md overflow-hidden text-[12px]')}
        style={{ background: color25 }}
      >
        <div
          className={clsx(
            'flex gap-2 items-center px-2 py-1.5 cursor-pointer rounded-t-md text-md select-none font-bold',
            colorHover,
            borderStyle
          )}
          style={{ background: color }}
          onClick={handleClick}
        >
          <TagIcon
            className={clsx(
              'w-4 h-4',
              tag?.hexColor ? 'fill-zinc-50' : 'fill-zinc-400'
            )}
          />
          {tag.name}
          <div className="flex-1" />
          {models.length > 0 && (
            <div className="cursor-pointer hover:bg-zinc-50/20">
              <Chevron />
            </div>
          )}
        </div>
        {open && (
          <div className="">
            {models.length > 0 &&
              models?.map((model) => {
                return (
                  <ModelList
                    key={tag.id + 'model' + model.id}
                    model={model}
                    color={color}
                  />
                );
              })}
          </div>
        )}
      </div>
    );
  };

  const findKeywordInField = (item) => {
    return fieldData?.some((field) => {
      return (
        field.parentId === item.id &&
        field.key.toLowerCase().includes(keyword.toLowerCase())
      );
    });
  };
  const findKeywordInFields = (item) => {
    const subFields = fieldData?.filter((field) => {
      return field.parentId === item.id;
    });
    return subFields.some((field) => {
      return findKeywordInField(field) || findKeywordInFields(field);
    });
  };

  const findKeywordInNestedFields = (item) => {
    return findKeywordInField(item) || findKeywordInFields(item);
  };
  const [open, setOpen] = useState(true);

  const Chevron = () => {
    const iconStyle = 'w-5 h-4 fill-zinc-50';

    return open ? (
      <ChevronUpIcon className={iconStyle} />
    ) : (
      <ChevronDownIcon className={iconStyle} />
    );
  };
  return (
    <div className="mt-4 text-zinc-50 px-2">
      <div className="px-1 mb-4">
        <Input
          placeholder="Search..."
          value={keyword}
          inputSize={'sm'}
          type="search"
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      <div
        id="sidebar-models"
        className="overflow-y-auto max-h-[calc(100vh-150px)] h-fit scrollbar rounded-sm ml-1"
      >
        {fieldTags?.map((tag) => {
          const models = fieldData?.filter((field) => {
            const hasKeyword =
              field.key.toLowerCase().includes(keyword.toLowerCase()) ||
              fieldData?.some((field2) => {
                return (
                  field2.parentId === field.id &&
                  field2.key.toLowerCase().includes(keyword.toLowerCase())
                );
              });
            return (
              !field.parentId && tag.fieldIds?.includes(field.id) && hasKeyword
            );
          });
          return (
            <TagList key={'tag-list' + tag.id} tag={tag} models={models} />
          );
        })}
        <div className="">
          <div
            className={clsx(
              'flex gap-2 items-center px-2 py-1 cursor-pointer hover:bg-zinc-800 rounded-t-md '
            )}
            onClick={() => setOpen(!open)}
          >
            <TableCellsIcon className={clsx('w-3 h-3', 'fill-zinc-400')} />
            <span className="flex-1">No tag</span>
            {fieldData.length > 0 && (
              <div className="cursor-pointer hover:bg-zinc-50/20">
                <Chevron />
              </div>
            )}
          </div>
          <div className="pl-0">
            {open && (
              <div>
                {fieldData?.map((item) => {
                  const hasKeyword =
                    keyword === ''
                      ? true
                      : item.key
                          .toLowerCase()
                          .includes(keyword.toLowerCase()) ||
                        findKeywordInNestedFields(item);
                  return (
                    !item.parentId &&
                    hasKeyword &&
                    !fieldTags?.some((tag) =>
                      tag.fieldIds?.includes(item.id)
                    ) && (
                      <ModelList
                        key={'empty-model-list' + item.id}
                        model={item}
                        color={null}
                      />
                    )
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
