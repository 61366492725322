import clsx from 'clsx';
interface Props {
  className?: string;
}
export const LineIcon = ({ className }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className)}
    >
      <path
        d="M12 5V20"
        stroke="current"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
