import { useCallback, useEffect, useRef, useState } from 'react';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { motion, useMotionValue } from 'framer-motion';
import clsx from 'clsx';

export const SplitPane = ({
  pane1,
  pane2,
  pane3,
  pane4,
  initialSize = window.innerWidth / 4,
}: {
  pane1?: any;
  pane2?: any;
  pane3?: any;
  pane4?: any;
  initialSize?: number;
}) => {
  const canvaRef = useRef(null);
  const innerCanva = useRef(null);
  const [newSize, setNewSize] = useState();

  const showModel = useUIUtilityStore((state) => state.showModel);
  const showComment = useUIUtilityStore((state) => state.showComment);
  const showCode = useUIUtilityStore((state) => state.showCode);
  const showHeader = useUIUtilityStore((state) => state.showHeader);
  const paneLeft = useUIUtilityStore((state) => state.paneLeft);
  const setPaneLeft = useUIUtilityStore((state) => state.setPaneLeft);
  const paneCode = useUIUtilityStore((state) => state.paneCode);
  const setPaneCode = useUIUtilityStore((state) => state.setPaneCode);
  const paneRight = useUIUtilityStore((state) => state.paneRight);
  const setPaneRight = useUIUtilityStore((state) => state.setPaneRight);
  const isSidebarActive = useUIUtilityStore((state) => state.isSidebarActive);

  useEffect(() => {
    setNewSize(innerCanva.current.clientWidth);
  }, [innerCanva]);

  return (
    <div
      ref={canvaRef}
      className={clsx(
        showHeader ? 'h-[calc(100vh-41px)]' : 'h-screen',
        `  flex flex-row w-screen flex-nowrap	`
      )}
    >
      {showModel && (
        <DragRight
          pane={pane1}
          initialSize={paneLeft}
          setPaneWidth={setPaneLeft}
          isHideSplitter={!isSidebarActive}
        />
      )}

      <motion.div
        style={{
          position: 'relative',
          height: '100%',
          flex: 1,
          width: newSize,
          zIndex: 0,
        }}
        ref={innerCanva}
      >
        <div
          className={clsx(
            showHeader ? 'h-[calc(100vh-41px)]' : 'h-screen',
            `  flex flex-row w-full`
          )}
        >
          {showCode && (
            <DragRight
              pane={pane2}
              initialSize={paneCode}
              setPaneWidth={setPaneCode}
            />
          )}
          <motion.div
            style={{
              position: 'relative',
              height: '100%',
              flex: 1,
              width: '100%',
              zIndex: 0,
            }}
          >
            {pane3}
          </motion.div>
        </div>
      </motion.div>

      {showComment && (
        <DragLeft
          pane={pane4}
          initialSize={paneRight}
          setPaneRight={setPaneRight}
        />
      )}
    </div>
  );
};

const DragRight = ({
  pane,
  initialSize,
  setPaneWidth,
  isHideSplitter,
}: {
  pane?: any;
  initialSize?: any;
  setPaneWidth?: any;
  isHideSplitter?: boolean;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [innerWidthSize, setInnerWidthSize] = useState(window.innerWidth);
  const mWidth = useMotionValue(initialSize);
  const sidebar = useUIUtilityStore((state) => state.showSidebar);
  const comment = useUIUtilityStore((state) => state.showComment);
  const [width, setWidth] = useState(window.innerWidth);
  const isSidebarActive = useUIUtilityStore((state) => state.isSidebarActive);

  useEffect(() => {
    mWidth.set(initialSize);
  }, [initialSize]);
  useEffect(() => {
    setInnerWidthSize(window.innerWidth);
  }, [mWidth, width]);

  const handleDrag = useCallback(
    (event: any, info: { delta: { x: number } }) => {
      const newWidth = mWidth.get() + info.delta.x;
      if (newWidth > 160 && newWidth < width) {
        mWidth.set(mWidth.get() + info.delta.x);
        setPaneWidth(mWidth.get());
      }
    },
    [mWidth, width]
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(innerWidthSize);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sidebar, comment, mWidth]);
  return (
    <>
      <motion.div
        className={`${
          isSidebarActive ? 'min-w-[280px]' : 'min-w-[45px]'
        }  z-0 w-full h-full relative pr-0 overflow-x-hidden`}
        style={{
          position: 'relative',
          height: '100%',
          width: mWidth,
          zIndex: 0,
        }}
      >
        {pane}
      </motion.div>

      {!isHideSplitter && (
        <motion.div
          className="relative h-full w-[1px] cursor-col-resize z-1 drop-shadow-lg flex justify-center items-center bg-zinc-700 hover:bg-zinc-600 hover:ring-1 hover:ring-zinc-600 px-[2px]"
          style={{
            position: 'relative',
            height: '100%',
          }}
          drag="x"
          dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
          dragElastic={0}
          dragMomentum={false}
          onDrag={handleDrag}
          onDragEnd={() => {
            setIsDragging(false);
          }}
          onDragStart={() => {
            setIsDragging(true);
          }}
        ></motion.div>
      )}
    </>
  );
};

const DragLeft = ({ pane, initialSize, setPaneRight }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [innerWidthSize, setInnerWidthSize] = useState(window.innerWidth);
  const mWidth = useMotionValue(initialSize);
  const sidebar = useUIUtilityStore((state) => state.showSidebar);
  const comment = useUIUtilityStore((state) => state.showComment);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setInnerWidthSize(window.innerWidth);
  }, [mWidth, width]);

  const handleDrag = useCallback(
    (event: any, info: { delta: { x: number } }) => {
      const newWidth = mWidth.get() - info.delta.x;
      if (newWidth > 160 && newWidth < width) {
        mWidth.set(mWidth.get() - info.delta.x);
        setPaneRight(mWidth.get());
      }
    },
    [mWidth, width]
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(innerWidthSize);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sidebar, comment, mWidth]);
  return (
    <>
      <motion.div
        className="relative h-full w-[1px] cursor-col-resize z-1 drop-shadow-lg flex justify-center items-center bg-zinc-700 hover:bg-zinc-600 hover:ring-1 hover:ring-zinc-600 px-[2px]"
        style={{
          position: 'relative',
          height: '100%',
        }}
        drag="x"
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        dragElastic={0}
        dragMomentum={false}
        onDrag={handleDrag}
        onDragEnd={() => {
          setIsDragging(false);
        }}
        onDragStart={() => {
          setIsDragging(true);
        }}
      ></motion.div>
      <motion.div
        className="min-w-[160px] z-0 w-full h-full relative pl-2"
        style={{
          position: 'relative',
          height: '100%',
          width: mWidth,
          zIndex: 0,
        }}
      >
        {pane}
      </motion.div>
    </>
  );
};
