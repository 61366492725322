import { useQuery } from '@apollo/client';
import { BuildingOfficeIcon, CheckIcon } from '@heroicons/react/20/solid';
import {
  WorkspaceMembershipsDocument,
  WorkspaceMembershipsQuery,
} from '@hubql/codegen';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Skeleton,
} from '@hubql/hubqlkit';
import clsx from 'clsx';
import { Clock3, CrownIcon } from 'lucide-react';
import router from 'next/router';
import { useState } from 'react';
import { UpgradeButton } from '../workspaces/UpgradeButton';

export const Account = ({ user, workspace, workspaceName }) => {
  const [selected, setSelected] = useState('');

  const planStyle = 'flex items-center gap-2 text-xs';
  const subscriptionLabel = (plan: any) => {
    switch (plan) {
      case 'TRIALING':
        return (
          <div className={planStyle}>
            <Clock3 className="w-4 h-4  stroke-yellow-500" />
            Trial | {workspaceName}
          </div>
        );
      case 'ACTIVE':
        return <div className={planStyle}>{workspaceName}</div>;
      default:
        return (
          <div className={planStyle}>
            <CrownIcon className="w-4 h-4 fill-yellow-500 stroke-yellow-500" />
            Try Schema Visualizer Pro | {workspaceName}
          </div>
        );
    }
  };

  const handleSelect = (workspace: any) => {
    setSelected(workspace?.name);
    router.push('/w/' + workspace?.slug);
  };

  const { loading, error, data } = useQuery<WorkspaceMembershipsQuery>(
    WorkspaceMembershipsDocument,
    {
      skip: !user.id,
      variables: {
        userId: user.id,
      },
    }
  );

  if (error) return <p>Error</p>;

  const workspaces = data?.workspaceMembers?.edges?.map(
    (workspaceMemberNode) => {
      if (!workspaceMemberNode) return null;
      const workspaceMember = workspaceMemberNode?.node;
      const currentWorkspace = workspace?.id === workspaceMember?.workspace.id;
      const currentClass = currentWorkspace
        ? 'text-zinc-50 fill-zinc-50'
        : 'text-zinc-400 fill-zinc-400';

      return (
        <DropdownMenuItem
          key={workspaceMember.id}
          onClick={() => handleSelect(workspaceMember.workspace)}
          className="text-xs w-full flex justify-start items-center gap-2 pl-2 pr-2 py-1.5 focus:ring-0 border-0  hover:bg-zinc-700 cursor-pointer rounded-md"
        >
          <BuildingOfficeIcon className={clsx('w-3.5 h-3.5', currentClass)} />
          <span className={clsx('flex-1 truncate', currentClass)}>
            {workspaceMember.workspace.name}
          </span>
          {currentWorkspace && <CheckIcon className="w-4 h-4" />}
        </DropdownMenuItem>
      );
    }
  );

  const messageStyle =
    'w-full flex flex-col items-center justify-center border border-zinc-700 rounded-sm bg-zinc-800 text-center gap-4 px-4 py-9 text-sm';

  const subsMessage = (plan: any) => {
    switch (plan) {
      case 'TRIALING':
        return (
          <div className={messageStyle}>
            Ready to unlock your workspace true potential full-time?
            <UpgradeButton
              workspaceId={workspace?.id}
              label="upgrade to PRO for 14€/user/month"
            />
            <a
              href="https://www.schemavisualizer.dev/pricing"
              className="underline text-sm"
              target="_blank"
              rel="noreferrer"
            >
              view plan detail
            </a>
          </div>
        );
      case 'ACTIVE':
        return null;
      default:
        return (
          <div className={messageStyle}>
            Like it so far? <br /> Get a taste of Schema Visualizer true power
            with Pro for 7 days for free
            <UpgradeButton
              workspaceId={workspace?.id}
              label="Try Schema Visualizer Pro"
            />
            <a
              href="https://www.schemavisualizer.dev/pricing"
              className="underline text-sm"
              target="_blank"
              rel="noreferrer"
            >
              view plan detail
            </a>
          </div>
        );
    }
  };

  const LoadingWorkspace = workspace ? (
    subscriptionLabel(workspace?.activeSubscription?.status)
  ) : (
    <Skeleton className="w-[120px] h-[16px]" />
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button
          variant="regular"
          className="h-[32px] bg-zinc-900 hover:bg-zinc-800"
        >
          {LoadingWorkspace}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className=" w-[360px]" align="center">
        <div className=" flex flex-col items-start gap-1 p-2">
          <DropdownMenuLabel>Your workspaces</DropdownMenuLabel>
          {subsMessage(workspace?.activeSubscription?.status)}
          <DropdownMenuSeparator />
          {workspaces}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
