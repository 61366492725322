import { EditorState, Extension } from '@codemirror/state';
import { keymap } from '@codemirror/view';
import { indentWithTab } from '@codemirror/commands';
import { basicSetup } from 'codemirror';
import { createTheme } from '@uiw/codemirror-themes';

export const baseExtensions: Extension[] = [
  basicSetup,
  keymap.of([indentWithTab]),
  EditorState.allowMultipleSelections.of(true),
  createTheme({
    theme: 'dark',
    settings: {
      foreground: '#fafafa',
      lineHighlight: '#37373A7F',
      gutterBackground: '#27272a',
      caret: '#ffffff',
    },
    styles: [],
  }),
];
