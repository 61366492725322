import type { NextRequest } from 'next/server';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand, S3ClientConfig } from '@aws-sdk/client-s3';
import { getFileConfig } from '@hubql/config';

export function getCommentMentions(comment: string): string[] {
  const regex = /!?\[([^\]]*)\]\(([^\)]+)\)/gm;
  const matches = comment.match(regex) ?? [];
  // const singleMatch = /\[([^\[]+)\]\((.*)\)/;
  const userIds: string[] = [];
  for (let i = 0; i < matches.length; i++) {
    // const text = regex.exec(matches[i]);
    //@ts-ignore
    const userId = matches[i]
      .split(':')
      .pop()
      .replace('user:', '')
      .replace(')', '');
    if (userId) {
      userIds.push(userId);
    }
  }
  const uniqueUserIds = [...new Set(userIds)];
  return uniqueUserIds;
}

const s3Config: S3ClientConfig = {
  credentials: {
    accessKeyId: process.env.APP_AWS_S3_ACCESS_KEY,
    secretAccessKey: process.env.APP_AWS_S3_SECRET_KEY,
  },
  region: 'eu-central-1',
  // TODO: can we enable this for prod?
  useAccelerateEndpoint:
    process.env.DOPPLER_ENVIRONMENT === 'development' ? true : false,
};
const s3Client = new S3Client(s3Config);
export const getSignedFileUrl = async ({
  workspaceId,
  fileId,
  fileName,
  commitHash,
}) => {
  const fileConfig = getFileConfig({
    workspaceId,
    fileId,
    fileName,
    commitHash,
  });
  const command = new GetObjectCommand({
    Bucket: fileConfig.Bucket,
    Key: fileConfig.Key,
  });
  const url = await getSignedUrl(s3Client, command, { expiresIn: 900 });
  return url;
};
