import { Button, Input, Tooltip } from '@hubql/hubqlkit';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { CreateFile } from '../files/CreateFile';
import {
  ArrowUpOnSquareIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import { usePostHog } from 'posthog-js/react';
import { getFileContent } from '../util/getFileContent';

export const UploadFile = ({ projectId, source }) => {
  const [file, setFile] = useState<File>();
  const [isDragging, setIsDragging] = useState(false);
  const { workspace } = useWorkspace();
  const posthog = usePostHog();

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [content, setContent] = useState<string>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleClick = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  };

  const [fileName, setFileName] = useState<string>();
  const [extension, setExtension] = useState<
    'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
  >();

  useEffect(() => {
    if (file) {
      getFileContent(file, (fileData) => {
        setFileName(fileData.fileName);
        setExtension(fileData.extension);
        setContent(fileData.fileContent);
        try {
          posthog?.capture('FILE_UPLOADED', {
            workspaceId: workspace?.id,
            fileName: fileData.fileName,
            source: source,
          });
        } catch (error) {}
      });
    }
  }, [file]);

  // Dragging Functions
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  if (!workspace?.id) return <></>;
  const nameLength = (fileName?.length ?? 0) + 3;

  return (
    <div
      className={`flex flex-col gap-2 items-center justify-center rounded-md  mt-4 max-w-5xl border border-zinc-700 
      ${isDragging ? 'border-dashed border-green-700' : ''}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        ref={inputFileRef}
        hidden={true}
        type="file"
        accept=".json,.xml,.prisma,.graphql,.proto,.yaml"
        onChange={handleFileChange}
      />
      {content && (
        <div className="w-full p-8 overflow-y-auto text-sm rounded-sm bg-zinc-800 max-h-64 scrollbar">
          <pre>{content}</pre>
        </div>
      )}

      {file && extension ? (
        <div
          className={clsx(
            'w-full flex gap-4 justify-between pb-3 mt-3 flex-col'
          )}
        >
          <div className="flex items-center gap-2 px-4">
            <div className="flex items-center ">
              <div className="flex items-center w-full gap-1 text-xs">
                Save file as:
                <Input
                  className={`min-w-[100px] max-w-2xl`}
                  width={'w-max'}
                  style={{ width: nameLength + 'ch' }}
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
                <span className="text-zinc-400">.{extension}</span>
              </div>
            </div>

            <CreateFile
              workspaceId={workspace?.id}
              workspaceSlug={workspace?.slug}
              fileContent={content}
              fileName={fileName ?? ''}
              label="Save"
              projectId={projectId}
              variant="green"
              size="md"
              extension={extension}
              source={'UPLOAD'}
              isLoading={false}
              setIsLoading={() => {
                //
              }}
            />
          </div>
          <div className="flex flex-col items-start px-4">
            <hr className="w-full py-2 border-zinc-700" />
            <div className="flex items-center gap-1">
              <p className="text-xs text-zinc-100">Not happy? Try again</p>

              <Button
                onClick={handleClick}
                size={'md'}
                variant="green"
                className="ml-2"
              >
                Upload
              </Button>
              <Tooltip
                message="Upload JSON, XML, Prisma, Protobuf, GraphQL or OpenAPI spec (YAML) files"
                id="upload-tip"
                place="right"
              >
                <QuestionMarkCircleIcon className="w-4 h-4 fill-zinc-400 hover:fill-zinc-300 cursor-help" />
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-2 py-8">
          <div className="flex flex-col items-center gap-1">
            {isDragging ? (
              <ArrowUpOnSquareIcon className={'w-6 h-6 fill-zinc-400'} />
            ) : null}
            <h3 className="text-md">
              {isDragging
                ? 'Drop your file here'
                : 'Upload or Drag your file here'}
            </h3>
            <span className="text-xs text-zinc-400">
              {!isDragging
                ? 'We currently only support JSON, XML, Prisma, Protobuf, GraphQL or OpenAPI spec (YAML) files'
                : ''}
            </span>
          </div>
          {!isDragging && (
            <Button onClick={handleClick} size={'md'} variant="green">
              Upload
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
