import { useFileStore } from '../state/stores/file';

export const FileWorkMode = () => {
  const workMode = useFileStore((state) => state.workMode);
  // TODO: reset workMode on file change
  return null;
  return workMode !== 'EDIT' ? (
    <span className="text-zinc-400 text-xs lowercase">You can: {workMode}</span>
  ) : null;
};
