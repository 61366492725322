import clsx from 'clsx';
interface Props {
  className?: string;
  variant?: 'show' | 'hide';
}
export const ZenIcon = ({ className, variant }: Props) => {
  return (
    <>
      {variant === 'hide' && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 19L21 19C21.5523 19 22 18.5523 22 18L22 6C22 5.44772 21.5523 5 21 5L3 5C2.44772 5 2 5.44772 2 6L2 18C2 18.5523 2.44772 19 3 19ZM21 21C22.6569 21 24 19.6569 24 18L24 6C24 4.34315 22.6569 3 21 3L3 3C1.34315 3 1.19392e-06 4.34315 1.04907e-06 6L0 18C-1.44845e-07 19.6569 1.34315 21 3 21L21 21Z"
            fill="current"
          />
        </svg>
      )}
      {variant === 'show' && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 19L21 19C21.5523 19 22 18.5523 22 18L22 6C22 5.44772 21.5523 5 21 5L3 5C2.44772 5 2 5.44772 2 6L2 18C2 18.5523 2.44772 19 3 19ZM21 21C22.6569 21 24 19.6569 24 18L24 6C24 4.34315 22.6569 3 21 3L3 3C1.34315 3 1.19392e-06 4.34315 1.04907e-06 6L0 18C-1.44845e-07 19.6569 1.34315 21 3 21L21 21Z"
            fill="current"
          />
          <path
            d="M21 19L3 19C2.44772 19 2 18.5523 2 18L2 6C2 5.44772 2.44772 5 3 5L21 5C21.5523 5 22 5.44772 22 6L22 18C22 18.5523 21.5523 19 21 19Z"
            fill="current"
          />
        </svg>
      )}
    </>
  );
};
