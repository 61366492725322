import { useQuery } from '@apollo/client';
import { FileCommentsDocument, FileCommentsQuery } from '@hubql/codegen';
import { useCommentStore } from '../state/stores/comments';
import { useFileStore } from '../state/stores/file';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/20/solid';
import { Button, Tooltip } from '@hubql/hubqlkit';

export const FileComments = ({ fileId }) => {
  const setComments = useCommentStore((state) => state.setComments);
  const workMode = useFileStore((state) => state.workMode);
  const setShowComment = useUIUtilityStore((state) => state.setShowComment);
  const showComment = useUIUtilityStore((state) => state.showComment);

  const { loading, error } = useQuery<FileCommentsQuery>(FileCommentsDocument, {
    variables: {
      fileId,
    },
    onCompleted: (data) => {
      //@ts-ignore
      setComments(data.fileComments?.edges?.map((edge) => edge.node) ?? []);
    },
  });

  if (workMode === 'VIEW' && !loading) return null;

  if (error) return <p>Error</p>;

  return (
    <Tooltip message={'Comments'}>
      <Button
        id="comment-sidebar"
        variant="regular"
        size={'md'}
        onClick={() => setShowComment(!showComment)}
        aria-label={'View Comments'}
        loadingLabel=""
        className="w-[32px] h-[32px]"
      >
        <ChatBubbleBottomCenterTextIcon className="w-4 h-4 fill-zinc-50" />
      </Button>
    </Tooltip>
  );
};
