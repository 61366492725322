import { useUIUtilityStore } from '@hubql/app-core';
import { AnimatePresence, motion } from 'framer-motion';
import { Sidebar } from './Sidebar';

export const MenuHoverAnimation = () => {
  const sidebarStatus = useUIUtilityStore((state) => state.showSidebar);
  const siderbarHover = useUIUtilityStore((state) => state.hoverSidebar);
  const toggleHoverSidebar = useUIUtilityStore(
    (state) => state.toggleHoverSidebar
  );

  const isSidebarOpen = siderbarHover;

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };

  const handleToggle = () => {
    !sidebarStatus && toggleHoverSidebar();
  };

  return (
    <>
      {!siderbarHover && (
        <div
          className="fixed h-screen w-[8px] left-0 top-[40px] z-10 opacity-0	"
          onMouseEnter={handleToggle}
        />
      )}

      <AnimatePresence>
        {isSidebarOpen ? (
          <motion.aside
            className="hover sidebar"
            initial={{
              width: '0px',
              x: '-240px',
              position: 'fixed',
              top: 40,
              left: 0,
              height: '100vh',
              zIndex: 4,
            }}
            animate={{
              width: '240px',
              x: '0px',
              zIndex: 4,
              position: 'fixed',
              transition: { duration: 0.3 },
            }}
            exit={{
              width: '0px',
              x: '-240px',
              zIndex: 4,
              position: 'fixed',
              transition: { duration: 0.3 },
            }}
          >
            <motion.div
              className="container"
              initial="open"
              animate="closed"
              exit="closed"
              variants={sideVariants}
            >
              <Sidebar />
            </motion.div>
          </motion.aside>
        ) : null}
      </AnimatePresence>
    </>
  );
};
