import { useQuery } from '@apollo/client';
import { UserProfile, useClerk, useUser } from '@clerk/nextjs';
import { BuildingOfficeIcon, CheckIcon } from '@heroicons/react/20/solid';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import {
  WorkspaceMembershipsDocument,
  WorkspaceMembershipsQuery,
} from '@hubql/codegen';
import { Modal, Portal } from '@hubql/shared-design-system';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { LogoIcon } from '@hubql/hubqlkit';
import { CreateWorkspace } from '../workspaces/CreateWorkspace';

export const Nav = () => {
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div className=" flex flex-row justify-start items-center">
        <Menu
          name={workspace?.name}
          id={workspace?.id}
          userId={workspaceUser?.id}
          setIsOpen={setIsOpen}
          workspace={workspace}
        />
      </div>
      {isOpen && (
        <Portal className="portal-root">
          <Modal>
            <>
              <div
                id="blur"
                className="w-screen h-screen top-0 left-0 z-0 fixed bg-zinc-900/90 backdrop-blur-sm"
                onClick={closeModal}
              />
              <div className=" flex min-h-full items-center justify-center p-4 text-center">
                <UserProfile />
              </div>
            </>
          </Modal>
        </Portal>
      )}
    </div>
  );
};

interface iMenu {
  name: string | undefined;
  id: string | undefined;
  userId: string | undefined;
  setIsOpen: (open: boolean) => void;
  workspace: any;
}

const Menu = ({ name, id, userId, setIsOpen, workspace }: iMenu) => {
  const [selected, setSelected] = useState('');
  const user = useUser();
  const { signOut } = useClerk();
  const router = useRouter();
  useEffect(() => {
    setSelected(name ?? '');
  }, [name]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleSelect = (workspace: any) => {
    setSelected(workspace.name);
    setOpen(false);
    router.push('/w/' + workspace.slug);
  };

  const { loading, error, data } = useQuery<WorkspaceMembershipsQuery>(
    WorkspaceMembershipsDocument,
    {
      skip: !userId,
      variables: {
        userId,
      },
    }
  );

  // if (loading) return <Spinner />;
  if (error) return <p>Error</p>;

  const workspaces = data?.workspaceMembers?.edges?.map(
    (workspaceMemberNode) => {
      if (!workspaceMemberNode) return null;
      const workspaceMember = workspaceMemberNode.node;
      const currentWorkspace = id === workspaceMember.workspace.id;
      const currentClass = currentWorkspace
        ? 'text-zinc-50 fill-zinc-50'
        : 'text-zinc-400 fill-zinc-400';
      return (
        <div
          key={workspaceMember.id}
          onClick={() => handleSelect(workspaceMember.workspace)}
          className="text-xs w-full flex justify-start items-center gap-2 pl-2 pr-2 py-1.5 focus:ring-0 border-0  hover:bg-zinc-700 cursor-pointer rounded-md"
        >
          <BuildingOfficeIcon className={clsx('w-3.5 h-3.5', currentClass)} />
          <span className={clsx('flex-1 truncate', currentClass)}>
            {workspaceMember.workspace.name}
          </span>
          {currentWorkspace && <CheckIcon className="w-4 h-4" />}
        </div>
      );
    }
  );

  const itemListClass =
    'text-xs w-full text-white flex justify-start items-center pl-2 py-1.5 focus:ring-0 border-0 hover:bg-zinc-700 cursor-pointer rounded-md';
  return (
    <div className="dropdown relative">
      <div
        onClick={handleOpen}
        className="flex items-center flex-row text-md w-full justify-between px-1 py-1 focus:ring-0 border-0 cursor-pointer hover:bg-zinc-700 rounded-lg"
      >
        <div className={'h-7 w-7'}>
          <LogoIcon className={'h-7 w-7'} />
        </div>
      </div>

      {open ? (
        <>
          <div
            id="blur"
            className="w-screen h-screen top-0 left-0 z-0 fixed"
            onClick={handleClickOutside}
          />
          <div className="menu absolute inset-auto top-[100%] bg-zinc-800 border border-zinc-700 mt-1 -left-0 w-[220px] z-20 shadow-xl rounded-sm p-1.5">
            <div className="flex text-xs w-full justify-between px-2 py-1 focus:ring-0 border-0  h-[40px] text-zinc-400  items-center">
              <span className="w-full truncate">
                {user.user?.primaryEmailAddress?.emailAddress}
              </span>
            </div>
            <hr className="border-zinc-600" />
            <div className="max-h-[100px] overflow-y-auto scrollbar my-1">
              {workspaces}
            </div>
            <hr className="border-zinc-600 mb-1" />
            <div className={itemListClass}>
              <CreateWorkspace
                variant="ghost"
                size="full"
                label="Create new workspace"
                textSize={'text-xs'}
                justify={'justify-start'}
              />
            </div>
            <div
              className={itemListClass}
              onClick={() => router.push(`/w/${workspace?.slug}/settings`)}
            >
              Workspace Settings & Billing
            </div>
            <div
              className={itemListClass}
              onClick={() => router.push(`/w/${workspace?.slug}/members`)}
            >
              Workspace members
            </div>
            <div
              className={itemListClass}
              onClick={async () => {
                await signOut({
                  redirectUrl: '/sign-in',
                });
                router.push('/sign-in');
              }}
            >
              Sign out
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
