import { useMutation } from '@apollo/client';
import { CreateSuggestionDocument } from '@hubql/codegen';
import {
  Button,
  DialogClose,
  Input,
  Option,
  Select,
  Spinner,
} from '@hubql/hubqlkit';
import clsx from 'clsx';
import router from 'next/router';
import { useEffect, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { v4 as uuidv4 } from 'uuid';
import { useLiveStore } from '../state/stores/live';
import { SelectType } from './SelectType';
import { usePostHog } from 'posthog-js/react';
import { useFileStore } from '../state/stores/file';

interface IsuggestInputs {
  initialKey: string;
  initialValue: string;
  initialType: string;
  fieldId: string | null;
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml';
  parentId: any;
  parentObjectKey: string;
  setIsDialogOpen?: any;
}

export const SuggestAddField = ({
  initialKey,
  initialValue,
  initialType,
  fieldId,
  extension,
  parentId,
  parentObjectKey,
  setIsDialogOpen,
}: IsuggestInputs) => {
  const posthog = usePostHog();

  const [key, setKey] = useState<string>(initialKey);
  const [exampleValue, setExampleValue] = useState<string>(initialValue);
  const [type, setType] = useState<string>(initialType);
  const [createSuggestion, createSuggestionState] = useMutation(
    CreateSuggestionDocument
  );
  const file_id = useFileStore((state) => state.fileId);
  const commit_hash = useFileStore((state) => state.commitHash);
  const suggestion = key ? 'CHANGE_KEY' : 'CHANGE_VALUE';
  const suggestions = useLiveStore((state) => state.suggestions);
  const fields = useLiveStore((state) => state.fields);
  const parentFields = fields?.filter((field) => !field.parentId);
  const setSuggestions = useLiveStore((state) => state.setSuggestions);
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();
  const newArr = [key, type];

  const returnKeyPressed = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !onclick) {
      !isInvalid && handleKey();
    }
  };

  // TODO - merge suggest key and value for new field suggestion
  const handleKey = async () => {
    if (!suggestion || !workspace || !workspaceUser) {
      return;
    }

    const fieldId = 'ff_' + uuidv4().replace(/-/gi, '');
    for (let index = 0; index < newArr.length; index++) {
      const uuid = uuidv4().replace(/-/gi, '');
      const id = 'sug_' + uuid;
      const newKey = {
        fileFieldFileId: file_id,
        fileFieldId: null,
        id: id,
        action: 'ADD',
        value: newArr[0],
        fileFieldCommitHash: commit_hash,
        workspaceId: workspace.id,
        suggestedBy: workspaceUser.id,
        type: 'KEY',
        meta: {
          newFieldId: fieldId,
          newFieldParentId: parentId,
        },
      };
      const newType = {
        fileFieldFileId: file_id,
        fileFieldId: null,
        id: id,
        action: 'ADD',
        value: newArr[1],
        fileFieldCommitHash: commit_hash,
        workspaceId: workspace.id,
        suggestedBy: workspaceUser.id,
        type:
          extension === 'json' || extension === 'xml' || extension === 'yaml'
            ? 'VALUE'
            : 'TYPE',
        meta: {
          newFieldId: fieldId,
          newFieldParentId: parentId,
        },
      };
      //@ts-expect-error types
      setSuggestions([...suggestions, newKey, newType]);
      await createSuggestion({
        variables: {
          input: newArr[index] === key ? newKey : newType,
        },
        refetchQueries: ['file'],
      });
      try {
        posthog?.capture('SUGGESTION_CREATED', {
          fileFieldFileId: file_id,
          fileFieldId: null,
          workspaceId: workspace.id,
          type: extension === 'json' ? 'VALUE' : 'TYPE',
        });
      } catch (error) {}
      setIsDialogOpen(false);
    }
  };

  useEffect(() => {
    setKey(initialKey);
    setExampleValue(initialValue);
    setType(initialType);
  }, [fieldId, initialKey, initialType, initialValue]);

  const isInvalid =
    initialKey + initialType + initialValue === key + type + exampleValue ||
    key === '' ||
    key === undefined ||
    type === '' ||
    type === undefined
      ? true
      : false;

  const SelectComp = () => {
    return (
      <SelectType
        extension={extension}
        setType={setType}
        parentFields={parentFields}
      />
    );
  };

  const CheckFileType = () => {
    switch (extension) {
      case 'prisma':
        return <SelectComp />;
      case 'proto':
        return <SelectComp />;
      case 'graphql':
        return <SelectComp />;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-col gap-2 py-2 " onKeyUp={returnKeyPressed}>
      <span className="text-sm">Add new field to: {parentObjectKey}</span>
      <div className="flex gap-2">
        <div className="grid gap-2 w-full grid-cols-2 justify-center">
          <input
            className={clsx(
              `w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 px-2 py-1 text-xs focus:border-zinc-400 focus:outline-none invalid:ring-1`
            )}
            id="input-key"
            placeholder={'Enter Key'}
            value={key}
            onChange={(e) => setKey(e.target.value)}
            autoComplete={'off'}
          />

          {extension === 'json' && (
            <input
              className="w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1 text-xs focus:border-zinc-400 focus:outline-none ring-zinc-200 invalid:ring-1"
              id="input-value"
              placeholder={'Enter value'}
              value={type}
              onChange={(e) => setType(e.target.value)}
              autoComplete={'off'}
            />
          )}

          {extension === 'xml' && (
            <input
              className="w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1 text-xs focus:border-zinc-400 focus:outline-none ring-zinc-200 invalid:ring-1"
              id="input-value"
              placeholder={'Enter value'}
              value={type}
              onChange={(e) => setType(e.target.value)}
              autoComplete={'off'}
            />
          )}
          {extension === 'yaml' && (
            <input
              className="w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1 text-xs focus:border-zinc-400 focus:outline-none ring-zinc-200 invalid:ring-1"
              id="input-value"
              placeholder={'Enter value'}
              value={type}
              onChange={(e) => setType(e.target.value)}
              autoComplete={'off'}
            />
          )}

          <CheckFileType />
        </div>
        <div className=" flex justify-end gap-2">
          <Button variant="green" isDisabled={isInvalid} onClick={handleKey}>
            {createSuggestionState.loading ? (
              <div className="flex flex-row gap-2">
                <Spinner /> <span>loading</span>
              </div>
            ) : (
              'Commit'
            )}
          </Button>
          <DialogClose asChild>
            <Button variant="ghost" className="px-2 py-1">
              Cancel
            </Button>
          </DialogClose>
        </div>
      </div>
    </div>
  );
};
