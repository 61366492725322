import { useQuery } from '@apollo/client';
import { useAuth } from '@clerk/nextjs';
import { createApolloClient } from '@hubql/apollo';
import {
  FileDocument,
  FileQuery,
  FindLastOrCreateHubDocument,
  FindLastOrCreateHubMutation,
  HubFilesDocument,
  HubFilesQuery,
} from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import {
  STORAGE_INDEX,
  generateGuestUser,
  getGuestIdFromCookie,
  saveUserCookie,
} from '../util/user';

export type FileDetailPageProps = {
  data: FileQuery;
  slugProp: string;
  workspaceData?: any;
  hubId?: string;
  errors?: string[];
};

export const useFilePage = () => {
  const router = useRouter();
  const fileId = router.query['file'] ?? router.query['id'];
  const hubId = router.query['hub'];
  const commitHash = router.query['ref'] ?? 'HEAD';
  const accessCode = router.query['access_code'];

  const [cookies, setCookie] = useCookies([STORAGE_INDEX]);

  const { getToken, isSignedIn, isLoaded } = useAuth();

  let guestId: string | null;
  if (!isSignedIn && isLoaded) {
    guestId = getGuestIdFromCookie(cookies);

    if (!guestId) {
      const newGuest = generateGuestUser();
      saveUserCookie(setCookie, newGuest);
      guestId = newGuest?.id;
    }
  }

  const client = useMemo(() => {
    return createApolloClient({
      getToken,
      skipTokenCache: false,
      guestId,
    });
  }, [isSignedIn, guestId]);

  const emptyPage = !hubId && !fileId;
  useEffect(() => {
    if (emptyPage && isLoaded) {
      const client = createApolloClient({
        getToken,
        skipTokenCache: false,
        guestId,
      });
      client
        .mutate<FindLastOrCreateHubMutation>({
          mutation: FindLastOrCreateHubDocument,
        })
        .then((res) => {
          if (
            res.data?.findLastOrCreateHub?.__typename ===
              'FindLastOrCreateHubSuccess' &&
            res.data.findLastOrCreateHub.data.id
          ) {
            router.push(`/?hub=${res.data.findLastOrCreateHub.data.id}`);
          }
        });
    }
  }, [isLoaded]);

  const hubQuery = useQuery<HubFilesQuery>(HubFilesDocument, {
    variables: {
      id: hubId,
      hubId,
      commitHash,
      useCommitHash: router.query['ref'] ? true : false,
    },
    skip: !hubId,
    client,
  });

  const fileQuery = useQuery<FileQuery>(FileDocument, {
    variables: {
      id: fileId,
      commitHash,
      accessCode,
    },
    skip: !fileId,
    client,
  });

  if (fileId) {
    return {
      loading:
        fileQuery.loading || hubQuery.loading || !isLoaded ? true : false,
      data: fileQuery?.data,
      hasFileAccess: fileQuery?.data?.file ? true : false,
      slugProp: fileQuery?.data?.file?.workspace?.slug ?? '',
      workspaceData: fileQuery?.data?.file?.workspace ?? null,
    };
  }
  if (hubId) {
    const fileData = hubQuery?.data?.projectFiles?.edges?.[0]?.node?.file
      ? {
          ...hubQuery?.data?.projectFiles?.edges?.[0]?.node?.file,
          fields:
            hubQuery?.data?.projectFiles?.edges?.[0]?.node?.file?.fields ??
            hubQuery?.data?.projectFiles?.edges?.[0]?.node?.file?.latestFields,
          projects: {
            edges: [
              {
                node: {
                  project: {
                    id: hubQuery?.data?.project?.id,
                    name: hubQuery?.data?.project?.name,
                  },
                },
              },
            ],
          },
        }
      : null;

    return {
      loading:
        fileQuery.loading || hubQuery.loading || !isLoaded ? true : false,
      data: fileData && hubQuery?.data?.project?.id ? { file: fileData } : null,
      hasFileAccess: true,
      slugProp:
        hubQuery?.data?.project?.workspace?.slug ??
        fileData?.workspace?.slug ??
        '',
      hubId: hubQuery?.data?.project?.id ?? null,
      workspaceData:
        hubQuery?.data?.project?.workspace ?? fileData?.workspace ?? null,
    };
  }

  return {
    loading: true,
  };
};
