import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';

import { useAuth, useSession, useUser } from '@clerk/nextjs';
import {
  STORAGE_INDEX,
  generateGuestUser,
  saveUserCookie,
  WorkspaceUser,
} from '../../util/user';
import { usePostHog } from 'posthog-js/react';

export const WorkspaceUserProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isLoaded, user, isSignedIn } = useUser();
  const { session } = useSession();
  const { getToken } = useAuth();
  const posthog = usePostHog();
  const [cookies, setCookie] = useCookies([STORAGE_INDEX]);

  const clerkToUser = (clerkUser): WorkspaceUser => {
    return {
      id: clerkUser?.['publicMetadata']?.['db_user_id'],
      name:
        clerkUser?.firstName && clerkUser?.firstName !== ''
          ? `${clerkUser?.firstName ?? ''} ${clerkUser?.lastName ?? ''}`
          : clerkUser?.username ?? '',
      email: clerkUser?.primaryEmailAddress?.emailAddress ?? null,
      onboardingCompletedAt:
        clerkUser?.['publicMetadata']?.onboardingCompletedAt,
      isGuest: false,
      picture: clerkUser?.picture,
    };
  };
  const [workspaceUser, setWorkspaceUser] = useState<WorkspaceUser | undefined>(
    clerkToUser(user)
  );

  const fetchToken = async () => {
    const latestToken = await getToken({ template: 'hasura', skipCache: true });
    const decoded = jwt_decode(latestToken ?? '');
    const newSessionId =
      decoded?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'];
    if (newSessionId && newSessionId !== workspaceUser?.id) {
      setUser({
        id: newSessionId,
        isGuest: false,
      });
    }
  };
  const checkSession = async () => {
    const sessionUserId = session?.user?.['publicMetadata']?.['db_user_id'];
    if (isSignedIn && isLoaded && !sessionUserId) {
      let fetchCount = 1;
      try {
        while (fetchCount < 20) {
          fetchCount++;
          fetchToken();
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
      } catch (error) {
        console.error('Failed to refetch token');
      }
    }
  };

  useEffect(() => {
    checkSession();
  }, [session]);

  const router = useRouter();

  const setUser = (newUser: WorkspaceUser) => {
    setWorkspaceUser(newUser);
    try {
      Sentry.setUser({ id: newUser.id });
    } catch (error) {
      console.error('Failed to set Sentry user');
    }
  };

  const trackUser = async (newUser: WorkspaceUser) => {
    try {
      posthog?.identify(newUser.id, {
        email: newUser.email,
        name: newUser.name,
      });

      const enableIntercom =
        router.query['intercom'] === '1' ||
        process.env['NEXT_PUBLIC_APP_ENV'] === 'production';

      if (enableIntercom) {
        const response = await fetch('/rest/analytics-auth', {
          method: 'POST',
          headers: {
            'accept-encoding': 'gzip, deflate, br',
            'content-type': 'application/json',
            ...(newUser?.isGuest && { 'x-hubql-guest-id': newUser?.id }),
          },
          body: null,
        });
        const userHMAC = await response.json();

        if (userHMAC?.hmac) {
          //@ts-ignore
          window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'wku86xdr',
            email: newUser.email,
            name: newUser.name,
            user_id: newUser.id,
            user_hash: userHMAC?.hmac,
            // https://www.intercom.com/help/en/articles/5181083-manage-multiple-email-lists-with-granular-subscriptions#h_ffa56a24ec
            // https://www.intercom.com/help/en/articles/320-tracking-user-data-in-intercom
            // companies: [
            // TODO sync with workspaces
            // ]
          });
        }
      }
    } catch (error) {
      console.error('Failed to init analytics', error);
    }
  };
  useEffect(() => {
    if (workspaceUser?.email) {
      trackUser(workspaceUser);
    }
  }, [workspaceUser]);

  useEffect(() => {
    if (!isLoaded) return;
    if (user?.['publicMetadata']?.['db_user_id']) {
      setUser(clerkToUser(user));
      return;
    }
    try {
      const storedGuest = cookies.hubql_guest;
      const parsedGuest = storedGuest ?? null;
      if (parsedGuest?.id) {
        setUser({
          ...parsedGuest,
          isGuest: true,
        });
        return;
      } else {
        const newGuest = generateGuestUser();
        saveUserCookie(setCookie, newGuest);
        setUser({
          ...newGuest,
        });
      }
    } catch (error) {
      console.error('Failed to set guest', error);
    }
  }, [router, isLoaded, user]);

  return (
    <WorkspaceUserContext.Provider
      value={{
        setWorkspaceUser: setWorkspaceUser,
        workspaceUser: workspaceUser,
      }}
    >
      {children}
    </WorkspaceUserContext.Provider>
  );
};

const WorkspaceUserContext = createContext<{
  workspaceUser: WorkspaceUser | undefined;
  setWorkspaceUser: (u: WorkspaceUser | undefined) => void;
}>({
  workspaceUser: undefined,
  setWorkspaceUser: () => {},
});

export const useWorkspaceUser = () => useContext(WorkspaceUserContext);
