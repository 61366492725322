import { useMutation } from '@apollo/client';
import {
  UpdateProjectNameDocument,
  UpdateProjectNameMutation,
} from '@hubql/codegen';
import { useProjectStore } from '../state/stores/project';
import { Input } from '@hubql/hubqlkit';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';

export const ProjectTitle = ({ projectId, defaultTitle }) => {
  const posthog = usePostHog();

  const [name, setName] = useState(defaultTitle);
  const project = useProjectStore((state) => state.project);
  const setProject = useProjectStore((state) => state.setProject);
  const [updateProjectTitle] = useMutation<UpdateProjectNameMutation>(
    UpdateProjectNameDocument
  );
  const updateTitle = async (newTitle: string) => {
    setProject({
      ...project,
      name: newTitle,
    });
    await updateProjectTitle({
      variables: {
        id: projectId,
        name: newTitle,
      },
    });
    try {
      posthog?.capture('HUB_NAME_CREATED', {
        projectId,
      });
    } catch (error) {}
    toast.success('Hub name changed!');
  };

  const handleOnblur = (newValue: string) => {
    if (newValue !== defaultTitle) updateTitle(newValue);
  };
  const nameLength = (name?.length ?? 0) + 3;
  return (
    <Input
      className={`min-w-ms max-w-2xl`}
      width={'w-max'}
      style={{ width: nameLength + 'ch' }}
      onChange={(e) => setName(e.target.value)}
      onBlur={(e) => {
        handleOnblur(e.target.value);
      }}
      value={name}
      placeholder="Untitled"
      variant="ghost"
      inputSize="lg"
    />
  );
};
