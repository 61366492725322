import { LanguageConfig } from '../HubqlEditor';

import { getFieldByLineNumber, getEditorPositionByFieldId } from './line';
import { convertRawContentToField } from './converter';

export const languageConfig: LanguageConfig = {
  language: 'text',
  extensions: [],
  getFieldByLineNumber,
  getEditorPositionByFieldId,
  convertRawContentToField,
};
