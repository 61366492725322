import clsx from 'clsx';
import { Tooltip } from '../Tooltip/Tooltip';

interface Props {
  className?: string;
  children: string | any;
  onClick: () => void;
  tabIndex: number;
  id?: string;
  variant?: string;
  tooltip?: string;
}

export const Tab = ({
  children,
  className,
  onClick,
  tabIndex,
  id,
  variant,
  tooltip,
}: Props) => {
  const tabStyle =
    tabIndex === -1 &&
    variant === 'regular' &&
    'text-zinc-400 bg-zinc-800 text-center flex justify-center items-center hover:bg-zinc-900/50';
  const activeStyle =
    tabIndex === 0 &&
    variant === 'regular' &&
    'bg-zinc-700 text-zinc-50 text-center flex justify-center items-center';

  const tabStyleGhost =
    tabIndex === -1 &&
    variant === 'ghost' &&
    'text-zinc-400 bg-transparent text-center flex justify-center items-center ';

  const activeStyleGhost =
    tabIndex === 0 &&
    variant === 'ghost' &&
    'bg-transparent text-zinc-50 text-center flex justify-center items-center outline outline-offset-2 outline-1 outline-zinc-400';

  const TabDetail = () => {
    return (
      <button
        id={id}
        className={clsx(
          tabStyle,
          activeStyle,
          tabStyleGhost,
          activeStyleGhost,
          className,
          'py-1.5 px-2 text-xs w-full rounded-sm'
        )}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        {children}
      </button>
    );
  };

  return (
    <li className="flex-1">
      {tooltip && (
        <Tooltip message={tooltip}>
          <TabDetail />
        </Tooltip>
      )}
      {!tooltip && <TabDetail />}
    </li>
  );
};
