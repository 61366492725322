import { useMutation } from '@apollo/client';
import { DeleteProjectDocument } from '@hubql/codegen';
import { Button } from '@hubql/hubqlkit';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';

interface iProps {
  id: string;
  name: string | undefined;
  closeModal: () => void;
  workspaceSlug: string;
}

export const ProjectDeleteModal = ({
  id,
  name,
  closeModal,
  workspaceSlug,
}: iProps) => {
  const posthog = usePostHog();

  const [deleteProject, deleteProjectState] = useMutation(
    DeleteProjectDocument
  );
  const router = useRouter();
  const handleDelete = async () => {
    await deleteProject({
      variables: {
        id,
      },
    });
    try {
      posthog?.capture('HUB_DELETED', {});
    } catch (error) {}
    closeModal();
    router.push('/w/' + workspaceSlug);
  };
  return (
    <div className="flex flex-col gap-2 itmes-start">
      <h2 className="text-xs">
        Do you really want to delete <strong>{name}</strong> ?
      </h2>
      <div className={'flex gap-2 mt-1 justify-end'}>
        <Button
          isDisabled={deleteProjectState.loading}
          onClick={closeModal}
          variant="ghost"
          size="lg"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          isLoading={deleteProjectState.loading}
          variant="red"
          size="lg"
        >
          Yes, let&apos;s delete
        </Button>
      </div>
    </div>
  );
};
