export { guessFileType } from './lib/guessFileType';
export { jsonToFields } from './lib/jsonToFieldsV2';
export { xmlToFields } from './lib/xmlToFields';
export { prismaToFields } from './lib/prismaToFields';
export { graphQLToFields, schemaToString } from './lib/graphqlToFields';
export { fieldsToFileStringJSON, fieldsToJSON } from './lib/fieldsToJson';
export { fieldsToFileStringXML, fieldsToXML } from './lib/fieldsToXml';
export { contentToFields } from './lib/contentToFields';
export { protobufToFields } from './lib/protobufToFields';
export { yamlToFields } from './lib/yamlToFields';
export { uploadFile } from './lib/uploadFile';
export { gitHashObject } from './lib/hashFile';
export type { IField } from './types';
export {
  renamePrismaField,
  renamePrismaType,
  addPrismaField,
} from './lib/updatePrisma';
export {
  addGraphQLField,
  renameGraphQLField,
  renameGraphQLType,
} from './lib/updateGraphQL';
export {
  addProtobufField,
  renameProtobufField,
  renameProtobufType,
} from './lib/updateProtobuf';
export { updateJSONKey, updateJSONValue, addJSONValue } from './lib/updateJSON';
export { updateXMLKey, updateXMLValue, addXMLValue } from './lib/updateXML';
