import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { WorkspaceName } from './WorkspaceName';
import clsx from 'clsx';
import Head from 'next/head';
import Link from 'next/link';
import router from 'next/router';

export const WorkspaceWrapper = ({ children }) => {
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();

  // TODO: move to backend
  const isWorkspaceMember = workspace?.members?.find(
    (m) => m.user?.id === workspaceUser?.id
  );
  if (!isWorkspaceMember && workspace) {
    return null;
  }

  return (
    <div className="wrapper flex-1 ml-0 overflow-auto h-screen scrollbar">
      <Head>
        <title>{workspace?.name}</title>
      </Head>
      <div className="w-full pt-4 pb-16 px-8">
        <div>
          <div className="flex flex-row items-center mb-2 justify-between gap-2">
            <WorkspaceName
              workspace={workspace}
              workspaceName={workspace?.name}
            />
          </div>

          <div>
            <NavBar />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const NavBar = () => {
  const { workspace } = useWorkspace();
  const isHome = router.asPath === `/w/${workspace?.slug}`;
  const isMembers = router.asPath.includes('members');
  const isSettings = router.asPath.includes('settings');
  const tabStyle =
    'text-zinc-400 bg-zinc-800 text-center flex justify-center items-center py-1.5 px-2 text-xs w-full rounded-sm hover:bg-zinc-900/50';
  const activeStyle =
    'bg-zinc-700 text-zinc-50 text-center flex justify-center items-center py-1.5 px-2 text-xs w-full rounded-sm hover:bg-zinc-700';
  return (
    <ul
      className={clsx(
        'flex items-center justify-start gap-1 rounded-sm bg-zinc-800 p-1 max-w-xs mb-4'
      )}
    >
      <li className="flex-1">
        <Link
          className={clsx(isHome ? activeStyle : tabStyle)}
          href={`/w/${workspace?.slug}`}
        >
          Home
        </Link>
      </li>
      <li className="flex-1">
        <Link
          className={clsx(isMembers ? activeStyle : tabStyle)}
          href={`/w/${workspace?.slug}/members`}
        >
          Members
        </Link>
      </li>
      <li className="flex-1">
        <Link
          className={clsx(isSettings ? activeStyle : tabStyle)}
          href={`/w/${workspace?.slug}/settings`}
        >
          Settings
        </Link>
      </li>
    </ul>
  );
};
