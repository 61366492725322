import {
  type ButtonHTMLAttributes,
  type ReactNode,
  MutableRefObject,
  useRef,
} from 'react';
import clsx from 'clsx';
import { Spinner } from '../Spinner/Spinner';
import styles from './Button.module.css';

type buttonVariant =
  | 'regular'
  | 'outlined'
  | 'ghost'
  | 'link'
  | 'green'
  | 'red'
  | 'shiny'
  | 'secondary'
  | 'purple';
type buttonSize = 'md' | 'lg' | 'sm' | 'xs' | 'icon' | 'full' | 'dropdown';
type buttonJustify =
  | 'justify-start'
  | 'justify-center'
  | 'justify-between'
  | 'justify-end';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  size?: buttonSize | string;
  variant?: buttonVariant | string;
  textSize?: string;
  font?: string;
  children?: ReactNode | JSX.Element;
  loadingLabel?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  justify?: buttonJustify | string;
  onClick?: (() => void) | ((e: any) => void) | (() => Promise<void>);
  className?: string;
}

export const Button = ({
  id = 'buttons',
  title,
  size = 'md',
  variant = 'outlined',
  children,
  className,
  isLoading = false,
  isDisabled = false,
  onClick,
  loadingLabel = 'loading',
  justify = 'justify-center',
  textSize = 'text-xs',
  font = 'font-medium',
  ...props
}: Props) => {
  const fullClasses =
    size === 'full' && ' py-0 w-full px-0 rounded-sm text-left';
  const lgClasses = size === 'lg' && 'px-4 py-2 text-sm rounded-sm';
  const mdClasses = size === 'md' && ' px-2 py-1 text-sm rounded-sm';
  const smallClasses = size === 'small' && ' px-3 py-1.5 rounded-sm';
  const smClasses = size === 'sm' && ' px-1 py-1 h-5 w-5 rounded-sm';
  const xsClasses = size === 'xs' && ' px-0.5 py-0.5 h-4 w-4 rounded-sm';
  const iconClasses = size === 'icon' && 'px-2 py-1 h-8 w-fit rounded-sm';
  const dropdownClasses =
    size === 'dropdown' && 'pl-2 pr-2 py-1 text-sm rounded-sm';

  const outlinedClasses =
    !isDisabled &&
    variant === 'outlined' &&
    'bg-zinc-900 border border-zinc-700 hover:bg-zinc-800 active:bg-zinc-900 focus:bg-zinc-800 text-zinc-50 shadow-sm  focus:outline-none';

  const regularClasses =
    !isDisabled &&
    variant === 'regular' &&
    ' bg-zinc-700 border hover:bg-zinc-600 active:bg-zinc-700 focus:bg-zinc-700 text-zinc-50 shadow-sm focus:outline focus:outline-2 focus:outline-zinc-500 border-none';

  const secondaryClasses =
    !isDisabled &&
    variant === 'secondary' &&
    ' bg-zinc-700 border hover:bg-zinc-600 active:bg-zinc-700 focus:bg-zinc-600 text-zinc-200 shadow-lg focus:outline-none border-none';

  const ghostClasses =
    !isDisabled &&
    variant === 'ghost' &&
    'text-zinc-50 hover:bg-zinc-700 active:bg-transparent focus:bg-zinc-800 bg-transparent';

  const linkClasses =
    !isDisabled &&
    variant === 'link' &&
    'text-brand-500 hover:underline disabled:no-underline';

  const greenClasses =
    !isDisabled &&
    variant === 'green' &&
    'bg-green-700 hover:bg-green-800 active:bg-green-700 focus:bg-green-800 text-zinc-50 fill-zinc-50 rounded-sm';

  const purpleClasses =
    !isDisabled &&
    variant === 'purple' &&
    'bg-purple-700 hover:bg-purple-800 active:bg-purple-700 focus:bg-purple-800 text-zinc-50 rounded-sm';

  const redClasses =
    !isDisabled &&
    variant === 'red' &&
    'bg-red-700 hover:bg-red-700/90 active:bg-red-600 text-zinc-50';

  const disabledClasses =
    isDisabled &&
    'bg-zinc-800 text-zinc-700 cursor-not-allowed ring-0 border-none hover:bg-zinc-800 hover:text-zinc-700 active:bg-zinc-800 active:text-zinc-700';

  const shinyClasses =
    !isDisabled &&
    variant === 'shiny' &&
    'button active:bg-zinc-900 active:ring-zinc-800 ring-0 focus:ring-0';

  const btnRef = useRef<HTMLButtonElement | null>(null);

  const onHover = (e: any) => {
    const button = btnRef.current;

    if (shinyClasses) {
      if (button !== null) {
        const rect = button.getBoundingClientRect(),
          x = e.clientX - rect.left,
          y = e.clientY - rect.top;

        button.style.setProperty('--mouse-x', `${x}px`);

        button.style.setProperty('--mouse-y', `${y}px`);
      }
    }
  };

  return (
    <button
      ref={btnRef}
      id={id}
      title={title}
      onMouseMove={(e) => onHover(e)}
      className={clsx(
        fullClasses,
        lgClasses,
        mdClasses,
        smallClasses,
        smClasses,
        xsClasses,
        dropdownClasses,
        regularClasses,
        secondaryClasses,
        outlinedClasses,
        ghostClasses,
        linkClasses,
        greenClasses,
        purpleClasses,
        redClasses,
        disabledClasses,
        shinyClasses,
        iconClasses,
        variant === 'shiny' && styles['button'],
        className,
        ' '
      )}
      {...props}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div
        className={clsx(
          'w-full flex items-center justify-between button-content ring-0 focus:ring-0 font-normal leading-relaxed flex-row gap-4',
          variant === 'shiny' && styles['button-content'],
          justify,
          textSize,
          font
        )}
      >
        {isLoading ? (
          <>
            <Spinner />
            <span className="ml-2 text-md  ">{loadingLabel}</span>
          </>
        ) : (
          children
        )}
      </div>
    </button>
  );
};
