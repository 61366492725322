import { parse } from 'yaml';
import { v4 as uuidv4 } from 'uuid';

import { IField } from '../types';

interface IYAMLToFields {
  yamlData: string;
  workspaceId: string;
  fileId: string;
  rawFields: any[];
}

export const yamlToFields = ({
  yamlData,
  workspaceId,
  fileId,
  rawFields,
}: IYAMLToFields) => {
  const rawObject = typeof yamlData === 'string' ? parse(yamlData) : yamlData;
  let line = 0;
  const fields: IField[] = [];

  // const fields = jsonToFields({
  //   fileId,
  //   workspaceId,
  //   jsonData: rawObject,
  //   rawFields,
  // })
  // return fields
  line++;
  const pathRawField = rawFields?.find(
    (raw) => raw.key === 'paths' && !raw.parentId
  );
  const pathId = pathRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
  fields.push({
    id: pathId,
    key: 'paths',
    type: 'object',
    exampleValue: null,
    workspaceId: workspaceId,
    fileId: fileId,
    parentId: null,
    suggestions: pathRawField?.suggestion,
    line,
    meta: null,
    positionX: pathRawField?.positionX,
    positionY: pathRawField?.positionY,
  });
  const tags: {
    [key: string]: any;
  } = {};
  Object.keys(rawObject?.paths ?? {})?.forEach((pathKey) => {
    line++;
    const rawField = rawFields?.find(
      (raw) => raw.key === pathKey && raw.parentId === pathId
    );
    const firstId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    const pathValue = rawObject.paths[pathKey];
    // fields.push({
    //   id: firstId,
    //   key: pathKey,
    //   type: 'object',
    //   exampleValue: null,
    //   workspaceId: workspaceId,
    //   fileId: fileId,
    //   parentId: pathId,
    //   suggestions: rawField?.suggestion,
    //   line,
    //   meta: null,
    //   positionX: rawField?.positionX,
    //   positionY: rawField?.positionY,
    // });
    Object.keys(pathValue).forEach((methodKey) => {
      line++;

      const methodValue = pathValue[methodKey];

      const firstTag = methodValue?.tags?.[0];

      let tagId = tags[firstTag];
      if (firstTag && !tagId) {
        line++;
        const tagRawField = rawFields?.find(
          (raw) => raw.key === firstTag && raw.parentId === pathId
        );
        tagId = tagRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
        tags[firstTag] = tagId;
        fields.push({
          id: tagId,
          key: firstTag,
          type: 'object',
          exampleValue: null,
          workspaceId: workspaceId,
          fileId: fileId,
          parentId: pathId,
          suggestions: tagRawField?.suggestion,
          line,
          meta: null,
          positionX: tagRawField?.positionX,
          positionY: tagRawField?.positionY,
        });
      }

      const parentId = tagId ?? pathId;
      const rawSubField = rawFields?.find(
        (raw) => raw.key === methodKey && raw.parentId === parentId
      );
      const nextId = rawSubField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');

      fields.push({
        id: nextId,
        key: methodKey + ' ' + pathKey,
        type: 'object',
        meta: methodValue,
        exampleValue: null,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: parentId,
        suggestions: rawSubField?.suggestion,
        line,
        positionX: rawSubField?.positionX,
        positionY: rawSubField?.positionY,
      });
    });
  });

  line++;
  const schemaRawField = rawFields?.find(
    (raw) => raw.key === 'schemas' && !raw.parentId
  );
  const schemaId = schemaRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
  // fields.push({
  //   id: schemaId,
  //   key: 'schemas',
  //   type: 'object',
  //   exampleValue: null,
  //   workspaceId: workspaceId,
  //   fileId: fileId,
  //   parentId: null,
  //   suggestions: schemaRawField?.suggestion,
  //   line,
  //   meta: null,
  //   positionX: schemaRawField?.positionX,
  //   positionY: schemaRawField?.positionY,
  // });
  Object.keys(rawObject?.components?.schemas ?? {})?.forEach((schemaKey) => {
    line++;
    const rawField = rawFields?.find(
      (raw) => raw.key === schemaKey && raw.parentId === schemaId
    );
    const firstId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    const pathValue = rawObject?.components?.schemas[schemaKey];
    fields.push({
      id: firstId,
      key: schemaKey,
      type: 'object',
      exampleValue: null,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      suggestions: rawField?.suggestion,
      line,
      meta: null,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
    });

    const sourceProperties = {
      ...(pathValue?.properties ?? {}),
      ...(pathValue?.allOf?.[0]?.properties ?? {}),
      ...(pathValue?.allOf?.[1]?.properties ?? {}),
    };

    Object.keys(sourceProperties).forEach((propertyKey) => {
      line++;

      const methodValue = sourceProperties[propertyKey];

      const rawSubField = rawFields?.find(
        (raw) => raw.key === propertyKey && raw.parentId === firstId
      );
      const nextId = rawSubField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');

      fields.push({
        id: nextId,
        key: propertyKey,
        type: methodValue?.type ?? 'object',
        meta: methodValue,
        exampleValue: null,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: firstId,
        suggestions: rawSubField?.suggestion,
        line,
        positionX: rawSubField?.positionX,
        positionY: rawSubField?.positionY,
      });
    });
  });

  return fields;
};
