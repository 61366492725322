import { ConvertRawContentToFieldFn } from '../HubqlEditor';
import { graphQLToFields } from '@hubql/file-util';

export const convertRawContentToField: ConvertRawContentToFieldFn = async (
  params
) => {
  const newFields = graphQLToFields({
    graphqlSchema: params.code,
    fileId: params.fileId,
    workspaceId: params.workspaceId,
    rawFields: params.fieldData,
  });

  if (newFields.length === 0) {
    return [null, [new Error('Could not parse GraphQL')]];
  }

  return [newFields, null];
};
