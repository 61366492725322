import Link from 'next/link';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { Button, Tooltip } from '@hubql/hubqlkit';
import router from 'next/router';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from '@hubql/hubqlkit';

export const WelcomeMessage = ({ showInstructions = false }) => {
  const isApp = router.asPath.includes('/w/');

  const HelpButton = () => {
    return (
      <div
        className={`w-[32px] h-[32px] flex items-center px-2 py-1 text-sm 
          rounded-sm shadow-sm cursor-pointer bg-zinc-700 hover:bg-zinc-600
          focus:bg-zinc-700 focus:outline-zinc-500`}
      >
        <QuestionMarkCircleIcon className="w-4 h-4 stroke-2 fill-zinc-50" />
      </div>
    );
  };

  return (
    <Dialog>
      <Tooltip message={'Need some help?'}>
        {/* @ts-ignore */}
        {!isApp ? (
          <Link
            href={'https://www.schemavisualizer.dev/docs'}
            target={'_blank'}
          >
            <HelpButton />
          </Link>
        ) : (
          <DialogTrigger>
            <HelpButton />
          </DialogTrigger>
        )}
      </Tooltip>

      <DialogContent className={clsx('px-8 py-6', !isApp && 'max-w-2xl', ' ')}>
        {isApp && showInstructions && <InstructionMessage />}
      </DialogContent>
    </Dialog>
  );
};

const InstructionMessage = ({}) => {
  return (
    <div className="w-full">
      <h2 className="text-2xl mb-4">Instructions</h2>
      <p className="text-zinc-400">
        Using Schema Visualizer is easy as Google Drive, GitHub or Notion with a
        similar experience.
      </p>
      <p className="text-zinc-400">
        Make sure to cover the following steps to get the full picture of Schema
        Visualizer:
      </p>
      <ol className="list-decimal mt-4 ml-4 flex flex-col gap-4 text-zinc-300">
        <li>Create a new private Hub to test first</li>
        <li>
          Add a file to your Hub by uploading or connecting your GitHub account
        </li>
        <li>Open a file</li>
        <li>To start collaborating with others share the file</li>
        <li>
          Share your file with others or invite them to your Hub to see all
          files
        </li>
      </ol>
      <div className="w-full flex justify-end mt-4">
        <DialogClose asChild>
          <Button size="md">Close</Button>
        </DialogClose>
      </div>
    </div>
  );
};
