import {
  LanguageSupport,
  LRLanguage,
  HighlightStyle,
  syntaxHighlighting,
} from '@codemirror/language';
import { parser } from './prisma.parser';
import { styleTags, tags as t } from '@lezer/highlight';
import { autocompletion } from '@codemirror/autocomplete';
import { prismaCompletion } from './prisma.autocomplete';

// Define a custom highlight style
const prismaHighlightStyle = HighlightStyle.define([
  { tag: t.keyword, color: '#c4b5fd' },
  { tag: t.className, color: '#ffffff' },
  { tag: t.lineComment, color: 'grey' },
  { tag: t.comment, color: 'grey' },
  { tag: t.attributeName, color: '#B55ABE' },
  { tag: t.propertyName, color: '#4ade80' },
  { tag: t.string, color: '#98c379' },
  { tag: t.typeName, color: '#c4b5fd' },
  { tag: t.variableName, color: '#4ade80' },
]);

// Define the Prisma language
export const prismaLanguage = LRLanguage.define({
  parser: parser.configure({
    props: [
      styleTags({
        DataSource: t.keyword,
        Generator: t.keyword,
        Keyword: t.keyword,
        Model: t.keyword,
        Enum: t.keyword,
        EnumValue: t.variableName,
        Directive: t.attributeName,
        Directives: t.attributeName,
        DirectiveName: t.keyword,
        LineComment: t.lineComment,
        Type: t.typeName,
        FieldName: t.propertyName,
        Value: t.string,
        ModelName: t.className,
      }),
    ],
  }),
  languageData: {
    commentTokens: { line: '//' },
    indentOnInput: /^\s*(?:\{[^}]*|\([^)]*)$/,
  },
});

// Export the Prisma language support
export function prisma() {
  return new LanguageSupport(prismaLanguage, [
    syntaxHighlighting(prismaHighlightStyle),
    autocompletion({ override: [prismaCompletion] }),
  ]);
}
