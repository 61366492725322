import { LanguageConfig } from '../HubqlEditor';
import { getFieldByLineNumber, getEditorPositionByFieldId } from './line';
import { convertRawContentToField } from './converter';
import { langs } from '@uiw/codemirror-extensions-langs';
import { theme } from './theme';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';

export const languageConfig: LanguageConfig = {
  language: 'xml',
  extensions: [langs.xml(), theme, vscodeDark],
  getFieldByLineNumber,
  getEditorPositionByFieldId,
  convertRawContentToField,
};
