import {
  type ReactNode,
  MutableRefObject,
  useRef,
  HTMLAttributes,
} from 'react';
import clsx from 'clsx';
import styles from './Card.module.css';

type buttonVariant =
  | 'regular'
  | 'outlined'
  | 'ghost'
  | 'link'
  | 'green'
  | 'red'
  | 'shiny'
  | 'secondary';
type buttonSize = 'md' | 'lg' | 'sm' | 'xs' | 'icon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  size?: buttonSize | string;
  variant?: buttonVariant | string;
  children?: ReactNode | JSX.Element;
  loadingLabel?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  ref?: MutableRefObject<undefined>;
  onClick?: (() => void) | ((e: any) => void) | (() => Promise<void>);
}

export const Card = ({
  title,
  size = 'md',
  variant = 'shiny',
  children,
  className,
  isLoading = false,
  isDisabled = false,
  onClick,
  loadingLabel = 'loading',
  ref,
  ...props
}: Props) => {
  const shinyClasses =
    !isDisabled &&
    variant === 'shiny' &&
    'card focus:bg-zinc-800 active:bg-zinc-900 active:ring-zinc-800 ring-0 focus:ring-0 text-xs rounded-sm p-2';
  const normalClasses =
    !isDisabled &&
    variant === 'normal' &&
    'hover:bg-zinc-800 focus:bg-zinc-800 active:bg-zinc-900 ring-0 focus:ring-0 text-xs rounded-none p-2 border-t border-zinc-700 ';

  const cardRef = useRef<HTMLDivElement>(null);

  const onHover = (e: any) => {
    const card = cardRef.current;

    if (shinyClasses) {
      if (card !== null) {
        const rect = card.getBoundingClientRect(),
          x = e.clientX - rect.left,
          y = e.clientY - rect.top;

        card.style.setProperty('--mouse-x', `${x}px`);

        card.style.setProperty('--mouse-y', `${y}px`);
      }
    }
  };

  return (
    <div
      ref={cardRef}
      id="cards"
      title={title}
      onMouseMove={(e) => onHover(e)}
      className={clsx(
        'font-medium cursor-pointer',
        shinyClasses,
        normalClasses,
        shinyClasses && styles['card']
      )}
      {...props}
      onClick={onClick}
    >
      <div
        className={clsx(
          'w-full flex card-content leading-none ring-0 focus:ring-0 font-normal',
          variant === 'shiny' && styles['card-content'],
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
