import { useUser } from '@clerk/nextjs';
import { Button, GitHubIcon, GoogleIcon, Spinner } from '@hubql/hubqlkit';
import router from 'next/router';
import { toast } from 'react-toastify';
export const AccountProfile = () => {
  const { user } = useUser();
  const connectWith = (strategy) => {
    user
      ?.createExternalAccount({
        strategy: strategy,
        redirectUrl: `/${router.asPath}`,
      })
      .then((externalAccount) => {
        const verify = externalAccount.verification;
        toast.success('Account connected!');
        router.replace(verify?.externalVerificationRedirectURL ?? '');
      })
      .catch((err) => {
        // handle error
      });
  };

  const hasGitHubProvider = user?.externalAccounts.find(
    (provider) => provider.provider === 'github'
  );
  const hasGoogleProvider = user?.externalAccounts.find(
    (provider) => provider.provider === 'google'
  );
  const atLeastOneAccount = user && user.externalAccounts.length > 1;

  const handleDestroy = (account) => {
    account.destroy();
    toast.success('Account removed successfully!');
  };

  return (
    <div className="w-full flex flex-col gap-4 items-center text-xs">
      {user && (
        <div className="w-full border border-zinc-700 rounded-lg p-4 flex flex-col gap-4 items-start">
          Connected Accounts
          {!hasGitHubProvider && (
            <Button onClick={() => connectWith('oauth_github')}>
              Connect with GitHub
            </Button>
          )}
          {!hasGoogleProvider && (
            <Button onClick={() => connectWith('oauth_google')}>
              Connect with Google
            </Button>
          )}
          {user?.externalAccounts.map((item) => {
            const showUserName = item.username
              ? item.username
              : item.emailAddress;

            const isVerified =
              item.verification?.status === 'verified' && showUserName;
            // TODO - improve refetch
            const notVerified = !isVerified && <Spinner />;

            return (
              <div
                key={item.id}
                className="w-full flex gap-4 items-center justify-between"
              >
                <div className="flex gap-4 items-center justify-start">
                  <div className="border border-zinc-700 rounded-sm">
                    {item.provider === 'google' && (
                      <GoogleIcon className="w-8 h-8" />
                    )}
                    {item.provider === 'github' && (
                      <div className="w-8 h-8 flex items-center justify-center p-2">
                        <GitHubIcon className="w-8 h-8" />
                      </div>
                    )}{' '}
                  </div>
                  <span className="capitalize">{item.provider}</span>
                  <span>
                    {isVerified}
                    {notVerified}
                  </span>
                </div>
                <span className="capitalize">{item.provider}</span>
                <span>
                  {isVerified}
                  {notVerified}
                </span>
                {atLeastOneAccount && (
                  <Button variant="red" onClick={() => handleDestroy(item)}>
                    Remove
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
