import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

export const theme = createTheme({
  theme: 'dark',
  settings: {
    foreground: '#fafafa',
    lineHighlight: '#27272a80',
    gutterBackground: '#27272a',
  },
  styles: [
    { tag: t.propertyName, color: '#4ade80' },
    { tag: t.comment, color: '#787b8099' },
    { tag: t.variableName, color: '#0080ff' },
    { tag: [t.string, t.special(t.brace)], color: '#c4b5fd' },
    { tag: t.number, color: '#bef264' },
    { tag: t.bool, color: '#fcd34d' },
  ],
});
