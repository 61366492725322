import { ConvertRawContentToFieldFn } from '../HubqlEditor';
import { xmlToFields } from '@hubql/file-util';

export const convertRawContentToField: ConvertRawContentToFieldFn = async ({
  fileId,
  workspaceId,
  fieldData,
  code,
}) => {
  const newFields = xmlToFields({
    fileId,
    workspaceId,
    xmlData: code,
    rawFields: fieldData,
  });

  const errors = newFields.length > 0 ? null : [new Error('Invalid XML')];

  return [newFields, errors];
};
