import { useMutation } from '@apollo/client';
import {
  InviteUsersToProjectDocument,
  UpdateProjectDefaultAccessDocument,
} from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useProjectStore } from '../state/stores/project';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  useModal,
} from '@hubql/hubqlkit';
import { FileShareDetails } from '../files/FileSharingDetails';
import { ProjectAccessItem } from './ProjectAccessItem';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';

export const ProjectSharing = () => {
  const posthog = usePostHog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { workspaceUser } = useWorkspaceUser();
  const router = useRouter();
  const { workspace } = useWorkspace();
  const projectId = router.query['hub'] as string;
  const [value, setValue] = useState([]);
  const [newAccess, setNewAccess] = useState('EDIT');
  const [sending, setSending] = useState(false);
  const [mode, setMode] = useState<'INVITE' | 'SEND'>('INVITE');
  const project = useProjectStore((state) => state.project);
  const setProject = useProjectStore((state) => state.setProject);
  const defaultAccess = project?.defaultAccess;
  const [saveDefaultAccess] = useMutation(UpdateProjectDefaultAccessDocument);
  const [inviteUserToProject] = useMutation(InviteUsersToProjectDocument);

  const updateProjectAccess = (newAccess: string) => {
    if (project) {
      setProject({
        ...project,
        defaultAccess: newAccess,
      });
    }
    saveDefaultAccess({
      variables: {
        defaultAccess: newAccess,
        id: projectId,
      },
    });
    try {
      posthog?.capture('HUB_DEFAULT_ACCESS_UPDATED', {
        defaultAccess: newAccess,
        projectId,
      });
    } catch (error) {}
  };

  const handleSend = async () => {
    if (!workspace?.id) return;
    setSending(true);
    const result = await inviteUserToProject({
      variables: {
        input: {
          projectId,
          projectRole: newAccess,
          userEmails: value,
          workspaceId: workspace?.id,
        },
      },
      refetchQueries: ['projectAccess', 'project'],
    });
    try {
      posthog?.capture('HUB_ACCESS_CREATED', {
        workspaceId: workspace?.id,
        projectId,
      });
    } catch (error) {}
    if (
      result?.data?.inviteUsersToProject?.__typename ===
      'InviteUsersToProjectSuccess'
    ) {
      toast.success('Done');
      setValue([]);
      setSending(false);
      setMode('INVITE');
      try {
        posthog?.capture('HUB_SHARED', {
          workspaceId: workspace?.id,
          projectId: projectId,
        });
      } catch (error) {}
    } else {
      console.error(result?.data?.inviteUsersToProject?.message);
      toast.error('Failed');
      setSending(false);
    }
  };
  const handleCopy = () => {
    // copy link
  };
  const handleCancelSend = () => {
    setMode('INVITE');
    setValue([]);
  };
  const validEmail = () => {
    return value.length > 0;
  };

  const isValidEmail = validEmail();
  useEffect(() => {
    if (isValidEmail) {
      setMode('SEND');
    }
  }, [isValidEmail]);

  const projectAccessData =
    project?.access?.edges?.map((node) => node?.node) ?? [];

  const isOwner = project?.ownedBy === workspaceUser?.id;
  const projectMemberList =
    projectAccessData?.length > 0 ? (
      projectAccessData.map((projectAccess) => {
        if (!projectAccess?.id) return null;
        return (
          <div key={projectAccess?.id}>
            <ProjectAccessItem
              id={projectAccess?.id}
              firstName={projectAccess?.user?.firstName}
              picture={projectAccess?.user?.picture}
              accessType={projectAccess?.accessType}
              allowChange={isOwner}
              invitationId={null}
            />
          </div>
        );
      })
    ) : (
      <p className="text-xs text-zinc-500">Not shared</p>
    );

  const invitations = project?.invitations?.map((invitation) => {
    if (!invitation?.id) return null;
    return (
      <div key={invitation?.id}>
        <ProjectAccessItem
          id={invitation?.id}
          firstName={invitation.email}
          picture={null}
          accessType={null}
          allowChange={false}
          invitationId={invitation?.id}
        />
      </div>
    );
  });
  const workspaceAccessData = workspace?.members ?? [];

  const workspaceMemberList =
    Number(workspaceAccessData?.length) > 0 &&
    workspaceAccessData
      .sort((a, b) => {
        if (a.role === 'OWNER') return -1;
        if (b.role === 'OWNER') return 1;
        return 1;
      })
      .map((workspaceAccess) => {
        const role = workspaceAccess?.role;
        const defaultAccess = workspaceAccess?.defaultAccess;
        return (
          <div
            key={workspaceAccess.id}
            className="flex justify-between items-center w-full my-2"
          >
            <Avatar
              src={workspaceAccess?.user?.picture}
              name={
                workspaceAccess?.user?.firstName +
                `${
                  workspaceAccess?.user?.id === workspaceUser?.id
                    ? ' (You)'
                    : ''
                }`
              }
            />
            <span className="text-xs text-zinc-400">
              {role} ({defaultAccess})
            </span>
          </div>
        );
      });

  const iconClasses = 'w-3 h-3 mr-2 fill-white';
  const icon =
    defaultAccess === 'WORKSPACE' ? (
      <UserPlusIcon className={iconClasses} />
    ) : (
      <UserPlusIcon className={iconClasses} />
    );

  const ownerName = project?.owner?.firstName;
  const projectOwner = (
    <div className="flex justify-between items-center w-full">
      <Avatar
        name={
          ownerName +
          `${project?.owner?.id === workspaceUser?.id ? ' (You)' : ''}`
        }
        src={project?.owner?.picture}
      ></Avatar>{' '}
      <span className="text-xs text-zinc-400">OWNER</span>
    </div>
  );
  const tabs = [
    {
      id: 'hub-tab-project-members',
      label: 'Hub members',
      content: (
        <div className="flex flex-col gap-2">
          {projectOwner}
          {projectMemberList}
          {invitations}
        </div>
      ),
    },
    {
      id: 'hub-tab-workspace-members',
      label: 'Workspace members',
      content: workspaceMemberList,
    },
  ];

  if (workspaceUser?.isGuest) return null;
  if (!project) return null;

  const hasMembers =
    projectAccessData.length > 0 ? projectAccessData.length + ' Members' : '';
  return (
    <div className="flex items-center">
      <Button
        onClick={() => setIsDialogOpen((state) => !state)}
        className="mr-2"
        variant="green"
      >
        Share {icon}
      </Button>

      <Button
        onClick={() => setIsDialogOpen((state) => !state)}
        className="mr-2"
        variant="ghost"
      >
        {hasMembers}
      </Button>

      <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
        <DialogContent
          className={
            'max-w-2xl p-4 bg-zinc-900 font-mono overflow-y-auto scrollbar border-zinc-700'
          }
        >
          <FileShareDetails
            tabs={tabs}
            fileName={project?.name}
            defaultAccess={defaultAccess}
            value={value}
            setValue={setValue}
            mode={mode}
            handleAccessSelect={updateProjectAccess}
            setNewAccess={setNewAccess}
            newAccess={newAccess}
            handleCancelSend={handleCancelSend}
            isValidEmail={isValidEmail}
            sending={sending}
            handleSend={handleSend}
            extension={''}
            allowDefaultAccess={isOwner}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
