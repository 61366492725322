import { useMutation } from '@apollo/client';

import {
  CreateCustomerPortalSessionDocument,
  UpgradeSubscriptionDocument,
} from '@hubql/codegen';
import { Button } from '@hubql/hubqlkit';

import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

export const UpgradeButton = ({
  workspaceId,
  label = 'Upgrade to Pro',
}: {
  workspaceId: string;
  label?: string;
}) => {
  const posthog = usePostHog();

  const [upgradeSubscription, upgradeSubscriptionState] = useMutation(
    UpgradeSubscriptionDocument
  );

  const upgrade = async () => {
    const session = await upgradeSubscription({
      variables: {
        workspaceId: workspaceId as string,
      },
    });
    if (
      session?.data?.upgradeSubscription?.__typename ===
      'UpgradeSubscriptionSuccess'
    ) {
      try {
        posthog?.capture('UPGRADE_CLICK', {
          workspaceId,
          plan: 'Pro',
        });
      } catch (error) {}
      window.location.assign(session?.data?.upgradeSubscription?.data);
    }
    return;
  };
  return (
    <Button
      isLoading={upgradeSubscriptionState.loading}
      onClick={upgrade}
      variant="green"
      className="w-fit py-2 pl-3"
    >
      {label} <ArrowRightIcon className="w-4 h-4" />
    </Button>
  );
};
