import { create } from 'zustand';
import { createClient } from '@liveblocks/client';
import { WithLiveblocks, liveblocks } from '@liveblocks/zustand';
import { FileComment, FileField, Suggestion } from '@hubql/codegen';

const client = createClient({
  throttle: 50,
  authEndpoint: async (room) => {
    try {
      const response = await fetch('/rest/live-auth', {
        method: 'POST',
        headers: {
          'accept-encoding': 'gzip, deflate, br',
          'content-type': 'application/json',
        },
        body: JSON.stringify({ room }),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },
});

export type Cursor = { x: number; y: number };
export type LiveField = FileField & {
  hidden?: boolean; // Current `hide` state of a field
  hideChildren?: boolean;
  fileId: string;
};

type State = {
  cursor: Cursor;
  setCursor: (cursor: Cursor) => void;
  selectedFieldId: null | string;
  setSelectedFieldId: (selectedFieldId: string | null) => void;
  fields: LiveField[];
  setFields: (fields: LiveField[]) => void;
  notes: FileComment[];
  setNotes: (notes: FileComment[]) => void;
  suggestions: Suggestion[];
  setSuggestions: (suggestions: Suggestion[]) => void;
  rawContent: string;
  setRawContent: (rawContent: string) => void;
  activeFieldColor: null | string;
  setActiveFieldColor: (activeFieldColor: string | null) => void;
  activeEdit: string | null;
  setActiveEdit: (activeEdit: string) => void;
  selectedSourceId: null | string;
  setSelectedSourceId: (selectedSourceId: string | null) => void;
  selectedTargetId: null | string;
  setSelectedTargetId: (selectedTargetId: string | null) => void;
  clickPosition: { x: number; y: number };
  setLastClickPosition: (position: { x: number; y: number }) => void;
  reset: () => void;
};

const initialState = {
  clickPosition: { x: 0, y: 0 },
  rawContent: '',
  selectedFieldId: null,
  selectedSourceId: null,
  selectedTargetId: null,
  activeFieldColor: null,
  fields: [],
  notes: [],
  suggestions: [],
  cursor: { x: 0, y: 0 },
  activeEdit: '',
};

type Presence = {
  cursor: Cursor;
  selectedFieldId: null | string;
  activeFieldColor: null | string;
};

export const useLiveStore = create<WithLiveblocks<State, Presence>>(
  liveblocks(
    (set) => ({
      ...initialState,
      setLastClickPosition: (clickPosition: { x: number; y: number }) =>
        set((state: State) => ({
          ...state,
          clickPosition,
        })),
      setRawContent: (rawContent: string) =>
        set((state: State) => ({
          ...state,
          rawContent,
        })),
      setSelectedFieldId: (selectedFieldId: string | null) =>
        set((state: State) => ({
          ...state,
          selectedFieldId,
        })),
      setSelectedSourceId: (selectedSourceId: string | null) =>
        set((state: State) => ({
          ...state,
          selectedSourceId,
        })),

      setSelectedTargetId: (selectedTargetId: string | null) =>
        set((state: State) => ({
          ...state,
          selectedTargetId,
        })),
      setActiveFieldColor: (activeFieldColor: string | null) =>
        set((state: State) => ({
          ...state,
          activeFieldColor,
        })),
      setFields: (fields: LiveField[]) => {
        return set((state: State) => ({
          ...state,
          fields,
        }));
      },

      setNotes: (notes: FileComment[]) =>
        set((state: State) => ({
          ...state,
          notes,
        })),
      setSuggestions: (suggestions: Suggestion[]) =>
        set((state: State) => ({
          ...state,
          suggestions,
        })),
      setCursor: (cursor: Cursor) => set({ cursor }),
      setActiveEdit: (activeEdit: string | null) =>
        set((state: State) => ({
          ...state,
          activeEdit,
        })),
      reset: () => set(initialState),
    }),
    {
      client,
      presenceMapping: {
        cursor: true,
        selectedFieldId: true,
        activeFieldColor: true,
      },
      storageMapping: {
        fields: true,
        rawContent: true,
        suggestions: true,
        notes: true,
      },
    }
  )
);
