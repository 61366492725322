import React, { useEffect } from 'react';
const debounce = require('lodash.debounce');

export const MenuPopover = ({ children, coords, updateTooltipCoords }) => {
  const updateCoords = debounce(updateTooltipCoords, 100);

  useEffect(() => {
    window.addEventListener('resize', updateCoords);
    return () => window.removeEventListener('resize', updateCoords);
  }, []);

  return <div style={{ ...styles.popover, ...coords }}>{children}</div>;
};

const styles = {
  popover: {
    position: 'absolute',
  },
};
