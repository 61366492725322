import { IField } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { xml2json } from 'xml-js';

interface IXmlToFields {
  xmlData: string;
  workspaceId: string;
  fileId: string;
  rawFields: any[];
}

const reAssignParentField = ({
  fields,
  id,
  newObject,
}: {
  fields: IField[];
  id: string;
  newObject: any;
}) => {
  const newFields = fields.map((field) => {
    if (field.id === id) {
      return {
        ...field,
        ...newObject,
      };
    }
    return field;
  });
  return newFields;
};

export const xmlToFields = ({
  xmlData,
  workspaceId,
  fileId,
  rawFields,
}: IXmlToFields) => {
  let raw: string | null;
  try {
    raw =
      typeof xmlData === 'string'
        ? xml2json(xmlData, { spaces: 2, compact: true })
        : xmlData;
  } catch (e) {
    console.error(e);

    return [];
  }

  const rawObject = typeof xmlData === 'string' ? JSON.parse(raw) : raw;
  delete rawObject._declaration;
  const jsonString = rawObject;
  let line = 0;
  let fields: IField[] = [];
  Object.keys(jsonString).forEach((k) => {
    const rawField = rawFields?.find((raw) => raw.key === k && !raw.parentId);
    const value = jsonString[k];
    const isArray = Array.isArray(value);
    const type = isArray ? 'array' : typeof value;
    line++;
    const firstId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    fields.push({
      id: firstId,
      key: k,
      type,
      exampleValue: value,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      suggestions: rawField?.suggestion,
      line,
      meta: null,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
    });
    if (value !== null && typeof value === 'object') {
      Object.keys(value).forEach((kk) => {
        const rawField2 = rawFields?.find(
          (raw) => raw.key === kk && raw.parentId === firstId
        );
        const value2 = value[kk];
        const isArray2 = Array.isArray(value2);
        const type2 = isArray2 ? 'array' : typeof value2;
        const secondId = rawField2?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
        //k + '.' + (isArray2 ? '[' : '') + kk + (isArray2 ? ']' : '');

        line++;
        if (kk !== '_text') {
          fields.push({
            id: secondId,
            type: type2,
            key: kk,
            exampleValue: value2,
            workspaceId: workspaceId,
            fileId: fileId,
            parentId: firstId,
            line,
            meta: null,
            positionX: rawField2?.positionX,
            positionY: rawField2?.positionY,
          });
        } else {
          fields = reAssignParentField({
            fields,
            id: firstId,
            newObject: {
              exampleValue: value2,
              type: type2,
            },
          });
        }
        if (value2 !== null && typeof value2 === 'object') {
          Object.keys(value2).forEach((kkk) => {
            const rawField3 = rawFields?.find(
              (raw) => raw.key === kkk && raw.parentId === secondId
            );
            const value3 = value2[kkk];
            const isArray3 = Array.isArray(value3);
            const type3 = isArray3 ? 'array' : typeof value3;
            const thirdId =
              rawField3?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
            // id + '.' + (isArray3 ? '[' : '') + kkk + (isArray3 ? ']' : '');
            line++;
            if (kkk !== '_text') {
              fields.push({
                id: thirdId,
                key: kkk,
                type: type3,
                exampleValue: value3,
                workspaceId: workspaceId,
                fileId: fileId,
                parentId: secondId,
                line,
                meta: null,
                positionX: rawField3?.positionX,
                positionY: rawField3?.positionY,
              });
            } else {
              fields = reAssignParentField({
                fields,
                id: secondId,
                newObject: {
                  exampleValue: value3,
                  type: type3,
                },
              });
            }
            if (value3 !== null && typeof value3 === 'object') {
              Object.keys(value3).forEach((kkkk) => {
                const rawField4 = rawFields?.find(
                  (raw) => raw.key === kkkk && raw.parentId === thirdId
                );
                const value4 = value3[kkkk];
                const array4 = Array.isArray(value4);
                const type4 = array4 ? 'array' : typeof value4;
                const fourthId =
                  rawField4?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
                // secondId +
                // '.' +
                // (array4 ? '[' : '') +
                // kkkk +
                // (array4 ? ']' : '');

                line++;
                if (kkkk !== '_text') {
                  fields.push({
                    id: fourthId,
                    key: kkkk,
                    type: type4,
                    exampleValue: value4,
                    workspaceId: workspaceId,
                    fileId: fileId,
                    parentId: thirdId,
                    line,
                    meta: null,
                    positionX: rawField4?.positionX,
                    positionY: rawField4?.positionY,
                  });
                } else {
                  fields = reAssignParentField({
                    fields,
                    id: thirdId,
                    newObject: {
                      exampleValue: value4,
                      type: type4,
                    },
                  });
                }
                if (value4 !== null && typeof value4 === 'object') {
                  Object.keys(value4).forEach((kkkkk) => {
                    const rawField5 = rawFields?.find(
                      (raw) => raw.key === kkkkk && raw.parentId === fourthId
                    );
                    const value5 = value4[kkkkk];
                    const fourthIsArray = Array.isArray(value5);
                    const type5 = fourthIsArray ? 'array' : typeof value5;
                    const fifthId =
                      rawField5?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
                    // thirdId +
                    // '.' +
                    // (fourthIsArray ? '[' : '') +
                    // kkkkk +
                    // (fourthIsArray ? ']' : '');
                    line++;
                    if (kkkkk !== '_text') {
                      fields.push({
                        id: fifthId,
                        key: kkkkk,
                        type: type5,
                        exampleValue: value5,
                        workspaceId: workspaceId,
                        fileId: fileId,
                        parentId: fourthId,
                        line,
                        meta: null,
                        positionX: rawField5?.positionX,
                        positionY: rawField5?.positionY,
                      });
                    } else {
                      fields = reAssignParentField({
                        fields,
                        id: fourthId,
                        newObject: {
                          exampleValue: value5,
                          type: type5,
                        },
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  });

  return fields;
};
