import { LanguageConfig } from '../HubqlEditor';
import { getFieldByLineNumber, getEditorPositionByFieldId } from './line';
import { convertRawContentToField } from './converter';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { linter } from '@codemirror/lint';
import { theme } from './theme';

export const languageConfig: LanguageConfig = {
  language: 'json',
  extensions: [json(), theme, linter(jsonParseLinter())],
  getFieldByLineNumber,
  getEditorPositionByFieldId,
  convertRawContentToField,
};
