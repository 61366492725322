import { useRouter } from 'next/router';
import { useSearchParam } from 'react-use';
import { useEffect, useMemo, useState } from 'react';
import { SignOutButton, useSession } from '@clerk/nextjs';
import {
  CreatePersonalAccessTokenDocument,
  PersonalAccessTokenExpiresAt,
} from '@hubql/codegen';
import { useMutation } from '@apollo/client';
import { Spinner } from '@hubql/hubqlkit';

const decodePayload = (
  payload: string
): {
  callback_url: string;
  app_version: string;
} => {
  return JSON.parse(Buffer.from(payload, 'base64').toString('ascii'));
};

type PageLifeCycle = 'INIT' | 'GETTING_TOKEN' | 'DONE';

export const SessionConfirms = () => {
  const router = useRouter();
  const payload = useSearchParam('payload');
  const [hasError, setHasError] = useState<string | null>(null);
  const [status, setStatus] = useState<PageLifeCycle>('INIT');
  const [hasSuccess, setHasSuccess] = useState<string | null>(null);
  const { session } = useSession();

  const [createPersonalAccessToken] = useMutation(
    CreatePersonalAccessTokenDocument
  );

  async function init() {
    if (!payload) {
      console.error('No payload found');
      return setHasError('PAYLOAD_NOT_FOUND');
    }

    if (session) {
      try {
        setStatus('GETTING_TOKEN');
        const patResult = await createPersonalAccessToken({
          variables: {
            name: 'Schema Visualizer CLI',
            expiresAt: PersonalAccessTokenExpiresAt.OneMonth,
          },
        });

        console.log(JSON.stringify(patResult, null, 2));

        if (patResult?.data?.createPersonalAccessToken.__typename === 'Error') {
          console.error(patResult?.data?.createPersonalAccessToken.message);
          setHasError('GENERATE_PAT_ERROR');

          return;
        }

        const tokenResult = patResult?.data?.createPersonalAccessToken.data;
        const email = session?.user?.primaryEmailAddress?.toString();
        if (!tokenResult) {
          console.error('No token found');
          setHasError('NO_TOKEN_FOUND');
          return;
        }

        const decodedPayload = decodePayload(payload);

        const url = new URL(decodedPayload.callback_url);
        url.searchParams.append('access_token_name', tokenResult.name);
        url.searchParams.append('access_token', tokenResult.token);
        if (email) {
          url.searchParams.append('email', email);
        }

        const callbackUrl = decodeURIComponent(url.toString());

        fetch(callbackUrl)
          .then(async (res) => {
            const resBody = await res.text();
            setStatus('DONE');
            setHasSuccess(resBody);
          })
          .catch((e) => {
            console.error(e);
            setHasError('FETCH_ERROR');
          });
      } catch (e) {
        console.error(e);
        setHasError('DECODE_ERROR');
      }
    }
  }

  useEffect(() => {
    init();
  }, [payload]);

  const statusText = useMemo(() => {
    if (status === 'GETTING_TOKEN') {
      return (
        <p className="flex items-center">
          <Spinner />
          <span className="ml-2 text-md  ">Generating access token...</span>
        </p>
      );
    }

    if (status === 'DONE') {
      return <p></p>;
    }

    return (
      <p>
        <p className="flex">
          <Spinner />
          <span className="ml-2 text-md  ">Initializing...</span>
        </p>
      </p>
    );
  }, [status]);

  return !payload || hasError ? (
    <div className="flex flex-col w-fill h-[80vh] items-center justify-center gap-4">
      <h1 className="text-lg">Something went wrong... </h1>
      <h2>CODE: {hasError}</h2>
      <p>
        {/* TODO: improve text */}
        Please try with <strong>Schema Visualizer login</strong> again or
        contact us at{' '}
        <a href={`mailto:support@schemavisualizer.dev`}>
          support@schemavisualizer.dev
        </a>
      </p>
      <SignOutButton redirectUrl={'/session-confirms'} />
    </div>
  ) : (
    <div className="flex flex-col w-fill h-[80vh] items-center justify-center gap-4">
      <h1 className="text-lg">Schema Visualizer</h1>
      <p>
        You are now logged in as{' '}
        {session?.user?.primaryEmailAddress?.toString()}
      </p>
      {statusText}
      {hasSuccess && <p>{hasSuccess}</p>}
    </div>
  );
};
