import { ArrowUpIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Button } from '@hubql/hubqlkit';
import { CommitChanges } from './CommitChanges';
import { CreatePR } from './CreatePR';
import { BranchSwitch } from './BranchSwitch';

export const GitChanges = ({
  commitMessage,
  setCommitMessage,
  prTitle,
  setPRTitle,
  canSave,
  isSaving,
  handleSave,
  isGitHubProject,
  prURL,
  closeModal,
  openModal,
  isChanged,
  openDiffEditor,
}) => {
  const tabs = [
    {
      id: 'tab-project',
      label: <ArrowUpIcon className="w-4" />,
      tooltip: 'Commit',
      content: (
        <CommitChanges
          commitMessage={commitMessage}
          setCommitMessage={setCommitMessage}
          canSave={canSave}
          isSaving={isSaving}
          handleSave={handleSave}
          isGitHubProject={isGitHubProject}
          prURL={isGitHubProject}
          openModal={openModal}
        />
      ),
    },

    {
      id: 'tab-project',
      label: <MagnifyingGlassIcon className="w-4" />,
      tooltip: 'Branch',
      content: <BranchSwitch />,
    },
    {
      id: 'tab-project',
      label: 'PR',
      tooltip: 'Create PR',
      content: (
        <CreatePR
          prTitle={prTitle}
          setPRTitle={setPRTitle}
          canSave={canSave}
          isSaving={isSaving}
          handleSave={handleSave}
          isGitHubProject={isGitHubProject}
          prURL={prURL}
        />
      ),
    },
  ];

  return (
    <>
      {/* TODO - Show current branch */}
      {/* <Tooltip
        message={
          'Tobias/hub 275 revokeremove users invited to a fileprojectworkspace 2'
        }
      >
        <div className="flex gap-1 truncate w-full cursor-default px-2">
          <SourceIcon w={'w-3'} h={'w-3'} />
         
          <h3 className="text-zinc-400 text-ellipsis overflow-hidden">
            Tobias/hub 275 revokeremove users invited to a fileprojectworkspace
            2
          </h3>
        </div>
      </Tooltip> */}

      {!isChanged && <span className="w-full text-center mt-4">No change</span>}
      {isChanged && (
        <>
          {!openDiffEditor && <Button onClick={openModal}>Show changes</Button>}
          {openDiffEditor && (
            <>
              <CommitChanges
                commitMessage={commitMessage}
                setCommitMessage={setCommitMessage}
                canSave={canSave}
                isSaving={isSaving}
                handleSave={handleSave}
                isGitHubProject={isGitHubProject}
                prURL={isGitHubProject}
                openModal={openModal}
              />
              <CreatePR
                prTitle={prTitle}
                setPRTitle={setPRTitle}
                canSave={canSave}
                isSaving={isSaving}
                handleSave={handleSave}
                isGitHubProject={isGitHubProject}
                prURL={prURL}
              />
              <Button
                isDisabled={canSave}
                isLoading={isSaving}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};
