import { ProjectListCards } from '../projects/ProjectListCards';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import clsx from 'clsx';
import Link from 'next/link';
import router from 'next/router';
import { WorkspaceWrapper } from './WorkspaceWrapper';

export const WorkspaceHome = () => {
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();

  // TODO: move to backend
  const isWorkspaceMember = workspace?.members?.find(
    (m) => m.user?.id === workspaceUser?.id
  );
  if (!isWorkspaceMember && workspace) {
    return null;
  }

  return (
    <WorkspaceWrapper>
      <ProjectListCards />
    </WorkspaceWrapper>
  );
};

const NavBar = () => {
  const { workspace } = useWorkspace();
  const isDetail = router.asPath === `/w/${workspace?.slug}`;
  const isMembers = router.asPath.includes('members');
  const isSettings = router.asPath.includes('settings');
  const isNeither = !isMembers && !isSettings;
  const tabStyle =
    'text-zinc-400 bg-zinc-800 text-center flex justify-center items-center py-1.5 px-2 rounded-sm text-xs w-full rounded-sm hover:bg-zinc-700/50';
  const activeStyle =
    'bg-zinc-700 text-zinc-50 text-center flex justify-center items-center hover:bg-zinc-700';

  return (
    <ul
      className={clsx(
        'flex items-center justify-start gap-1 rounded-sm bg-zinc-800 p-1 max-w-xs mb-4'
      )}
    >
      <li className="flex-1">
        <Link
          className={clsx(tabStyle, isDetail && activeStyle)}
          href={`/w/${workspace?.slug}`}
        >
          Home
        </Link>
      </li>

      <li className="flex-1">
        <Link
          className={clsx(tabStyle, isMembers && activeStyle)}
          href={`/w/${workspace?.slug}/members`}
        >
          Members
        </Link>
      </li>
      <li className="flex-1">
        <Link
          className={clsx(tabStyle, isSettings && activeStyle)}
          href={`/w/${workspace?.slug}/settings`}
        >
          Settings
        </Link>
      </li>
    </ul>
  );
};
