import { fieldsToFileStringJSON } from './fieldsToJson';

export const updateJSONKey = ({
  newKey,
  fields,
  fieldId,
}: {
  newKey: string;
  fields: any;
  fieldId: string;
}) => {
  const newFields = fields.map((field: any) => {
    if (field.id === fieldId) {
      return {
        ...field,
        key: newKey,
      };
    }
    return field;
  });
  return fieldsToFileStringJSON(newFields, 'json');
};

export const addJSONValue = ({
  fields,
  newField,
}: {
  fields: any;
  newField: any;
}) => {
  return fieldsToFileStringJSON([...fields, newField], 'json');
};

export const updateJSONValue = ({
  newValue,
  fields,
  fieldId,
}: {
  newValue: any;
  fields: any;
  fieldId: string;
}) => {
  const newFields = fields.map((field: any) => {
    if (field.id === fieldId) {
      return {
        ...field,
        exampleValue: newValue,
      };
    }
    return field;
  });
  return fieldsToFileStringJSON(newFields, 'json');
};
