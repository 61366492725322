interface Props {
  className?: string;
}
export const LogoIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(13.333333%,77.254902%,36.862745%)',
            fillOpacity: 1,
          }}
          d="M 15.824219 9.265625 L 11.011719 18.191406 L 8.019531 14.515625 L 12.636719 4.570312 Z M 15.824219 9.265625 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'evenodd',
            fill: 'rgb(13.333333%,77.254902%,36.862745%)',
            fillOpacity: 1,
          }}
          d="M 9 22.605469 L 13 24.960938 L 15.085938 23.730469 L 11.0625 18.78125 L 11.234375 18.46875 L 15.371094 23.5625 L 19.011719 21.417969 L 21.253906 17.257812 L 21.460938 17.5625 L 19.554688 21.101562 L 22.632812 19.285156 L 21.460938 17.5625 L 21.636719 17.238281 L 22.914062 19.121094 L 23.226562 18.9375 L 23.226562 14.289062 L 21.636719 17.238281 L 21.429688 16.933594 L 23.226562 13.601562 L 23.226562 6.890625 L 18.863281 4.320312 L 16.207031 9.246094 L 16 8.941406 L 18.582031 4.15625 L 14.0625 1.492188 L 12.796875 4.222656 L 12.582031 3.910156 L 13.78125 1.328125 L 13 0.867188 L 11.226562 1.910156 L 12.582031 3.910156 L 12.421875 4.253906 L 10.941406 2.078125 L 2.773438 6.890625 L 2.773438 8.054688 L 7.792969 14.234375 L 7.640625 14.5625 L 2.773438 8.574219 L 2.773438 18.9375 L 5 20.25 L 7.640625 14.5625 L 7.867188 14.84375 L 5.285156 20.414062 L 8.71875 22.4375 L 10.839844 18.503906 L 11.0625 18.78125 Z M 11.011719 18.191406 L 11.234375 18.46875 L 16.03125 9.570312 L 21.253906 17.257812 L 21.429688 16.933594 L 16.207031 9.246094 L 16.03125 9.570312 L 15.824219 9.265625 L 16 8.941406 L 12.796875 4.222656 L 12.636719 4.570312 L 12.421875 4.253906 L 7.792969 14.234375 L 8.019531 14.515625 L 7.867188 14.84375 L 10.839844 18.503906 Z M 11.011719 18.191406 "
        />
      </g>
    </svg>
  );
};
