import { ConvertRawContentToFieldFn } from '../HubqlEditor';
import { IField, jsonToFields } from '@hubql/file-util';

export const convertRawContentToField: ConvertRawContentToFieldFn = async ({
  code,
  fieldData,
  fileId,
  workspaceId,
}) => {
  try {
    const newObject = JSON.parse(code);
    const newFields = jsonToFields({
      fileId,
      workspaceId,
      jsonData: newObject,
      rawFields: fieldData as unknown as IField[],
    });

    const errors = newFields.length > 0 ? null : [new Error('Invalid JSON')];

    return [newFields, errors];
  } catch (e) {
    return [null, [e as Error]];
  }
};
