import { CommentItem } from './CommentItem';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useCommentStore } from '../state/stores/comments';
import { useFileStore } from '../state/stores/file';
import { useLiveStore } from '../state/stores/live';

export const CommentList = ({
  fieldId,
  threadId,
}: {
  fieldId: string | object | null | undefined;
  threadId: string | null;
}) => {
  const { workspaceUser } = useWorkspaceUser();
  const commentData = useCommentStore((state) => state.comments);
  const notes = useLiveStore((state) => state.notes);

  const commentsAndNotes = [...commentData, ...notes];
  const workMode = useFileStore((state) => state.workMode);

  if (workMode === 'VIEW' || !workspaceUser?.id) return null;
  const comments = commentsAndNotes.filter((comment) => {
    if (!threadId && comment.parentCommentId) return false;
    if (threadId && comment.parentCommentId !== threadId) return false;
    if (!fieldId) return comment;
    return comment.fieldId === fieldId;
  });
  const commentItems = comments?.map((comment) => {
    const replyCount =
      commentsAndNotes?.filter((item) => item.parentCommentId === comment.id)
        ?.length ?? 0;
    return (
      <CommentItem
        userId={workspaceUser?.id}
        key={comment.id}
        comment={comment}
        showThread={threadId ? false : true}
        replyCount={replyCount}
      />
    );
  });
  const commentCount = comments.length;

  if (!threadId && commentCount === 0)
    return (
      <div className=" mb-5">
        <span className="text-xs px-0.5 pt-1 text-center block w-full">
          Start a new discussion
        </span>
      </div>
    );
  if (threadId && commentCount === 0)
    return (
      <span className="text-xs px-0.5 pt-1 text-center">
        No replies here yet
      </span>
    );
  return (
    <div>
      <h4 className="mb-4">Discussion:</h4>
      <div className="text-zinc-300">{commentItems}</div>
    </div>
  );
};
