import {create} from 'zustand';

interface fileViewModeType {
  toggleFileViewMode: string;
  setToggleFileViewMode: (state: any) => void;
}

export const useToggleFileViewModeStore = create<fileViewModeType>((set) => ({
  toggleFileViewMode: 'NODES',
  setToggleFileViewMode: () =>
    set((state) => ({
      toggleFileViewMode:
        state.toggleFileViewMode === 'NODES' ? 'CODE' : 'NODES',
    })),
}));
