import {
  Spinner,
  StarsIcon,
  Tabs,
  TabsContent,
  TabsList,
  TabsNew,
  TabsTrigger,
} from '@hubql/hubqlkit';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useProjectStore } from '../state/stores/project';
import { UploadFile } from './UploadFile';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { ChooseTemplate } from './ChooseTemplate';
import { AskAi } from './AskAi';
import { useRouter } from 'next/router';
import { UploadFiles } from './UploadFiles';
import { useFileStore } from '../state/stores/file';
import { CustomEvents } from './CustomEvents';
import clsx from 'clsx';
import { FileData } from '../util/getFileContent';

export type Template = {
  fileContent: string;
  url: string;
  label: string;
  fileName: string;
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml';
  id: string;
  img: {
    src: string;
  };
};

export const EmptyProjectFileList = ({
  projectId,
  setFiles,
}: {
  projectId: string;
  setFiles?: Dispatch<SetStateAction<FileData[]>>;
}) => {
  const { workspace } = useWorkspace();
  const project = useProjectStore((state) => state.project);
  const fileProjectId = useFileStore((state) => state.projectId);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchTemplates = async () => {
    setLoading(true);
    const response = await fetch('/rest/templates', {
      method: 'GET',
      headers: {
        'accept-encoding': 'gzip, deflate, br',
        'content-type': 'application/json',
      },
    });
    const data = await response.json();
    setTemplates(data?.templates ?? []);
    setLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const router = useRouter();
  const hubId =
    (router.query['hub'] as string) ??
    fileProjectId ??
    projectId ??
    project?.id;
  if (!workspace) return null;

  const tabsStyle = 'text-left justify-start text-white text-sm';
  const tabTitleStyle = 'py-1 px-3 text-zinc-400 text-xs mt-4';

  return (
    <TabsNew
      className="flex flex-row items-start h-full "
      defaultValue={'tab-all-template'}
    >
      <TabsList
        className={clsx(
          'w-[280px] flex flex-col items-start h-full justify-start p-4'
        )}
      >
        <div className="px-3 py-1 text-lg text-white">New File</div>
        <div className={tabTitleStyle}>Start from scratch</div>
        <TabsTrigger value="tab-new-template" className={tabsStyle}>
          New empty file
        </TabsTrigger>
        <div className={tabTitleStyle}>Templates</div>
        <TabsTrigger value="tab-all-template" className={tabsStyle}>
          All
        </TabsTrigger>
        <TabsTrigger value="tab-json-template" className={tabsStyle}>
          JSON
        </TabsTrigger>
        <TabsTrigger value="tab-graphql-template" className={tabsStyle}>
          Graphql
        </TabsTrigger>
        <TabsTrigger value="tab-prisma-template" className={tabsStyle}>
          Prisma
        </TabsTrigger>
        <TabsTrigger value="tab-protobuf-template" className={tabsStyle}>
          Protobuf
        </TabsTrigger>
        <TabsTrigger value="tab-xml-template" className={tabsStyle}>
          XML
        </TabsTrigger>
        <TabsTrigger value="tab-xml-openapi" className={tabsStyle}>
          OpenAPI
        </TabsTrigger>
        <div className={tabTitleStyle}>Generate</div>
        <TabsTrigger value="tab-ask-ai" className={tabsStyle}>
          Ask AI
          <StarsIcon className="w-6 h-6 fill-green-500" />
        </TabsTrigger>
        <div className={tabTitleStyle}>Bring your own</div>
        <TabsTrigger value="tab-payload" className={tabsStyle}>
          Payloads
        </TabsTrigger>
        <TabsTrigger value="tab-upload" className={tabsStyle}>
          Upload file
        </TabsTrigger>
      </TabsList>
      <div className="w-full h-full px-8 py-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <TabsContent value="tab-new-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="blank"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-all-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="all"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-json-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="json"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-graphql-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="graphql"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-prisma-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="prisma"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-protobuf-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="proto"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-xml-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="xml"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
            <TabsContent value="tab-openapi-template">
              <ChooseTemplate
                projectId={hubId}
                workspace={workspace}
                filterTab="yaml"
                templates={templates}
                loading={loading}
              />
            </TabsContent>
          </>
        )}
        <TabsContent value="tab-ask-ai" className="">
          <AskAi projectId={hubId} workspace={workspace} />
        </TabsContent>
        <TabsContent value="tab-payload">
          <CustomEvents projectId={hubId} workspace={workspace} />
        </TabsContent>
        <TabsContent value="tab-upload">
          {setFiles ? (
            <UploadFiles
              projectId={hubId}
              source="Upload"
              setFiles={setFiles}
            />
          ) : (
            <UploadFile projectId={hubId} source="UPLOAD" />
          )}
        </TabsContent>
      </div>
    </TabsNew>
  );
};
