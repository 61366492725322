import path from 'path';
import { PostHog } from 'posthog-js';
import { Dispatch, SetStateAction } from 'react';

export type FileExtension =
  | 'json'
  | 'prisma'
  | 'graphql'
  | 'proto'
  | 'xml'
  | 'yaml';
export type FileData = {
  fileName: string;
  extension: FileExtension;
  fileContent: any;
};

export function getFileContent(
  file: File,
  setFile: (fileData: FileData) => void
) {
  const fileName = path
    .basename(file.name)
    .replace(path.extname(file?.name).replace('.', ''), '')
    .replace(/\.$/, '');
  const extension = path
    .extname(path.basename(file.name))
    .replace('.', '') as FileExtension;
  let fileContent: string;
  const reader = new FileReader();
  reader.readAsText(file, 'UTF-8');
  reader.onload = (evt) => {
    if (evt.target?.result) {
      fileContent = evt.target.result as string;
      setFile({ fileContent, fileName, extension });
    }
  };
  reader.onerror = function (evt) {
    console.error(evt);
  };
}
