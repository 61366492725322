import { useLiveStore } from '../state/stores/live';

export const useScrollToField = () => {
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);

  // Sidebar scroll function
  const handleScroll = (containerId: string) => {
    if (selectedFieldId) {
      try {
        const idToScrollTo = `fieldList-${selectedFieldId}`;
        const elementOfId = document.getElementById(idToScrollTo);
        const container = document.getElementById(containerId);

        if (elementOfId && container) {
          const elementHeight = elementOfId.offsetHeight;
          const containerHeight = container.clientHeight;
          const scrollPosition =
            elementOfId.offsetTop - containerHeight / 2 + elementHeight / 2;

          container.scrollTo({
            behavior: 'smooth',
            top: scrollPosition,
          });
        }
      } catch (error) {
        console.error(error, 'Scroll failed');
      }
    }
  };

  return { handleScroll };
};
