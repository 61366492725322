import { IField } from '../types';
import type { DMMF } from '@prisma/generator-helper';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import type { ExtractedPrismaErrors } from '@hubql/prisma/lib/extract-prisma-error';

interface IPrismaToFields {
  dmmf: DMMF.Document;
  workspaceId: string;
  fileId: string;
  rawFields: any[];
}

type ConvertFileArgs = {
  rawContent: string;
  fileId: string;
  workspaceId: string;
  fieldData: any;
  url?: string;
};
export const prismaToFields = async ({
  rawContent,
  fileId,
  workspaceId,
  fieldData,
  url,
}: ConvertFileArgs): Promise<
  [IField[] | null, ExtractedPrismaErrors | null]
> => {
  const response = await fetch(url ?? '/rest/prisma-dmmf', {
    method: 'POST',
    headers: {
      'accept-encoding': 'gzip, deflate, br',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ prismaSchema: rawContent }),
  });

  if (!response.ok) {
    const text = await response.text();
    return [
      null,
      {
        fullMessage: text,
        errors: [],
        errorCode: 'NETWORK_ERROR',
        errorCount: 1,
      },
    ];
  }

  const dmmf = await response.json();

  if (dmmf?.errors) {
    return [null, dmmf];
  }

  if (dmmf?.datamodel) {
    const newFields = prismaDMMFToFields({
      dmmf,
      fileId,
      workspaceId,
      rawFields: fieldData,
    });

    return [newFields, null];
  }

  return [fieldData, null];
};

export const prismaDMMFToFields = ({
  dmmf,
  workspaceId,
  fileId,
  rawFields,
}: IPrismaToFields) => {
  const fields: IField[] = [];
  let line = 0;
  dmmf?.datamodel?.models.forEach((model) => {
    line++;

    const rawField = rawFields?.find(
      (raw) => raw.key === model.name && !raw.parentId
    );
    const firstId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    fields.push({
      id: firstId,
      key: model.name,
      type: 'Model',
      exampleValue: undefined,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
      line,
      meta: {
        ...model,
        fields: undefined,
      },
    });

    model.fields.forEach((field) => {
      const rawModelField = rawFields?.find(
        (raw) => raw.key === field.name && raw.parentId === firstId
      );
      const id = rawModelField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
      line++;
      fields.push({
        id,
        key: field.name,
        type: field.type,
        exampleValue: undefined,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: firstId,
        line,
        positionX: rawModelField?.positionX,
        positionY: rawModelField?.positionY,
        meta: {
          ...field,
        },
      });
    });
  });
  dmmf?.datamodel?.enums.forEach((enumField) => {
    line++;
    const rawField = rawFields?.find(
      (raw) => raw.key === enumField.name && !raw.parentId
    );
    const enumId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    fields.push({
      id: enumId,
      key: enumField.name,
      type: 'Enum',
      exampleValue: undefined,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      line,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
      meta: {
        ...enumField,
        values: undefined,
      },
    });
    enumField.values.forEach((enumValue) => {
      const rawEnumField = rawFields?.find(
        (raw) => raw.key === enumValue.name && raw.parentId === enumId
      );
      const valueId = rawEnumField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
      line++;
      fields.push({
        id: valueId,
        key: enumValue.name,
        type: 'EnumValue',
        exampleValue: undefined,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: enumId,
        line,
        positionX: rawEnumField?.positionX,
        positionY: rawEnumField?.positionY,
        meta: {
          ...enumValue,
        },
      });
    });
  });
  return fields;
};
