import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSONObject: { input: any; output: any };
};

export type ApplyDiscountCodeResult = ApplyDiscountCodeSuccess | Error;

export type ApplyDiscountCodeSuccess = {
  __typename?: 'ApplyDiscountCodeSuccess';
  data: Scalars['Boolean']['output'];
};

export type CompleteUserOnboardingResult =
  | CompleteUserOnboardingSuccess
  | Error;

export type CompleteUserOnboardingSuccess = {
  __typename?: 'CompleteUserOnboardingSuccess';
  data: UserOnboarding;
};

export type ConditionArgsWhereArgs = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerPortalSessionResult =
  | CreateCustomerPortalSessionSuccess
  | Error;

export type CreateCustomerPortalSessionSuccess = {
  __typename?: 'CreateCustomerPortalSessionSuccess';
  data: Scalars['String']['output'];
};

export type CreateEndpointEventResult = CreateEndpointEventSuccess | Error;

export type CreateEndpointEventSuccess = {
  __typename?: 'CreateEndpointEventSuccess';
  data: EndpointEvent;
};

export type CreateEndpointResult = CreateEndpointSuccess | Error;

export type CreateEndpointSuccess = {
  __typename?: 'CreateEndpointSuccess';
  data: Endpoint;
};

export type CreateFieldTagResult = CreateFieldTagSuccess | Error;

export type CreateFieldTagSuccess = {
  __typename?: 'CreateFieldTagSuccess';
  data: FieldTag;
};

export type CreateFileAccessResult = CreateFileAccessSuccess | Error;

export type CreateFileAccessSuccess = {
  __typename?: 'CreateFileAccessSuccess';
  data: FileAccess;
};

export type CreateFileCommentResult = CreateFileCommentSuccess | Error;

export type CreateFileCommentSuccess = {
  __typename?: 'CreateFileCommentSuccess';
  data: FileComment;
};

export type CreateFileFieldResult = CreateFileFieldSuccess | Error;

export type CreateFileFieldSuccess = {
  __typename?: 'CreateFileFieldSuccess';
  data: FileField;
};

export type CreateFileFieldTagResult = CreateFileFieldTagSuccess | Error;

export type CreateFileFieldTagSuccess = {
  __typename?: 'CreateFileFieldTagSuccess';
  data: FileFieldTag;
};

export type CreateFileFieldsResult = CreateFileFieldsSuccess | Error;

export type CreateFileFieldsSuccess = {
  __typename?: 'CreateFileFieldsSuccess';
  data: Array<FileField>;
};

export type CreateFileFromCommitInput = {
  commitHash: Scalars['String']['input'];
  defaultAccess?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['String']['input'];
  installationId: Scalars['Int']['input'];
  projectId: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
  repositoryOwnerName: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type CreateFileFromCommitOutput = {
  __typename?: 'CreateFileFromCommitOutput';
  success: Scalars['Boolean']['output'];
};

export type CreateFileFromCommitResult = CreateFileFromCommitSuccess | Error;

export type CreateFileFromCommitSuccess = {
  __typename?: 'CreateFileFromCommitSuccess';
  data: CreateFileFromCommitOutput;
};

export type CreateFileFromUrlResult = CreateFileFromUrlSuccess | Error;

export type CreateFileFromUrlSuccess = {
  __typename?: 'CreateFileFromUrlSuccess';
  data: File;
};

export type CreateFileRevisionResult = CreateFileRevisionSuccess | Error;

export type CreateFileRevisionSuccess = {
  __typename?: 'CreateFileRevisionSuccess';
  data: FileRevision;
};

export type CreateHubRouteResult = CreateHubRouteSuccess | Error;

export type CreateHubRouteSuccess = {
  __typename?: 'CreateHubRouteSuccess';
  data: HubRoute;
};

export type CreatePersonalAccessTokenResult =
  | CreatePersonalAccessTokenSuccess
  | Error;

export type CreatePersonalAccessTokenSuccess = {
  __typename?: 'CreatePersonalAccessTokenSuccess';
  data: PersonalAccessTokenOutput;
};

export type CreatePrResult = CreatePrSuccess | Error;

export type CreatePrSuccess = {
  __typename?: 'CreatePrSuccess';
  data: PrCreateOutput;
};

export type CreateProjectFileResult = CreateProjectFileSuccess | Error;

export type CreateProjectFileSuccess = {
  __typename?: 'CreateProjectFileSuccess';
  data: ProjectFile;
};

export type CreateProjectRepositoryResult =
  | CreateProjectRepositorySuccess
  | Error;

export type CreateProjectRepositorySuccess = {
  __typename?: 'CreateProjectRepositorySuccess';
  data: ProjectRepository;
};

export type CreateProjectResult = CreateProjectSuccess | Error;

export type CreateProjectSuccess = {
  __typename?: 'CreateProjectSuccess';
  data: Project;
};

export type CreateRouteResult = CreateRouteSuccess | Error;

export type CreateRouteSuccess = {
  __typename?: 'CreateRouteSuccess';
  data: Route;
};

export type CreateSuggestionResult = CreateSuggestionSuccess | Error;

export type CreateSuggestionSuccess = {
  __typename?: 'CreateSuggestionSuccess';
  data: Suggestion;
};

export type CreateUserBookmarkResult = CreateUserBookmarkSuccess | Error;

export type CreateUserBookmarkSuccess = {
  __typename?: 'CreateUserBookmarkSuccess';
  data: UserBookmark;
};

export type CreateWorkspaceResult = CreateWorkspaceSuccess | Error;

export type CreateWorkspaceSuccess = {
  __typename?: 'CreateWorkspaceSuccess';
  data: Workspace;
};

export type DeleteFieldTagResult = DeleteFieldTagSuccess | Error;

export type DeleteFieldTagSuccess = {
  __typename?: 'DeleteFieldTagSuccess';
  data: FieldTag;
};

export type DeleteFileAccessResult = DeleteFileAccessSuccess | Error;

export type DeleteFileAccessSuccess = {
  __typename?: 'DeleteFileAccessSuccess';
  data: FileAccess;
};

export type DeleteFileCommentResult = DeleteFileCommentSuccess | Error;

export type DeleteFileCommentSuccess = {
  __typename?: 'DeleteFileCommentSuccess';
  data: FileComment;
};

export type DeleteFileCommentThreadResult =
  | DeleteFileCommentThreadSuccess
  | Error;

export type DeleteFileCommentThreadSuccess = {
  __typename?: 'DeleteFileCommentThreadSuccess';
  data: FileComment;
};

export type DeleteFileFieldTagResult = DeleteFileFieldTagSuccess | Error;

export type DeleteFileFieldTagSuccess = {
  __typename?: 'DeleteFileFieldTagSuccess';
  data: FileFieldTag;
};

export type DeleteFileResult = DeleteFileSuccess | Error;

export type DeleteFileSuccess = {
  __typename?: 'DeleteFileSuccess';
  data: File;
};

export type DeleteHubRouteResult = DeleteHubRouteSuccess | Error;

export type DeleteHubRouteSuccess = {
  __typename?: 'DeleteHubRouteSuccess';
  data: HubRoute;
};

export type DeleteProjectAccessResult = DeleteProjectAccessSuccess | Error;

export type DeleteProjectAccessSuccess = {
  __typename?: 'DeleteProjectAccessSuccess';
  data: ProjectAccess;
};

export type DeleteProjectResult = DeleteProjectSuccess | Error;

export type DeleteProjectSuccess = {
  __typename?: 'DeleteProjectSuccess';
  data: Project;
};

export type DeleteRouteResult = DeleteRouteSuccess | Error;

export type DeleteRouteSuccess = {
  __typename?: 'DeleteRouteSuccess';
  data: Route;
};

export type DeleteSuggestionResult = DeleteSuggestionSuccess | Error;

export type DeleteSuggestionSuccess = {
  __typename?: 'DeleteSuggestionSuccess';
  data: Suggestion;
};

export type DeleteUserBookmarkResult = DeleteUserBookmarkSuccess | Error;

export type DeleteUserBookmarkSuccess = {
  __typename?: 'DeleteUserBookmarkSuccess';
  data: UserBookmark;
};

export type DeleteWorkspaceMemberResult = DeleteWorkspaceMemberSuccess | Error;

export type DeleteWorkspaceMemberSuccess = {
  __typename?: 'DeleteWorkspaceMemberSuccess';
  data: WorkspaceMember;
};

export type DeleteWorkspaceResult = DeleteWorkspaceSuccess | Error;

export type DeleteWorkspaceSuccess = {
  __typename?: 'DeleteWorkspaceSuccess';
  data: Workspace;
};

export type Endpoint = Node & {
  __typename?: 'Endpoint';
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type EndpointCreateInput = {
  id: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type EndpointEvent = Node & {
  __typename?: 'EndpointEvent';
  endpoint: Endpoint;
  endpointId: Scalars['String']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
};

export type EndpointEventCreateInput = {
  endpointId: Scalars['String']['input'];
  event: EventCreateInput;
  id: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String']['output'];
};

export type Event = Node & {
  __typename?: 'Event';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  payload: Scalars['JSONObject']['output'];
};

export type EventCreateInput = {
  id: Scalars['String']['input'];
  payload: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type EventWhereArgs = {
  projectId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type FieldTag = Node & {
  __typename?: 'FieldTag';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  fieldIds: Array<Scalars['String']['output']>;
  hexColor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
};

export type FieldTagCreateInput = {
  hexColor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type FieldTagUpdateInput = {
  hexColor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type File = Node & {
  __typename?: 'File';
  access: FileAccessConnection;
  accessCode?: Maybe<Scalars['String']['output']>;
  comments: FileCommentsConnection;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  defaultAccess: Scalars['String']['output'];
  extension: Scalars['String']['output'];
  fields: FileFieldsConnection;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitations: Array<Invitation>;
  lastCommitHash: Scalars['String']['output'];
  latestFields: FileLatestFieldsConnection;
  nodeId: Scalars['ID']['output'];
  notes: FileNotesConnection;
  ownedBy: Scalars['String']['output'];
  owner: User;
  path?: Maybe<Scalars['String']['output']>;
  projects: FileProjectsConnection;
  signedUrl: Scalars['String']['output'];
  suggestions: FileSuggestionsConnection;
  tags: FileTagsConnection;
  userAccess: Scalars['String']['output'];
  workspace: Workspace;
};

export type FileAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileFieldOrderByInput>;
  where?: InputMaybe<FileFieldWhereInput>;
};

export type FileLatestFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileSignedUrlArgs = {
  commitHash?: InputMaybe<Scalars['String']['input']>;
};

export type FileSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  commitHash: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileAccess = Node & {
  __typename?: 'FileAccess';
  accessType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  file: File;
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId?: Maybe<Scalars['String']['output']>;
};

export type FileAccessConnection = {
  __typename?: 'FileAccessConnection';
  edges: Array<Maybe<FileAccessConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileAccessConnectionEdge = {
  __typename?: 'FileAccessConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileAccess;
};

export type FileAccessCreateInput = {
  accessType: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type FileAccessOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type FileAccessWhereInput = {
  userId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type FileArgs = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type FileComment = Node & {
  __typename?: 'FileComment';
  assignedTo?: Maybe<Scalars['String']['output']>;
  comment: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  fieldId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isComplete: Scalars['Boolean']['output'];
  isNote: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
  parentCommentId?: Maybe<Scalars['String']['output']>;
  positionX?: Maybe<Scalars['Float']['output']>;
  positionY?: Maybe<Scalars['Float']['output']>;
};

export type FileCommentCreateInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  fieldId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isNote?: InputMaybe<Scalars['Boolean']['input']>;
  parentCommentId?: InputMaybe<Scalars['String']['input']>;
  positionX?: InputMaybe<Scalars['Float']['input']>;
  positionY?: InputMaybe<Scalars['Float']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type FileCommentOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type FileCommentWhereInput = {
  fileId: ConditionArgsWhereArgs;
  isNote?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCommentsConnection = {
  __typename?: 'FileCommentsConnection';
  edges: Array<Maybe<FileCommentsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileCommentsConnectionEdge = {
  __typename?: 'FileCommentsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileComment;
};

export type FileCreateInput = {
  extension?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<FileFileFieldsCreateInput>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type FileField = Node & {
  __typename?: 'FileField';
  commitHash: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  line: Scalars['Int']['output'];
  meta?: Maybe<Scalars['JSONObject']['output']>;
  nodeId: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  positionX?: Maybe<Scalars['Float']['output']>;
  positionY?: Maybe<Scalars['Float']['output']>;
  suggestions: FileFieldSuggestionsConnection;
  type?: Maybe<Scalars['String']['output']>;
};

export type FileFieldSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type FileFieldCreateInput = {
  commitHash?: InputMaybe<Scalars['String']['input']>;
  exampleValue?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  line: Scalars['Int']['input'];
  meta?: InputMaybe<Scalars['JSONObject']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  positionX?: InputMaybe<Scalars['Float']['input']>;
  positionY?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type FileFieldOrderByInput = {
  line?: InputMaybe<OrderByArg>;
};

export type FileFieldSuggestionsConnection = {
  __typename?: 'FileFieldSuggestionsConnection';
  edges: Array<Maybe<FileFieldSuggestionsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileFieldSuggestionsConnectionEdge = {
  __typename?: 'FileFieldSuggestionsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Suggestion;
};

export type FileFieldTag = Node & {
  __typename?: 'FileFieldTag';
  commitHash: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  fieldId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  tag: FieldTag;
};

export type FileFieldTagCreateInput = {
  commitHash: Scalars['String']['input'];
  fieldId: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  hexColor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  tagId: Scalars['String']['input'];
  tagName?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type FileFieldWhereInput = {
  commitHash?: InputMaybe<ConditionArgsWhereArgs>;
};

export type FileFieldsConnection = {
  __typename?: 'FileFieldsConnection';
  edges: Array<Maybe<FileFieldsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileFieldsConnectionEdge = {
  __typename?: 'FileFieldsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileField;
};

export type FileFileFieldsCreateInput = {
  data: Array<FileFieldCreateInput>;
};

export type FileLatestFieldsConnection = {
  __typename?: 'FileLatestFieldsConnection';
  edges: Array<Maybe<FileLatestFieldsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileLatestFieldsConnectionEdge = {
  __typename?: 'FileLatestFieldsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileField;
};

export type FileNotesConnection = {
  __typename?: 'FileNotesConnection';
  edges: Array<Maybe<FileNotesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileNotesConnectionEdge = {
  __typename?: 'FileNotesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileComment;
};

export type FileProjectsConnection = {
  __typename?: 'FileProjectsConnection';
  edges: Array<Maybe<FileProjectsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileProjectsConnectionEdge = {
  __typename?: 'FileProjectsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ProjectFile;
};

export type FileRevision = Node & {
  __typename?: 'FileRevision';
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  previousRevision: Scalars['String']['output'];
  pullRequestNumber?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type FileRevisionCreateInput = {
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  previousRevision: Scalars['String']['input'];
  pullRequestNumber?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type FileSuggestionsConnection = {
  __typename?: 'FileSuggestionsConnection';
  edges: Array<Maybe<FileSuggestionsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileSuggestionsConnectionEdge = {
  __typename?: 'FileSuggestionsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Suggestion;
};

export type FileTagsConnection = {
  __typename?: 'FileTagsConnection';
  edges: Array<Maybe<FileTagsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type FileTagsConnectionEdge = {
  __typename?: 'FileTagsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FieldTag;
};

export type FindLastOrCreateHubResult = Error | FindLastOrCreateHubSuccess;

export type FindLastOrCreateHubSuccess = {
  __typename?: 'FindLastOrCreateHubSuccess';
  data: Project;
};

export type HubRoute = Node & {
  __typename?: 'HubRoute';
  createdAt: Scalars['DateTime']['output'];
  excludeFiles: Array<Scalars['String']['output']>;
  hubId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  includeFiles: Array<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  routeId: Scalars['String']['output'];
};

export type HubRouteCreateInput = {
  hubId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  routeId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type ImportFilesOutput = {
  __typename?: 'ImportFilesOutput';
  success: Scalars['Boolean']['output'];
};

export type ImportFilesResult = Error | ImportFilesSuccess;

export type ImportFilesSuccess = {
  __typename?: 'ImportFilesSuccess';
  data: ImportFilesOutput;
};

export type IncreaseAiRequestCountResult =
  | Error
  | IncreaseAiRequestCountSuccess;

export type IncreaseAiRequestCountSuccess = {
  __typename?: 'IncreaseAIRequestCountSuccess';
  data: Scalars['Boolean']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type InviteUserToFileInput = {
  fileCommitHash?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  fileRole: Scalars['String']['input'];
  userEmails: Array<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type InviteUserToProjectInput = {
  projectId: Scalars['String']['input'];
  projectRole: Scalars['String']['input'];
  userEmails: Array<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type InviteUserToWorkspaceInput = {
  userEmails: Array<Scalars['String']['input']>;
  workspaceAccess?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
  workspaceRole: Scalars['String']['input'];
};

export type InviteUsersToFileResult = Error | InviteUsersToFileSuccess;

export type InviteUsersToFileSuccess = {
  __typename?: 'InviteUsersToFileSuccess';
  data: FileAccess;
};

export type InviteUsersToProjectResult = Error | InviteUsersToProjectSuccess;

export type InviteUsersToProjectSuccess = {
  __typename?: 'InviteUsersToProjectSuccess';
  data: ProjectAccess;
};

export type InviteUsersToWorkspaceResult =
  | Error
  | InviteUsersToWorkspaceSuccess;

export type InviteUsersToWorkspaceSuccess = {
  __typename?: 'InviteUsersToWorkspaceSuccess';
  data: WorkspaceMember;
};

export type Mutation = {
  __typename?: 'Mutation';
  applyDiscountCode: ApplyDiscountCodeResult;
  completeUserOnboarding: CompleteUserOnboardingResult;
  createCustomerPortalSession: CreateCustomerPortalSessionResult;
  createEndpoint: CreateEndpointResult;
  createEndpointEvent: CreateEndpointEventResult;
  createFieldTag: CreateFieldTagResult;
  createFileAccess: CreateFileAccessResult;
  createFileComment: CreateFileCommentResult;
  createFileField: CreateFileFieldResult;
  createFileFieldTag: CreateFileFieldTagResult;
  createFileFields: CreateFileFieldsResult;
  createFileFromCommit: CreateFileFromCommitResult;
  createFileFromUrl: CreateFileFromUrlResult;
  createFileRevision: CreateFileRevisionResult;
  createHubRoute: CreateHubRouteResult;
  createPersonalAccessToken: CreatePersonalAccessTokenResult;
  createPr: CreatePrResult;
  createProject: CreateProjectResult;
  createProjectFile: CreateProjectFileResult;
  createProjectRepository: CreateProjectRepositoryResult;
  createRoute: CreateRouteResult;
  createSuggestion: CreateSuggestionResult;
  createUserBookmark: CreateUserBookmarkResult;
  createWorkspace: CreateWorkspaceResult;
  deleteFieldTag: DeleteFieldTagResult;
  deleteFile: DeleteFileResult;
  deleteFileAccess: DeleteFileAccessResult;
  deleteFileComment: DeleteFileCommentResult;
  deleteFileCommentThread: DeleteFileCommentThreadResult;
  deleteFileFieldTag: DeleteFileFieldTagResult;
  deleteHubRoute: DeleteHubRouteResult;
  deleteProject: DeleteProjectResult;
  deleteProjectAccess: DeleteProjectAccessResult;
  deleteRoute: DeleteRouteResult;
  deleteSuggestion: DeleteSuggestionResult;
  deleteUserBookmark: DeleteUserBookmarkResult;
  deleteWorkspace: DeleteWorkspaceResult;
  deleteWorkspaceMember: DeleteWorkspaceMemberResult;
  findLastOrCreateHub: FindLastOrCreateHubResult;
  importFiles: ImportFilesResult;
  increaseAIRequestCount: IncreaseAiRequestCountResult;
  inviteUsersToFile: InviteUsersToFileResult;
  inviteUsersToProject: InviteUsersToProjectResult;
  inviteUsersToWorkspace: InviteUsersToWorkspaceResult;
  revokeInvitation: RevokeInvitationResult;
  updateFieldTag: UpdateFieldTagResult;
  updateFileAccess: UpdateFileAccessResult;
  updateFileCommentContent: UpdateFileCommentContentResult;
  updateFileCommentPosition: UpdateFileCommentPositionResult;
  updateFileDefaultAccess: UpdateFileDefaultAccessResult;
  updateFileFieldPosition: UpdateFileFieldPositionResult;
  updateFileFieldPositions: UpdateFileFieldPositionsResult;
  updateFileHash: UpdateFileHashResult;
  updateFileName: UpdateFileNameResult;
  updateHubRouteFiles: UpdateHubRouteFilesResult;
  updateProjectDefaultAccess: UpdateProjectDefaultAccessResult;
  updateProjectName: UpdateProjectNameResult;
  updateRouteName: UpdateRouteNameResult;
  updateUserOnboarding: UpdateUserOnboardingResult;
  updateUserSubscriptions: UpdateUserSubscriptionsResult;
  updateWorkspaceMemberAccess: UpdateWorkspaceMemberAccessResult;
  updateWorkspaceName: UpdateWorkspaceNameResult;
  updateWorkspaceSlug: UpdateWorkspaceSlugResult;
  upgradeSubscription: UpgradeSubscriptionResult;
  validateDiscountCode: ValidateDiscountCodeResult;
};

export type MutationApplyDiscountCodeArgs = {
  discountCode: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type MutationCompleteUserOnboardingArgs = {
  userId: Scalars['String']['input'];
};

export type MutationCreateCustomerPortalSessionArgs = {
  flow?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type MutationCreateEndpointArgs = {
  input: EndpointCreateInput;
};

export type MutationCreateEndpointEventArgs = {
  input: EndpointEventCreateInput;
};

export type MutationCreateFieldTagArgs = {
  input: FieldTagCreateInput;
};

export type MutationCreateFileAccessArgs = {
  input: FileAccessCreateInput;
};

export type MutationCreateFileCommentArgs = {
  input: FileCommentCreateInput;
};

export type MutationCreateFileFieldArgs = {
  input: FileFieldCreateInput;
};

export type MutationCreateFileFieldTagArgs = {
  input: FileFieldTagCreateInput;
};

export type MutationCreateFileFieldsArgs = {
  input: Array<FileFieldCreateInput>;
};

export type MutationCreateFileFromCommitArgs = {
  input: CreateFileFromCommitInput;
};

export type MutationCreateFileFromUrlArgs = {
  commitHash: Scalars['String']['input'];
  defaultAccess?: InputMaybe<Scalars['String']['input']>;
  fileUrl: Scalars['String']['input'];
  hubId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateFileRevisionArgs = {
  input: FileRevisionCreateInput;
};

export type MutationCreateHubRouteArgs = {
  input: HubRouteCreateInput;
};

export type MutationCreatePersonalAccessTokenArgs = {
  expiresAt?: InputMaybe<PersonalAccessTokenExpiresAt>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreatePrArgs = {
  input: PrCreateInput;
};

export type MutationCreateProjectArgs = {
  input: ProjectCreateInput;
};

export type MutationCreateProjectFileArgs = {
  input: ProjectFileCreateInput;
};

export type MutationCreateProjectRepositoryArgs = {
  input: ProjectRepositoryCreateInput;
};

export type MutationCreateRouteArgs = {
  input: RouteCreateInput;
};

export type MutationCreateSuggestionArgs = {
  input: SuggestionCreateInput;
};

export type MutationCreateUserBookmarkArgs = {
  input: UserBookmarkCreateInput;
};

export type MutationCreateWorkspaceArgs = {
  input: WorkspaceCreateInput;
};

export type MutationDeleteFieldTagArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFileArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFileAccessArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFileCommentArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFileCommentThreadArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFileFieldTagArgs = {
  commitHash: Scalars['String']['input'];
  fieldId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
};

export type MutationDeleteHubRouteArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteProjectArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteProjectAccessArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRouteArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteSuggestionArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteUserBookmarkArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteWorkspaceArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteWorkspaceMemberArgs = {
  id: Scalars['String']['input'];
};

export type MutationFindLastOrCreateHubArgs = {
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationImportFilesArgs = {
  projectId: Scalars['String']['input'];
};

export type MutationIncreaseAiRequestCountArgs = {
  workspaceId: Scalars['String']['input'];
};

export type MutationInviteUsersToFileArgs = {
  input: InviteUserToFileInput;
};

export type MutationInviteUsersToProjectArgs = {
  input: InviteUserToProjectInput;
};

export type MutationInviteUsersToWorkspaceArgs = {
  input: InviteUserToWorkspaceInput;
};

export type MutationRevokeInvitationArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdateFieldTagArgs = {
  input: FieldTagUpdateInput;
};

export type MutationUpdateFileAccessArgs = {
  accessType: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationUpdateFileCommentContentArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationUpdateFileCommentPositionArgs = {
  id: Scalars['String']['input'];
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
};

export type MutationUpdateFileDefaultAccessArgs = {
  defaultAccess: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationUpdateFileFieldPositionArgs = {
  commitHash: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
};

export type MutationUpdateFileFieldPositionsArgs = {
  inputs: Array<UpdateFileFieldPositionInput>;
};

export type MutationUpdateFileHashArgs = {
  id: Scalars['String']['input'];
  lastCommitHash: Scalars['String']['input'];
};

export type MutationUpdateFileNameArgs = {
  fileName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationUpdateHubRouteFilesArgs = {
  excludeFiles: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  includeFiles: Array<Scalars['String']['input']>;
};

export type MutationUpdateProjectDefaultAccessArgs = {
  defaultAccess: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationUpdateProjectNameArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationUpdateRouteNameArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationUpdateUserOnboardingArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  role?: InputMaybe<OnboardingRole>;
  useCases?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationUpdateUserSubscriptionsArgs = {
  id: Scalars['String']['input'];
  subscribedToNews?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToProductUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToStatusUpdates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateWorkspaceMemberAccessArgs = {
  defaultAccess: Scalars['String']['input'];
  id: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type MutationUpdateWorkspaceNameArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationUpdateWorkspaceSlugArgs = {
  id: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type MutationUpgradeSubscriptionArgs = {
  discountCode?: InputMaybe<Scalars['String']['input']>;
  priceId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type MutationValidateDiscountCodeArgs = {
  discountCode: Scalars['String']['input'];
};

export type Node = {
  nodeId: Scalars['ID']['output'];
};

export enum OnboardingRole {
  BusinessAnalyst = 'BUSINESS_ANALYST',
  ItArchitect = 'IT_ARCHITECT',
  Other = 'OTHER',
  ProductManager = 'PRODUCT_MANAGER',
  ProjectManager = 'PROJECT_MANAGER',
  SoftwareEngineer = 'SOFTWARE_ENGINEER',
  Student = 'STUDENT',
}

export enum OrderByArg {
  Asc = 'asc',
  Desc = 'desc',
}

export type PrCreateInput = {
  commitHash: Scalars['String']['input'];
  commitMessage: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  prTitle: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type PrCreateOutput = {
  __typename?: 'PRCreateOutput';
  commitHash: Scalars['String']['output'];
  pullRequestNumber: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PersonalAccessToken = Node & {
  __typename?: 'PersonalAccessToken';
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
};

/** The time when the personal access token expires */
export enum PersonalAccessTokenExpiresAt {
  Never = 'NEVER',
  OneMonth = 'ONE_MONTH',
  OneYear = 'ONE_YEAR',
}

export type PersonalAccessTokenOutput = {
  __typename?: 'PersonalAccessTokenOutput';
  expiresAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type Project = Node & {
  __typename?: 'Project';
  access: ProjectAccessConnection;
  createdAt: Scalars['DateTime']['output'];
  defaultAccess: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitations: Array<Invitation>;
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  ownedBy: Scalars['String']['output'];
  owner: User;
  repositories: ProjectRepositoriesConnection;
  workspace: Workspace;
};

export type ProjectAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectRepositoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectAccess = Node & {
  __typename?: 'ProjectAccess';
  accessType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  project: Project;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type ProjectAccessConnection = {
  __typename?: 'ProjectAccessConnection';
  edges: Array<Maybe<ProjectAccessConnectionEdge>>;
  pageInfo: PageInfo;
};

export type ProjectAccessConnectionEdge = {
  __typename?: 'ProjectAccessConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ProjectAccess;
};

export type ProjectAccessOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type ProjectAccessWhereInput = {
  userId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type ProjectCreateInput = {
  defaultAccess: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectFile = Node & {
  __typename?: 'ProjectFile';
  file: File;
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  project: Project;
};

export type ProjectFileCreateInput = {
  file?: InputMaybe<ProjectFileRelationCreateInput>;
  fileId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  projectId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type ProjectFileOrderByInput = {
  file?: InputMaybe<FileArgs>;
};

export type ProjectFileRelationCreateInput = {
  data: FileCreateInput;
};

export type ProjectFileWhereInput = {
  projectId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type ProjectOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type ProjectRepositoriesConnection = {
  __typename?: 'ProjectRepositoriesConnection';
  edges: Array<Maybe<ProjectRepositoriesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type ProjectRepositoriesConnectionEdge = {
  __typename?: 'ProjectRepositoriesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ProjectRepository;
};

export type ProjectRepository = Node & {
  __typename?: 'ProjectRepository';
  createdAt: Scalars['DateTime']['output'];
  githubRepositoryFullName: Scalars['String']['output'];
  githubRepositoryId: Scalars['String']['output'];
  hubqlBaseFolder: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  productionBranch: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProjectRepositoryCreateInput = {
  githubAppInstallationId: Scalars['String']['input'];
  githubRepositoryFullName: Scalars['String']['input'];
  githubRepositoryId: Scalars['String']['input'];
  hubqlBaseFolder: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  productionBranch: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type ProjectWhereInput = {
  defaultAccess?: InputMaybe<ConditionArgsWhereArgs>;
  ownedBy?: InputMaybe<ConditionArgsWhereArgs>;
  workspaceId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type Query = {
  __typename?: 'Query';
  endpoints: QueryEndpointsConnection;
  events: QueryEventsConnection;
  file?: Maybe<File>;
  fileAccess: QueryFileAccessConnection;
  fileComments: QueryFileCommentsConnection;
  node?: Maybe<Node>;
  nodes: Array<Maybe<Node>>;
  project?: Maybe<Project>;
  projectAccess: QueryProjectAccessConnection;
  projectFiles: QueryProjectFilesConnection;
  projects: QueryProjectsConnection;
  route?: Maybe<Route>;
  routes: QueryRoutesConnection;
  userBookmarks: QueryUserBookmarksConnection;
  userRepos: Array<UserReposOutput>;
  validatePersonalAccessToken: ValidatePersonalAccessTokenResult;
  workspace: Workspace;
  workspaceBySlug: Workspace;
  workspaceMembers: QueryWorkspaceMembersConnection;
  workspaces: QueryWorkspacesConnection;
};

export type QueryEndpointsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where: EventWhereArgs;
};

export type QueryFileArgs = {
  accessCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type QueryFileAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileAccessOrderByInput>;
  where?: InputMaybe<FileAccessWhereInput>;
};

export type QueryFileCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<FileCommentOrderByInput>;
  where?: InputMaybe<FileCommentWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProjectAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProjectAccessOrderByInput>;
  where?: InputMaybe<ProjectAccessWhereInput>;
};

export type QueryProjectFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProjectFileOrderByInput>;
  where?: InputMaybe<ProjectFileWhereInput>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ProjectOrderByInput>;
  where?: InputMaybe<ProjectWhereInput>;
};

export type QueryRouteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RouteOrderByInput>;
  where?: InputMaybe<RouteWhereInput>;
};

export type QueryUserBookmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserBookmarkOrderByInput>;
  where?: InputMaybe<UserBookmarkWhereInput>;
};

export type QueryValidatePersonalAccessTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWorkspaceBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryWorkspaceMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<WorkspaceMemberOrderByInput>;
  where?: InputMaybe<WorkspaceMemberWhereInput>;
};

export type QueryWorkspacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryEndpointsConnection = {
  __typename?: 'QueryEndpointsConnection';
  edges: Array<Maybe<QueryEndpointsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryEndpointsConnectionEdge = {
  __typename?: 'QueryEndpointsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Endpoint;
};

export type QueryEventsConnection = {
  __typename?: 'QueryEventsConnection';
  edges: Array<Maybe<QueryEventsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryEventsConnectionEdge = {
  __typename?: 'QueryEventsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Event;
};

export type QueryFileAccessConnection = {
  __typename?: 'QueryFileAccessConnection';
  edges: Array<Maybe<QueryFileAccessConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryFileAccessConnectionEdge = {
  __typename?: 'QueryFileAccessConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileAccess;
};

export type QueryFileCommentsConnection = {
  __typename?: 'QueryFileCommentsConnection';
  edges: Array<Maybe<QueryFileCommentsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryFileCommentsConnectionEdge = {
  __typename?: 'QueryFileCommentsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: FileComment;
};

export type QueryProjectAccessConnection = {
  __typename?: 'QueryProjectAccessConnection';
  edges: Array<Maybe<QueryProjectAccessConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryProjectAccessConnectionEdge = {
  __typename?: 'QueryProjectAccessConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ProjectAccess;
};

export type QueryProjectFilesConnection = {
  __typename?: 'QueryProjectFilesConnection';
  edges: Array<Maybe<QueryProjectFilesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryProjectFilesConnectionEdge = {
  __typename?: 'QueryProjectFilesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: ProjectFile;
};

export type QueryProjectsConnection = {
  __typename?: 'QueryProjectsConnection';
  edges: Array<Maybe<QueryProjectsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryProjectsConnectionEdge = {
  __typename?: 'QueryProjectsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type QueryRoutesConnection = {
  __typename?: 'QueryRoutesConnection';
  edges: Array<Maybe<QueryRoutesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryRoutesConnectionEdge = {
  __typename?: 'QueryRoutesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Route;
};

export type QueryUserBookmarksConnection = {
  __typename?: 'QueryUserBookmarksConnection';
  edges: Array<Maybe<QueryUserBookmarksConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryUserBookmarksConnectionEdge = {
  __typename?: 'QueryUserBookmarksConnectionEdge';
  cursor: Scalars['String']['output'];
  node: UserBookmark;
};

export type QueryWorkspaceMembersConnection = {
  __typename?: 'QueryWorkspaceMembersConnection';
  edges: Array<Maybe<QueryWorkspaceMembersConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryWorkspaceMembersConnectionEdge = {
  __typename?: 'QueryWorkspaceMembersConnectionEdge';
  cursor: Scalars['String']['output'];
  node: WorkspaceMember;
};

export type QueryWorkspacesConnection = {
  __typename?: 'QueryWorkspacesConnection';
  edges: Array<Maybe<QueryWorkspacesConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryWorkspacesConnectionEdge = {
  __typename?: 'QueryWorkspacesConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Workspace;
};

export type RevokeInvitationResult = Error | RevokeInvitationSuccess;

export type RevokeInvitationSuccess = {
  __typename?: 'RevokeInvitationSuccess';
  data: Invitation;
};

export type Route = Node & {
  __typename?: 'Route';
  createdAt: Scalars['DateTime']['output'];
  hubs: RouteHubsConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type RouteHubsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RouteCreateInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type RouteHubsConnection = {
  __typename?: 'RouteHubsConnection';
  edges: Array<Maybe<RouteHubsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type RouteHubsConnectionEdge = {
  __typename?: 'RouteHubsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: HubRoute;
};

export type RouteOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type RouteWhereInput = {
  workspaceId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type Suggestion = Node & {
  __typename?: 'Suggestion';
  action: Scalars['String']['output'];
  assignedTo?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileFieldCommitHash?: Maybe<Scalars['String']['output']>;
  fileFieldFileId?: Maybe<Scalars['String']['output']>;
  fileFieldId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isResolved: Scalars['Boolean']['output'];
  meta?: Maybe<Scalars['JSONObject']['output']>;
  nodeId: Scalars['ID']['output'];
  referenceKey?: Maybe<Scalars['String']['output']>;
  suggestedBy: Scalars['String']['output'];
  suggestor: User;
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type SuggestionCreateInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  fileFieldCommitHash?: InputMaybe<Scalars['String']['input']>;
  fileFieldFileId?: InputMaybe<Scalars['String']['input']>;
  fileFieldId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isResolved?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSONObject']['input']>;
  referenceKey?: InputMaybe<Scalars['String']['input']>;
  suggestedBy?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFieldTagResult = Error | UpdateFieldTagSuccess;

export type UpdateFieldTagSuccess = {
  __typename?: 'UpdateFieldTagSuccess';
  data: FieldTag;
};

export type UpdateFileAccessResult = Error | UpdateFileAccessSuccess;

export type UpdateFileAccessSuccess = {
  __typename?: 'UpdateFileAccessSuccess';
  data: FileAccess;
};

export type UpdateFileCommentContentResult =
  | Error
  | UpdateFileCommentContentSuccess;

export type UpdateFileCommentContentSuccess = {
  __typename?: 'UpdateFileCommentContentSuccess';
  data: FileComment;
};

export type UpdateFileCommentPositionResult =
  | Error
  | UpdateFileCommentPositionSuccess;

export type UpdateFileCommentPositionSuccess = {
  __typename?: 'UpdateFileCommentPositionSuccess';
  data: FileComment;
};

export type UpdateFileDefaultAccessResult =
  | Error
  | UpdateFileDefaultAccessSuccess;

export type UpdateFileDefaultAccessSuccess = {
  __typename?: 'UpdateFileDefaultAccessSuccess';
  data: File;
};

export type UpdateFileFieldPositionInput = {
  commitHash: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
};

export type UpdateFileFieldPositionResult =
  | Error
  | UpdateFileFieldPositionSuccess;

export type UpdateFileFieldPositionSuccess = {
  __typename?: 'UpdateFileFieldPositionSuccess';
  data: FileField;
};

export type UpdateFileFieldPositionsResult =
  | Error
  | UpdateFileFieldPositionsSuccess;

export type UpdateFileFieldPositionsSuccess = {
  __typename?: 'UpdateFileFieldPositionsSuccess';
  data: Array<FileField>;
};

export type UpdateFileHashResult = Error | UpdateFileHashSuccess;

export type UpdateFileHashSuccess = {
  __typename?: 'UpdateFileHashSuccess';
  data: File;
};

export type UpdateFileNameResult = Error | UpdateFileNameSuccess;

export type UpdateFileNameSuccess = {
  __typename?: 'UpdateFileNameSuccess';
  data: File;
};

export type UpdateHubRouteFilesResult = Error | UpdateHubRouteFilesSuccess;

export type UpdateHubRouteFilesSuccess = {
  __typename?: 'UpdateHubRouteFilesSuccess';
  data: HubRoute;
};

export type UpdateProjectDefaultAccessResult =
  | Error
  | UpdateProjectDefaultAccessSuccess;

export type UpdateProjectDefaultAccessSuccess = {
  __typename?: 'UpdateProjectDefaultAccessSuccess';
  data: Project;
};

export type UpdateProjectNameResult = Error | UpdateProjectNameSuccess;

export type UpdateProjectNameSuccess = {
  __typename?: 'UpdateProjectNameSuccess';
  data: Project;
};

export type UpdateRouteNameResult = Error | UpdateRouteNameSuccess;

export type UpdateRouteNameSuccess = {
  __typename?: 'UpdateRouteNameSuccess';
  data: Route;
};

export type UpdateUserOnboardingResult = Error | UpdateUserOnboardingSuccess;

export type UpdateUserOnboardingSuccess = {
  __typename?: 'UpdateUserOnboardingSuccess';
  data: UserOnboarding;
};

export type UpdateUserSubscriptionsResult =
  | Error
  | UpdateUserSubscriptionsSuccess;

export type UpdateUserSubscriptionsSuccess = {
  __typename?: 'UpdateUserSubscriptionsSuccess';
  data: User;
};

export type UpdateWorkspaceMemberAccessResult =
  | Error
  | UpdateWorkspaceMemberAccessSuccess;

export type UpdateWorkspaceMemberAccessSuccess = {
  __typename?: 'UpdateWorkspaceMemberAccessSuccess';
  data: WorkspaceMember;
};

export type UpdateWorkspaceNameResult = Error | UpdateWorkspaceNameSuccess;

export type UpdateWorkspaceNameSuccess = {
  __typename?: 'UpdateWorkspaceNameSuccess';
  data: Workspace;
};

export type UpdateWorkspaceSlugResult = Error | UpdateWorkspaceSlugSuccess;

export type UpdateWorkspaceSlugSuccess = {
  __typename?: 'UpdateWorkspaceSlugSuccess';
  data: Workspace;
};

export type UpgradeSubscriptionResult = Error | UpgradeSubscriptionSuccess;

export type UpgradeSubscriptionSuccess = {
  __typename?: 'UpgradeSubscriptionSuccess';
  data: Scalars['String']['output'];
};

export type User = Node & {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  nodeId: Scalars['ID']['output'];
  picture?: Maybe<Scalars['String']['output']>;
  subscribedToNews: Scalars['Boolean']['output'];
  subscribedToProductUpdates: Scalars['Boolean']['output'];
  subscribedToStatusUpdates: Scalars['Boolean']['output'];
};

export type UserBookmark = Node & {
  __typename?: 'UserBookmark';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Scalars['DateTime']['output'];
  file?: Maybe<File>;
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  project?: Maybe<Project>;
};

export type UserBookmarkCreateInput = {
  id: Scalars['ID']['input'];
  projectId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type UserBookmarkOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type UserBookmarkWhereInput = {
  userId?: InputMaybe<ConditionArgsWhereArgs>;
  workspaceId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type UserOnboarding = Node & {
  __typename?: 'UserOnboarding';
  companyName?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  role: OnboardingRole;
  useCases?: Maybe<Array<Scalars['String']['output']>>;
  userId: Scalars['String']['output'];
};

export type UserReposOutput = {
  __typename?: 'UserReposOutput';
  appInstallationId: Scalars['String']['output'];
  avatarUrl: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  private: Scalars['Boolean']['output'];
};

export type ValidateDiscountCodeResult = Error | ValidateDiscountCodeSuccess;

export type ValidateDiscountCodeSuccess = {
  __typename?: 'ValidateDiscountCodeSuccess';
  data: Array<Scalars['String']['output']>;
};

export type ValidatePersonalAccessTokenOutput = {
  __typename?: 'ValidatePersonalAccessTokenOutput';
  userId: Scalars['String']['output'];
};

export type ValidatePersonalAccessTokenResult =
  | Error
  | ValidatePersonalAccessTokenSuccess;

export type ValidatePersonalAccessTokenSuccess = {
  __typename?: 'ValidatePersonalAccessTokenSuccess';
  data: ValidatePersonalAccessTokenOutput;
};

export type Workspace = Node & {
  __typename?: 'Workspace';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invitations: Array<Invitation>;
  members: WorkspaceMembersConnection;
  name: Scalars['String']['output'];
  nodeId: Scalars['ID']['output'];
  seatsUsed: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  subscriptions: WorkspaceSubscriptionsConnection;
  usage?: Maybe<WorkspaceUsage>;
};

export type WorkspaceMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspaceSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkspaceCreateInput = {
  createdBy: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  members?: InputMaybe<WorkspaceMemberRelationCreateInput>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type WorkspaceMember = Node & {
  __typename?: 'WorkspaceMember';
  defaultAccess: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
  workspace: Workspace;
};

export type WorkspaceMemberCreateInput = {
  defaultAccess?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  role: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type WorkspaceMemberOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
};

export type WorkspaceMemberRelationCreateInput = {
  data: Array<WorkspaceMemberCreateInput>;
};

export type WorkspaceMemberWhereInput = {
  userId?: InputMaybe<ConditionArgsWhereArgs>;
};

export type WorkspaceMembersConnection = {
  __typename?: 'WorkspaceMembersConnection';
  edges: Array<Maybe<WorkspaceMembersConnectionEdge>>;
  pageInfo: PageInfo;
};

export type WorkspaceMembersConnectionEdge = {
  __typename?: 'WorkspaceMembersConnectionEdge';
  cursor: Scalars['String']['output'];
  node: WorkspaceMember;
};

export type WorkspaceSubscription = Node & {
  __typename?: 'WorkspaceSubscription';
  createdAt: Scalars['DateTime']['output'];
  hasPaymentMethod: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  nodeId: Scalars['ID']['output'];
  seatsTotal: Scalars['Int']['output'];
  /** @deprecated Use seatsUsed on workspace type instead */
  seatsUsed: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  stripeCustomerId: Scalars['String']['output'];
  stripePlanId: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  subscriptionEndsAt: Scalars['DateTime']['output'];
  subscriptionStartsAt: Scalars['DateTime']['output'];
  trialEndsAt?: Maybe<Scalars['DateTime']['output']>;
  trialStartsAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkspaceSubscriptionsConnection = {
  __typename?: 'WorkspaceSubscriptionsConnection';
  edges: Array<Maybe<WorkspaceSubscriptionsConnectionEdge>>;
  pageInfo: PageInfo;
};

export type WorkspaceSubscriptionsConnectionEdge = {
  __typename?: 'WorkspaceSubscriptionsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: WorkspaceSubscription;
};

export type WorkspaceUsage = Node & {
  __typename?: 'WorkspaceUsage';
  /** AI Request limit reached */
  aiRequestCountLimitReached: Scalars['Boolean']['output'];
  nodeId: Scalars['ID']['output'];
};

export type ApplyDiscountCodeMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  discountCode: Scalars['String']['input'];
}>;

export type ApplyDiscountCodeMutation = {
  __typename?: 'Mutation';
  applyDiscountCode:
    | { __typename: 'ApplyDiscountCodeSuccess' }
    | { __typename: 'Error'; message: string };
};

export type CompleteUserOnboardingMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type CompleteUserOnboardingMutation = {
  __typename?: 'Mutation';
  completeUserOnboarding:
    | {
        __typename: 'CompleteUserOnboardingSuccess';
        data: {
          __typename?: 'UserOnboarding';
          id: string;
          completedAt?: any | null;
          userId: string;
        };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateCustomerPortalSessionMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  flow?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCustomerPortalSessionMutation = {
  __typename?: 'Mutation';
  createCustomerPortalSession:
    | { __typename: 'CreateCustomerPortalSessionSuccess'; data: string }
    | { __typename: 'Error'; message: string };
};

export type CreateEndpointMutationVariables = Exact<{
  input: EndpointCreateInput;
}>;

export type CreateEndpointMutation = {
  __typename?: 'Mutation';
  createEndpoint:
    | {
        __typename: 'CreateEndpointSuccess';
        data: { __typename?: 'Endpoint'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFieldTagMutationVariables = Exact<{
  input: FieldTagCreateInput;
}>;

export type CreateFieldTagMutation = {
  __typename?: 'Mutation';
  createFieldTag:
    | {
        __typename: 'CreateFieldTagSuccess';
        data: { __typename?: 'FieldTag'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileAccessMutationVariables = Exact<{
  input: FileAccessCreateInput;
}>;

export type CreateFileAccessMutation = {
  __typename?: 'Mutation';
  createFileAccess:
    | {
        __typename: 'CreateFileAccessSuccess';
        data: { __typename?: 'FileAccess'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileCommentMutationVariables = Exact<{
  input: FileCommentCreateInput;
}>;

export type CreateFileCommentMutation = {
  __typename?: 'Mutation';
  createFileComment:
    | {
        __typename: 'CreateFileCommentSuccess';
        data: { __typename?: 'FileComment'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileFieldMutationVariables = Exact<{
  input: FileFieldCreateInput;
}>;

export type CreateFileFieldMutation = {
  __typename?: 'Mutation';
  createFileField:
    | {
        __typename: 'CreateFileFieldSuccess';
        data: { __typename?: 'FileField'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileFieldTagMutationVariables = Exact<{
  input: FileFieldTagCreateInput;
}>;

export type CreateFileFieldTagMutation = {
  __typename?: 'Mutation';
  createFileFieldTag:
    | {
        __typename: 'CreateFileFieldTagSuccess';
        data: { __typename?: 'FileFieldTag'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileFieldsMutationVariables = Exact<{
  input: Array<FileFieldCreateInput> | FileFieldCreateInput;
}>;

export type CreateFileFieldsMutation = {
  __typename?: 'Mutation';
  createFileFields:
    | {
        __typename: 'CreateFileFieldsSuccess';
        data: Array<{ __typename?: 'FileField'; id: string }>;
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileFromUrlMutationVariables = Exact<{
  fileUrl: Scalars['String']['input'];
  commitHash: Scalars['String']['input'];
  hubId?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateFileFromUrlMutation = {
  __typename?: 'Mutation';
  createFileFromUrl:
    | {
        __typename: 'CreateFileFromUrlSuccess';
        data: { __typename?: 'File'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateFileRevisionMutationVariables = Exact<{
  input: FileRevisionCreateInput;
}>;

export type CreateFileRevisionMutation = {
  __typename?: 'Mutation';
  createFileRevision:
    | {
        __typename: 'CreateFileRevisionSuccess';
        data: { __typename?: 'FileRevision'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreatePrMutationVariables = Exact<{
  input: PrCreateInput;
}>;

export type CreatePrMutation = {
  __typename?: 'Mutation';
  createPr:
    | {
        __typename: 'CreatePrSuccess';
        data: {
          __typename?: 'PRCreateOutput';
          url: string;
          pullRequestNumber: number;
        };
      }
    | { __typename: 'Error'; message: string };
};

export type CreatePersonalAccessTokenMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<PersonalAccessTokenExpiresAt>;
}>;

export type CreatePersonalAccessTokenMutation = {
  __typename?: 'Mutation';
  createPersonalAccessToken:
    | {
        __typename: 'CreatePersonalAccessTokenSuccess';
        data: {
          __typename: 'PersonalAccessTokenOutput';
          expiresAt: any;
          name: string;
          token: string;
        };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateProjectMutationVariables = Exact<{
  input: ProjectCreateInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject:
    | {
        __typename: 'CreateProjectSuccess';
        data: { __typename?: 'Project'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateProjectFileMutationVariables = Exact<{
  input: ProjectFileCreateInput;
}>;

export type CreateProjectFileMutation = {
  __typename?: 'Mutation';
  createProjectFile:
    | {
        __typename: 'CreateProjectFileSuccess';
        data: { __typename?: 'ProjectFile'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateProjectRepositoryMutationVariables = Exact<{
  input: ProjectRepositoryCreateInput;
}>;

export type CreateProjectRepositoryMutation = {
  __typename?: 'Mutation';
  createProjectRepository:
    | {
        __typename: 'CreateProjectRepositorySuccess';
        data: {
          __typename?: 'ProjectRepository';
          id: string;
          projectId: string;
          provider: string;
          githubRepositoryId: string;
          productionBranch: string;
          hubqlBaseFolder: string;
          createdAt: any;
          updatedAt: any;
          githubRepositoryFullName: string;
        };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateSuggestionMutationVariables = Exact<{
  input: SuggestionCreateInput;
}>;

export type CreateSuggestionMutation = {
  __typename?: 'Mutation';
  createSuggestion:
    | {
        __typename: 'CreateSuggestionSuccess';
        data: { __typename?: 'Suggestion'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateUserBookmarkMutationVariables = Exact<{
  input: UserBookmarkCreateInput;
}>;

export type CreateUserBookmarkMutation = {
  __typename?: 'Mutation';
  createUserBookmark:
    | {
        __typename: 'CreateUserBookmarkSuccess';
        data: { __typename?: 'UserBookmark'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type CreateWorkspaceMutationVariables = Exact<{
  input: WorkspaceCreateInput;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutation';
  createWorkspace:
    | {
        __typename: 'CreateWorkspaceSuccess';
        data: {
          __typename?: 'Workspace';
          id: string;
          slug: string;
          name: string;
        };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteFieldTagMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteFieldTagMutation = {
  __typename?: 'Mutation';
  deleteFieldTag:
    | {
        __typename: 'DeleteFieldTagSuccess';
        data: { __typename?: 'FieldTag'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile:
    | {
        __typename: 'DeleteFileSuccess';
        data: { __typename?: 'File'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteFileAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteFileAccessMutation = {
  __typename?: 'Mutation';
  deleteFileAccess:
    | {
        __typename: 'DeleteFileAccessSuccess';
        data: { __typename?: 'FileAccess'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteFileCommentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteFileCommentMutation = {
  __typename?: 'Mutation';
  deleteFileComment:
    | {
        __typename: 'DeleteFileCommentSuccess';
        data: { __typename?: 'FileComment'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteFileCommentThreadMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteFileCommentThreadMutation = {
  __typename?: 'Mutation';
  deleteFileCommentThread:
    | {
        __typename: 'DeleteFileCommentThreadSuccess';
        data: { __typename?: 'FileComment'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteFileFieldTagMutationVariables = Exact<{
  commitHash: Scalars['String']['input'];
  fieldId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
}>;

export type DeleteFileFieldTagMutation = {
  __typename?: 'Mutation';
  deleteFileFieldTag:
    | {
        __typename: 'DeleteFileFieldTagSuccess';
        data: { __typename?: 'FileFieldTag'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject:
    | {
        __typename: 'DeleteProjectSuccess';
        data: { __typename?: 'Project'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteProjectAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteProjectAccessMutation = {
  __typename?: 'Mutation';
  deleteProjectAccess:
    | {
        __typename: 'DeleteProjectAccessSuccess';
        data: { __typename?: 'ProjectAccess'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteSuggestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteSuggestionMutation = {
  __typename?: 'Mutation';
  deleteSuggestion:
    | {
        __typename: 'DeleteSuggestionSuccess';
        data: { __typename?: 'Suggestion'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteUserBookmarkMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteUserBookmarkMutation = {
  __typename?: 'Mutation';
  deleteUserBookmark:
    | {
        __typename: 'DeleteUserBookmarkSuccess';
        data: { __typename?: 'UserBookmark'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteWorkspaceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutation';
  deleteWorkspace:
    | {
        __typename: 'DeleteWorkspaceSuccess';
        data: { __typename?: 'Workspace'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type DeleteWorkspaceMemberMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteWorkspaceMemberMutation = {
  __typename?: 'Mutation';
  deleteWorkspaceMember:
    | {
        __typename: 'DeleteWorkspaceMemberSuccess';
        data: { __typename?: 'WorkspaceMember'; id: string };
      }
    | { __typename: 'Error'; message: string };
};

export type FindLastOrCreateHubMutationVariables = Exact<{
  workspaceId?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindLastOrCreateHubMutation = {
  __typename?: 'Mutation';
  findLastOrCreateHub:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'FindLastOrCreateHubSuccess';
        data: { __typename?: 'Project'; id: string };
      };
};

export type ImportFilesMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type ImportFilesMutation = {
  __typename?: 'Mutation';
  importFiles:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'ImportFilesSuccess';
        data: { __typename?: 'ImportFilesOutput'; success: boolean };
      };
};

export type IncreaseAiRequestCountMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;

export type IncreaseAiRequestCountMutation = {
  __typename?: 'Mutation';
  increaseAIRequestCount:
    | { __typename: 'Error'; message: string }
    | { __typename: 'IncreaseAIRequestCountSuccess'; data: boolean };
};

export type InviteUsersToProjectMutationVariables = Exact<{
  input: InviteUserToProjectInput;
}>;

export type InviteUsersToProjectMutation = {
  __typename?: 'Mutation';
  inviteUsersToProject:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'InviteUsersToProjectSuccess';
        data: { __typename?: 'ProjectAccess'; id: string };
      };
};

export type InviteUsersToWorkspaceMutationVariables = Exact<{
  input: InviteUserToWorkspaceInput;
}>;

export type InviteUsersToWorkspaceMutation = {
  __typename?: 'Mutation';
  inviteUsersToWorkspace:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'InviteUsersToWorkspaceSuccess';
        data: { __typename?: 'WorkspaceMember'; id: string };
      };
};

export type InviteUsersToFileMutationVariables = Exact<{
  input: InviteUserToFileInput;
}>;

export type InviteUsersToFileMutation = {
  __typename?: 'Mutation';
  inviteUsersToFile:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'InviteUsersToFileSuccess';
        data: { __typename?: 'FileAccess'; id: string };
      };
};

export type RevokeInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RevokeInvitationMutation = {
  __typename?: 'Mutation';
  revokeInvitation:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'RevokeInvitationSuccess';
        data: { __typename?: 'Invitation'; id: string };
      };
};

export type UpdateFieldTagMutationVariables = Exact<{
  input: FieldTagUpdateInput;
}>;

export type UpdateFieldTagMutation = {
  __typename?: 'Mutation';
  updateFieldTag:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFieldTagSuccess';
        data: { __typename?: 'FieldTag'; id: string };
      };
};

export type UpdateFileDefaultAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
  defaultAccess: Scalars['String']['input'];
}>;

export type UpdateFileDefaultAccessMutation = {
  __typename?: 'Mutation';
  updateFileDefaultAccess:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileDefaultAccessSuccess';
        data: { __typename?: 'File'; id: string; defaultAccess: string };
      };
};

export type UpdateFileCommentContentMutationVariables = Exact<{
  comment: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type UpdateFileCommentContentMutation = {
  __typename?: 'Mutation';
  updateFileCommentContent:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileCommentContentSuccess';
        data: { __typename?: 'FileComment'; id: string; comment: string };
      };
};

export type UpdateFileCommentPositionMutationVariables = Exact<{
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
  id: Scalars['String']['input'];
}>;

export type UpdateFileCommentPositionMutation = {
  __typename?: 'Mutation';
  updateFileCommentPosition:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileCommentPositionSuccess';
        data: {
          __typename?: 'FileComment';
          id: string;
          positionX?: number | null;
          positionY?: number | null;
        };
      };
};

export type UpdateFileFieldPositionMutationVariables = Exact<{
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  commitHash: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
}>;

export type UpdateFileFieldPositionMutation = {
  __typename?: 'Mutation';
  updateFileFieldPosition:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileFieldPositionSuccess';
        data: {
          __typename?: 'FileField';
          id: string;
          positionX?: number | null;
          positionY?: number | null;
        };
      };
};

export type BulkUpdateFileFieldPositionsMutationVariables = Exact<{
  inputs: Array<UpdateFileFieldPositionInput> | UpdateFileFieldPositionInput;
}>;

export type BulkUpdateFileFieldPositionsMutation = {
  __typename?: 'Mutation';
  updateFileFieldPositions:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileFieldPositionsSuccess';
        data: Array<{
          __typename?: 'FileField';
          id: string;
          positionX?: number | null;
          positionY?: number | null;
        }>;
      };
};

export type UpdateFileHashMutationVariables = Exact<{
  lastCommitHash: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type UpdateFileHashMutation = {
  __typename?: 'Mutation';
  updateFileHash:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileHashSuccess';
        data: { __typename?: 'File'; id: string; lastCommitHash: string };
      };
};

export type UpdateFileNameMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type UpdateFileNameMutation = {
  __typename?: 'Mutation';
  updateFileName:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileNameSuccess';
        data: { __typename?: 'File'; id: string; fileName: string };
      };
};

export type UpdateFileAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
  accessType: Scalars['String']['input'];
}>;

export type UpdateFileAccessMutation = {
  __typename?: 'Mutation';
  updateFileAccess:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateFileAccessSuccess';
        data: { __typename?: 'FileAccess'; id: string; accessType: string };
      };
};

export type UpdateProjectDefaultAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
  defaultAccess: Scalars['String']['input'];
}>;

export type UpdateProjectDefaultAccessMutation = {
  __typename?: 'Mutation';
  updateProjectDefaultAccess:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateProjectDefaultAccessSuccess';
        data: { __typename?: 'Project'; id: string; defaultAccess: string };
      };
};

export type UpdateProjectNameMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateProjectNameMutation = {
  __typename?: 'Mutation';
  updateProjectName:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateProjectNameSuccess';
        data: { __typename?: 'Project'; id: string; name: string };
      };
};

export type UpdateUserOnboardingMutationVariables = Exact<{
  id: Scalars['String']['input'];
  role?: InputMaybe<OnboardingRole>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  useCases?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type UpdateUserOnboardingMutation = {
  __typename?: 'Mutation';
  updateUserOnboarding:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateUserOnboardingSuccess';
        data: {
          __typename?: 'UserOnboarding';
          id: string;
          role: OnboardingRole;
          companyName?: string | null;
          useCases?: Array<string> | null;
        };
      };
};

export type UpdateUserSubscriptionsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  subscribedToNews?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToProductUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  subscribedToStatusUpdates?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateUserSubscriptionsMutation = {
  __typename?: 'Mutation';
  updateUserSubscriptions:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateUserSubscriptionsSuccess';
        data: {
          __typename?: 'User';
          id: string;
          subscribedToNews: boolean;
          subscribedToProductUpdates: boolean;
          subscribedToStatusUpdates: boolean;
        };
      };
};

export type UpdateWorkspaceNameMutationVariables = Exact<{
  name: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type UpdateWorkspaceNameMutation = {
  __typename?: 'Mutation';
  updateWorkspaceName:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateWorkspaceNameSuccess';
        data: { __typename?: 'Workspace'; id: string; name: string };
      };
};

export type UpdateWorkspaceSlugMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type UpdateWorkspaceSlugMutation = {
  __typename?: 'Mutation';
  updateWorkspaceSlug:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'UpdateWorkspaceSlugSuccess';
        data: { __typename?: 'Workspace'; id: string; slug: string };
      };
};

export type UpgradeSubscriptionMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  discountCode?: InputMaybe<Scalars['String']['input']>;
  priceId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpgradeSubscriptionMutation = {
  __typename?: 'Mutation';
  upgradeSubscription:
    | { __typename: 'Error'; message: string }
    | { __typename: 'UpgradeSubscriptionSuccess'; data: string };
};

export type ValidateDiscountCodeMutationVariables = Exact<{
  discountCode: Scalars['String']['input'];
}>;

export type ValidateDiscountCodeMutation = {
  __typename?: 'Mutation';
  validateDiscountCode:
    | { __typename: 'Error'; message: string }
    | { __typename: 'ValidateDiscountCodeSuccess'; data: Array<string> };
};

export type EndpointsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;

export type EndpointsQuery = {
  __typename?: 'Query';
  endpoints: {
    __typename?: 'QueryEndpointsConnection';
    edges: Array<{
      __typename?: 'QueryEndpointsConnectionEdge';
      node: { __typename?: 'Endpoint'; id: string };
    } | null>;
  };
};

export type EventsQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type EventsQuery = {
  __typename?: 'Query';
  events: {
    __typename?: 'QueryEventsConnection';
    edges: Array<{
      __typename?: 'QueryEventsConnectionEdge';
      node: { __typename?: 'Event'; id: string; payload: any; createdAt: any };
    } | null>;
  };
};

export type FileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  commitHash: Scalars['String']['input'];
  accessCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type FileQuery = {
  __typename?: 'Query';
  file?: {
    __typename?: 'File';
    id: string;
    extension: string;
    fileName: string;
    path?: string | null;
    userAccess: string;
    defaultAccess: string;
    signedUrl: string;
    workspace: {
      __typename?: 'Workspace';
      id: string;
      name: string;
      slug: string;
      usage?: {
        __typename?: 'WorkspaceUsage';
        aiRequestCountLimitReached: boolean;
      } | null;
      subscriptions: {
        __typename?: 'WorkspaceSubscriptionsConnection';
        edges: Array<{
          __typename?: 'WorkspaceSubscriptionsConnectionEdge';
          node: {
            __typename?: 'WorkspaceSubscription';
            id: string;
            status: string;
            stripePlanId: string;
            stripeSubscriptionId: string;
          };
        } | null>;
      };
      members: {
        __typename?: 'WorkspaceMembersConnection';
        edges: Array<{
          __typename?: 'WorkspaceMembersConnectionEdge';
          node: {
            __typename?: 'WorkspaceMember';
            id: string;
            role: string;
            defaultAccess: string;
            user: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              picture?: string | null;
            };
          };
        } | null>;
      };
    };
    tags: {
      __typename?: 'FileTagsConnection';
      edges: Array<{
        __typename?: 'FileTagsConnectionEdge';
        node: {
          __typename?: 'FieldTag';
          id: string;
          name: string;
          hexColor: string;
          fieldIds: Array<string>;
        };
      } | null>;
    };
    fields: {
      __typename?: 'FileFieldsConnection';
      edges: Array<{
        __typename?: 'FileFieldsConnectionEdge';
        node: {
          __typename?: 'FileField';
          id: string;
          line: number;
          key: string;
          type?: string | null;
          exampleValue?: string | null;
          parentId?: string | null;
          commitHash: string;
          meta?: any | null;
          positionX?: number | null;
          positionY?: number | null;
        };
      } | null>;
    };
    suggestions: {
      __typename?: 'FileSuggestionsConnection';
      edges: Array<{
        __typename?: 'FileSuggestionsConnectionEdge';
        node: {
          __typename?: 'Suggestion';
          id: string;
          workspaceId: string;
          meta?: any | null;
          suggestedBy: string;
          type: string;
          action: string;
          referenceKey?: string | null;
          value: string;
          isResolved: boolean;
          assignedTo?: string | null;
          createdAt: any;
          fileFieldFileId?: string | null;
          fileFieldId?: string | null;
          fileFieldCommitHash?: string | null;
          suggestor: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            picture?: string | null;
          };
        };
      } | null>;
    };
    notes: {
      __typename?: 'FileNotesConnection';
      edges: Array<{
        __typename?: 'FileNotesConnectionEdge';
        node: {
          __typename?: 'FileComment';
          id: string;
          fieldId?: string | null;
          createdAt: any;
          createdBy: string;
          comment: string;
          positionX?: number | null;
          positionY?: number | null;
          parentCommentId?: string | null;
          isNote: boolean;
        };
      } | null>;
    };
    comments: {
      __typename?: 'FileCommentsConnection';
      edges: Array<{
        __typename?: 'FileCommentsConnectionEdge';
        node: {
          __typename?: 'FileComment';
          id: string;
          fieldId?: string | null;
          createdAt: any;
          createdBy: string;
          comment: string;
          isNote: boolean;
        };
      } | null>;
    };
    projects: {
      __typename?: 'FileProjectsConnection';
      edges: Array<{
        __typename?: 'FileProjectsConnectionEdge';
        node: {
          __typename?: 'ProjectFile';
          project: {
            __typename?: 'Project';
            id: string;
            name: string;
            access: {
              __typename?: 'ProjectAccessConnection';
              edges: Array<{
                __typename?: 'ProjectAccessConnectionEdge';
                node: {
                  __typename?: 'ProjectAccess';
                  id: string;
                  userId: string;
                  accessType: string;
                };
              } | null>;
            };
            repositories: {
              __typename?: 'ProjectRepositoriesConnection';
              edges: Array<{
                __typename?: 'ProjectRepositoriesConnectionEdge';
                node: {
                  __typename?: 'ProjectRepository';
                  id: string;
                  githubRepositoryId: string;
                  productionBranch: string;
                  hubqlBaseFolder: string;
                  githubRepositoryFullName: string;
                  provider: string;
                };
              } | null>;
            };
          };
        };
      } | null>;
    };
  } | null;
};

export type FileAccessQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type FileAccessQuery = {
  __typename?: 'Query';
  file?: {
    __typename?: 'File';
    id: string;
    ownedBy: string;
    createdBy: string;
    extension: string;
    fileName: string;
    path?: string | null;
    defaultAccess: string;
    owner: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      picture?: string | null;
    };
    invitations: Array<{
      __typename?: 'Invitation';
      id: string;
      email?: string | null;
    }>;
    workspace: {
      __typename?: 'Workspace';
      id: string;
      members: {
        __typename?: 'WorkspaceMembersConnection';
        edges: Array<{
          __typename?: 'WorkspaceMembersConnectionEdge';
          node: {
            __typename?: 'WorkspaceMember';
            id: string;
            role: string;
            defaultAccess: string;
            user: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              picture?: string | null;
            };
          };
        } | null>;
      };
    };
    access: {
      __typename?: 'FileAccessConnection';
      edges: Array<{
        __typename?: 'FileAccessConnectionEdge';
        node: {
          __typename?: 'FileAccess';
          id: string;
          email?: string | null;
          userId?: string | null;
          accessType: string;
          createdAt: any;
          updatedAt: any;
          createdBy: string;
          user: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        };
      } | null>;
    };
    projects: {
      __typename?: 'FileProjectsConnection';
      edges: Array<{
        __typename?: 'FileProjectsConnectionEdge';
        node: {
          __typename?: 'ProjectFile';
          project: {
            __typename?: 'Project';
            id: string;
            defaultAccess: string;
            access: {
              __typename?: 'ProjectAccessConnection';
              edges: Array<{
                __typename?: 'ProjectAccessConnectionEdge';
                node: {
                  __typename?: 'ProjectAccess';
                  id: string;
                  userId: string;
                  accessType: string;
                  user: {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null;
                    lastName?: string | null;
                    picture?: string | null;
                  };
                };
              } | null>;
            };
          };
        };
      } | null>;
    };
  } | null;
};

export type FileCommentsQueryVariables = Exact<{
  fileId: Scalars['String']['input'];
}>;

export type FileCommentsQuery = {
  __typename?: 'Query';
  fileComments: {
    __typename?: 'QueryFileCommentsConnection';
    edges: Array<{
      __typename?: 'QueryFileCommentsConnectionEdge';
      node: {
        __typename?: 'FileComment';
        id: string;
        fieldId?: string | null;
        comment: string;
        createdAt: any;
        createdBy: string;
        assignedTo?: string | null;
        isComplete: boolean;
        completedAt?: any | null;
        parentCommentId?: string | null;
        isNote: boolean;
      };
    } | null>;
  };
};

export type HubFilesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  hubId: Scalars['String']['input'];
  commitHash: Scalars['String']['input'];
  useCommitHash: Scalars['Boolean']['input'];
}>;

export type HubFilesQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    name: string;
    workspace: {
      __typename?: 'Workspace';
      id: string;
      name: string;
      slug: string;
      usage?: {
        __typename?: 'WorkspaceUsage';
        aiRequestCountLimitReached: boolean;
      } | null;
      subscriptions: {
        __typename?: 'WorkspaceSubscriptionsConnection';
        edges: Array<{
          __typename?: 'WorkspaceSubscriptionsConnectionEdge';
          node: {
            __typename?: 'WorkspaceSubscription';
            id: string;
            status: string;
            stripePlanId: string;
            stripeSubscriptionId: string;
          };
        } | null>;
      };
      members: {
        __typename?: 'WorkspaceMembersConnection';
        edges: Array<{
          __typename?: 'WorkspaceMembersConnectionEdge';
          node: {
            __typename?: 'WorkspaceMember';
            id: string;
            role: string;
            defaultAccess: string;
            user: {
              __typename?: 'User';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              picture?: string | null;
            };
          };
        } | null>;
      };
    };
  } | null;
  projectFiles: {
    __typename?: 'QueryProjectFilesConnection';
    edges: Array<{
      __typename?: 'QueryProjectFilesConnectionEdge';
      node: {
        __typename?: 'ProjectFile';
        id: string;
        file: {
          __typename?: 'File';
          id: string;
          extension: string;
          fileName: string;
          path?: string | null;
          userAccess: string;
          defaultAccess: string;
          signedUrl: string;
          workspace: {
            __typename?: 'Workspace';
            id: string;
            name: string;
            slug: string;
            usage?: {
              __typename?: 'WorkspaceUsage';
              aiRequestCountLimitReached: boolean;
            } | null;
            subscriptions: {
              __typename?: 'WorkspaceSubscriptionsConnection';
              edges: Array<{
                __typename?: 'WorkspaceSubscriptionsConnectionEdge';
                node: {
                  __typename?: 'WorkspaceSubscription';
                  id: string;
                  status: string;
                  stripePlanId: string;
                  stripeSubscriptionId: string;
                };
              } | null>;
            };
            members: {
              __typename?: 'WorkspaceMembersConnection';
              edges: Array<{
                __typename?: 'WorkspaceMembersConnectionEdge';
                node: {
                  __typename?: 'WorkspaceMember';
                  id: string;
                  role: string;
                  defaultAccess: string;
                  user: {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null;
                    lastName?: string | null;
                    email?: string | null;
                    picture?: string | null;
                  };
                };
              } | null>;
            };
          };
          tags: {
            __typename?: 'FileTagsConnection';
            edges: Array<{
              __typename?: 'FileTagsConnectionEdge';
              node: {
                __typename?: 'FieldTag';
                id: string;
                name: string;
                hexColor: string;
                fieldIds: Array<string>;
              };
            } | null>;
          };
          latestFields?: {
            __typename?: 'FileLatestFieldsConnection';
            edges: Array<{
              __typename?: 'FileLatestFieldsConnectionEdge';
              node: {
                __typename?: 'FileField';
                id: string;
                line: number;
                key: string;
                type?: string | null;
                exampleValue?: string | null;
                parentId?: string | null;
                commitHash: string;
                meta?: any | null;
                positionX?: number | null;
                positionY?: number | null;
              };
            } | null>;
          };
          fields?: {
            __typename?: 'FileFieldsConnection';
            edges: Array<{
              __typename?: 'FileFieldsConnectionEdge';
              node: {
                __typename?: 'FileField';
                id: string;
                line: number;
                key: string;
                type?: string | null;
                exampleValue?: string | null;
                parentId?: string | null;
                commitHash: string;
                meta?: any | null;
                positionX?: number | null;
                positionY?: number | null;
              };
            } | null>;
          };
          suggestions: {
            __typename?: 'FileSuggestionsConnection';
            edges: Array<{
              __typename?: 'FileSuggestionsConnectionEdge';
              node: {
                __typename?: 'Suggestion';
                id: string;
                workspaceId: string;
                meta?: any | null;
                suggestedBy: string;
                type: string;
                action: string;
                referenceKey?: string | null;
                value: string;
                isResolved: boolean;
                assignedTo?: string | null;
                createdAt: any;
                fileFieldFileId?: string | null;
                fileFieldId?: string | null;
                fileFieldCommitHash?: string | null;
                suggestor: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  picture?: string | null;
                };
              };
            } | null>;
          };
          notes: {
            __typename?: 'FileNotesConnection';
            edges: Array<{
              __typename?: 'FileNotesConnectionEdge';
              node: {
                __typename?: 'FileComment';
                id: string;
                fieldId?: string | null;
                createdAt: any;
                createdBy: string;
                comment: string;
                positionX?: number | null;
                positionY?: number | null;
                parentCommentId?: string | null;
                isNote: boolean;
              };
            } | null>;
          };
          comments: {
            __typename?: 'FileCommentsConnection';
            edges: Array<{
              __typename?: 'FileCommentsConnectionEdge';
              node: {
                __typename?: 'FileComment';
                id: string;
                fieldId?: string | null;
                createdAt: any;
                createdBy: string;
                comment: string;
                isNote: boolean;
              };
            } | null>;
          };
          projects: {
            __typename?: 'FileProjectsConnection';
            edges: Array<{
              __typename?: 'FileProjectsConnectionEdge';
              node: {
                __typename?: 'ProjectFile';
                project: {
                  __typename?: 'Project';
                  id: string;
                  name: string;
                  access: {
                    __typename?: 'ProjectAccessConnection';
                    edges: Array<{
                      __typename?: 'ProjectAccessConnectionEdge';
                      node: {
                        __typename?: 'ProjectAccess';
                        id: string;
                        userId: string;
                        accessType: string;
                      };
                    } | null>;
                  };
                  repositories: {
                    __typename?: 'ProjectRepositoriesConnection';
                    edges: Array<{
                      __typename?: 'ProjectRepositoriesConnectionEdge';
                      node: {
                        __typename?: 'ProjectRepository';
                        id: string;
                        githubRepositoryId: string;
                        productionBranch: string;
                        hubqlBaseFolder: string;
                        githubRepositoryFullName: string;
                        provider: string;
                      };
                    } | null>;
                  };
                };
              };
            } | null>;
          };
        };
      };
    } | null>;
  };
};

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'Project';
    id: string;
    name: string;
    defaultAccess: string;
    ownedBy: string;
    owner: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    invitations: Array<{
      __typename?: 'Invitation';
      id: string;
      email?: string | null;
    }>;
    access: {
      __typename?: 'ProjectAccessConnection';
      edges: Array<{
        __typename?: 'ProjectAccessConnectionEdge';
        node: {
          __typename?: 'ProjectAccess';
          id: string;
          userId: string;
          accessType: string;
          user: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        };
      } | null>;
    };
    repositories: {
      __typename?: 'ProjectRepositoriesConnection';
      edges: Array<{
        __typename?: 'ProjectRepositoriesConnectionEdge';
        node: {
          __typename?: 'ProjectRepository';
          id: string;
          githubRepositoryId: string;
          productionBranch: string;
          hubqlBaseFolder: string;
          githubRepositoryFullName: string;
          provider: string;
        };
      } | null>;
    };
  } | null;
};

export type ProjectFileListQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
}>;

export type ProjectFileListQuery = {
  __typename?: 'Query';
  projectFiles: {
    __typename?: 'QueryProjectFilesConnection';
    edges: Array<{
      __typename?: 'QueryProjectFilesConnectionEdge';
      node: {
        __typename?: 'ProjectFile';
        id: string;
        file: {
          __typename?: 'File';
          id: string;
          fileName: string;
          extension: string;
          path?: string | null;
          createdAt: any;
          lastCommitHash: string;
          defaultAccess: string;
          access: {
            __typename?: 'FileAccessConnection';
            edges: Array<{
              __typename?: 'FileAccessConnectionEdge';
              node: {
                __typename?: 'FileAccess';
                id: string;
                accessType: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  picture?: string | null;
                };
              };
            } | null>;
          };
        };
      };
    } | null>;
  };
};

export type HubsQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type HubsQuery = {
  __typename?: 'Query';
  projectAccess: {
    __typename?: 'QueryProjectAccessConnection';
    edges: Array<{
      __typename?: 'QueryProjectAccessConnectionEdge';
      node: {
        __typename?: 'ProjectAccess';
        id: string;
        project: {
          __typename?: 'Project';
          id: string;
          name: string;
          createdAt: any;
          defaultAccess: string;
          ownedBy: string;
          owner: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
          repositories: {
            __typename?: 'ProjectRepositoriesConnection';
            edges: Array<{
              __typename?: 'ProjectRepositoriesConnectionEdge';
              node: {
                __typename?: 'ProjectRepository';
                id: string;
                githubRepositoryFullName: string;
                provider: string;
              };
            } | null>;
          };
          access: {
            __typename?: 'ProjectAccessConnection';
            edges: Array<{
              __typename?: 'ProjectAccessConnectionEdge';
              node: {
                __typename?: 'ProjectAccess';
                id: string;
                userId: string;
                accessType: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  picture?: string | null;
                };
              };
            } | null>;
          };
        };
      };
    } | null>;
  };
  privateProjects: {
    __typename?: 'QueryProjectsConnection';
    edges: Array<{
      __typename?: 'QueryProjectsConnectionEdge';
      node: {
        __typename?: 'Project';
        id: string;
        name: string;
        createdAt: any;
        defaultAccess: string;
        ownedBy: string;
        owner: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
        access: {
          __typename?: 'ProjectAccessConnection';
          edges: Array<{
            __typename?: 'ProjectAccessConnectionEdge';
            node: {
              __typename?: 'ProjectAccess';
              id: string;
              userId: string;
              accessType: string;
              user: {
                __typename?: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                picture?: string | null;
              };
            };
          } | null>;
        };
        repositories: {
          __typename?: 'ProjectRepositoriesConnection';
          edges: Array<{
            __typename?: 'ProjectRepositoriesConnectionEdge';
            node: {
              __typename?: 'ProjectRepository';
              id: string;
              githubRepositoryFullName: string;
              provider: string;
            };
          } | null>;
        };
      };
    } | null>;
  };
  workspaceProjects: {
    __typename?: 'QueryProjectsConnection';
    edges: Array<{
      __typename?: 'QueryProjectsConnectionEdge';
      node: {
        __typename?: 'Project';
        id: string;
        name: string;
        createdAt: any;
        defaultAccess: string;
        ownedBy: string;
        repositories: {
          __typename?: 'ProjectRepositoriesConnection';
          edges: Array<{
            __typename?: 'ProjectRepositoriesConnectionEdge';
            node: {
              __typename?: 'ProjectRepository';
              id: string;
              githubRepositoryFullName: string;
              provider: string;
            };
          } | null>;
        };
        access: {
          __typename?: 'ProjectAccessConnection';
          edges: Array<{
            __typename?: 'ProjectAccessConnectionEdge';
            node: {
              __typename?: 'ProjectAccess';
              id: string;
              accessType: string;
              user: {
                __typename?: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                picture?: string | null;
              };
            };
          } | null>;
        };
      };
    } | null>;
  };
  fileAccess: {
    __typename?: 'QueryFileAccessConnection';
    edges: Array<{
      __typename?: 'QueryFileAccessConnectionEdge';
      node: {
        __typename?: 'FileAccess';
        id: string;
        file: {
          __typename?: 'File';
          id: string;
          fileName: string;
          extension: string;
          path?: string | null;
          createdAt: any;
          lastCommitHash: string;
          defaultAccess: string;
        };
      };
    } | null>;
  };
};

export type RouteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RouteQuery = {
  __typename?: 'Query';
  route?: {
    __typename?: 'Route';
    id: string;
    slug: string;
    name: string;
  } | null;
};

export type WorkspaceRoutesQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
}>;

export type WorkspaceRoutesQuery = {
  __typename?: 'Query';
  routes: {
    __typename?: 'QueryRoutesConnection';
    edges: Array<{
      __typename?: 'QueryRoutesConnectionEdge';
      node: { __typename?: 'Route'; id: string; slug: string; name: string };
    } | null>;
  };
};

export type UserBookmarksQueryVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type UserBookmarksQuery = {
  __typename?: 'Query';
  userBookmarks: {
    __typename?: 'QueryUserBookmarksConnection';
    edges: Array<{
      __typename?: 'QueryUserBookmarksConnectionEdge';
      node: {
        __typename?: 'UserBookmark';
        id: string;
        createdAt: any;
        file?: { __typename?: 'File'; id: string; fileName: string } | null;
        project?: {
          __typename?: 'Project';
          id: string;
          name: string;
          createdAt: any;
          defaultAccess: string;
          ownedBy: string;
          owner: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
          access: {
            __typename?: 'ProjectAccessConnection';
            edges: Array<{
              __typename?: 'ProjectAccessConnectionEdge';
              node: {
                __typename?: 'ProjectAccess';
                id: string;
                userId: string;
                accessType: string;
                user: {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  picture?: string | null;
                };
              };
            } | null>;
          };
          repositories: {
            __typename?: 'ProjectRepositoriesConnection';
            edges: Array<{
              __typename?: 'ProjectRepositoriesConnectionEdge';
              node: {
                __typename?: 'ProjectRepository';
                id: string;
                githubRepositoryFullName: string;
                provider: string;
              };
            } | null>;
          };
        } | null;
      };
    } | null>;
  };
};

export type UserReposQueryVariables = Exact<{ [key: string]: never }>;

export type UserReposQuery = {
  __typename?: 'Query';
  userRepos: Array<{
    __typename?: 'UserReposOutput';
    id: string;
    name: string;
    fullName: string;
    private: boolean;
    avatarUrl: string;
    appInstallationId: string;
  }>;
};

export type ValidatePersonalAccessTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ValidatePersonalAccessTokenQuery = {
  __typename?: 'Query';
  validatePersonalAccessToken:
    | { __typename: 'Error'; message: string }
    | {
        __typename: 'ValidatePersonalAccessTokenSuccess';
        data: {
          __typename?: 'ValidatePersonalAccessTokenOutput';
          userId: string;
        };
      };
};

export type WorkspaceBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type WorkspaceBySlugQuery = {
  __typename?: 'Query';
  workspaceBySlug: {
    __typename?: 'Workspace';
    id: string;
    name: string;
    slug: string;
    usage?: {
      __typename?: 'WorkspaceUsage';
      aiRequestCountLimitReached: boolean;
    } | null;
    subscriptions: {
      __typename?: 'WorkspaceSubscriptionsConnection';
      edges: Array<{
        __typename?: 'WorkspaceSubscriptionsConnectionEdge';
        node: {
          __typename?: 'WorkspaceSubscription';
          id: string;
          status: string;
          stripePlanId: string;
          stripeSubscriptionId: string;
        };
      } | null>;
    };
    members: {
      __typename?: 'WorkspaceMembersConnection';
      edges: Array<{
        __typename?: 'WorkspaceMembersConnectionEdge';
        node: {
          __typename?: 'WorkspaceMember';
          id: string;
          role: string;
          defaultAccess: string;
          user: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            picture?: string | null;
          };
        };
      } | null>;
    };
  };
};

export type WorkspaceSubscriptionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type WorkspaceSubscriptionsQuery = {
  __typename?: 'Query';
  workspace: {
    __typename?: 'Workspace';
    id: string;
    seatsUsed: number;
    invitations: Array<{
      __typename?: 'Invitation';
      id: string;
      email?: string | null;
    }>;
    subscriptions: {
      __typename?: 'WorkspaceSubscriptionsConnection';
      edges: Array<{
        __typename?: 'WorkspaceSubscriptionsConnectionEdge';
        node: {
          __typename?: 'WorkspaceSubscription';
          id: string;
          status: string;
          stripePlanId: string;
          stripeSubscriptionId: string;
          seatsTotal: number;
          trialStartsAt?: any | null;
          trialEndsAt?: any | null;
          hasPaymentMethod: boolean;
        };
      } | null>;
    };
  };
};

export type WorkspaceMembershipsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type WorkspaceMembershipsQuery = {
  __typename?: 'Query';
  workspaceMembers: {
    __typename?: 'QueryWorkspaceMembersConnection';
    edges: Array<{
      __typename?: 'QueryWorkspaceMembersConnectionEdge';
      node: {
        __typename?: 'WorkspaceMember';
        id: string;
        role: string;
        userId: string;
        workspace: {
          __typename?: 'Workspace';
          id: string;
          name: string;
          slug: string;
          createdAt: any;
          subscriptions: {
            __typename?: 'WorkspaceSubscriptionsConnection';
            edges: Array<{
              __typename?: 'WorkspaceSubscriptionsConnectionEdge';
              node: {
                __typename?: 'WorkspaceSubscription';
                id: string;
                status: string;
                stripePlanId: string;
                stripeSubscriptionId: string;
                seatsTotal: number;
                trialStartsAt?: any | null;
                trialEndsAt?: any | null;
                hasPaymentMethod: boolean;
              };
            } | null>;
          };
        };
      };
    } | null>;
  };
};

export const ApplyDiscountCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyDiscountCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'discountCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyDiscountCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'discountCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'discountCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyDiscountCodeMutation,
  ApplyDiscountCodeMutationVariables
>;
export const CompleteUserOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'completeUserOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completeUserOnboarding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'CompleteUserOnboardingSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteUserOnboardingMutation,
  CompleteUserOnboardingMutationVariables
>;
export const CreateCustomerPortalSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCustomerPortalSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'flow' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscriptionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomerPortalSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'flow' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'flow' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscriptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscriptionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'CreateCustomerPortalSessionSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCustomerPortalSessionMutation,
  CreateCustomerPortalSessionMutationVariables
>;
export const CreateEndpointDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createEndpoint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EndpointCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEndpoint' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateEndpointSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEndpointMutation,
  CreateEndpointMutationVariables
>;
export const CreateFieldTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFieldTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FieldTagCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFieldTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFieldTagSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFieldTagMutation,
  CreateFieldTagMutationVariables
>;
export const CreateFileAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileAccessCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileAccessSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileAccessMutation,
  CreateFileAccessMutationVariables
>;
export const CreateFileCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileCommentCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileCommentSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileCommentMutation,
  CreateFileCommentMutationVariables
>;
export const CreateFileFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileFieldCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileFieldSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileFieldMutation,
  CreateFileFieldMutationVariables
>;
export const CreateFileFieldTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileFieldTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileFieldTagCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileFieldTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileFieldTagSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileFieldTagMutation,
  CreateFileFieldTagMutationVariables
>;
export const CreateFileFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FileFieldCreateInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileFieldsSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileFieldsMutation,
  CreateFileFieldsMutationVariables
>;
export const CreateFileFromUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileFromUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hubId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileFromUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'commitHash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'commitHash' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hubId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hubId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileFromUrlSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileFromUrlMutation,
  CreateFileFromUrlMutationVariables
>;
export const CreateFileRevisionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFileRevision' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileRevisionCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFileRevision' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateFileRevisionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFileRevisionMutation,
  CreateFileRevisionMutationVariables
>;
export const CreatePrDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPr' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PRCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPr' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreatePrSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'pullRequestNumber',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePrMutation, CreatePrMutationVariables>;
export const CreatePersonalAccessTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePersonalAccessToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'expiresAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PersonalAccessTokenExpiresAt' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPersonalAccessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expiresAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'expiresAt' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'CreatePersonalAccessTokenSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expiresAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'token' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePersonalAccessTokenMutation,
  CreatePersonalAccessTokenMutationVariables
>;
export const CreateProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateProjectSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const CreateProjectFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectFileCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateProjectFileSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectFileMutation,
  CreateProjectFileMutationVariables
>;
export const CreateProjectRepositoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProjectRepository' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectRepositoryCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProjectRepository' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'CreateProjectRepositorySuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'provider' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'githubRepositoryId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productionBranch' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hubqlBaseFolder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'githubRepositoryFullName',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProjectRepositoryMutation,
  CreateProjectRepositoryMutationVariables
>;
export const CreateSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SuggestionCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateSuggestionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSuggestionMutation,
  CreateSuggestionMutationVariables
>;
export const CreateUserBookmarkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserBookmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserBookmarkCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateUserBookmarkSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserBookmarkMutation,
  CreateUserBookmarkMutationVariables
>;
export const CreateWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WorkspaceCreateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateWorkspaceSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const DeleteFieldTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFieldTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFieldTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteFieldTagSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFieldTagMutation,
  DeleteFieldTagMutationVariables
>;
export const DeleteFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteFileSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileMutation, DeleteFileMutationVariables>;
export const DeleteFileAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFileAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFileAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteFileAccessSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFileAccessMutation,
  DeleteFileAccessMutationVariables
>;
export const DeleteFileCommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFileComment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFileComment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteFileCommentSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFileCommentMutation,
  DeleteFileCommentMutationVariables
>;
export const DeleteFileCommentThreadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFileCommentThread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFileCommentThread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'DeleteFileCommentThreadSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFileCommentThreadMutation,
  DeleteFileCommentThreadMutationVariables
>;
export const DeleteFileFieldTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteFileFieldTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tagId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFileFieldTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'commitHash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'commitHash' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fieldId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fieldId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tagId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tagId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteFileFieldTagSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFileFieldTagMutation,
  DeleteFileFieldTagMutationVariables
>;
export const DeleteProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteProjectSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const DeleteProjectAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteProjectAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProjectAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteProjectAccessSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProjectAccessMutation,
  DeleteProjectAccessMutationVariables
>;
export const DeleteSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteSuggestionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSuggestionMutation,
  DeleteSuggestionMutationVariables
>;
export const DeleteUserBookmarkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteUserBookmark' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserBookmark' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteUserBookmarkSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserBookmarkMutation,
  DeleteUserBookmarkMutationVariables
>;
export const DeleteWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteWorkspaceSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;
export const DeleteWorkspaceMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteWorkspaceMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWorkspaceMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'DeleteWorkspaceMemberSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWorkspaceMemberMutation,
  DeleteWorkspaceMemberMutationVariables
>;
export const FindLastOrCreateHubDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'findLastOrCreateHub' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findLastOrCreateHub' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FindLastOrCreateHubSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindLastOrCreateHubMutation,
  FindLastOrCreateHubMutationVariables
>;
export const ImportFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'importFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'projectId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImportFilesSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'success' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportFilesMutation, ImportFilesMutationVariables>;
export const IncreaseAiRequestCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'increaseAIRequestCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'increaseAIRequestCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'IncreaseAIRequestCountSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IncreaseAiRequestCountMutation,
  IncreaseAiRequestCountMutationVariables
>;
export const InviteUsersToProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteUsersToProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InviteUserToProjectInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsersToProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'InviteUsersToProjectSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteUsersToProjectMutation,
  InviteUsersToProjectMutationVariables
>;
export const InviteUsersToWorkspaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteUsersToWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InviteUserToWorkspaceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsersToWorkspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'InviteUsersToWorkspaceSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteUsersToWorkspaceMutation,
  InviteUsersToWorkspaceMutationVariables
>;
export const InviteUsersToFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteUsersToFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InviteUserToFileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsersToFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'InviteUsersToFileSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteUsersToFileMutation,
  InviteUsersToFileMutationVariables
>;
export const RevokeInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'revokeInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revokeInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'RevokeInvitationSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RevokeInvitationMutation,
  RevokeInvitationMutationVariables
>;
export const UpdateFieldTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFieldTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FieldTagUpdateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFieldTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateFieldTagSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFieldTagMutation,
  UpdateFieldTagMutationVariables
>;
export const UpdateFileDefaultAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileDefaultAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'defaultAccess' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileDefaultAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'defaultAccess' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'defaultAccess' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateFileDefaultAccessSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultAccess' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileDefaultAccessMutation,
  UpdateFileDefaultAccessMutationVariables
>;
export const UpdateFileCommentContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileCommentContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'comment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileCommentContent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'comment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateFileCommentContentSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileCommentContentMutation,
  UpdateFileCommentContentMutationVariables
>;
export const UpdateFileCommentPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileCommentPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'positionX' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'positionY' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileCommentPosition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionX' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'positionX' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionY' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'positionY' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateFileCommentPositionSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionX' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionY' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileCommentPositionMutation,
  UpdateFileCommentPositionMutationVariables
>;
export const UpdateFileFieldPositionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileFieldPosition' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'positionX' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'positionY' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileFieldPosition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'commitHash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'commitHash' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionX' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'positionX' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'positionY' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'positionY' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateFileFieldPositionSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionX' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionY' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileFieldPositionMutation,
  UpdateFileFieldPositionMutationVariables
>;
export const BulkUpdateFileFieldPositionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkUpdateFileFieldPositions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inputs' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateFileFieldPositionInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileFieldPositions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inputs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateFileFieldPositionsSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionX' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'positionY' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkUpdateFileFieldPositionsMutation,
  BulkUpdateFileFieldPositionsMutationVariables
>;
export const UpdateFileHashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileHash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastCommitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileHash' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastCommitHash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastCommitHash' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateFileHashSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastCommitHash' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileHashMutation,
  UpdateFileHashMutationVariables
>;
export const UpdateFileNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateFileNameSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileNameMutation,
  UpdateFileNameMutationVariables
>;
export const UpdateFileAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFileAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFileAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateFileAccessSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accessType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFileAccessMutation,
  UpdateFileAccessMutationVariables
>;
export const UpdateProjectDefaultAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectDefaultAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'defaultAccess' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectDefaultAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'defaultAccess' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'defaultAccess' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateProjectDefaultAccessSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultAccess' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectDefaultAccessMutation,
  UpdateProjectDefaultAccessMutationVariables
>;
export const UpdateProjectNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProjectName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProjectName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateProjectNameSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProjectNameMutation,
  UpdateProjectNameMutationVariables
>;
export const UpdateUserOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OnboardingRole' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'useCases' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserOnboarding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'role' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useCases' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'useCases' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateUserOnboardingSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'useCases' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserOnboardingMutation,
  UpdateUserOnboardingMutationVariables
>;
export const UpdateUserSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserSubscriptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscribedToNews' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscribedToProductUpdates' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subscribedToStatusUpdates' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserSubscriptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscribedToNews' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscribedToNews' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscribedToProductUpdates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscribedToProductUpdates' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subscribedToStatusUpdates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subscribedToStatusUpdates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'UpdateUserSubscriptionsSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscribedToNews' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subscribedToProductUpdates',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subscribedToStatusUpdates',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserSubscriptionsMutation,
  UpdateUserSubscriptionsMutationVariables
>;
export const UpdateWorkspaceNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWorkspaceName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateWorkspaceNameSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceNameMutation,
  UpdateWorkspaceNameMutationVariables
>;
export const UpdateWorkspaceSlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateWorkspaceSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWorkspaceSlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpdateWorkspaceSlugSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWorkspaceSlugMutation,
  UpdateWorkspaceSlugMutationVariables
>;
export const UpgradeSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upgradeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'discountCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upgradeSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'discountCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'discountCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UpgradeSubscriptionSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpgradeSubscriptionMutation,
  UpgradeSubscriptionMutationVariables
>;
export const ValidateDiscountCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'validateDiscountCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'discountCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateDiscountCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'discountCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'discountCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ValidateDiscountCodeSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidateDiscountCodeMutation,
  ValidateDiscountCodeMutationVariables
>;
export const EndpointsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'endpoints' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'endpoints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workspaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workspaceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EndpointsQuery, EndpointsQueryVariables>;
export const EventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'events' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payload' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EventsQuery, EventsQueryVariables>;
export const FileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'file' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userAccess' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultAccess' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signedUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'commitHash' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'commitHash' },
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'aiRequestCountLimitReached',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'stripePlanId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'stripeSubscriptionId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'role' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'defaultAccess',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'email',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'picture',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'commitHash' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'commitHash' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hexColor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fieldIds' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fields' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'line' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'commitHash' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'commitHash' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'line' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'exampleValue',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'commitHash' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meta' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'positionX' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'positionY' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'workspaceId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meta' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'suggestedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'action' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'referenceKey',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isResolved' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'assignedTo' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fileFieldFileId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fileFieldId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fileFieldCommitHash',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'suggestor' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'picture',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fieldId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'positionX' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'positionY' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentCommentId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isNote' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'comments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fieldId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isNote' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'project' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'access',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'edges',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'node',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'userId',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'accessType',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'repositories',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'edges',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'node',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'githubRepositoryId',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'productionBranch',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'hubqlBaseFolder',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'githubRepositoryFullName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'provider',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuery, FileQueryVariables>;
export const FileAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fileAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ownedBy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'role' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'defaultAccess',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'email',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'picture',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'extension' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultAccess' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'access' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accessType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdBy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'picture',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'project' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'defaultAccess',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'access',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'edges',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'node',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'userId',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'accessType',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'user',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'firstName',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'lastName',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'picture',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileAccessQuery, FileAccessQueryVariables>;
export const FileCommentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'fileComments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileComments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fileId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'isNote' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignedTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isComplete' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentCommentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isNote' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileCommentsQuery, FileCommentsQueryVariables>;
export const HubFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'hubFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hubId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'commitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'useCommitHash' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workspace' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'aiRequestCountLimitReached',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'stripePlanId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'stripeSubscriptionId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'members' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'role' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'defaultAccess',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'user' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'firstName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'lastName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'email',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'picture',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'file' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'createdAt' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'hubId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'extension' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'path' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userAccess' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultAccess',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signedUrl' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'commitHash',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'commitHash',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workspace' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'usage',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'aiRequestCountLimitReached',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subscriptions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'edges',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'node',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'status',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'stripePlanId',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'stripeSubscriptionId',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'members',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'edges',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'node',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'role',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'defaultAccess',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'user',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'firstName',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'lastName',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'email',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'picture',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tags' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'commitHash',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'commitHash',
                                          },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'hexColor',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fieldIds',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'latestFields',
                                    },
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: { kind: 'Name', value: 'skip' },
                                        arguments: [
                                          {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'if' },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'useCommitHash',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'line',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'key',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'type',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'exampleValue',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'parentId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'commitHash',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'meta',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positionX',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positionY',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fields' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'orderBy',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'line',
                                              },
                                              value: {
                                                kind: 'EnumValue',
                                                value: 'asc',
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'where' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'commitHash',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_eq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'commitHash',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    directives: [
                                      {
                                        kind: 'Directive',
                                        name: {
                                          kind: 'Name',
                                          value: 'include',
                                        },
                                        arguments: [
                                          {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'if' },
                                            value: {
                                              kind: 'Variable',
                                              name: {
                                                kind: 'Name',
                                                value: 'useCommitHash',
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'line',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'key',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'type',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'exampleValue',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'parentId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'commitHash',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'meta',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positionX',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positionY',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'suggestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'workspaceId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'meta',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'suggestedBy',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'type',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'action',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'referenceKey',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'value',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'isResolved',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'assignedTo',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'createdAt',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'fileFieldFileId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fileFieldId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'fileFieldCommitHash',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'suggestor',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'firstName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'picture',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fieldId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'createdAt',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'createdBy',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'comment',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positionX',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'positionY',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'parentCommentId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'isNote',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fieldId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'createdAt',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'createdBy',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'comment',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'isNote',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projects' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'project',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'name',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'access',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'edges',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'node',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'id',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'userId',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'accessType',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'repositories',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'edges',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'node',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'id',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'githubRepositoryId',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'productionBranch',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'hubqlBaseFolder',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'githubRepositoryFullName',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'provider',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HubFilesQuery, HubFilesQueryVariables>;
export const ProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'project' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultAccess' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ownedBy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'picture' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'access' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accessType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'picture',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'repositories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'githubRepositoryId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productionBranch',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hubqlBaseFolder',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'githubRepositoryFullName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'provider' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectQuery, ProjectQueryVariables>;
export const ProjectFileListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'projectFileList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'projectId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'file' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'createdAt' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'projectId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'projectId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'extension' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'path' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastCommitHash',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultAccess',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'access' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'accessType',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'user',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'firstName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'lastName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'picture',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectFileListQuery,
  ProjectFileListQueryVariables
>;
export const HubsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'hubs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projectAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultAccess',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownedBy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'owner' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'picture',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'repositories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'githubRepositoryFullName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'provider',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'access' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'userId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'accessType',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'user',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'firstName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'lastName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'picture',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'privateProjects' },
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'workspaceId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ownedBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'defaultAccess' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'PRIVATE',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultAccess' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ownedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'picture' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'access' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'userId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'accessType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'user',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'picture',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'repositories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'githubRepositoryFullName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'provider',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'workspaceProjects' },
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'workspaceId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'defaultAccess' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'StringValue',
                              value: 'PRIVATE',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'defaultAccess' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ownedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'repositories' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'githubRepositoryFullName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'provider',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'access' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'accessType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'user',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'picture',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileAccess' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'extension' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'path' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastCommitHash',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultAccess',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HubsQuery, HubsQueryVariables>;
export const RouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'route' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RouteQuery, RouteQueryVariables>;
export const WorkspaceRoutesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'workspaceRoutes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'workspaceId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WorkspaceRoutesQuery,
  WorkspaceRoutesQueryVariables
>;
export const UserBookmarksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userBookmarks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userBookmarks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'workspaceId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'workspaceId' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'file' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultAccess',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownedBy' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'owner' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'picture',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'access' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'userId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'accessType',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'user',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'firstName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'lastName',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'picture',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'repositories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'githubRepositoryFullName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'provider',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBookmarksQuery, UserBookmarksQueryVariables>;
export const UserReposDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userRepos' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRepos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appInstallationId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserReposQuery, UserReposQueryVariables>;
export const ValidatePersonalAccessTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidatePersonalAccessToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validatePersonalAccessToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'ValidatePersonalAccessTokenSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Error' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ValidatePersonalAccessTokenQuery,
  ValidatePersonalAccessTokenQueryVariables
>;
export const WorkspaceBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'workspaceBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'aiRequestCountLimitReached',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'stripePlanId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'stripeSubscriptionId',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'defaultAccess',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'picture',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WorkspaceBySlugQuery,
  WorkspaceBySlugQueryVariables
>;
export const WorkspaceSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'workspaceSubscriptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspace' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsUsed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'stripePlanId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'stripeSubscriptionId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seatsTotal' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'trialStartsAt',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'trialEndsAt',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasPaymentMethod',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WorkspaceSubscriptionsQuery,
  WorkspaceSubscriptionsQueryVariables
>;
export const WorkspaceMembershipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'workspaceMemberships' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workspaceMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'role' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workspace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subscriptions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'edges',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'status',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'stripePlanId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'stripeSubscriptionId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'seatsTotal',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'trialStartsAt',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'trialEndsAt',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'hasPaymentMethod',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WorkspaceMembershipsQuery,
  WorkspaceMembershipsQueryVariables
>;
