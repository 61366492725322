import { useFileStore } from '../state/stores/file';
import {
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
} from '@heroicons/react/20/solid';
import { useLiveStore } from '../state/stores/live';
import { useCommentStore } from '../state/stores/comments';
import { useEffect } from 'react';

export const FileCommentFilter = ({ commentData }) => {
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setObjectKey = useFileStore((state) => state.setObjectKey);
  const fields = useLiveStore((state) => state.fields);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const setThreadId = useCommentStore((state) => state.setThreadId);
  const notes = useLiveStore((state) => state.notes);
  const setCurrentComments = useCommentStore(
    (state) => state.setCurrentComments
  );
  const note = notes.find((note) => note.id === selectedFieldId);

  useEffect(() => {
    if (selectedFieldId !== null && note) {
      setCurrentComments(note);
    }
  }, [note, selectedFieldId, setCurrentComments]);
  const handleClick = (isNote?: boolean, fieldId?: string, id?: string) => {
    if (isNote && id) {
      setThreadId(id);
      setSelectedFieldId(id);
    } else if (fieldId) {
      setSelectedFieldId(fieldId);
    }
    if (fieldId === '__GENERAL__') {
      setObjectKey(null);
    }
  };
  const groupedData = commentData
    .sort((a, b) => {
      if (a.parentCommentId) {
        return 1;
      }
      if (b.parentCommentId) {
        return -1;
      }
    })
    .reduce((groups, item) => {
      const group = groups.find((group) =>
        item.isNote
          ? item.parentCommentId
            ? group.id === item.parentCommentId
            : group.comment === item.comment
          : group.fieldId === item.fieldId
      );
      const rawField = fields.find((field) => field.id === item.fieldId);
      const note = commentData
        ?.find((comment) => comment.isNote && comment.id === item.id)
        ?.comment?.substring(0, 40);
      const name = rawField?.key ?? note;

      const parentName = fields.find(
        (field) => field.id === rawField?.parentId
      )?.key;

      if (group) {
        group.items.push({
          ...item,
          name,
          parentName,
        });
      } else {
        groups.push({
          id: item.id,
          parentName,
          isNote: item.isNote,
          name,
          fieldId: item.fieldId,
          items: [item],
        });
      }
      return groups;
    }, []);

  return (
    <div className=" flex flex-col gap-2">
      {selectedFieldId === null && (
        <>
          <div
            className="relative px-3 py-3 w-full bg-zinc-700 rounded-sm flex gap-4 cursor-pointer hover:bg-zinc-600"
            onClick={() => handleClick(false, '__GENERAL__')}
          >
            <div className="h-[38px] w-[38px] bg-zinc-800 rounded-sm flex justify-center items-center">
              <ChatBubbleLeftRightIcon className="fill-zinc-400 w-6 h-6" />
            </div>
            General
          </div>

          {groupedData?.map((field: any) => {
            const isNotGeneral = field && field.fieldId !== '__GENERAL__';
            const noteThread = field?.isNote;
            const removeTags = (str: string) => {
              if (str === null || str === '') return false;
              else str = str.toString();
              return str.replace(/(<([^>]+)>)/gi, '');
            };
            const isNotEmpty = field && field.name !== undefined;
            return (
              isNotEmpty &&
              isNotGeneral && (
                <div
                  key={'channel-list' + (field.id ?? field.fieldId)}
                  className="relative px-3 py-3 w-full bg-zinc-700 rounded-sm gap-x-4 gap-y-0 cursor-pointer hover:bg-zinc-600 grid grid-cols-[40px_minmax(100px,_1fr)] "
                  onClick={() =>
                    handleClick(field.isNote, field.fieldId, field.id)
                  }
                >
                  <div className="h-[38px] w-[38px] bg-zinc-800 rounded-sm flex justify-center items-center">
                    {noteThread ? (
                      <DocumentTextIcon className="fill-zinc-400 w-6 h-6" />
                    ) : (
                      <ChatBubbleLeftRightIcon className="fill-zinc-400 w-6 h-6" />
                    )}
                  </div>
                  <div className="truncate h-fit w-full">
                    {field.parentName && field.parentName}
                    {field.parentName && '.'}
                    {noteThread ? removeTags(field.name) : field?.name}
                  </div>
                </div>
              )
            );
          })}
        </>
      )}
    </div>
  );
};
