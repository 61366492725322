import { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { useFileStore } from '../state/stores/file';
import { useCommentStore } from '../state/stores/comments';
import { PresenceIndicator } from '../files/PresenceIndicator';
import { LiveField, useLiveStore } from '../state/stores/live';
import { FieldContextMenu } from './FieldContextMenu';
import { useContextMenuStore } from '../state/stores/contextmenu';
import clsx from 'clsx';
import { SuggestAddField } from '../files/SuggestAddField';
import { Indicators } from './Indicators';
import { WrapNodeProps } from 'reactflow';
import { NodeToggleIcon } from 'libs/hubqlkit/src/lib/Icons/NodeToggleIcon';
import {
  ContextMenu,
  ContextMenuTrigger,
  Dialog,
  DialogContent,
  Modal,
  useModal,
} from '@hubql/hubqlkit';
import { useCenterView } from '../util/useCenterView';
import { toast } from 'react-toastify';

interface Props {
  objectKey: string;
  value: string;
  isObject: string;
  parentId: string;
  nodeProps: WrapNodeProps;
  hasChildren: boolean;
}

export const FieldRowJSON = ({
  objectKey,
  value,
  isObject,
  parentId,
  nodeProps,
  hasChildren,
}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const workMode = useFileStore((state) => state.workMode);
  const setObjectKey = useFileStore((state) => state.setObjectKey);
  const setExampleValue = useFileStore((state) => state.setExampleValue);
  const setType = useFileStore((state) => state.setType);
  const setFields = useLiveStore((state) => state.setFields);
  const fields = useLiveStore((state) => state.fields);
  const suggestions = useLiveStore((state) => state.suggestions);
  const fieldData = fields?.find(
    (field) => field.key === objectKey && field.parentId == parentId
  );
  const fieldId = fieldData?.id;
  const parentObjectKey =
    fields?.find((field) => field.id == parentId)?.key ?? '';
  const fieldSuggestions =
    suggestions.filter((item) => item.fileFieldId === fieldId)?.[0]?.action ??
    '';
  const type = fieldData?.type ?? Array.isArray(value) ? 'array' : typeof value;
  const canComment = workMode === 'VIEW' ? false : true;
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const fileUsers = useLiveStore((state) => state.liveblocks.others);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const others = fileUsers.filter(
    (other) => other.presence['selectedFieldId'] === fieldId
  );

  const isActiveField = selectedFieldId === fieldId;
  const hasComment = useCommentStore(
    useCallback(
      (state) => {
        if (!canComment) return false;
        return state.comments.find((comment) => comment.fieldId === fieldId)
          ? true
          : false;
      },
      [canComment, fieldId]
    )
  );

  const suggestionColorMap = (key: string) => {
    switch (key) {
      case 'REMOVE':
        return 'bg-[#361C1C] line-through	text-red-700';
      case 'ADD':
        return 'bg-sky-900/20';
      case 'EDIT':
        return 'bg-sky-900/20';
      default:
        return 'inherit';
    }
  };

  const [showIcon, setShowIcon] = useState(hasComment);

  useEffect(() => {
    if (fieldId === selectedFieldId) {
      setShowIcon(true);
    } else {
      setShowIcon(hasComment);
    }
  }, [selectedFieldId, fieldId, hasComment]);

  const showLiveLocation = isActiveField ? activeFieldColor : 'transparent';
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const showSuggest = useContextMenuStore((state) => state.showSuggest);

  const { handleCenterView } = useCenterView();

  const findMatchedField = (key: string, parentId?: string) => {
    return fields.find((field) => {
      if (!parentId) {
        return field.key === key && !field.parentId;
      }
      return field.key === key && field.parentId === parentId;
    });
  };

  const findAndToggleChildren = (field: LiveField, status: boolean) => {
    field.hideChildren = status;

    const children = fields.filter((child) => child.parentId === field.id);

    if (children.length === 0) {
      return;
    }

    children.forEach((child) => {
      child.hidden = status;
      findAndToggleChildren(child, status);
    });
  };

  const handleShowActions = () => {
    if (!fieldId) return;
    setSelectedFieldId(fieldId);
    setObjectKey(objectKey);
    setExampleValue(value);
    setType(type);
    if (showSuggest) {
      setShowSuggest(false);
    }
  };

  const handleDoubleClick = () => {
    if (!fieldId) return;
    setSelectedFieldId(fieldId);
    if (selectedFieldId) {
      handleCenterView(selectedFieldId, parentId);
    }
  };

  useEffect(() => {
    setSelectedFieldId(null);
    setObjectKey(null);
    setExampleValue(null);
    setType(null);
  }, []);

  const isNotViewMode =
    workMode !== 'VIEW' && suggestionColorMap(fieldSuggestions);

  if (!fieldId) return null;

  return (
    <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
      <div
        className={clsx('w-full', isNotViewMode)}
        onClick={handleShowActions}
        onDoubleClick={handleDoubleClick}
        id={'table-row'}
        style={{
          outline: isActiveField
            ? `1px solid ${showLiveLocation}`
            : '1px solid transparent',
          zIndex: isActiveField ? 100 : 0,
        }}
        onContextMenu={() => {
          setSelectedFieldId(fieldId);
        }}
      >
        <ContextMenu>
          <ContextMenuTrigger className="gap-1 w-full relative rounded-md flex items-center">
            <div className=" flex-1 flex self-center items-start gap-1 py-2 px-4 h-full">
              <span className="inline-block align-middle text-green-400   w-max text-[16px]">
                {objectKey}:
              </span>
              <span className=" text-violet-300   break-words text-[16px] w-full">
                {isObject
                  ? JSON.stringify(value)?.substring(0, 30)
                  : value?.substring(0, 30)}
              </span>
              <div className="mx-4 flex flex-row items-center justify-center gap-2 h-full text-[16px]">
                <Indicators
                  hasComment={hasComment}
                  fieldSuggestions={fieldSuggestions}
                  flex
                />
              </div>
              {hasChildren && (
                <NodeToggleIcon
                  onClick={(status) => {
                    const matchedField = findMatchedField(objectKey, parentId);

                    if (!matchedField) {
                      return toast.error('Cannot find matched field');
                    }
                    matchedField.hideChildren = status;
                    findAndToggleChildren(matchedField, status);

                    setFields([...fields]);
                    return;
                  }}
                />
              )}
            </div>
            <div className="relative right-[20px]">
              <PresenceIndicator others={others} />
            </div>
            <FieldContextMenu
              fieldId={fieldId}
              extension="json"
              parentNodeProps={nodeProps}
              setIsDialogOpen={setIsDialogOpen}
            />
          </ContextMenuTrigger>
        </ContextMenu>
      </div>

      <DialogContent className="max-w-xl p-4">
        <SuggestAddField
          initialKey={''}
          initialValue={''}
          initialType={''}
          fieldId={fieldId}
          extension={'json'}
          parentId={parentId}
          parentObjectKey={parentObjectKey}
          setIsDialogOpen={setIsDialogOpen}
        />
      </DialogContent>
    </Dialog>
  );
};
