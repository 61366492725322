import { useMutation } from '@apollo/client';
import {
  DeleteFileAccessDocument,
  UpdateFileAccessDocument,
} from '@hubql/codegen';
import {
  Avatar,
  SelectValue,
  SelectNew,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectGroup,
} from '@hubql/hubqlkit';
import { useState } from 'react';
import { WorkMode, useFileStore } from '../state/stores/file';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { usePostHog } from 'posthog-js/react';

export const FileAccessItem = ({
  id,
  picture,
  firstName,
  accessType,
  allowChange,
  invitationId,
}) => {
  const posthog = usePostHog();

  const [deleteFileAccess] = useMutation(DeleteFileAccessDocument);
  const [updateFileAccess] = useMutation(UpdateFileAccessDocument);
  const [newAccess, setNewAccess] = useState(accessType);
  const setWorkMode = useFileStore((state) => state.setWorkMode);
  const { workspaceUser } = useWorkspaceUser();
  const userId = workspaceUser?.id;

  const updateAccess = async (workMode: WorkMode) => {
    setWorkMode(workMode);
    const result = await updateFileAccess({
      variables: {
        id,
        accessType: workMode,
      },
      refetchQueries: ['fileAccess'],
    });
    if (result.data?.updateFileAccess.__typename === 'Error') {
      console.error(result.data?.updateFileAccess.message);
      alert('Failed');
    }
  };

  const setValueChange = (value) => {
    if (value === 'deleting...') {
      handleDelete();
    } else {
      setNewAccess(value);
      updateAccess(value);
    }
  };

  const handleDelete = () => {
    const confirmed = confirm('Are you sure?');
    if (confirmed) {
      deleteFileAccess({
        variables: {
          id,
        },
        refetchQueries: ['fileAccess'],
      });
      try {
        posthog?.capture('FILE_ACCESS_DELETED', {});
      } catch (error) {}
    }
  };

  return (
    <div className="flex items-center justify-between w-full">
      <Avatar
        src={picture}
        name={firstName + `${invitationId ? ' (Invited)' : ''}`}
      ></Avatar>
      {allowChange ? (
        <SelectNew value={newAccess} onValueChange={setValueChange}>
          <SelectTrigger className="w-fit" id={'file-sharing-access-type'}>
            <SelectValue aria-label={newAccess}>{newAccess}</SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value={WorkMode.VIEW}>Read only</SelectItem>
              <SelectItem value={WorkMode.COMMENT}>Read and comment</SelectItem>
              <SelectItem value={WorkMode.EDIT}>
                Read, comment and edit
              </SelectItem>
              <SelectItem value="deleting...">Remove</SelectItem>
            </SelectGroup>
          </SelectContent>
        </SelectNew>
      ) : (
        <span className="text-xs text-zinc-400">{accessType}</span>
      )}
    </div>
  );
};
