import { create } from 'zustand';
import { LiveField } from './live';

export type FieldTag = {
  id: string | number;
  name: string;
  hexColor?: string;
  fieldIds?: string[];
};

export enum WorkMode {
  'VIEW' = 'VIEW',
  'COMMENT' = 'COMMENT',
  'EDIT' = 'EDIT',
}

type FilesState = {
  fileId: null | string;
  setFileId: (fileId: string | null) => void;
  commitHash: null | string;
  setCommitHash: (commitHash: string | null) => void;
  initialFields: null | LiveField[];
  fileName: string;
  setFileName: (fileName: string) => void;
  extension: null | 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml';
  setExtension: (
    extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
  ) => void;
  initialContent: string;
  setInitialContent: (initialContent: string) => void;
  setInitialFields: (initialFields: LiveField[]) => void;
  workMode: WorkMode;
  setWorkMode: (workMode: WorkMode) => void;
  availableWorkModes: WorkMode[];
  setAvailableWorkModes: (availableWorkModes: WorkMode[]) => void;
  projectId: null | string;
  setProjectId: (projectId: string) => void;
  projectName: null | string;
  setProjectName: (projectName: string) => void;
  hasProjectAccess: boolean;
  setHasProjectAccess: (hasProjectAccess: boolean) => void;
  isGitHubProject: boolean;
  setIsGitHubProject: (isGitHubProject: boolean) => void;
  hasSuggestion: boolean;
  setHasSuggestion: (hasSuggestion: boolean) => void;
  objectKey: string | null;
  setObjectKey: (objectKey: string | null) => void;
  exampleValue: any;
  setExampleValue: (exampleValue: any) => void;
  type: string | null;
  setType: (type: string | null) => void;
  addField: boolean;
  setAddField: (addField: boolean) => void;
  showKeyInputField: boolean;
  setShowKeyInputField: (showKeyInputField: boolean) => void;
  hasChanges: boolean;
  setHasChanges: (hasChanges: boolean) => void;
  openDiffEditor: boolean;
  setOpenDiffEditor: (openDiffEditor: boolean) => void;
  showFields: boolean;
  setShowFields: (showFields: boolean) => void;
  fieldTags: FieldTag[];
  setFieldTags: <T extends FieldTag>(fieldTags: T[]) => void;
  hoverFieldId: string | null;
  setHoverFieldId: (hoverFieldId: string | null) => void;
};

export const useFileStore = create<FilesState>((set) => ({
  fileId: null,
  setFileId: (fileId: string | null) =>
    set((state) => ({
      ...state,
      fileId,
    })),
  commitHash: null,
  setCommitHash: (commitHash: string | null) =>
    set((state) => ({
      ...state,
      commitHash,
    })),
  fileName: '',
  setFileName: (fileName: string) =>
    set((state) => ({
      ...state,
      fileName,
    })),
  extension: null,
  setExtension: (
    extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
  ) =>
    set((state) => ({
      ...state,
      extension,
    })),
  workMode: WorkMode.VIEW,
  hasProjectAccess: false,
  setHasProjectAccess: (hasProjectAccess: boolean) =>
    set((state) => ({
      ...state,
      hasProjectAccess,
    })),
  projectName: null,
  setProjectName: (projectName: string) =>
    set((state) => ({
      ...state,
      projectName,
    })),
  projectId: null,
  setProjectId: (projectId: string) =>
    set((state) => ({
      ...state,
      projectId,
    })),
  showKeyInputField: false,
  setShowKeyInputField: (showKeyInputField: boolean) =>
    set((state) => ({
      ...state,
      showKeyInputField,
    })),
  hasSuggestion: false,
  setHasSuggestion: (hasSuggestion: boolean) =>
    set((state) => ({
      ...state,
      hasSuggestion,
    })),
  addField: false,
  setAddField: (addField: boolean) =>
    set((state) => ({
      ...state,
      addField,
    })),
  type: null,
  setType: (type: string | null) =>
    set((state) => ({
      ...state,
      type,
    })),
  objectKey: null,
  setObjectKey: (objectKey: string | null) =>
    set((state) => ({
      ...state,
      objectKey,
    })),
  exampleValue: null,
  setExampleValue: (exampleValue: any) =>
    set((state) => ({
      ...state,
      exampleValue,
    })),
  isGitHubProject: false,
  setIsGitHubProject: (isGitHubProject: boolean) =>
    set((state) => ({
      ...state,
      isGitHubProject,
    })),
  initialContent: '',
  setInitialContent: (initialContent: string) =>
    set((state) => ({ ...state, initialContent })),
  initialFields: [],
  setInitialFields: (initialFields: LiveField[]) =>
    set((state) => ({
      ...state,
      initialFields,
    })),
  setWorkMode: (workMode: WorkMode) =>
    set((state) => ({
      ...state,
      workMode,
    })),
  availableWorkModes: [WorkMode.VIEW],
  setAvailableWorkModes: (availableWorkModes: WorkMode[]) =>
    set((state) => ({
      ...state,
      availableWorkModes,
    })),
  hasChanges: false,
  setHasChanges: (hasChanges: boolean) =>
    set((state) => ({
      ...state,
      hasChanges,
    })),
  openDiffEditor: false,
  setOpenDiffEditor: (openDiffEditor: boolean) =>
    set((state) => ({
      ...state,
      openDiffEditor,
    })),
  showFields: true,
  setShowFields: (showFields: boolean) =>
    set((state) => ({
      ...state,
      showFields,
    })),
  fieldTags: [],
  setFieldTags: <T extends FieldTag>(fieldTags: T[]) =>
    set((state) => ({
      ...state,
      fieldTags,
    })),
  hoverFieldId: null,
  setHoverFieldId: (hoverFieldId: string | null) =>
    set((state) => ({
      ...state,
      hoverFieldId,
    })),
}));
