import { useUIUtilityStore } from '@hubql/app-core';
import { motion } from 'framer-motion';
import { Sidebar } from './Sidebar';
export const MenuSidebar = () => {
  const showSidebar = useUIUtilityStore((state) => state.showSidebar);
  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };
  return (
    <motion.aside
      initial={{
        width: showSidebar ? '240px' : '0px',
        x: showSidebar ? '0px' : '-240px',
        height: '100vh',
      }}
      animate={{
        width: showSidebar ? '240px' : '0px',
        x: showSidebar ? '0px' : '-240px',
        transition: { duration: 0.3 },
      }}
      exit={{
        width: showSidebar ? '0px' : '240px',
        x: showSidebar ? '-240px' : '0px',
        transition: { duration: 0.3 },
      }}
    >
      <motion.div
        className="container"
        initial="closed"
        animate="open"
        exit="closed"
        variants={sideVariants}
      >
        <Sidebar />
      </motion.div>
    </motion.aside>
  );
};
