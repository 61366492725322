import {
  addGraphQLField,
  addJSONValue,
  addXMLValue,
  addPrismaField,
  addProtobufField,
  renameGraphQLField,
  renameGraphQLType,
  renamePrismaField,
  renamePrismaType,
  renameProtobufField,
  renameProtobufType,
  updateJSONKey,
  updateJSONValue,
  updateXMLKey,
  updateXMLValue,
} from '@hubql/file-util';

export const applySuggestion = ({
  suggestion,
  suggestions,
  extension,
  fieldId,
  code,
  fields,
}) => {
  const newSuggestions = suggestions?.map((suggestionNode) => {
    if (suggestionNode?.id === suggestion.id) {
      return {
        ...suggestionNode,

        isResolved: true,
      };
    }
    return suggestionNode;
  });

  const currentField = fields.find((field) => field.id === fieldId);
  const parentField = fields.find(
    (field) =>
      field.id === currentField?.parentId ||
      field.id === suggestion.meta?.newFieldParentId
  );

  let newCode = code;
  const newKey =
    suggestion.type === 'KEY'
      ? suggestion.value
      : currentField?.key ??
        suggestions.find(
          (suggestion) =>
            suggestion.type === 'KEY' && suggestion.meta?.newFieldId === fieldId
        )?.value;
  const newType =
    suggestion.type === 'TYPE'
      ? suggestion.value
      : currentField?.type ??
        suggestions.find(
          (suggestion) =>
            suggestion.type === 'TYPE' &&
            suggestion.meta?.newFieldId === fieldId
        )?.value;
  const newValue =
    suggestion.type === 'VALUE'
      ? suggestion.value
      : currentField?.exampleValue ??
        suggestions.find(
          (suggestion) =>
            suggestion.type === 'VALUE' &&
            suggestion.meta?.newFieldId === fieldId
        )?.value;

  const newField = {
    ...fields[0],
    meta: null,
    id: suggestion.meta?.newFieldId,
    parentId: suggestion.meta?.newFieldParentId,
    key: suggestion.type === 'KEY' ? suggestion.value : newKey,
    type: suggestion.type === 'TYPE' ? suggestion.value : newType,
    exampleValue: suggestion.type === 'VALUE' ? suggestion.value : newValue,
  };

  if (extension === 'prisma') {
    const modelName = parentField.key;
    if (suggestion.type === 'KEY' && modelName) {
      if (suggestion.action === 'ADD') {
        newCode = addPrismaField({
          prismaFile: code,
          modelName,
          newFieldName: newKey,
          newType,
        });
      }
      if (suggestion.action === 'EDIT') {
        newCode = renamePrismaField({
          modelName,
          oldFieldName: currentField?.key,
          newFieldName: suggestion.value,
          prismaFile: code,
        });
      }
    }
    if (suggestion.type === 'TYPE' && modelName) {
      if (suggestion.action === 'ADD') {
        newCode = addPrismaField({
          prismaFile: code,
          modelName,
          newFieldName: newKey,
          newType,
        });
      }
      if (suggestion.action === 'EDIT') {
        newCode = renamePrismaType({
          modelName,
          oldType: currentField?.type ?? '',
          newType: suggestion.value,
          fieldName: currentField?.key,
          prismaFile: code,
        });
      }
    }
  }

  if (extension === 'proto') {
    const typeName = parentField.key;
    if (suggestion.type === 'KEY' && typeName) {
      if (suggestion.action === 'ADD') {
        newCode = addProtobufField({
          protobufFile: code,
          typeName,
          newFieldName: newKey,
          newType,
        });
      }
      if (suggestion.action === 'EDIT') {
        newCode = renameProtobufField({
          typeName,
          oldFieldName: currentField?.key,
          newFieldName: suggestion.value,
          protobufFile: code,
        });
      }
    }
    if (suggestion.type === 'TYPE' && typeName) {
      if (suggestion.action === 'ADD') {
        newCode = addProtobufField({
          protobufFile: code,
          typeName,
          newFieldName: newKey,
          newType,
        });
      }
      if (suggestion.action === 'EDIT') {
        newCode = renameProtobufType({
          typeName,
          oldType: currentField?.type ?? '',
          newType: suggestion.value,
          fieldName: currentField?.key,
          protobufFile: code,
        });
      }
    }
  }
  if (extension === 'graphql') {
    const typeName = parentField.key;
    if (suggestion.type === 'KEY' && typeName) {
      if (suggestion.action === 'ADD') {
        newCode = addGraphQLField({
          graphQLFile: code,
          typeName,
          newFieldName: newKey,
          newType,
        });
      }
      if (suggestion.action === 'EDIT') {
        newCode = renameGraphQLField({
          typeName,
          oldFieldName: currentField?.key,
          newFieldName: suggestion.value,
          graphQLFile: code,
        });
      }
    }
    if (suggestion.type === 'TYPE' && typeName) {
      if (suggestion.action === 'ADD') {
        newCode = addGraphQLField({
          graphQLFile: code,
          typeName,
          newFieldName: newKey,
          newType,
        });
      }
      if (suggestion.action === 'EDIT') {
        newCode = renameGraphQLType({
          typeName,
          oldType: currentField?.type ?? '',
          newType: suggestion.value,
          fieldName: currentField?.key,
          graphQLFile: code,
        });
      }
    }
  }

  if (extension === 'json') {
    if (suggestion.type === 'KEY') {
      if (suggestion.action === 'EDIT' && currentField?.id) {
        newCode = updateJSONKey({
          newKey: suggestion.value,
          fields,
          fieldId,
        });
      }
    }
    if (suggestion.type === 'VALUE') {
      if (suggestion.action === 'EDIT' && currentField?.id) {
        newCode = updateJSONValue({
          newValue: suggestion.value,
          fields,
          fieldId,
        });
      }
    }
    if (suggestion.action === 'ADD') {
      newCode = addJSONValue({
        fields,
        newField,
      });
    }
  }

  if (extension === 'xml') {
    if (suggestion.type === 'KEY') {
      if (suggestion.action === 'EDIT' && currentField?.id) {
        newCode = updateXMLKey({
          newKey: suggestion.value,
          fields,
          fieldId,
        });
      }
    }
    if (suggestion.type === 'VALUE') {
      if (suggestion.action === 'EDIT' && currentField?.id) {
        newCode = updateXMLValue({
          newValue: suggestion.value,
          fields,
          fieldId,
        });
      }
    }
    if (suggestion.action === 'ADD') {
      newCode = addXMLValue({
        fields,
        newField,
      });
    }
  }

  const newFields = fields.map((field) => {
    if (field.id === fieldId) {
      return {
        ...field,
        key: suggestion.type === 'KEY' ? suggestion.value : field.key,
        type: suggestion.type === 'TYPE' ? suggestion.value : field.type,
        exampleValue:
          suggestion.type === 'VALUE' ? suggestion.value : field.exampleValue,
      };
    }
    return field;
  });
  if (suggestion.action === 'ADD' && suggestion.meta?.newFieldId) {
    newFields.push(newField);
  }
  return {
    newCode,
    newFields,
    newSuggestions,
  };
};
