import { useMutation } from '@apollo/client';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  UpdateWorkspaceNameDocument,
  UpdateWorkspaceNameMutation,
} from '@hubql/codegen';
import { Input } from '@hubql/hubqlkit';
import Link from 'next/link';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const WorkspaceName = ({ workspace, workspaceName }) => {
  const posthog = usePostHog();

  const [name, setName] = useState(workspaceName);
  useEffect(() => {
    setName(workspaceName);
  }, [workspaceName]);

  const [updateWorkspaceName] = useMutation<UpdateWorkspaceNameMutation>(
    UpdateWorkspaceNameDocument
  );
  if (!workspace) return null;
  const updateName = async (newTitle: string) => {
    const newName = newTitle !== '' ? newTitle : 'Untitled';
    await updateWorkspaceName({
      variables: {
        id: workspace.id,
        name: newName,
      },
    });
    try {
      posthog?.capture('WORKSPACE_NAME_CHANGED', {
        workspaceId: workspace.id,
      });
    } catch (error) {}
    toast.success('Workspace name changed!');
  };

  const handleOnBlur = (newValue: string) => {
    if (newValue !== workspaceName) updateName(newValue);
  };
  return (
    <div className="flex flex-row items-center gap-2 justify-start">
      <div className="flex items-center">
        <div className="flex flex-row items-center gap-1">
          <Link href={workspace.slug ? `/w/${workspace.slug}` : '/'}>
            <span className="text-sm cursor-pointer hover:underline hover:underline-offset-8 text-zinc-300">
              Workspace
            </span>
          </Link>
          <ChevronRightIcon className="w-4 h-4 fill-zinc-500" />
        </div>

        <Input
          className={`min-w-ms max-w-2xl`}
          inputSize="lg"
          width={'w-max'}
          style={{ width: name && name.length + 3 + 'ch' }}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => {
            handleOnBlur(e.target.value);
          }}
          value={name}
          placeholder="Untitled"
          variant="ghost"
        />
      </div>
    </div>
  );
};
