import { useFileStore } from '../state/stores/file';
import { TablePRISMA } from './TablePRISMA';
import { FieldRowPRISMA } from './FieldRowPRISMA';
import { WrapNodeProps } from 'reactflow';
import { useMemo } from 'react';

// inspiration here: https://raw.githubusercontent.com/Ovyerus/prismaliser/dev/components/ModelNode.tsx
export const ObjectNodePRISMA = (object: WrapNodeProps) => {
  const parentId = object.data['_parentId'];
  const parentKey = object.data['_parentKey'];
  const fieldTags = useFileStore((state) => state.fieldTags);

  const findColor = fieldTags.find((item) =>
    item.fieldIds?.find((i) => i === parentId)
  );
  const getColor = findColor && findColor.hexColor;
  const workMode = useFileStore((state) => state.workMode);

  const fields = useMemo(
    () =>
      Object.keys(object.data).map((objectKey, index) => {
        if (objectKey.startsWith('_')) return null;
        const value = object.data[objectKey];
        return (
          <FieldRowPRISMA
            key={'row-' + parentId + index}
            value={value}
            objectKey={objectKey}
            parentId={parentId}
            nodeProps={object}
            rowNumber={index}
          />
        );
      }),
    [object.data, parentId]
  );

  return (
    parentId !== undefined && (
      <TablePRISMA
        fields={fields}
        workMode={workMode}
        headingTitle={parentKey ?? 'Models'}
        parentId={parentId}
        parentObjectKey={parentKey}
        getColor={getColor}
        nodeProps={object}
      />
    )
  );
};
