import { Select, Option } from '@hubql/hubqlkit';
import { useState } from 'react';

export const SelectType = ({ setType, parentFields, extension }) => {
  const [searchResult, setSearchResult] = useState('');

  let baseTypes: {
    key: string;
  }[] = [];
  if (extension === 'proto') {
    baseTypes = [
      {
        key: 'double',
      },
      {
        key: 'float',
      },
      {
        key: 'int32',
      },
      {
        key: 'int64',
      },
      {
        key: 'uint32',
      },
      {
        key: 'uint64',
      },
      {
        key: 'sint32',
      },
      {
        key: 'sint64',
      },
      {
        key: 'fixed32',
      },
      {
        key: 'fixed64',
      },
      {
        key: 'sfixed32',
      },
      {
        key: 'sfixed64',
      },
      {
        key: 'bool',
      },
      {
        key: 'string',
      },
      {
        key: 'bytes',
      },
    ];
  }
  if (extension === 'graphql') {
    baseTypes = [
      {
        key: 'Boolean',
      },
      {
        key: 'Float',
      },
      {
        key: 'ID',
      },
      {
        key: 'Int',
      },
      {
        key: 'String',
      },
    ];
  }
  if (extension === 'prisma') {
    baseTypes = [
      {
        key: 'BigInt',
      },
      {
        key: 'Boolean',
      },
      {
        key: 'Bytes',
      },
      {
        key: 'DateTime',
      },
      {
        key: 'Decimal',
      },
      {
        key: 'Float',
      },
      {
        key: 'Int',
      },
      {
        key: 'Json',
      },
      {
        key: 'String',
      },
    ];
  }
  const deDupe = (arr) => {
    const newArr: {
      key: string;
    }[] = [];
    arr.forEach((item) => {
      if (!newArr.find((t) => t.key === item.key)) {
        newArr.push(item);
      }
    });
    return newArr;
  };
  const types = deDupe([...baseTypes, ...parentFields]);

  return (
    <Select
      selected={searchResult}
      positionX="left"
      id="new-field-type"
      size="w-full"
      searchable={true}
      searchValue={searchResult}
      setSearchValue={setSearchResult}
    >
      {types?.map((field) => {
        if (!field.key) return null;
        if (
          extension === 'graphql' &&
          (field.key === 'Query' ||
            field.key === 'Mutation' ||
            field.key === 'Subscription')
        ) {
          return null;
        }
        const hasResult = field.key
          .toString()
          .toLowerCase()
          .includes(searchResult.toLowerCase());
        return (
          hasResult && (
            <Option
              key={'new-type' + field?.key}
              onClick={() => {
                setType(field.key ?? '');
                setSearchResult(field.key);
              }}
            >
              <span className="text-white">{field.key}</span>
            </Option>
          )
        );
      })}
    </Select>
  );
};
