import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { SidebarModels } from './SidebarModels';
import { useEffect, useState } from 'react';
import { useLiveStore } from '../state/stores/live';
import { useScrollToField } from '../util/useScrollToField';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs';
import {
  EllipsisVerticalIcon,
  FolderIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import {
  Menubar,
  MenubarMenu,
  MenubarTrigger,
  MenubarContent,
  MenubarItem,
} from '@hubql/hubqlkit';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useRouter } from 'next/router';
import { useFileStore } from '../state/stores/file';
import { Dialog, DialogContent, DialogTrigger } from '@hubql/hubqlkit';
import { EmptyProjectFileList } from '../projects/EmptyProjectFileList';
import { APITab } from './APITab';
import clsx from 'clsx';
import { ProjectFileList } from '../projects/ProjectFileList';

export const Sidebar = () => {
  const { workspace } = useWorkspace();
  const [activeTab, setActiveTab] = useState('inspector');
  const router = useRouter();
  const { handleScroll } = useScrollToField();
  const projectId = useFileStore((state) => state.projectId);
  const projectName = useFileStore((state) => state.projectName);
  const setIsSidebarActive = useUIUtilityStore(
    (state) => state.setIsSidebarActive
  );
  const isSidebarActive = useUIUtilityStore((state) => state.isSidebarActive);
  const paneLeft = useUIUtilityStore((state) => state.paneLeft);
  const setPaneLeft = useUIUtilityStore((state) => state.setPaneLeft);
  const previousWidth = useUIUtilityStore((state) => state.fullSidebarWidth);
  const setPreviousWidth = useUIUtilityStore(
    (state) => state.setFullSidebarWidth
  );
  const sidebarHover = useUIUtilityStore((state) => state.hoverSidebar);
  const toggleHoverSidebar = useUIUtilityStore(
    (state) => state.toggleHoverSidebar
  );
  const handleSidebar = () => {
    sidebarHover ? toggleHoverSidebar() : null;
  };
  function handleTabIconClick(id: string) {
    if (id === activeTab) {
      if (isSidebarActive) {
        setPreviousWidth(paneLeft);
        setIsSidebarActive(false);
        setPaneLeft(45);
      } else {
        setPaneLeft(previousWidth);
        setIsSidebarActive(true);
      }
    } else {
      setIsSidebarActive(true);
      setPaneLeft(previousWidth);
      setActiveTab(id);
    }
  }

  // Scrolling Behaviour for Scrollbar
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);

  useEffect(() => {
    handleScroll('sidebar-models');
  }, [selectedFieldId]);

  const tabs = [
    // {
    //   id: 'explorer',
    //   title: `Explorer: ${projectName ?? ''}`,
    //   icon: <FolderIcon className="w-6 h-6" />,
    //   content: <ProjectFileList />,
    // },
    {
      id: 'inspector',
      title: 'Inspector',
      icon: <MagnifyingGlassIcon className="w-6 h-6" />,
      content: <SidebarModels />,
    },
    {
      id: 'api',
      title: 'API client',
      icon: <span>API</span>,
      content: <APITab />,
    },
  ];

  const SidebarHeader = ({ title }: { title: string }) => {
    return (
      <div className="relative grid items-center justify-between w-full grid-cols-12 gap-2 px-4 py-1 text-xs font-bold border-b text-zinc-300 border-zinc-700">
        <div className="col-span-10">
          <h1 className="text-[16px] truncate">{title}</h1>
        </div>
        <div className="flex justify-end col-span-2  ">
          <Dialog>
            <Menubar>
              <MenubarMenu>
                <MenubarTrigger className="px-1">
                  <EllipsisVerticalIcon className="w-4 h-4 fill-zinc-50" />
                </MenubarTrigger>
                <MenubarContent>
                  <MenubarItem>
                    <DialogTrigger>New File</DialogTrigger>
                  </MenubarItem>
                  <MenubarItem
                    onClick={() => {
                      router.push(
                        `/w/${
                          workspace?.slug !== '' ? workspace?.slug : '-'
                        }/hub/${projectId !== '' ? projectId : '-'}`
                      );
                    }}
                  >
                    Open Hub
                  </MenubarItem>
                </MenubarContent>
              </MenubarMenu>
            </Menubar>
            <DialogContent
              className={
                'max-w-4xl lg:max-w-screen-xl w-full p-4 mx-8 bg-zinc-900  '
              }
            >
              <h2 className="pb-2 text-lg text-zinc-50 ">Create a new file</h2>
              <EmptyProjectFileList projectId={projectId ?? ''} />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  };

  const tabBtn =
    'w-[45px] h-[45px] flex items-center justify-center hover:bg-zinc-800 cursor-pointer border-r border-zinc-700';

  return (
    <div
      id={'sidebar'}
      className={`relative flex flex-row items-top text-xs leading-normal top-0 left-0 w-full h-full bg-zinc-900 overflow-x-inherit overflow-y-inherit `}
      onMouseLeave={handleSidebar}
    >
      <div className="h-full w-full ">
        <Tabs defaultValue="inspector" className="flex flex-row w-full h-full">
          <TabsList className="w-[45px] h-full border-r  border-zinc-700 ">
            {tabs.map((tab) => (
              <TabsTrigger
                value={tab.id}
                className={clsx(
                  tabBtn,
                  isSidebarActive &&
                    'data-[state=active]:border-r data-[state=active]:border-green-500  data-[state=active]:bg-zinc-800/50'
                )}
                key={`lm-${tab.id}`}
                onClick={() => {
                  handleTabIconClick(tab.id);
                }}
              >
                {tab.icon}
              </TabsTrigger>
            ))}
          </TabsList>
          {isSidebarActive &&
            tabs.map((tab) => (
              <TabsContent
                key={`lmc-${tab.id}`}
                value={tab.id}
                className="w-full h-full"
              >
                <SidebarHeader title={tab.title} />
                <div className="w-full h-[calc(100%-45px)] flex-1 overflow-y-auto scrollbar">
                  {tab.content}
                </div>
              </TabsContent>
            ))}
        </Tabs>
      </div>
    </div>
  );
};
