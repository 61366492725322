import { ReactNode, useState } from 'react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';

interface iSelect {
  options?: any[];
  selected?: string | null | ReactNode | object;
  positionX?: 'right' | 'left';
  className?: string;
  children?: JSX.Element | ReactNode;
  size?: string;
  id?: string;
  searchable?: boolean;
  searchValue?: any;
  setSearchValue?: any;
}

export const Select: React.FC<iSelect> = ({
  selected,
  positionX = 'right',
  className,
  children,
  size = 'w-fit',
  id,
  searchable = false,
  searchValue,
  setSearchValue,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const posRight = positionX === 'right' && 'right-0';
  const posLeft = positionX === 'left' && 'left-0';
  const textColor = selected ? 'text-zinc-50' : 'text-zinc-400';
  const selectedOption = options?.find((option) => option.value === selected);

  return (
    <div className="dropdown relative">
      <Button
        id={id}
        onClick={() => setIsOpen(true)}
        className={clsx('text-xs justify-between focus:ring-0  ', className)}
        variant="regular"
        justify="justify-between"
        size="md"
      >
        {searchable && (
          <>
            <Input
              id="input-search"
              type="input"
              placeholder="Enter type"
              variant="search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              autocomplete="off"
            />
            {searchValue !== '' && (
              <div onClick={() => setSearchValue('')} className="w-4 h-4">
                <XMarkIcon className="w-4 h-4" />
              </div>
            )}
            <hr className="border-zinc-600" />
          </>
        )}

        {!searchable && (
          <span className={clsx('w-full truncate text-left flex', textColor)}>
            {selectedOption?.image && (
              <img
                className="w-4 h-4 mr-2"
                src={selectedOption?.image}
                alt={selected?.toString() ?? ''}
              />
            )}{' '}
            {selected?.toString()}
          </span>
        )}

        <ChevronDownIcon className="w-3 h-3 ml-1 fill-zinc-400" />
      </Button>
      {isOpen && (
        <>
          <div
            id="blur"
            className="w-screen h-screen top-0 left-0 z-0 fixed"
            onClick={() => setIsOpen(false)}
          />
          <div
            className={clsx(
              'menu absolute inset-auto top-[100%] max-h-[140px] overlfow-x-hidden overflow-y-auto scrollbar outline outline-zinc-600 bg-zinc-700 border border-zinc-700 mt-2 z-30 cursor-pointer shadow-xl rounded-md',
              posRight,
              posLeft,
              size
            )}
            onClick={() => setIsOpen(false)}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};
