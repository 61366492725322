import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/20/solid';
import { Button, Spinner } from '@hubql/hubqlkit';
import { useEffect, useState } from 'react';
import { useProjectStore } from '../state/stores/project';
import { EnterFile } from './EnterFile';
import { EventList } from './EventList';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateEndpointDocument,
  CreateEndpointMutation,
  EndpointsDocument,
  EndpointsQuery,
} from '@hubql/codegen';
import { usePostHog } from 'posthog-js/react';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';

export const CustomEvents = ({
  projectId,
  workspace,
  showSendPayload = true,
}: {
  projectId: string;
  workspace: any;
  showSendPayload?: boolean;
}) => {
  const posthog = usePostHog();
  const { workspaceUser } = useWorkspaceUser();
  const setShowGuestSignUp = useUIUtilityStore(
    (state) => state.setShowGuestSignUp
  );
  const [isActive, setIsActive] = useState(false);
  const [isIndex, setIsIndex] = useState();
  const projectEvents = useProjectStore((state) => state.projectEvent);
  const endpointsQuery = useQuery<EndpointsQuery>(EndpointsDocument, {
    skip: !workspace,
    variables: {
      workspaceId: workspace?.id,
    },
  });
  const [createEndpoint, createEndpointState] =
    useMutation<CreateEndpointMutation>(CreateEndpointDocument);
  const createEndpointHandler = async () => {
    const uuid = uuidv4().replace(/-/gi, '');
    await createEndpoint({
      variables: {
        input: {
          id: 'ep_' + uuid,
          slug: '-',
          workspaceId: workspace.id,
        },
      },
      refetchQueries: ['endpoints'],
    });
    try {
      posthog?.capture('ENDPOINT_CREATED', {
        workspaceId: workspace.id,
        projectId,
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (endpointsQuery.loading || createEndpointState.loading) return;
    if (
      endpointsQuery?.data?.endpoints?.edges?.length === 0 &&
      !workspaceUser?.isGuest
    ) {
      createEndpointHandler();
    }
  }, [endpointsQuery.loading]);
  const endpointId = endpointsQuery?.data?.endpoints?.edges?.[0]?.node?.id;
  const host =
    typeof window !== 'undefined' &&
    window?.location?.hostname?.includes('gitpod')
      ? 'https://4210-hubql-hubqlcom-t6qqq0zf31l.ws-us108.gitpod.io'
      : //process.env['NEXT_PUBLIC_HUBQL_REST_API']
      process.env['CONTEXT'] === 'deploy-preview'
      ? process.env['DEPLOY_PRIME_URL']?.replace('-app-hubql', '-hermes-hubql')
      : process.env['NEXT_PUBLIC_HUBQL_HERMES_URL'];
  const endpointUrl = `${host}/w/${workspace?.id}/hubs/${projectId}/ep/${endpointId}`;

  const collapsibles = [
    { title: 'Incoming events', content: <EventList /> },
    ...(showSendPayload
      ? [
          {
            title: 'Send a custom JSON payload',
            content: (
              <EnterFile projectId={projectId} endpointUrl={endpointUrl} />
            ),
          },
        ]
      : []),
  ];

  const handleOpen = (index) => {
    setIsIndex(index);
    setIsActive(true);
    if (isIndex === index && isActive) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    if (workspaceUser?.isGuest) {
      setShowGuestSignUp(true);
    }
  }, []);

  if (workspaceUser?.isGuest) {
    return (
      <div className=" w-full py-2 flex flex-col stretch mt-4 max-w-5xl h-full">
        <p>
          Our import files via payloads feature is only available to logged in
          users.
        </p>
        <Button
          onClick={() => setShowGuestSignUp(true)}
          className="mt-4 w-[200px]"
        >
          Sign up
        </Button>
      </div>
    );
  }
  if (endpointsQuery.loading || createEndpointState.loading)
    return (
      <div>
        <Spinner />
      </div>
    );
  if (!workspace) return <div>Workspace not found</div>;
  if (!endpointId)
    return <Button onClick={createEndpointHandler}>Create Endpoint</Button>;
  return (
    <div className=" bg-zinc-800 rounded-md p-5 max-w-5xl">
      <SendPayload endpointUrl={endpointUrl} />

      {collapsibles.map((collapsible, index) => (
        <div
          className="w-full first:border-t border-b border-zinc-700 py-4"
          key={index}
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => handleOpen(index)}
          >
            <span>{collapsible.title}</span>
            <div className="flex justify-between items-center gap-4">
              {projectEvents && projectEvents > 0 && index === 1 && (
                <span>
                  {projectEvents + ' event' + (projectEvents > 1 ? 's' : '')}
                </span>
              )}
              {isActive && isIndex === index ? (
                <ChevronUpIcon className="w-4 h-4" />
              ) : (
                <ChevronDownIcon className="w-4 h-4" />
              )}
            </div>
          </div>
          {isActive && isIndex === index && (
            <div className="flex flex-col gap-4 pt-4">
              {collapsible.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const SendPayload = ({ endpointUrl }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div>
      <h2>Your endpoint to receive JSON payloads:</h2>
      <div className="flex flex-row w-full items-center bg-zinc-900 py-2 px-1 mt-1">
        <span className="w-full truncate text-xs">{endpointUrl}</span>
      </div>
      <div className="flex justify-end mt-2">
        <CopyToClipboard text={endpointUrl} onCopy={() => setCopied(true)}>
          <Button variant={'outlined'} className="ml-8" size={'md'}>
            <ClipboardDocumentListIcon className="w-4 h-4 mr-1" />
            {copied ? 'Copied' : 'Copy'}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};
