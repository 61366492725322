import { useCallback, useState } from 'react';
import { useFileStore } from '../state/stores/file';
import { useCommentStore } from '../state/stores/comments';
import { Handle, Position, WrapNodeProps } from 'reactflow';
import { PresenceIndicator } from '../files/PresenceIndicator';
import { useLiveStore } from '../state/stores/live';
import { FieldContextMenu } from './FieldContextMenu';
import { CellGraphQL } from './CellGraphQL';
import { RowGraphQL } from './RowGraphQL';
import { useContextMenuStore } from '../state/stores/contextmenu';
import {
  ContextMenu,
  ContextMenuTrigger,
  Dialog,
  DialogContent,
} from '@hubql/hubqlkit';
import { SuggestAddField } from '../files/SuggestAddField';
import { Indicators } from './Indicators';
import { useCenterView } from '../util/useCenterView';

interface Props {
  objectKey: string;
  value: string;
  parentId: string;
  nodeProps: WrapNodeProps;
}

export const FieldRowGraphQL = ({
  objectKey,
  value,
  parentId,
  nodeProps,
}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const showFields = useFileStore((state) => state.showFields);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const workMode = useFileStore((state) => state.workMode);
  const setObjectKey = useFileStore((state) => state.setObjectKey);
  const setExampleValue = useFileStore((state) => state.setExampleValue);
  const setHoverFieldId = useFileStore((state) => state.setHoverFieldId);
  const setType = useFileStore((state) => state.setType);
  const fields = useLiveStore((state) => state.fields);
  const suggestions = useLiveStore((state) => state.suggestions);
  const fieldData = fields?.find(
    (field) => field.key === objectKey && field.parentId == parentId
  );
  const parentObjectKey =
    fields?.find((field) => field.id == parentId)?.key ?? '';
  const fieldId = fieldData?.id;
  const fieldSuggestions =
    suggestions.filter((item) => item.fileFieldId === fieldId)?.[0]?.action ??
    '';
  const meta = fieldData?.meta ?? '';
  const type = fieldData?.type
    ? fieldData?.type
    : Array.isArray(value)
    ? 'array'
    : typeof value;

  const hasKind = meta.kind === 'object' && (
    <div>
      <span className="text-[#3dc9b0]">{type}</span>
    </div>
  );

  const primaryHighlight = 'text-[#4ade80]';
  const secondaryHighlight = 'text-[#c4b5fd]';

  const hasRelation = meta.relationFromFields &&
    meta.relationFromFields.length > 0 && (
      <div>
        <span className="text-[#cc6566]">@relation</span>
        <span>{`(`}</span>
        <span className={primaryHighlight}>fields</span>
        <span>{`:[`}</span>
        <span className={primaryHighlight}>{meta.relationFromFields?.[0]}</span>
        <span>{`])`}</span>
      </div>
    );

  const canComment = workMode === 'VIEW' ? false : true;
  const fileUsers = useLiveStore((state) => state.liveblocks.others);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setThreadId = useCommentStore((state) => state.setThreadId);

  const selectedSourceId = useLiveStore((state) => state.selectedSourceId);
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const others = fileUsers.filter(
    (other) => other.presence['selectedFieldId'] === fieldId
  );

  const isActiveField = selectedFieldId === fieldId;
  const hasComment = useCommentStore(
    useCallback(
      (state) => {
        if (!canComment) return false;
        return state.comments.find((comment) => comment.fieldId === fieldId)
          ? true
          : false;
      },
      [canComment, fieldId]
    )
  );

  const suggestionColorMap = (key: string) => {
    switch (key) {
      case 'REMOVE':
        return 'bg-[#361C1C] line-through	text-red-700';
      case 'ADD':
        return 'bg-[#1E2F28]';
      case 'EDIT':
        return 'bg-[#1E252F]';
      default:
        return 'inherit';
    }
  };
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const showSuggest = useContextMenuStore((state) => state.showSuggest);

  const handleShowActions = () => {
    if (!fieldId) return;
    setSelectedFieldId(fieldId);
    setThreadId(null);
    setObjectKey(objectKey);
    setType(type);
    setExampleValue(value);
    if (showSuggest) {
      setShowSuggest(false);
    }
  };

  const { handleCenterView } = useCenterView();

  const handleDoubleClick = () => {
    if (!fieldId) return;
    setSelectedFieldId(fieldId);
    if (selectedFieldId) {
      handleCenterView(selectedFieldId, parentId);
    }
  };

  const isNotViewMode =
    workMode !== 'VIEW' && suggestionColorMap(fieldSuggestions);

  const handleMouseEnter = () => {
    if (fieldId) setHoverFieldId(fieldId);
  };
  const handleMouseLeave = () => {
    setHoverFieldId(null);
  };

  const isSelectedSource = selectedSourceId === fieldId;
  if (!fieldId) return null;

  return (
    <>
      {showFields && (
        <ContextMenu>
          <RowGraphQL
            key={objectKey}
            isNotViewMode={isNotViewMode}
            handleShowActions={handleShowActions}
            showLiveLocation={activeFieldColor}
            isActiveField={isActiveField}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            isSelectedSource={isSelectedSource}
            handleDoubleClick={handleDoubleClick}
            onContextMenu={() => {
              setSelectedFieldId(fieldId);
              setThreadId(null);
            }}
          >
            <CellGraphQL className={primaryHighlight}>{objectKey}</CellGraphQL>
            <CellGraphQL>
              <div className="flex">
                {hasKind ? (
                  hasKind
                ) : (
                  <span className={secondaryHighlight}>{type}</span>
                )}
                {meta.isList && <span className="inline">[]</span>}
              </div>
            </CellGraphQL>
            {hasRelation && <CellGraphQL>{hasRelation}</CellGraphQL>}

            <CellGraphQL colSpan={meta === '' ? 1 : 2}>
              <Indicators
                hasComment={hasComment}
                fieldSuggestions={fieldSuggestions}
              />

              <div className="relative -right-[20px] top-0">
                <PresenceIndicator others={others} />
              </div>
              <Handle
                id={fieldId}
                type="source"
                position={Position.Right}
                isConnectable={false}
                className="absolute right-0 opacity-0"
              />
              <Handle
                type="target"
                position={Position.Left}
                isConnectable={false}
                className="absolute left-0 opacity-0"
              />
              <FieldContextMenu
                fieldId={fieldId}
                extension="graphql"
                parentNodeProps={nodeProps}
                setIsDialogOpen={setIsDialogOpen}
              />
            </CellGraphQL>
          </RowGraphQL>
        </ContextMenu>
      )}
      <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
        <DialogContent className="max-w-xl p-4">
          <SuggestAddField
            initialKey={''}
            initialValue={''}
            initialType={''}
            fieldId={fieldId}
            extension={'graphql'}
            parentId={parentId}
            parentObjectKey={parentObjectKey}
            setIsDialogOpen={setIsDialogOpen}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
