import { GetFieldByLineNumberFn, GetPositionByFieldIdFn } from '../HubqlEditor';
import type { LiveField } from '../../../state/stores/live';

export const getFieldByLineNumber: GetFieldByLineNumberFn = ({
  code,
  fieldData,
  lineNumber,
}) => {
  const lines = code.split('\n');
  const currentLine = lines[lineNumber - 1];
  if (currentLine?.startsWith('model ')) {
    const modelName = currentLine.split(' ')[1];
    const model = fieldData.find(
      (field) => field.key === modelName && !field.parentId
    );
    if (model) {
      return model;
    }
  }

  if (currentLine?.startsWith('enum ')) {
    const enumName = currentLine.split(' ')[1];
    const enumField = fieldData.find(
      (field) => field.key === enumName && !field.parentId
    );
    if (enumField) {
      return enumField;
    }
  }

  for (let i = lineNumber - 1; i > 0; i--) {
    const line = lines[i]?.trim();
    if (line?.startsWith('model ')) {
      const modelName = line.split(' ')[1];
      const model = fieldData.find(
        (field) => field.key === modelName && !field.parentId
      );
      if (currentLine && model) {
        // TODO: this doesnt work for enums
        const field = fieldData.find(
          (field) =>
            currentLine.trim().startsWith(field.key + ' ') &&
            field.parentId === model.id
        );
        if (field) return field;
      }
    } else if (line?.startsWith('enum ')) {
      const enumName = line.split(' ')[1];
      const enumField = fieldData.find(
        (field) => field.key === enumName && !field.parentId
      );

      if (enumField) {
        const key = currentLine.trim();
        const field = fieldData.find(
          (f) => f.key === key && f.parentId === enumField.id
        );

        if (field) return field;
      }
    }
  }

  return null;
};

export const getEditorPositionByFieldId: GetPositionByFieldIdFn = ({
  fieldData,
  selectedFieldId,
  code,
}) => {
  const field = fieldData.find((field) => field.id === selectedFieldId);
  if (!field) return null;

  const lines = code.split('\n');

  if (field.type === 'Model') {
    const line = lines.findIndex((line) =>
      line.trim().startsWith(`model ${field.key}`)
    );
    return { lineNumber: line + 1, columnNumber: 0 };
  } else if (field.type === 'Enum') {
    const line = lines.findIndex((line) =>
      line.trim().startsWith(`enum ${field.key} {`)
    );
    return { lineNumber: line + 1, columnNumber: 0 };
  } else {
    const parentField = fieldData.find((f) => f.id === field.parentId);
    if (!parentField) return null;

    const parentLineNumber = lines.findIndex((line) => {
      const trimmed = line.trim();
      return (
        trimmed.startsWith(`model ${parentField.key} {`) ||
        trimmed.startsWith(`enum ${parentField.key} {`)
      );
    });

    const startLine = parentLineNumber + 1;
    lines.splice(0, startLine);

    const line = lines.findIndex((line) => {
      if (parentField.type === 'Enum') {
        return line.trim().startsWith(field.key); // no type for enum fields
      }

      return line.trim().startsWith(`${field.key} `); // check space after field name
    });

    return { lineNumber: startLine + line + 1, columnNumber: 2 };
  }
};
