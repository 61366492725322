export const Radio = ({
  id,
  label,
  name,
  onClick,
}: {
  id?: string;
  label?: string;
  name?: string;
  onClick?: any;
}) => {
  return (
    <div className={'radio flex gap-2 py-1 px-1'}>
      <input type="radio" id={id} name={name} value={id} onClick={onClick} />
      <span className="checkmark"></span>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
