import { Project } from '@hubql/codegen';
import { create } from 'zustand';

type ProjectState = {
  project: Project | undefined;
  setProject: (project: Project) => void;
  projectEvent: number | null;
  setProjectEvent: (projectEvent: number) => void;
};

export const useProjectStore = create<ProjectState>((set) => ({
  project: undefined,
  projectEvent: null,
  setProject: (project) =>
    set((state) => ({
      ...state,
      project,
    })),
  setProjectEvent: (projectEvent) =>
    set((state) => ({
      ...state,
      projectEvent,
    })),
}));
