import { v4 as uuidv4 } from 'uuid';
import { IField } from '../types';

type JSONObject = Record<string, unknown>;
type FullPathKey = string; // e.g. "customer_info.address.zip_code"
type TableContent = {
  data: unknown;
  contentLine: number;
  key: string;
  parentKey?: string;
  type: string;
};

interface IJsonToFields {
  jsonData: JSONObject;
  workspaceId: string;
  fileId: string;
  rawFields: IField[];
}

/**
 * Get full path of a targetField
 * example output: customer_info.address.zip_code
 */
function getFullPath(targetField: IField, fieldById: Map<string, IField>) {
  let parentKeyPath = '';
  let currentParentId = targetField.parentId;
  while (currentParentId) {
    const currentParentField = fieldById.get(currentParentId);
    if (!currentParentField) {
      break;
    }

    parentKeyPath = `${currentParentField.key}.${parentKeyPath}`;
    currentParentId = currentParentField.parentId;
  }

  return `${parentKeyPath}${targetField.key}`;
}

export const jsonToFields = (params: IJsonToFields) => {
  const jsonData = params.jsonData;

  const flattenTable = new Map<FullPathKey, TableContent>();
  const fields: IField[] = [];
  let counter = 0;

  const rawFields = params.rawFields;
  const fieldById = new Map<string, IField>();
  rawFields.forEach((rawField) => {
    fieldById.set(rawField.id, rawField);
  });

  const rawFieldByKeyAndParentKey = new Map<string, IField>();
  rawFields.forEach((rawField) => {
    const fullKeyPath = getFullPath(rawField, fieldById);
    rawFieldByKeyAndParentKey.set(fullKeyPath, rawField);
  });

  const rootField = rawFieldByKeyAndParentKey.get('__root__');
  const rootId = rootField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
  fields.push({
    id: rootId,
    key: '__root__',
    type: 'root',
    exampleValue: '__root__',
    workspaceId: params.workspaceId,
    fileId: params.fileId,
    parentId: null,
    line: 0,
    meta: { isRoot: true },
    positionX: rootField?.positionX,
    positionY: rootField?.positionY,
  });

  const flattenJson = (
    object: JSONObject,
    keyPath: string,
    parentId?: string
  ) => {
    Object.keys(object).map((k, index) => {
      const currentValue = object[k];
      const currentKeyPath = keyPath ? `${keyPath}.${k}` : k;
      let type = typeof currentValue as string;

      flattenTable.set(currentKeyPath, {
        type,
        data: currentValue,
        contentLine: 0,
        key: k,
        parentKey: keyPath == '' ? undefined : keyPath.split('.').pop(),
      });

      const lookupKey = `${keyPath ? `${keyPath}.` : ''}${k}`;
      const matchedField = rawFieldByKeyAndParentKey.get(lookupKey);
      const fieldId = matchedField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');

      fields.push({
        id: fieldId,
        key: k,
        type: Array.isArray(currentValue) ? 'array' : type,
        exampleValue: currentValue as string,
        workspaceId: params.workspaceId,
        fileId: params.fileId,
        parentId: parentId ?? null,
        line: ++counter, // TODO: implement line pre-calculation
        meta: null,
        positionX: matchedField?.positionX,
        positionY: matchedField?.positionY,
      });

      if (typeof currentValue == 'object') {
        flattenJson(currentValue as JSONObject, currentKeyPath, fieldId);
      }
    });
  };

  flattenJson(jsonData, '');

  return fields;
};
