import { Edge, EdgeProps, Node } from 'reactflow';
import { flatObject } from '../flatObject';
import type { LiveField } from '../../state/stores/live';

const getAlphaNumeric = (text: string) => {
  return text.replace(/[^0-9A-Z]+/gi, '');
};

export type Object = {
  [k in string]: Object | string;
};

export type xmlNode = {
  name?: string;
  value?: any;
  attributes?: { [key: string]: string };
};
// TODO: separate function per extension
export const makeNodesEdges = (
  object: Object,
  fieldData: LiveField[],
  extension: string | null,
  edgeStatus?: EdgeProps
) => {
  const edgeType =
    extension === 'prisma' || extension === 'graphql' || extension === 'proto'
      ? 'smoothstep'
      : 'default';
  const parsedObject = object;
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  const highlightEdge = edgeStatus?.selected;

  const isGraphQLCustomScalarType = (type: string) => {
    if (extension !== 'graphql') {
      return false;
    }

    return !['String', 'Int', 'Float', 'Boolean', 'ID'].includes(type);
  };

  const hasInitPosition = (field?: LiveField): boolean => {
    return !!field?.positionX && !!field?.positionY;
  };

  Object.keys(parsedObject).forEach((k, index = 1) => {
    const value = parsedObject[k];
    const objectSizeOne = Object.keys(value ?? {})?.length;
    const isCustomScalar = isGraphQLCustomScalarType(k);
    const isValidObject = value !== null && typeof value === 'object';

    if (isValidObject && (objectSizeOne > 0 || isCustomScalar)) {
      const rawField = fieldData.find(
        (tempField) => tempField.key === k && !tempField.parentId
      );
      const firstId = rawField?.id ?? '';

      nodes.push({
        id: firstId,
        type: 'object',
        data: {
          ...flatObject(value),
          _parentId: firstId,
          _parentKey: k,
          _hasInitPos: hasInitPosition(rawField),
        },
        position: {
          x: rawField?.positionX ?? 0,
          y: rawField?.positionY ?? 0,
        },
        hidden: rawField?.hideChildren ?? false,
      });

      Object.keys(value).forEach((k2, index = 1) => {
        const value2 = value[k2];
        const rawField2 = fieldData.find(
          (tempField) => tempField.key === k2 && tempField.parentId === firstId
        );

        const secondId = rawField2?.id ?? '';

        if (extension === 'yaml') {
          const ref =
            rawField2?.meta?.responses?.[200]?.content?.['application/json']
              ?.schema?.$ref ??
            rawField2?.meta?.responses?.[200]?.content?.['application/json']
              ?.schema?.items?.$ref;

          if (ref) {
            const refName = ref.split('/').pop();
            const refNode = fieldData.find((tempField) => {
              return tempField.key === refName && tempField.parentId === null;
            });

            if (refNode)
              edges.push({
                id: 'e' + secondId,
                type: 'enum',
                source: firstId,
                target: refNode.id,
                sourceHandle: secondId,
                label: refName,
              });
          }

          const inputRef =
            rawField2?.meta?.requestBody?.content?.['application/json']?.schema
              ?.$ref ??
            rawField2?.meta?.requestBody?.content?.['application/json']?.schema
              ?.items?.$ref;
          if (inputRef) {
            const refName = inputRef.split('/').pop();
            const refNode = fieldData.find((tempField) => {
              return tempField.key === refName && tempField.parentId === null;
            });

            if (refNode) {
              // edges.push({
              //   id: 'e-input-' + secondId,
              //   type: 'enum',
              //   source: refNode.id,
              //   target: secondId,
              //   sourceHandle: refNode.id,
              //   label: refName,
              // });
            }
          }
        }

        if (
          extension === 'prisma' ||
          extension === 'graphql' ||
          extension === 'proto'
        ) {
          const typeName = getAlphaNumeric(rawField2?.type ?? '');
          const relationNode = fieldData.find((tempField) => {
            return tempField.key === typeName && tempField.parentId === null;
          });

          const relationName = rawField2?.meta?.relationName;
          const isEnum = relationNode?.type === 'Enum';

          // for some reason Prisma DMMF returns random start or end relationName
          const relationMatch =
            rawField2?.type === relationNode?.key ||
            relationName?.startsWith(relationNode?.key) ||
            relationName?.endsWith(relationNode?.key);
          const isRelationSource =
            extension === 'prisma'
              ? relationMatch &&
                rawField2?.meta?.relationFromFields?.length === 0
              : true;
          const label = relationName ?? typeName + ':' + rawField2?.key;

          if (relationNode && isEnum) {
            edges.push({
              id: 'e' + (relationName ?? secondId),
              type: 'enum',
              source: firstId,
              target: relationNode.id,
              sourceHandle: secondId,
              label,
            });
          }

          if (relationNode && isRelationSource) {
            edges.push({
              id: 'e' + (relationName ?? secondId),
              type: 'relation',
              source: firstId,
              target: relationNode.id,
              sourceHandle: secondId,
              label,
            });
          }
        }

        const objectSizeTwo = Object.keys(value2 ?? {})?.length;
        if (
          value2 !== null &&
          typeof value2 === 'object' &&
          objectSizeTwo > 0
        ) {
          edges.push({
            id: 'e' + secondId,
            type: edgeType,
            source: firstId,
            target: secondId,
            sourceHandle: secondId,
            hidden: rawField2?.hideChildren ?? false,
            zIndex: highlightEdge ? 100 : 0,
          });
          nodes.push({
            id: secondId,
            type: 'object',
            data: {
              ...flatObject(value2),
              _parentId: secondId,
              _parentKey: k2,
              _hasInitPos: hasInitPosition(rawField2),
            },
            position: {
              x: rawField2?.positionX ?? 0,
              y: rawField2?.positionY ?? 0,
            },
            hidden: rawField2?.hideChildren ?? false,
          });

          Object.keys(value2).forEach((k3, index = 1) => {
            const value3 = value2[k3];
            const objectSizeThree = Object.keys(value3 ?? {})?.length;
            const rawField3 = fieldData.find(
              (tempField) =>
                tempField.key === k3 && tempField.parentId === secondId
            );

            const thirdId = rawField3?.id ?? '';

            if (extension === 'yaml') {
              const ref =
                rawField3?.meta?.responses?.[200]?.content?.['application/json']
                  ?.schema?.$ref ??
                rawField3?.meta?.responses?.[200]?.content?.['application/json']
                  ?.schema?.items?.$ref;

              if (ref) {
                const refName = ref.split('/').pop();
                const refNode = fieldData.find((tempField) => {
                  return (
                    tempField.key === refName && tempField.parentId === null
                  );
                });

                if (refNode)
                  edges.push({
                    id: 'e' + thirdId,
                    type: 'enum',
                    source: secondId,
                    target: refNode.id,
                    sourceHandle: thirdId,
                    label: refName,
                  });
              }

              const inputRef =
                rawField3?.meta?.requestBody?.content?.['application/json']
                  ?.schema?.$ref ??
                rawField3?.meta?.requestBody?.content?.['application/json']
                  ?.schema?.items?.$ref;
              if (inputRef) {
                const refName = inputRef.split('/').pop();
                const refNode = fieldData.find((tempField) => {
                  return (
                    tempField.key === refName && tempField.parentId === null
                  );
                });

                if (refNode) {
                  // edges.push({
                  //   id: 'e-input-' + secondId,
                  //   type: 'enum',
                  //   source: refNode.id,
                  //   target: secondId,
                  //   sourceHandle: refNode.id,
                  //   label: refName,
                  // });
                }
              }
            }

            if (
              value3 !== null &&
              typeof value3 === 'object' &&
              objectSizeThree > 0
            ) {
              edges.push({
                id: 'e' + thirdId,
                type: edgeType,
                source: secondId,
                target: thirdId,
                sourceHandle: thirdId,
                hidden: rawField3?.hideChildren ?? false,
                zIndex: highlightEdge ? 100 : 0,
              });
              nodes.push({
                id: thirdId,
                type: 'object',
                data: {
                  ...flatObject(value3),
                  _parentId: thirdId,
                  _parentKey: k3,
                  _hasInitPos: hasInitPosition(rawField3),
                },
                position: {
                  x: rawField3?.positionX ?? 0,
                  y: rawField3?.positionY ?? 0,
                },
                hidden: rawField3?.hideChildren ?? false,
              });
              Object.keys(value3).forEach((k4, index = 1) => {
                const value4 = value3[k4];
                const objectSizeFour = Object.keys(value4)?.length;
                const rawField4 = fieldData.find(
                  (tempField) =>
                    tempField.key === k4 && tempField.parentId === thirdId
                );
                const fourthId = rawField4?.id ?? '';
                if (
                  value4 !== null &&
                  typeof value4 === 'object' &&
                  objectSizeFour > 0
                ) {
                  edges.push({
                    id: 'e' + fourthId,
                    type: edgeType,
                    source: thirdId,
                    target: fourthId,
                    sourceHandle: fourthId,
                    hidden: rawField4?.hideChildren ?? false,
                    zIndex: highlightEdge ? 100 : 0,
                  });
                  nodes.push({
                    id: fourthId,
                    type: 'object',
                    data: {
                      ...flatObject(value4),
                      _parentId: fourthId,
                      _parentKey: k4,
                      _hasInitPos: hasInitPosition(rawField4),
                    },
                    position: {
                      x: rawField4?.positionX ?? 0,
                      y: rawField4?.positionY ?? 0,
                    },
                    hidden: rawField4?.hideChildren ?? false,
                  });
                  Object.keys(value4).forEach((k5, index = 1) => {
                    const value5 = value4[k5];
                    const objectSizeFive = Object.keys(value5)?.length;
                    const rawField5 = fieldData.find(
                      (tempField) =>
                        tempField.key === k5 && tempField.parentId === fourthId
                    );
                    const fifthId = rawField5?.id ?? '';
                    if (
                      value5 !== null &&
                      typeof value5 === 'object' &&
                      objectSizeFive > 0
                    ) {
                      edges.push({
                        id: 'e' + fifthId,
                        type: edgeType,
                        source: fourthId,
                        target: fifthId,
                        sourceHandle: fifthId,
                        hidden: rawField5?.hideChildren ?? false,
                        zIndex: highlightEdge ? 100 : 0,
                      });
                      nodes.push({
                        id: fifthId,
                        type: 'object',
                        data: {
                          ...flatObject(value5),
                          _parentId: fifthId,
                          _parentKey: k5,
                          _hasInitPos: hasInitPosition(rawField5),
                        },
                        position: {
                          x: rawField5?.positionX ?? 0,
                          y: rawField5?.positionY ?? 0,
                        },
                        hidden: rawField5?.hideChildren ?? false,
                      });
                      Object.keys(value5).forEach((k6) => {
                        const value6 = value5[k6];
                        const objectSizeSix = Object.keys(value6)?.length;
                        const rawField6 = fieldData.find(
                          (tempField) =>
                            tempField.key === k6 &&
                            tempField.parentId === fifthId
                        );
                        const sixthId = rawField6?.id ?? '';
                        if (
                          value6 !== null &&
                          typeof value6 === 'object' &&
                          objectSizeSix > 0
                        ) {
                          edges.push({
                            id: 'e' + sixthId,
                            type: edgeType,
                            source: fifthId,
                            target: sixthId,
                            sourceHandle: sixthId,
                            hidden: rawField6?.hideChildren ?? false,
                          });
                          nodes.push({
                            id: sixthId,
                            type: 'object',
                            data: {
                              ...flatObject(value6),
                              _parentId: sixthId,
                              _parentKey: k6,
                              _hasInitPos: hasInitPosition(rawField6),
                            },
                            position: {
                              x: rawField6?.positionX ?? 0,
                              y: rawField6?.positionY ?? 0,
                            },
                            hidden: rawField6?.hideChildren ?? false,
                          });
                          Object.keys(value6).forEach((k7) => {
                            const value7 = value6[k7];
                            const objectSizeSeven = Object.keys(value7)?.length;
                            const rawField7 = fieldData.find(
                              (tempField) =>
                                tempField.key === k7 &&
                                tempField.parentId === sixthId
                            );
                            const seventhId = rawField7?.id ?? '';
                            if (
                              value7 !== null &&
                              typeof value7 === 'object' &&
                              objectSizeSeven > 0
                            ) {
                              edges.push({
                                id: 'e' + seventhId,
                                type: edgeType,
                                source: sixthId,
                                target: seventhId,
                                sourceHandle: seventhId,
                                hidden: rawField7?.hideChildren ?? false,
                              });
                              nodes.push({
                                id: seventhId,
                                type: 'object',
                                data: {
                                  ...flatObject(value7),
                                  _parentId: seventhId,
                                  _parentKey: k7,
                                  _hasInitPos: hasInitPosition(rawField7),
                                },
                                position: {
                                  x: rawField7?.positionX ?? 0,
                                  y: rawField7?.positionY ?? 0,
                                },
                                hidden: rawField7?.hideChildren ?? false,
                              });
                              Object.keys(value7).forEach((k8) => {
                                const value8 = value7[k8];
                                const objectSizeEight =
                                  Object.keys(value8)?.length;
                                const rawField8 = fieldData.find(
                                  (tempField) =>
                                    tempField.key === k8 &&
                                    tempField.parentId === seventhId
                                );
                                const eighthId = rawField8?.id ?? '';
                                if (
                                  value8 !== null &&
                                  typeof value8 === 'object' &&
                                  objectSizeEight > 0
                                ) {
                                  edges.push({
                                    id: 'e' + eighthId,
                                    type: edgeType,
                                    source: seventhId,
                                    target: eighthId,
                                    sourceHandle: eighthId,
                                    hidden: rawField8?.hideChildren ?? false,
                                  });
                                  nodes.push({
                                    id: eighthId,
                                    type: 'object',
                                    data: {
                                      ...flatObject(value8),
                                      _parentId: eighthId,
                                      _parentKey: k8,
                                      _hasInitPos: hasInitPosition(rawField8),
                                    },
                                    position: {
                                      x: rawField8?.positionX ?? 0,
                                      y: rawField8?.positionY ?? 0,
                                    },
                                    hidden: rawField8?.hideChildren ?? false,
                                  });
                                  Object.keys(value8).forEach((k9) => {
                                    const value9 = value8[k9];
                                    const objectSizeNine =
                                      Object.keys(value9)?.length;
                                    const rawField9 = fieldData.find(
                                      (tempField) =>
                                        tempField.key === k9 &&
                                        tempField.parentId === eighthId
                                    );
                                    const ninethId = rawField9?.id ?? '';
                                    if (
                                      value9 !== null &&
                                      typeof value9 === 'object' &&
                                      objectSizeNine > 0
                                    ) {
                                      edges.push({
                                        id: 'e' + ninethId,
                                        type: edgeType,
                                        source: eighthId,
                                        target: ninethId,
                                        sourceHandle: ninethId,
                                        hidden:
                                          rawField9?.hideChildren ?? false,
                                      });
                                      nodes.push({
                                        id: ninethId,
                                        type: 'object',
                                        data: {
                                          ...flatObject(value9),
                                          _parentId: ninethId,
                                          _parentKey: k9,
                                          _hasInitPos:
                                            hasInitPosition(rawField9),
                                        },
                                        position: {
                                          x: rawField9?.positionX ?? 0,
                                          y: rawField9?.positionY ?? 0,
                                        },
                                        hidden:
                                          rawField9?.hideChildren ?? false,
                                      });
                                    }
                                  });
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  });

  return {
    nodes: [...nodes],
    edges: edges.map((edge) => {
      if (edge.type === 'relation') {
        const sourceField = fieldData.find((f) => f.id === edge.sourceHandle);
        const targetField = fieldData.find((f) => {
          return f.type === edge.source && f.id?.startsWith(edge.target);
        });
        const sourceCardinality =
          targetField?.meta?.isList === true ? 'many' : 'one';
        const targetCardinality =
          sourceField?.meta?.isList === true ? 'many' : 'one';
        const sourceModality =
          sourceField?.meta?.isRequired === true ? 'required' : 'optional';
        const targetModality =
          targetField?.meta?.isRequired === true ? 'required' : 'optional';

        return {
          ...edge,
          sourceHandle: sourceField?.id,
          data: {
            sourceCardinality,
            targetCardinality,
            sourceModality,
            targetModality,
          },
        };
      } else {
        return edge;
      }
    }),
  };
};
