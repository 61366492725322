import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useLiveStore } from '../state/stores/live';
import { useContextMenuStore } from '../state/stores/contextmenu';
import { useReactFlow, WrapNodeProps } from 'reactflow';
import { CreateFileCommentDocument } from '@hubql/codegen';
import { useMutation } from '@apollo/client';
import { createNote } from '../comments/Note';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';
import { useFileStore } from '../state/stores/file';
import { ContextMenuContent, ContextMenuItem } from '@hubql/hubqlkit';

interface IProps {
  fieldId: string | null;
  extension: string;
  parentNodeProps: WrapNodeProps;
  setIsDialogOpen?: any;
}

export const FieldContextMenu = ({
  fieldId,
  extension,
  parentNodeProps,
  setIsDialogOpen,
}: IProps) => {
  const posthog = usePostHog();

  const showComment = useUIUtilityStore((state) => state.showComment);
  const setShowCommentSidebar = useUIUtilityStore(
    (state) => state.setShowComment
  );
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);

  const setActiveEdit = useLiveStore((state) => state.setActiveEdit);

  const handleComment = () => {
    setSelectedFieldId(fieldId);
    if (!showComment) {
      setShowCommentSidebar(true);
    }
  };
  const { setCenter } = useReactFlow();
  const notes = useLiveStore((state) => state.notes);
  const setNotes = useLiveStore((state) => state.setNotes);
  const [createFileComment, createFileCommentState] = useMutation(
    CreateFileCommentDocument
  );

  const { workspace } = useWorkspace();

  const fileId = useFileStore((state) => state.fileId);
  const { workspaceUser } = useWorkspaceUser();
  const userId = workspaceUser?.id;
  const handleAddNote = () => {
    const workspaceId = workspace?.id;

    if (!workspaceId || !userId || !fileId) {
      toast.error('Unexpected error');
      console.error('workspaceId or userId is null');
      return;
    }

    const noteXPos = parentNodeProps.xPos + 400;
    const noteYPos = parentNodeProps.yPos;

    const { newNotes } = createNote({
      notes,
      fileId,
      workspaceId,
      userId,
      x: noteXPos,
      y: noteYPos,
      fieldId: fieldId ?? '__NOTE__',
      createFileComment,
      posthog,
    });

    setCenter(noteXPos, noteYPos, {
      zoom: 1,
      duration: 300,
    });

    setNotes(newNotes);
  };

  const handleEdit = (param: string) => {
    setShowSuggest(true);
    setSelectedFieldId(fieldId);
    setActiveEdit(param);
    if (!showComment) {
      setShowCommentSidebar(true);
    }
  };

  return (
    <ContextMenuContent>
      <ContextMenuItem onClick={handleComment}>
        <PlusIcon className="w-4 h-4 fill-zinc-200 " />
        Start discussion
      </ContextMenuItem>
      <ContextMenuItem onClick={handleAddNote}>
        <PlusIcon className="w-4 h-4 fill-zinc-200 " />
        Create note
      </ContextMenuItem>

      <ContextMenuItem onClick={() => setIsDialogOpen(true)}>
        <PencilSquareIcon className="w-4 h-4 fill-zinc-200" /> Suggest New Field
      </ContextMenuItem>

      <ContextMenuItem onClick={() => handleEdit('KEY')}>
        <PencilSquareIcon className="w-4 h-4 fill-zinc-200" /> Suggest Edit Key
      </ContextMenuItem>
      {(extension === 'prisma' ||
        extension === 'graphql' ||
        extension === 'proto') && (
        <ContextMenuItem onClick={() => handleEdit('TYPE')}>
          <PencilSquareIcon className="w-4 h-4 fill-zinc-200" /> Suggest Edit
          Type
        </ContextMenuItem>
      )}
      {(extension === 'json' ||
        extension === 'xml' ||
        extension === 'yaml') && (
        <ContextMenuItem onClick={() => handleEdit('VALUE')}>
          <PencilSquareIcon className="w-4 h-4 fill-zinc-200" /> Suggest Edit
          Value
        </ContextMenuItem>
      )}
    </ContextMenuContent>
  );
};
