import { S3Client, S3ClientConfig } from '@aws-sdk/client-s3';

export const S3_BUCKET_NAME =
  process.env.DOPPLER_ENVIRONMENT === 'development'
    ? 's3bucketstack-developmenthubqlfilesv189bc7fe4-1v8m0xkyskj6h'
    : 's3bucketstack-productionhubqlfilesv1a56306d4-14b4u3wd6cwwy';

const s3Config: S3ClientConfig = {
  credentials: {
    accessKeyId: process.env.APP_AWS_S3_ACCESS_KEY!,
    secretAccessKey: process.env.APP_AWS_S3_SECRET_KEY!,
  },
  region: 'eu-central-1',
  useAccelerateEndpoint:
    process.env.DOPPLER_ENVIRONMENT === 'development' ? true : false,
};
export const s3Client = new S3Client(s3Config);

export const getFileConfig = ({
  workspaceId,
  fileId,
  commitHash,
  fileName,
}) => {
  const bucket = S3_BUCKET_NAME;
  const s3Path = workspaceId + '/' + fileId + '/' + commitHash + '/';

  const key = s3Path + fileName;
  return {
    Bucket: bucket,
    Key: key,
  };
};

export const putFileConfig = ({
  workspaceId,
  fileId,
  userId,
  fileName,
  commitHash,
}) => {
  const bucket = S3_BUCKET_NAME;
  const s3Path = workspaceId + '/' + fileId + '/' + commitHash + '/';
  const tagging = `workspaceId=${workspaceId}&userId=${userId}&fileId=${fileId}&commitHash=${commitHash}`;
  const extn = fileName.split('.').pop();
  const contentType = extn === 'json' ? 'application/json' : 'text/plain';
  const cacheControl = null;
  const acl = 'private';
  return {
    Bucket: bucket,
    Key: s3Path + fileName,
    Tagging: tagging,
    ContentType: contentType,
    ACL: acl,
    CacheControl: cacheControl,
  };
};
