import { Diagnostic, linter } from '@codemirror/lint';
import type { ExtractedPrismaErrors } from '@hubql/prisma';

export function lineToOffset(rawContent: string, lineNumber: number) {
  const zeroBasedLine = lineNumber - 1;

  const lines = rawContent.split('\n');

  if (zeroBasedLine < 0 || zeroBasedLine >= lines.length) {
    return { from: 0, to: 0 };
  }

  let startOffset = 0;
  for (let i = 0; i < zeroBasedLine; i++) {
    startOffset += lines[i].length + 1; // +1 for the newline character
  }

  const endOffset =
    zeroBasedLine < lines.length - 1
      ? startOffset + lines[zeroBasedLine].length + 1
      : rawContent.length;

  return { from: startOffset, to: endOffset };
}

export const diagnosticExtension = linter(async (view) => {
  let diagnostics: Diagnostic[] = [];
  const prismaSchemaRaw = view.state.doc.toString();

  const response = await fetch('/rest/prisma-dmmf', {
    method: 'POST',
    headers: {
      'accept-encoding': 'gzip, deflate, br',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ prismaSchema: prismaSchemaRaw }),
  });

  const dmmf = await response.json();

  if (dmmf?.errors) {
    const prismaErrors: ExtractedPrismaErrors = dmmf;
    diagnostics = prismaErrors.errors.map((error) => {
      const { from, to } = lineToOffset(prismaSchemaRaw, error.lineNumber);
      return {
        from,
        to,
        message: error.message,
        severity: 'error',
      };
    });
  }

  return diagnostics;
});
