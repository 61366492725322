import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

export const theme = createTheme({
  theme: 'dark',
  settings: {
    foreground: '#fafafa',
    lineHighlight: '#27272a80',
    gutterBackground: '#27272a',
  },
  styles: [
    { tag: t.keyword, color: '#c4b5fd' },
    { tag: t.name, color: '#4ade80' },
  ],
});
