import { useFileStore } from '../state/stores/file';
import { FieldRowJSON } from '../visualization/FieldRowJSON';
import { TableJSON } from '../visualization/TableJSON';
import type { WrapNodeProps } from 'reactflow';
import { useMemo } from 'react';

export const ObjectNodeJson = (object: WrapNodeProps) => {
  const parentId = object.data['_parentId'];
  const parentKey = object.data['_parentKey'];
  const workMode = useFileStore((state) => state.workMode);
  const canSuggest = !parentId && workMode !== 'VIEW';

  const fields = useMemo(() => {
    return Object.keys(object.data).map((objectKey) => {
      if (objectKey.startsWith('_')) return null;
      const value = object.data[objectKey];
      // TODO: centralize this logic
      const isArray = Array.isArray(value);
      const isObject = isArray ? 'array' : typeof value;
      // Checking for Children
      const hasChildren = isArray || (isObject === 'object' && value !== null);

      return (
        <div className="flex flex-row " key={'row-' + parentId + objectKey}>
          <FieldRowJSON
            value={value}
            isObject={isObject}
            objectKey={objectKey}
            parentId={parentId}
            nodeProps={object}
            hasChildren={hasChildren}
          />
        </div>
      );
    });
  }, [object.data]);

  return (
    <TableJSON
      fields={fields}
      workMode={workMode}
      headingTitle=""
      parentId={parentId}
    />
  );
};
