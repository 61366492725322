import React, { useCallback, useState } from 'react';
import ReactFlow, {
  Background,
  Edge,
  Node,
  NodeChange,
  Panel,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { useFileStore } from '../../state/stores/file';
import { LiveField, useLiveStore } from '../../state/stores/live';
import { edgeTypes, getNodeTypes } from '../../util/reactflowConfig';
import { Button } from '@hubql/hubqlkit';
import {
  downloadImage,
  exportVisualization,
} from '../../util/exportVisualization';
import { toast } from 'react-toastify';
import { getElkLayoutElements } from '../../util/getElkLayoutElements';

type VisualizationEngineProps = {
  fields?: LiveField[];
};
/**
 * The visualization engine is the component that renders the graph visualization of fields
 * This component is used to export the image from the command line(check `cli` app)
 */
export const VisualizationEngineDetail: React.FC<
  VisualizationEngineProps
> = () => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [error, setError] = useState('');
  const [isReady, setIsReady] = useState(false);

  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const { getNodes, getEdges } = useReactFlow();
  const setExtension = useFileStore((state) => state.setExtension);
  const extension = useFileStore((state) => state.extension);
  const setFields = useLiveStore((state) => state.setFields);
  const fields = useLiveStore((state) => state.fields);
  const setRawContent = useLiveStore((state) => state.setRawContent);
  const rawContent = useLiveStore((state) => state.rawContent);

  const initializeLayout = async (opts: {
    nodes: Node[];
    edges: Edge[];
    fields: LiveField[];
  }) => {
    const noPositionNodes = getNodes().filter((n) => !n.data._hasInitPos);

    if (noPositionNodes.length === 0) {
      console.log('keeping the positions');
      return;
    }

    const { nodes: layoutedNodes, edges: layoutedEdges } =
      await getElkLayoutElements({
        nodes: opts.nodes,
        edges: opts.edges,
      });

    if (!layoutedNodes?.length || !layoutedEdges?.length) return;

    setNodes(layoutedNodes as Node[]);
    setEdges(layoutedEdges as unknown as Edge[]);
  };

  const onNodeStateChange = useCallback((changes: NodeChange[]) => {
    onNodesChange(changes);

    // if the first change is not dimensions, means there's no newly added node
    if (changes[0].type !== 'dimensions') {
      return;
    }

    const dimensionChangedNodes = changes.filter(
      ({ type }) => type === 'dimensions'
    );

    // if all nodes are dimension changed, it's the initial rendering
    const isInitialRendering =
      dimensionChangedNodes.length === getNodes().length;

    if (isInitialRendering) {
      initializeLayout({
        nodes: getNodes(),
        edges: getEdges(),
        fields: fields,
      });
    }
  }, []);

  return (
    <ReactFlow
      proOptions={{ hideAttribution: true }}
      nodeTypes={getNodeTypes(extension as string)}
      edgeTypes={edgeTypes}
      fitViewOptions={{
        padding: 0.1,
        includeHiddenNodes: true,
        duration: 300,
      }}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodeStateChange}
      onEdgesChange={onEdgesChange}
      fitView
      minZoom={0.1}
      maxZoom={2}
    >
      <Background />

      <Panel position="top-left" style={{ background: 'transparent' }}>
        {error && <p id="error-details">Error: ${error} </p>}
        {rawContent && <p>Raw Content: {rawContent.substring(0, 200)}</p>}
        {fileName && <p>File Name: {fileName.substring(0, 200)}</p>}
        {isReady && <p id="is-ready" />}
      </Panel>

      <Panel position="bottom-right" style={{ background: 'transparent' }}>
        <div className="flex gap-1 p-1 rounded-sm bg-zinc-900 outline outline-1 outline-zinc-600 w-max">
          <Button
            id="button-export"
            onClick={async () => {
              await exportVisualization({
                action: downloadImage,
                fileName:
                  fileName ?? `${extension}-${new Date().toISOString()}`,
                extension: extension as string,
              });
            }}
          >
            Export
          </Button>
          <Button
            id="button-fit-view"
            onClick={async () => {
              fitView({
                padding: 0.1,
                includeHiddenNodes: true,
                duration: 0,
              });
            }}
          >
            FitView
          </Button>

          <Button
            id="button-visualize"
            onClick={async () => {
              const rawContent = window.sessionStorage.getItem('raw-content');
              if (!rawContent) {
                console.error('Local storage key `raw-content` not found');
                toast.error('Local storage key `raw-content` not found');
                return;
              }

              const rawFileInput =
                window.sessionStorage.getItem('raw-file-input');

              if (!rawFileInput) {
                console.error('Local storage key `raw-file-input` not found');
                toast.error('Local storage key `raw-file-input` not found');
                return;
              }

              const rawNodesEdges =
                window.sessionStorage.getItem('raw-nodes-edges');
              if (!rawNodesEdges) {
                console.error('Local storage key `raw-nodes-edges` not found');
                toast.error('Local storage key `raw-nodes-edges` not found');
                return;
              }

              const fileInput = JSON.parse(rawFileInput);
              const nodesEdges = JSON.parse(rawNodesEdges);
              const { extension, fields, fileName } = fileInput;
              const { nodes, edges } = nodesEdges;

              setRawContent(rawContent);
              setExtension(extension);
              setFields(fields);
              setFileName(fileName);
              setNodes(nodes);
              setEdges(edges);
            }}
          >
            Visualize
          </Button>
        </div>
      </Panel>
    </ReactFlow>
  );
};
