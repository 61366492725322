import { useRouter } from 'next/router';
import { useUser } from '@clerk/nextjs';
import clsx from 'clsx';
import { FileSharing } from '../files/FileSharing';
import { Button, SaveIcon } from '@hubql/hubqlkit';
import Head from 'next/head';
import { Account } from './Account';
import { BreadcrumNav } from './BreadcrumNav';
import { useEffect, useState } from 'react';
import { FielChangeIndicator } from '../sidebar/FileChangeIndicator';
import { FileExport } from '../files/FileExport';
import Link from 'next/link';
import { EmptyProjectFileList } from '../projects/EmptyProjectFileList';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Dialog, DialogContent } from '@hubql/hubqlkit';
import { FileWorkMode } from '../files/FileWorkMode';
import { FileComments } from '../comments/FileComments';
import { WelcomeMessage } from '../layout/WelcomeMessage';
import { FilePresence } from '../files/FilePresence';
import { Nav } from './Nav';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { useFileStore } from '../state/stores/file';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';

export const Header = () => {
  const { workspaceUser } = useWorkspaceUser();

  const { user, isSignedIn } = useUser();
  const router = useRouter();
  const isDemo = router.pathname.startsWith('/demo');
  const workMode = useFileStore((state) => state.workMode);
  const isApp =
    router.asPath.includes('file=fil_') || router.asPath.includes('hub=hub_');
  const isAuth = !workspaceUser?.isGuest;
  const fileId = useFileStore((state) => state.fileId);

  const fileName = useFileStore((state) => state.fileName);
  const setFileName = useFileStore((state) => state.setFileName);
  const extension = useFileStore((state) => state.extension);
  const { workspace } = useWorkspace();
  const projectId = useFileStore((state) => state.projectId);
  const projectName = useFileStore((state) => state.projectName);
  const isProject =
    router.asPath.includes('project') || router.asPath.includes('file');
  const isFile =
    router.asPath.includes('file') || router.asPath.includes('hub=hub_');
  const isWorkspace = workspace?.id;
  const workspaceName = workspace?.name;
  // TODO: we want this from backend
  const isWorkspaceMember = workspace?.members?.find(
    (member) => member.user.id === workspaceUser?.id
  )
    ? true
    : false;
  const showHeader = useUIUtilityStore((state) => state.showHeader);
  const openDiffEditor = useFileStore((state) => state.openDiffEditor);
  const setOpenDiffEditor = useFileStore((state) => state.setOpenDiffEditor);
  const [isVisible, setIsVisible] = useState(false);
  const hubId = router.query['hub'] as string;
  const fileQuery = router.query['file'] as string;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDiff = () => {
    setOpenDiffEditor(!openDiffEditor);
  };
  const showNewFile = useUIUtilityStore((state) => state.showNewFile);

  useEffect(() => {
    if (showNewFile && isApp && hubId && !fileQuery && !fileId) {
      setIsDialogOpen(true);
    }
    if (fileId) {
      setIsDialogOpen(false);
    }
  }, [showNewFile]);

  return (
    <div
      className={clsx(
        `min-h-full h-11 bg-zinc-900 border-b border-zinc-700 z-30 w-full
      `,
        showHeader ? 'block' : 'hidden'
      )}
    >
      <Head>
        {isFile && <title>{fileName}</title>}
        {!isFile && <title>{workspace?.name}</title>}
      </Head>
      <div className={clsx('pl-0 text-sm')}>
        <div className="w-full flex flew-row h-11 justify-between items-center px-1.5">
          <div className="flex items-center flex-1 gap-1 flew-row">
            <div className="flex items-center justify-between flex-1 gap-1 w-max">
              {isAuth && <Nav />}
              {isAuth && user && (
                <Account
                  user={workspaceUser}
                  workspace={workspace}
                  workspaceName={workspaceName}
                />
              )}
              <BreadcrumNav
                isWorkspaceMember={isWorkspaceMember}
                isWorkspace={isWorkspace}
                workspaceSlug={workspace?.slug ?? ''}
                isProject={isProject}
                projectId={projectId ?? ''}
                projectName={projectName ?? ''}
                isFile={isFile}
                fileName={fileName}
                extension={extension}
                fileId={fileId ?? ''}
                onFileNameChanged={(fileName) => {
                  setFileName(fileName);
                }}
              />
              {isApp && (
                <div>
                  <Button
                    variant="secondary"
                    className="relative w-fit h-[32px]"
                    onClick={() => setIsDialogOpen((state) => !state)}
                  >
                    New file
                    <PlusIcon className="w-3 h-3 fill-zinc-50" />
                  </Button>
                  <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
                    <DialogContent
                      className={
                        'w-11/12 h-5/6 p-0 bg-zinc-900 font-mono max-w-none overflow-y-auto scrollbar border-zinc-700'
                      }
                    >
                      <EmptyProjectFileList projectId={hubId} />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
            </div>
          </div>

          <div
            className={`flex flex-row h-10 items-center gap-2 w-full justify-end `}
          >
            <div className="flex flex-row items-center justify-end w-full h-10 gap-1 flex-nowrap">
              {isApp && !isSignedIn && (
                <div className="flex items-center px-2 py-1 font-normal leading-relaxed text-white bg-green-700 rounded-sm button-content ring-0 focus:ring-0 hover:bg-green-800 active:bg-green-700 focus:bg-green-800">
                  <Link href={'/sign-in'}>Login</Link>
                </div>
              )}
              {isApp && <FilePresence />}
              {isApp && <FileSharing />}
              {isApp && (
                <Button
                  isDisabled={isDemo || !user}
                  onClick={handleOpenDiff}
                  variant="regular"
                  className="relative w-fit h-[32px]"
                >
                  Save
                  <SaveIcon className="w-3 h-3 fill-zinc-50" />
                  <FielChangeIndicator />
                </Button>
              )}

              {isApp && (
                <FileExport
                  fileName={fileName}
                  extension={extension as string}
                />
              )}
              {isApp && workMode !== 'VIEW' && <FileComments fileId={fileId} />}
              {isAuth && <WelcomeMessage showInstructions={true} />}
              {isApp && <FileWorkMode />}
              {/* {isAuth && <Notifications />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
