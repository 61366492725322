import { Button } from '@hubql/hubqlkit';
import Editor from '@monaco-editor/react';
import { useRef, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { CreateFile } from '../files/CreateFile';
import { usePostHog } from 'posthog-js/react';
import { toast } from 'react-toastify';

export const EnterFile = ({ projectId, endpointUrl }) => {
  const posthog = usePostHog();

  const { workspace } = useWorkspace();
  const editorRef = useRef(null);
  const [extension, setExtension] = useState<
    'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
  >('json');

  function handleEditorDidMount(editor) {
    editorRef.current = editor;
  }

  const defaultValue = `{
    "key":"value"
}`;

  const [editorContent, setEditorContent] = useState<string>(defaultValue);
  const handleChange = (value: string) => {
    setEditorContent(value);
  };
  const parseJSON = (content: string) => {
    try {
      return JSON.parse(content);
    } catch (error) {
      return null;
    }
  };
  const isValid = parseJSON(editorContent);
  const [isSending, setIsSending] = useState(false);
  if (!workspace) return null;
  const handleClick = async () => {
    if (isValid) {
      try {
        setIsSending(true);
        await fetch(endpointUrl, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'accept-encoding': 'gzip, deflate, br',
            'content-type': 'application/json',
          },
          body: editorContent,
        });
        try {
          posthog?.capture('EVENT_SENT', {
            workspaceId: workspace?.id,
            projectId: projectId,
          });
        } catch (error) {}
        setIsSending(false);
        toast.success('Event sent successfully');
      } catch (error) {
        console.error(error);
        setIsSending(false);
        toast.error('Failed to send event');
      }
    }
  };
  return (
    <div className="flex flex-col">
      <div className="w-full flex justify-between items-center pb-4">
        <span className="text-zinc-400 w-full">Enter your payload below</span>
        <div className="w-full flex justify-end items-center gap-2">
          <CreateFile
            fileName={'test'}
            extension={extension}
            workspaceId={workspace.id}
            workspaceSlug={workspace.slug}
            fileContent={editorContent}
            label="Save as file"
            projectId={projectId}
            variant="green"
            size="md"
            isLoading={false}
            setIsLoading={() => {
              //
            }}
          />
          <Button
            size={'md'}
            disabled={!isValid}
            isLoading={isSending}
            onClick={handleClick}
          >
            Send as event
          </Button>
        </div>
      </div>
      <div className="h-full min-h-[300px] border border-zinc-700 bg-[#1e1e1e] rounded-md overflow-none p-4">
        <Editor
          onMount={handleEditorDidMount}
          onChange={handleChange}
          height="300px"
          theme={'vs-dark'}
          defaultLanguage={extension}
          value={editorContent}
        />
      </div>
    </div>
  );
};
