import { useReactFlow } from 'reactflow';

/* Zooming into Node field  on a click */

export const useCenterView = (
  { centerWhenNotFound } = { centerWhenNotFound: true }
) => {
  const { getNode, setCenter } = useReactFlow();

  const handleCenterView = (nodeId: string, parentId: string) => {
    const node = getNode(nodeId) ?? getNode(parentId);
    if (node?.position === undefined) return;
    if (node?.width === undefined || node?.width === null) return;
    if (node?.height === undefined || node?.height === null) return;
    const x = node?.position.x + node?.width / 2;
    const y = node?.position.y + node?.height / 2;
    (node?.position || (centerWhenNotFound && !node?.position)) &&
      setCenter(x, y, {
        zoom: 1.2,
        duration: 300,
      });
  };
  return { handleCenterView };
};
