import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  ConnectionLineType,
  Edge,
  MiniMap,
  Node,
  Panel,
  useEdgesState,
  useKeyPress,
  useNodesState,
  useReactFlow,
  NodeChange,
} from 'reactflow';
import { makeNodesEdges } from '../util/make-nodes';
import { edgeTypes, getNodeTypes } from '../util/reactflowConfig';

import { fieldsToFileStringJSON, IField } from '@hubql/file-util';

import { LiveField, useLiveStore } from '../state/stores/live';
import { useFileStore } from '../state/stores/file';
import {
  Button,
  DeleteConfirmationModal,
  Tooltip,
  useModal,
  ContextMenu,
  ContextMenuTrigger,
} from '@hubql/hubqlkit';
import {
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  MapIcon,
  PlusIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import {
  CreateFileCommentDocument,
  DeleteFileCommentDocument,
  UpdateFileCommentPositionDocument,
  UpdateFileFieldPositionDocument,
  BulkUpdateFileFieldPositionsDocument,
} from '@hubql/codegen';
import { CanvaContextMenu } from '../visualization/CanvaContextMenu';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { Helper } from '../visualization/Helper';
import { useCommentStore } from '../state/stores/comments';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { createNote } from '../comments/Note';
import { usePostHog } from 'posthog-js/react';
import { convertFileCommentToReactFlowSchema } from './graph';
import posthog from 'posthog-js';
import { keyBy } from '@hubql/util';
import { LiveCursor } from '../visualization/LiveCursor';
import { getElkLayoutElements } from '../util/getElkLayoutElements';
import { Maximize2, Minimize2 } from 'lucide-react';
import { UpgradeFullContent } from '../onboard/UpgradeModal';

type FlowProps = {
  onLayout: (direction: string) => void;
  setShowMap: (showMap: boolean) => void;
  showMap: boolean;
  extension: string;
  fileId: string;
  workspaceId: string;
  userId: string;
  showNotes: boolean;
  handleNoteToggler: () => void;
};

const Flow = ({
  onLayout,
  setShowMap,
  showMap,
  extension,
  fileId,
  workspaceId,
  userId,
  showNotes,
  handleNoteToggler,
}: FlowProps) => {
  const { fitView, zoomIn, zoomOut, getViewport, setCenter, getZoom } =
    useReactFlow();
  const showFields = useFileStore((state) => state.showFields);
  const setShowFields = useFileStore((state) => state.setShowFields);
  const posthog = usePostHog();

  const handleTransform = () => {
    fitView({
      padding: 0.1,
      includeHiddenNodes: true,
      duration: 300,
    });
  };

  const maptoggle = showMap ? 'green' : 'regular';
  const mapToggleMessage = showMap ? 'Hide map' : 'Show map';
  const fieldtoggle = showFields ? 'green' : 'regular';

  const iconClass = 'h-3.5 w-3.5 fill-zinc-50';
  const buttonClass = ' text-[8px]';
  const buttonVariant = 'regular';

  const handleShowField = () => {
    setShowFields(!showFields);
    onLayout('RIGHT');
  };
  // Note Toggle Setup
  const noteToggleMessage = showNotes ? 'Hide notes' : 'Show notes';
  const noteToggleButton = showNotes ? 'regular' : 'green';

  const notes = useLiveStore((state) => state.notes);
  const setNotes = useLiveStore((state) => state.setNotes);
  const [createFileComment, createFileCommentState] = useMutation(
    CreateFileCommentDocument
  );

  const handleClickAddNote = () => {
    const { x, y } = getViewport();
    const { newNotes, newNote } = createNote({
      notes,
      fileId,
      workspaceId,
      userId,
      x,
      y,
      createFileComment,
      posthog,
    });

    setNotes(newNotes);
    setCenter(x, y, {
      zoom: getZoom() ?? 1,
      duration: 300,
    });
  };

  const shouldDisplayShowFieldButton =
    extension === 'prisma' || extension === 'graphql' || extension === 'proto';

  return (
    <div className="flex gap-1 p-1 rounded-sm bg-zinc-900 outline outline-1 outline-zinc-600 w-max items-center">
      <Tooltip id={'zoom-in'} message="Zoom in" place="bottom">
        <Button
          onClick={() => zoomIn({ duration: 300 })}
          variant={buttonVariant}
          className={buttonClass}
        >
          <MagnifyingGlassPlusIcon className={iconClass} />
        </Button>
      </Tooltip>
      <Tooltip message="Zoom out" id={'zoom-out'}>
        <Button
          onClick={() => zoomOut({ duration: 300 })}
          variant={buttonVariant}
          className={buttonClass}
        >
          <MagnifyingGlassMinusIcon className={iconClass} />
        </Button>
      </Tooltip>
      <Tooltip message="Re-center" id={'center'}>
        <Button
          onClick={handleTransform}
          variant={buttonVariant}
          className={buttonClass}
        >
          <ViewfinderCircleIcon className={iconClass} />
        </Button>
      </Tooltip>
      <Tooltip message={mapToggleMessage} id={'map'}>
        <Button
          onClick={() => setShowMap(!showMap)}
          variant={maptoggle}
          className={buttonClass}
        >
          <MapIcon className={clsx(iconClass, showMap && ' fill-zinc-50')} />
        </Button>
      </Tooltip>
      {shouldDisplayShowFieldButton && (
        <Tooltip message="Show fields" id={'fields'}>
          <Button
            onClick={handleShowField}
            variant={'regular'}
            className={buttonClass}
          >
            {showFields ? (
              <Minimize2 className={clsx(iconClass)} />
            ) : (
              <Maximize2 className={clsx(iconClass)} />
            )}
          </Button>
        </Tooltip>
      )}
      {/* Add notes */}
      <Tooltip message="Add notes" id={'add-notes'}>
        <Button
          onClick={handleClickAddNote}
          variant={buttonVariant}
          className={clsx(buttonClass, 'text-zinc-50 relative')}
        >
          <PlusIcon className={iconClass} />
        </Button>
      </Tooltip>
      {/* Notes Toggler */}
      <Tooltip message={noteToggleMessage} id={'notes'}>
        <Button
          onClick={handleNoteToggler}
          variant={'regular'}
          className={buttonClass}
        >
          {showNotes ? (
            <EyeIcon className={clsx(iconClass)} />
          ) : (
            <EyeSlashIcon className={clsx(iconClass)} />
          )}
        </Button>
      </Tooltip>
    </div>
  );
};

interface FileVisualizationProps {
  commitHash: string;
  fileId: string;
  workspaceId: string;
}

export const FileVisualization = ({
  commitHash,
  fileId,
  workspaceId,
}: FileVisualizationProps) => {
  const [showNotes, setShowNotes] = useState(true);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { workspaceUser } = useWorkspaceUser();
  const userId = workspaceUser?.id;
  const [showMap, setShowMap] = useState(false);
  const [isInitPosition, setIsInitPosition] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [selectedNode, setSelectedNode] = useState<Node | null>();
  const fieldData = useLiveStore((state) => state.fields);
  const setFields = useLiveStore((state) => state.setFields);
  const notes = useLiveStore((state) => state.notes);
  const { fitView, getNodes, getEdges } = useReactFlow();
  const setNotes = useLiveStore((state) => state.setNotes);
  const [deleteFileComment] = useMutation(DeleteFileCommentDocument);
  const [updateFileFieldPosition] = useMutation(
    UpdateFileFieldPositionDocument
  );
  const [updateFileCommentPosition] = useMutation(
    UpdateFileCommentPositionDocument
  );

  const [updateFileFileFieldPositions] = useMutation(
    BulkUpdateFileFieldPositionsDocument
  );
  const {
    isOpen: isDeleteConfirmationModalOpen,
    openModal: openDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
  } = useModal();

  const extension = useFileStore((state) => state.extension);
  const showFields = useFileStore((state) => state.showFields);
  const setSelectedSourceId = useLiveStore(
    (state) => state.setSelectedSourceId
  );
  const setSelectedTargetId = useLiveStore(
    (state) => state.setSelectedTargetId
  );
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setLastClickPosition = useLiveStore(
    (state) => state.setLastClickPosition
  );
  const setThreadId = useCommentStore((state) => state.setThreadId);

  const onNodeDragStop = (_: ReactMouseEvent, node: Node) => {
    const { id, position } = node;

    const fieldId = fieldData.find((field) => field.id === id)?.id;
    if (node.type === 'note' && position.x && position.y) {
      let isNoteUpdated = false;
      const updatedNotes = notes.map((note) => {
        if (
          note.id === id &&
          (note.positionX !== position.x || note.positionY !== position.y)
        ) {
          isNoteUpdated = true;
          return {
            ...note,
            positionX: position.x,
            positionY: position.y,
          };
        }

        return { ...note };
      });

      isNoteUpdated && setNotes(updatedNotes);

      updateFileCommentPosition({
        variables: {
          id,
          positionX: position.x,
          positionY: position.y,
        },
      });
    }

    if (node.type === 'object' && fieldId && position.x && position.y) {
      const updatedFields = fieldData.map((field) => {
        if (field.id === id) {
          return {
            ...field,
            positionX: position.x,
            positionY: position.y,
          };
        }

        return { ...field };
      });
      setFields(updatedFields);
      updateFileFieldPosition({
        variables: {
          id: fieldId,
          commitHash,
          fileId,
          positionX: position.x,
          positionY: position.y,
        },
      });
    }
  };

  const onConnect = useCallback(
    (params: Connection) =>
      setEdges((edges) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          edges
        )
      ),
    []
  );

  // custom delete function
  const deletePressed = useKeyPress('Backspace');
  useEffect(() => {
    const selectedNode = nodes?.find((node) => node.selected);
    if (selectedNode) {
      setSelectedNode(selectedNode);
      if (selectedNode.type === 'note') {
        openDeleteConfirmationModal();
      } else {
        setNodes(nodes.filter((node) => node.id !== selectedNode?.id));
      }
    }
  }, [deletePressed]);

  useEffect(() => {
    const selectedEdge = edges?.find((edge) => edge.selected);
    if (selectedEdge) {
      setSelectedSourceId(selectedEdge.sourceHandle ?? null);
      setSelectedTargetId(selectedEdge.target);
      setSelectedFieldId(null);
      setThreadId(null);
    } else {
      setSelectedSourceId(null);
      setSelectedTargetId(null);
    }
  }, [edges]);

  const proOptions = { hideAttribution: true };

  const onPaneClick = (event: React.MouseEvent) => {
    setThreadId(null);
    setSelectedFieldId(null);
    setLastClickPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const [location, setLocation] = useState({ x: 0, y: 0 });
  const showVisualizationMenu = useUIUtilityStore(
    (state) => state.showVisualizationMenu
  );
  const reachedGuestLimit = useUIUtilityStore(
    (state) => state.reachedGuestLimit
  );
  const reachedPlanLimit = useUIUtilityStore((state) => state.reachedPlanLimit);
  const showHelper = useUIUtilityStore((state) => state.showHelper);
  const [cursorPos, setCurosrPos] = useState({ x: 0, y: 0 });

  const setClickLocation = (open: boolean) => {
    if (open) {
      setLocation(cursorPos);
    }
  };

  const handleNoteToggler = () => {
    setNodes(
      nodes.map((node) => {
        return {
          ...node,
          hidden: node.type === 'note' && showNotes,
        };
      })
    );
    setEdges(
      edges.map((edge) => {
        return {
          ...edge,
          hidden: edge.data?.['_type'] === 'note' && showNotes,
        };
      })
    );
    setShowNotes(!showNotes);
  };

  const handleDeleteNote = () => {
    if (selectedNode) {
      setNotes(notes.filter((note) => note.id !== selectedNode?.id));
      deleteFileComment({
        variables: {
          id: selectedNode?.id,
        },
      });
      try {
        posthog?.capture('NOTE_DELETED', {
          noteId: selectedNode?.id,
        });
      } catch (error) {
        console.error('Posthog error', error);
      }
      isDeleteConfirmationModalOpen && closeDeleteConfirmationModal();
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    setCurosrPos({ x: event.clientX, y: event.clientY });
  };

  const onNodeDrag = (event: React.MouseEvent) => {
    setCurosrPos({ x: event.clientX, y: event.clientY });
  };

  // State to hide Nodes and Edges
  useEffect(
    function onFieldDataChanged() {
      if (!fieldData || fieldData.length === 0 || !notes || !extension) {
        setNodes([]);
        setEdges([]);
        setIsReady(true);
        return;
      }

      const fileObject = JSON.parse(
        fieldsToFileStringJSON(fieldData as unknown as IField[], extension)
      );

      const dataGraph = makeNodesEdges(fileObject, fieldData, extension);
      const notesGraph = convertFileCommentToReactFlowSchema(
        notes,
        fieldData,
        extension
      );

      setNodes([...dataGraph.nodes.concat(notesGraph.nodes)]);
      setEdges([...dataGraph.edges.concat(notesGraph.edges)]);
    },
    [fieldData, notes]
  );

  const initializeLayout = async (opts: {
    nodes: Node[];
    edges: Edge[];
    fields: LiveField[];
  }) => {
    const noPositionNodes = getNodes().filter((n) => !n.data._hasInitPos);

    if (noPositionNodes.length === 0) {
      console.log('keeping the positions');
      return;
    }

    const { nodes: layoutedNodes, edges: layoutedEdges } =
      await getElkLayoutElements({
        nodes: opts.nodes,
        edges: opts.edges,
      });

    if (!layoutedNodes?.length || !layoutedEdges?.length) return;

    setNodes(layoutedNodes as Node[]);
    setEdges(layoutedEdges as unknown as Edge[]);

    const nodeByFieldId = keyBy(layoutedNodes, 'id');
    const updatedPosFieldData = opts.fields.map((field) => {
      const node = nodeByFieldId[field.id];

      return {
        ...field,
        positionX: node?.position.x ?? field.positionX,
        positionY: node?.position.y ?? field.positionY,
      };
    });

    console.log('Updating all positions');
    updateFileFileFieldPositions({
      variables: {
        inputs: updatedPosFieldData.map((f) => ({
          id: f.id,
          fileId,
          positionX: f.positionX ?? 0,
          positionY: f.positionY ?? 0,
          commitHash: commitHash ?? 'HEAD',
        })),
      },
    }).then(() => {
      setFields([...updatedPosFieldData]);
    });
  };

  const onNodeStateChange = useCallback((changes: NodeChange[]) => {
    onNodesChange(changes);

    // if the first change is not dimensions, means there's no newly added node
    if (changes[0].type !== 'dimensions') {
      return;
    }

    const dimensionChangedNodes = changes.filter(
      ({ type }) => type === 'dimensions'
    );

    // if all nodes are dimension changed, it's the initial rendering
    const isInitialRendering =
      dimensionChangedNodes.length === getNodes().length;
    const shouldReLayout =
      !isInitialRendering && dimensionChangedNodes.length >= 3;

    if (isInitialRendering || shouldReLayout) {
      initializeLayout({
        nodes: getNodes(),
        edges: getEdges(),
        fields: useLiveStore.getState().fields,
      });
      setIsInitPosition(true);
    }
  }, []);

  useEffect(() => {
    if (isInitPosition) {
      setTimeout(() => {
        fitView({ padding: 0.1, includeHiddenNodes: true, duration: 0 });
        setIsReady(true);
      }, 200);
    }
  }, [isInitPosition]);

  return (
    <ContextMenu onOpenChange={setClickLocation}>
      <ContextMenuTrigger>
        <div
          id="file-visualization"
          className="relative z-0 flex-1 w-full h-full overflow-hidden"
        >
          <DeleteConfirmationModal
            isOpen={isDeleteConfirmationModalOpen}
            onCancel={closeDeleteConfirmationModal}
            onDelete={handleDeleteNote}
          />
          {reachedGuestLimit && (
            <UpgradeFullContent workspaceId={workspaceId} />
          )}
          {reachedPlanLimit && <UpgradeFullContent workspaceId={workspaceId} />}
          {!reachedGuestLimit && !reachedPlanLimit && (
            <ReactFlow
              proOptions={proOptions}
              nodeTypes={getNodeTypes(extension as string)}
              onMouseMove={handleMouseMove}
              edgeTypes={edgeTypes}
              nodes={nodes}
              fitViewOptions={{
                padding: 0.1,
                includeHiddenNodes: true,
                duration: 300,
              }}
              edges={edges}
              onNodeDrag={onNodeDrag}
              onNodesChange={onNodeStateChange}
              onNodeDragStop={onNodeDragStop}
              onEdgesChange={onEdgesChange}
              deleteKeyCode={[]}
              onConnect={onConnect}
              onPaneClick={onPaneClick}
              fitView
              minZoom={0.1}
              maxZoom={2}
            >
              <LiveCursor cursorPos={cursorPos} />
              <Background />
              {showMap && <MiniMap zoomable pannable />}
              {showVisualizationMenu && (
                <Panel
                  position="top-center"
                  style={{ background: 'transparent' }}
                >
                  <Flow
                    showNotes={showNotes}
                    fileId={fileId}
                    workspaceId={workspaceId}
                    onLayout={() => null}
                    setShowMap={setShowMap}
                    showMap={showMap}
                    extension={extension as string}
                    userId={userId as string}
                    handleNoteToggler={handleNoteToggler}
                  />
                </Panel>
              )}

              {showHelper && (
                <Panel
                  position="bottom-left"
                  style={{ background: 'transparent' }}
                >
                  <Helper />
                </Panel>
              )}
            </ReactFlow>
          )}

          <div id="vizualisation-root" />
        </div>
      </ContextMenuTrigger>
      <CanvaContextMenu location={location} />
    </ContextMenu>
  );
};
