import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { TabsContent, TabsList, TabsNew, TabsTrigger } from '@hubql/hubqlkit';
import { PrivateProject } from './PrivateProject';
import { TeamspaceProject } from './TeamspaceProject';
import { SharedProject } from './SharedProject';
import { BookmarkedProject } from './BookmarkedProject';
import { useProjectList } from '../util/useProjectList';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';

export const ProjectListCards = () => {
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();
  const {
    bookmarkedProjects,
    sharedProjectsFromAccess,
    sharedFilesFromAccess,
    hubsQuery,
  } = useProjectList();

  const tabs = [
    {
      id: 'tab-teamspace',
      label: 'Hubs',
      content: <TeamspaceProject hubsQuery={hubsQuery} workspace={workspace} />,
    },
    {
      id: 'tab-private',
      label: 'Private',
      content: <PrivateProject hubsQuery={hubsQuery} workspace={workspace} />,
    },
    {
      id: 'tab-shared',
      label: 'Shared with me',
      content: (
        <SharedProject
          sharedFilesFromAccess={sharedFilesFromAccess}
          hubsQuery={hubsQuery}
          sharedProjectsFromAccess={sharedProjectsFromAccess}
          workspace={workspace}
        />
      ),
    },
    {
      id: 'tab-favorite',
      label: 'Favorite',
      content: (
        <BookmarkedProject
          bookmarkedProjects={bookmarkedProjects}
          workspace={workspace}
        />
      ),
    },
  ];

  return (
    <div className="w-full">
      <h1 className="text-md text-zinc-50">Welcome {workspaceUser?.name}!</h1>
      <span className="text-xs text-zinc-300">
        Create a Hub and turbo-charge your next software development project
      </span>
      <div className="flex flex-col justify-start items-start w-full gap-4 mt-4 ">
        {workspace && (
          <TabsNew defaultValue={tabs?.[0]?.id} className="w-full">
            <TabsList className="max-w-xl">
              {tabs?.map((tab) => {
                return (
                  <TabsTrigger key={'project-list-' + tab.id} value={tab.id}>
                    {tab.label}
                  </TabsTrigger>
                );
              })}
            </TabsList>
            {tabs?.map((tab) => {
              return (
                <TabsContent
                  key={'project-list-content-' + tab.id}
                  value={tab.id}
                >
                  {tab.content}
                </TabsContent>
              );
            })}
          </TabsNew>
        )}
      </div>
    </div>
  );
};
