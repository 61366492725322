import { CreateFile } from '../files/CreateFile';
import { InputNew } from '@hubql/hubqlkit';
import { useState } from 'react';
import { Template } from './EmptyProjectFileList';

export const ChooseTemplate = ({
  projectId,
  workspace,
  filterTab,
  fileName,
  templates,
  loading,
}: {
  loading?: boolean;
  templates?: any;
  projectId: string;
  filterTab?: string;
  fileName?: string;
  workspace: {
    id: string;
    slug: string;
  };
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchPrompt, setSearchPrompt] = useState('');

  const templateList = templates
    .filter((t) => {
      if (filterTab === 'blank') return t.fileName === filterTab;
      if (filterTab === 'all' && searchPrompt === '')
        return t.fileName !== 'blank';
      if (searchPrompt !== '' && filterTab === 'all')
        return (
          t.fileName !== 'blank' &&
          t.label.toLowerCase().includes(searchPrompt.toLowerCase())
        );
      return t.extension === filterTab && t.fileName !== 'blank';
    })
    .map((file: Template) => {
      if (!file) return null;
      return (
        <CreateFile
          key={file.id}
          fileContent={file.fileContent}
          fileUrl={file.url}
          label={file.label}
          projectId={projectId}
          workspaceId={workspace.id}
          workspaceSlug={workspace.slug}
          fileName={file.fileName}
          extension={file.extension}
          id={file.id}
          variant="outlined"
          template={true}
          img={file.img}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      );
    });
  const isNotBlank = fileName !== 'blank' && filterTab === 'all';
  return (
    <div className="flex flex-col mt-8">
      {isNotBlank && (
        <SearchTemplate
          searchPrompt={searchPrompt}
          setSearchPrompt={setSearchPrompt}
        />
      )}
      <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4">
        {templateList}
      </div>
      {!loading && templateList?.length === 0 && 'No Result'}
    </div>
  );
};

const SearchTemplate = ({ searchPrompt, setSearchPrompt }) => {
  const handleSearch = (e) => {
    setSearchPrompt(e);
  };
  return (
    <div className="mb-4">
      <InputNew
        placeholder="Search"
        type="search"
        onChange={(e) => handleSearch(e.target.value)}
        value={searchPrompt}
        className="max-w-md"
      />
    </div>
  );
};
