import { useMutation, useQuery } from '@apollo/client';
import {
  FileAccessDocument,
  FileAccessQuery,
  InviteUsersToFileDocument,
  UpdateFileDefaultAccessDocument,
} from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useFileStore, WorkMode } from '../state/stores/file';
import {
  GlobeAltIcon,
  LockClosedIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import {
  Avatar,
  Button,
  DialogContent,
  Modal,
  Tooltip,
  useModal,
} from '@hubql/hubqlkit';
import { toast } from 'react-toastify';
import { ProjectMemberItem } from '../projects/ProjectMemberItem';
import { FileAccessItem } from './FileAccessItem';
import { FileShareDetails } from './FileSharingDetails';
import { usePostHog } from 'posthog-js/react';
import { Dialog } from '@radix-ui/react-dialog';

export const FileSharing = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const posthog = usePostHog();
  const router = useRouter();
  const isDemo = router.pathname.startsWith('/demo');

  const { workspaceUser } = useWorkspaceUser();
  const userId = workspaceUser?.id;

  const { workspace } = useWorkspace();
  const fileId = useFileStore((state) => state.fileId);

  const fileName = useFileStore((state) => state.fileName);
  const extension = useFileStore((state) => state.extension);
  const fileAccessQuery = useQuery<FileAccessQuery>(FileAccessDocument, {
    variables: {
      id: fileId,
    },
    skip: !fileId,
  });
  const fileAccessData = fileAccessQuery.data?.file?.access?.edges?.map(
    (node) => node?.node
  );

  const projectDefaultAccess =
    fileAccessQuery.data?.file?.projects?.edges?.[0]?.node?.project
      ?.defaultAccess;
  const projectAccessData =
    fileAccessQuery.data?.file?.projects?.edges?.[0]?.node?.project?.access?.edges?.map(
      (node) => node?.node
    );
  const workspaceAccessData =
    projectDefaultAccess === 'WORKSPACE'
      ? fileAccessQuery.data?.file?.workspace?.members?.edges?.map(
          (edge) => edge?.node
        )
      : [];

  const hasWorkspaceAccess = workspaceAccessData?.find(
    (member) => member?.user.id === userId
  );
  const hasProjectAccess = projectAccessData?.find(
    (member) => member?.userId === userId
  );
  const hasFileAccess = fileAccessData?.find(
    (member) => member?.userId === userId
  );
  const isOwner = fileAccessQuery?.data?.file?.ownedBy === userId;
  const isCreator = fileAccessQuery?.data?.file?.createdBy === userId;

  const isSystemFile = fileAccessQuery?.data?.file?.ownedBy === 'SYSTEM';

  const setWorkMode = useFileStore((state) => state.setWorkMode);
  const setAvailableWorkModes = useFileStore(
    (state) => state.setAvailableWorkModes
  );
  const [saveDefaultAccess] = useMutation(UpdateFileDefaultAccessDocument);
  const [inviteUsersToFile] = useMutation(InviteUsersToFileDocument);
  useEffect(() => {
    if (!fileAccessQuery.loading && fileAccessQuery?.data) {
      const fileDefaultAccess = fileAccessQuery.data?.file?.defaultAccess;
      //@ts-ignore TODO: we shoukd move to enums for default access
      setDefaultAccess(fileDefaultAccess);

      let workmodes = [WorkMode.VIEW];
      if (fileDefaultAccess !== 'PRIVATE' && hasWorkspaceAccess) {
        workmodes = [WorkMode.EDIT, WorkMode.COMMENT, WorkMode.VIEW];
      }
      if (fileDefaultAccess !== 'PRIVATE' && hasProjectAccess) {
        workmodes = [WorkMode.EDIT, WorkMode.COMMENT, WorkMode.VIEW];
      }

      if (isOwner || isCreator || isSystemFile) {
        workmodes = [WorkMode.EDIT, WorkMode.COMMENT, WorkMode.VIEW];
      }
      if (hasFileAccess?.accessType === WorkMode.VIEW) {
        workmodes = [WorkMode.VIEW];
      }
      if (hasFileAccess?.accessType === WorkMode.COMMENT) {
        workmodes = [WorkMode.COMMENT, WorkMode.VIEW];
      }
      if (hasFileAccess?.accessType === WorkMode.EDIT) {
        workmodes = [WorkMode.EDIT, WorkMode.COMMENT, WorkMode.VIEW];
      }
      setAvailableWorkModes(workmodes);
      setWorkMode(
        WorkMode.EDIT
        // workmodes?.includes(WorkMode.EDIT)
        //   ? WorkMode.EDIT
        //   : workmodes.includes(WorkMode.COMMENT)
        //   ? WorkMode.COMMENT
        //   : WorkMode.VIEW
      );
    }
  }, [fileAccessQuery?.data]);
  const [value, setValue] = useState([]);
  const [newAccess, setNewAccess] = useState(WorkMode.VIEW);
  const [sending, setSending] = useState(false);
  const [mode, setMode] = useState<'INVITE' | 'SEND'>('INVITE');
  const [defaultAccess, setDefaultAccess] = useState<
    'PUBLIC_VIEW' | 'WORKSPACE' | 'PRIVATE'
  >('PRIVATE');

  const handleSend = async () => {
    if (!workspace?.id || !fileId) return;
    setSending(true);
    const result = await inviteUsersToFile({
      variables: {
        input: {
          userEmails: value,
          fileRole: newAccess,
          workspaceId: workspace?.id,
          fileId,
        },
      },
    });
    if (result.data?.inviteUsersToFile.__typename === 'Error') {
      console.error(result.data?.inviteUsersToFile.message);
      alert('Failed');
      setSending(false);
    }
    if (
      result.data?.inviteUsersToFile.__typename === 'InviteUsersToFileSuccess'
    ) {
      toast.success('New user invited');
      setValue([]);
      setSending(false);
      setMode('INVITE');
      fileAccessQuery.refetch({
        id: fileId,
      });
      try {
        posthog?.capture('FILE_SHARED', {
          workspaceId: workspace?.id,
          fileId: fileId,
        });
      } catch (error) {}
    }
  };

  const handleCopy = () => {
    // copy link
  };
  const handleCancelSend = () => {
    setMode('INVITE');
    setValue([]);
  };
  const validEmail = () => {
    return value.length > 0;
  };

  const isValidEmail = validEmail();
  useEffect(() => {
    if (isValidEmail) {
      setMode('SEND');
    }
  }, [isValidEmail]);

  const handleAccessSelect = (
    newAccess: 'PUBLIC_VIEW' | 'WORKSPACE' | 'PRIVATE'
  ) => {
    if (!fileId) return;
    setDefaultAccess(newAccess);
    saveDefaultAccess({
      variables: {
        defaultAccess: newAccess,
        id: fileId,
      },
    });
    try {
      posthog?.capture('FILE_DEFAULT_ACCESS_CHANGED', {
        defaultAccess: newAccess,

        fileId,
      });
    } catch (error) {}
  };

  const fileAccessList =
    Number(fileAccessData?.length) > 0 ? (
      fileAccessData?.map((fileAccess) => {
        return (
          <div key={fileAccess?.id}>
            <FileAccessItem
              id={fileAccess?.id}
              firstName={fileAccess?.user?.firstName ?? fileAccess?.email ?? ''}
              picture={fileAccess?.user?.picture}
              accessType={fileAccess?.accessType}
              allowChange={isOwner}
              invitationId={null}
            />
          </div>
        );
      })
    ) : (
      <p className="text-xs text-zinc-500">Not shared</p>
    );

  const invitations = fileAccessQuery?.data?.file?.invitations?.map(
    (invitation) => {
      return (
        <div key={invitation?.id}>
          <FileAccessItem
            id={invitation?.id}
            firstName={invitation?.email}
            picture={null}
            accessType={null}
            allowChange={false}
            invitationId={invitation?.id}
          />
        </div>
      );
    }
  );

  const projectMemberList =
    Number(projectAccessData?.length) > 0 ? (
      projectAccessData?.map((projectAccess) => {
        return (
          <ProjectMemberItem
            key={projectAccess?.id}
            id={projectAccess?.id}
            picture={projectAccess?.user?.picture}
            firstName={projectAccess?.user?.firstName}
            allowChange={false}
          />
        );
      })
    ) : (
      <p className="text-xs text-zinc-500">Hub not shared with anyone</p>
    );

  const workspaceAccessLabel =
    projectDefaultAccess === 'WORKSPACE'
      ? 'Workspace not shared with anyone'
      : 'This is a private Hub hence no workspace members have access';
  const workspaceMemberList =
    Number(workspaceAccessData?.length) > 0 ? (
      workspaceAccessData
        ?.sort((a, b) => {
          if (a?.role === 'OWNER') return -1;
          if (b?.role === 'OWNER') return 1;
          return 1;
        })
        ?.map((workspaceAccess) => {
          if (!workspaceAccess?.id) return null;
          const role = workspaceAccess?.role;
          const defaultAccess = workspaceAccess?.defaultAccess;
          return (
            <div
              key={workspaceAccess.id}
              className="flex justify-between items-center w-full my-2"
            >
              <Avatar
                src={workspaceAccess?.user?.picture}
                name={
                  workspaceAccess?.user?.firstName +
                  `${
                    workspaceAccess?.user?.id === workspaceUser?.id
                      ? ' (You)'
                      : ''
                  }`
                }
              />
              <span className="text-xs text-zinc-400">
                {role} ({defaultAccess})
              </span>
            </div>
          );
        })
    ) : (
      <p className="text-xs text-zinc-500">{workspaceAccessLabel}</p>
    );

  const iconClasses = 'w-4 h-4 fill-zinc-50';
  const icon = fileAccessQuery?.loading ? null : defaultAccess ===
    'PUBLIC_VIEW' ? (
    <GlobeAltIcon className={iconClasses} />
  ) : defaultAccess === 'WORKSPACE' ? (
    <UserPlusIcon className={iconClasses} />
  ) : (
    <LockClosedIcon className={iconClasses} />
  );

  const owner = fileAccessQuery?.data?.file?.owner;

  const fileOwner = (
    <div className="flex items-center justify-between w-full">
      <Avatar
        src={owner?.picture ?? null}
        name={
          owner?.firstName
            ? owner?.firstName +
              `${owner?.id === workspaceUser?.id ? ' (You)' : ''}`
            : null
        }
      />
      <span className="text-xs text-zinc-400">OWNER</span>
    </div>
  );

  const canShare =
    isCreator ||
    isSystemFile ||
    isOwner ||
    hasWorkspaceAccess ||
    hasProjectAccess ||
    hasFileAccess
      ? true
      : false;
  const skip = workspaceUser?.isGuest || !canShare;
  if (skip) return null;

  const tabs = [
    {
      id: 'tab-file-access',
      label: 'File access',
      content: (
        <div className="flex flex-col gap-2">
          {fileOwner}
          {fileAccessList}
          {invitations}
        </div>
      ),
    },
    {
      id: 'tab-project-members',
      label: 'Hub members',
      content: projectMemberList,
    },
    {
      id: 'tab-workspace-members',
      label: 'Workspace members',
      content: workspaceMemberList,
    },
  ];

  return (
    <div id="file-share">
      <Tooltip message={'Invite users on this file'}>
        <Button
          id="file-share-button"
          isDisabled={isDemo || fileAccessQuery.loading}
          onClick={setIsDialogOpen}
          variant="green"
          className="h-[32px]"
        >
          <span className="ml-1">Share</span>
          {icon}
        </Button>
      </Tooltip>

      <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
        <DialogContent
          className={
            'max-w-2xl w-full p-4 bg-zinc-900 font-mono overflow-y-auto scrollbar border-zinc-700'
          }
        >
          <FileShareDetails
            tabs={tabs}
            fileName={fileName}
            defaultAccess={defaultAccess}
            value={value}
            setValue={setValue}
            mode={mode}
            handleAccessSelect={handleAccessSelect}
            setNewAccess={setNewAccess}
            newAccess={newAccess}
            handleCancelSend={handleCancelSend}
            isValidEmail={isValidEmail}
            sending={sending}
            handleSend={handleSend}
            extension={extension}
            allowDefaultAccess={isOwner}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
