import { ContextMenuTrigger } from '@hubql/hubqlkit';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  isNotViewMode?: string | boolean;
  handleShowActions?: () => void;
  handleDoubleClick?: () => void;
  showLiveLocation?: string | null;
  onContextMenu?: (event: {
    clientX: number;
    clientY: number;
    preventDefault: () => void;
  }) => void;
  children: ReactNode;
  isActiveField?: any;
  isSelectedSource?: boolean;
  bg?: string;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
}

export const RowOpenAPI = ({
  isNotViewMode,
  handleShowActions,
  handleDoubleClick,
  showLiveLocation,
  onContextMenu,
  children,
  isActiveField,
  isSelectedSource = false,
  bg = 'bg-zinc-800',
  handleMouseEnter,
  handleMouseLeave,
}: Props) => {
  return (
    <ContextMenuTrigger asChild>
      <tr
        className={clsx('relative  w-full ', isNotViewMode, bg)}
        onClick={handleShowActions}
        onDoubleClick={handleDoubleClick}
        style={{
          zIndex: isActiveField ? 100 : 0,
          background:
            isActiveField || isSelectedSource
              ? `${showLiveLocation}30`
              : 'transparent',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={onContextMenu}
      >
        {children}
      </tr>
    </ContextMenuTrigger>
  );
};
