interface Props {
  className?: string;
}
export const SourceIcon = ({ className = 'fill-zinc-400' }: Props) => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.56098 4C3.44593 4 4.12195 3.30585 4.12195 2.5C4.12195 1.69415 3.44593 1 2.56098 1C1.67602 1 1 1.69415 1 2.5C1 3.30585 1.67602 4 2.56098 4ZM5.12195 2.5C5.12195 3.71368 4.236 4.72536 3.06098 4.95238V8.7117C3.52076 8.26263 4.14466 8 4.80806 8H6.70732C7.38304 8 7.9544 7.55319 8.14208 6.9389C6.99966 6.68767 6.14634 5.69112 6.14634 4.5C6.14634 3.11929 7.29293 2 8.70732 2C10.1217 2 11.2683 3.11929 11.2683 4.5C11.2683 5.72819 10.361 6.74953 9.16505 6.96018C8.94905 8.12108 7.93082 9 6.70732 9H4.80806C4.24514 9 3.72961 9.31517 3.47299 9.81619L3.32144 10.1121C4.3645 10.4283 5.12195 11.3778 5.12195 12.5C5.12195 13.8807 3.97536 15 2.56098 15C1.14659 15 0 13.8807 0 12.5C0 11.2863 0.885953 10.2746 2.06098 10.0476V4.95238C0.885953 4.72536 0 3.71368 0 2.5C0 1.11929 1.14659 0 2.56098 0C3.97536 0 5.12195 1.11929 5.12195 2.5ZM10.2683 4.5C10.2683 5.30585 9.59227 6 8.70732 6C7.82236 6 7.14634 5.30585 7.14634 4.5C7.14634 3.69415 7.82236 3 8.70732 3C9.59227 3 10.2683 3.69415 10.2683 4.5ZM4.12195 12.5C4.12195 13.3058 3.44593 14 2.56098 14C1.67602 14 1 13.3058 1 12.5C1 11.6942 1.67602 11 2.56098 11C3.44593 11 4.12195 11.6942 4.12195 12.5Z"
        fill="current"
      />
    </svg>
  );
};
