import { useBookmarkStore } from '../state/stores/bookmarks';
import { useQuery } from '@apollo/client';
import { HubsDocument, HubsQuery } from '@hubql/codegen';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';

export const useProjectList = () => {
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();

  // TODO: move to backend
  const isWorkspaceMember = workspace?.members?.find(
    (m) => m.user?.id === workspaceUser?.id
  );
  const hubsQuery = useQuery<HubsQuery>(HubsDocument, {
    skip: !workspace || !isWorkspaceMember || !workspaceUser?.id,
    variables: {
      workspaceId: workspace?.id,
      userId: workspaceUser?.id,
    },
  });

  const bookmarks = useBookmarkStore((state) => state.bookmarks);

  const bookmarkedProjects = bookmarks
    .filter((bookmark) => bookmark.project)
    .map((bookmark) => bookmark.project);

  const sharedProjectsFromAccess = hubsQuery?.data?.projectAccess?.edges?.map(
    (accessNode) => {
      const access = accessNode?.node;
      if (!access) return null;
      return access.project;
    }
  );

  const sharedFilesFromAccess = hubsQuery?.data?.fileAccess?.edges?.map(
    (accessNode) => {
      const access = accessNode?.node;
      if (!access) return null;
      return access.file;
    }
  );

  const mergeProject = hubsQuery?.data?.privateProjects.edges
    ?.map((e) => e?.node)
    ?.concat(
      //@ts-ignore
      sharedProjectsFromAccess,
      //@ts-ignore
      hubsQuery?.data?.workspaceProjects?.edges?.map((e) => e.node),
      bookmarkedProjects,
      sharedFilesFromAccess
    );

  return {
    hubsQuery,
    bookmarkedProjects,
    sharedProjectsFromAccess,
    mergeProject,
    sharedFilesFromAccess,
  };
};
