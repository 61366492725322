import { FC } from 'react';
import {
  EdgeProps,
  EdgeLabelRenderer,
  BaseEdge,
  getSmoothStepPath,
} from 'reactflow';
import { useFileStore } from '../state/stores/file';
import { useLiveStore } from '../state/stores/live';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { LineIcon } from '@hubql/hubqlkit';
import clsx from 'clsx';

function EdgeLabel({
  transform,
  label,
  fontSize,
  icon,
  className,
  iconClassName,
  labeClassName,
  zIndex,
}: {
  transform: string;
  label: any;
  background?: string;
  fontSize?: string;
  icon?: any;
  className?: string;
  iconClassName?: string;
  labeClassName?: string;
  zIndex?: number;
}) {
  return (
    <div
      style={{
        transform,
        fontSize,
        zIndex,
      }}
      className={clsx('nodrag nopan absolute flex items-center', className)}
    >
      <div className={iconClassName}>{icon}</div>
      <div
        className={clsx(
          'bg-zinc-900 border border-zinc-700 py-0.5 text-[10px] rounded-md text-white px-1 pointer-events-none',
          labeClassName
        )}
      >
        {label}
      </div>
    </div>
  );
}

export const ObjectEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  selected,
  target,
  source,
  targetHandleId,
  sourceHandleId,
  label,
}) => {
  const [edgePath, centerX, centerY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const extension = useFileStore((state) => state.extension);
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const fields = useLiveStore((state) => state.fields);
  const hoverFieldId = useFileStore((state) => state.hoverFieldId);
  const targetField = fields.find((field) => field.id === target);
  const sourceField = fields.find((field) => field.id === source);

  const shouldHighlight =
    selected ||
    target === selectedFieldId ||
    source === selectedFieldId ||
    targetHandleId === selectedFieldId ||
    sourceHandleId === selectedFieldId ||
    target === hoverFieldId ||
    source === hoverFieldId ||
    targetHandleId === hoverFieldId ||
    sourceHandleId === hoverFieldId ||
    (hoverFieldId && targetField?.parentId === hoverFieldId) ||
    (hoverFieldId && sourceField?.parentId === hoverFieldId) ||
    (selectedFieldId && targetField?.parentId === selectedFieldId) ||
    (selectedFieldId && sourceField?.parentId === selectedFieldId);

  const CardinalSource = () => {
    switch (data?.sourceCardinality) {
      case 'many':
        return (
          <EdgeLabel
            transform={`translate(15%, -50%) translate(${sourceX}px,${sourceY}px)`}
            label={'M'}
            icon={<ChevronRightIcon className="w-12 h-12 fill-zinc-400" />}
            fontSize={'10px'}
            className={'flex-row '}
            iconClassName="-ml-4"
            labeClassName="-ml-3"
          />
        );
      case 'one':
        return (
          <EdgeLabel
            transform={`translate(15%, -50%) translate(${sourceX}px,${sourceY}px)`}
            label={'1'}
            icon={<LineIcon className="w-10 h-10 stroke-zinc-400" />}
            fontSize={'10px'}
            className={'flex-row '}
            iconClassName="-ml-4"
            labeClassName="-ml-3"
          />
        );
      default:
        return <div />;
    }
  };

  const CardinalTarget = () => {
    switch (data?.targetCardinality) {
      case 'many':
        return (
          <EdgeLabel
            transform={`translate(-150%, -50%) translate(${targetX}px,${targetY}px)`}
            label={'N'}
            icon={<ChevronRightIcon className="w-12 h-12 fill-zinc-400" />}
            fontSize={'10px'}
            className={'flex-row-reverse'}
            iconClassName="-mr-4 rotate-180"
            labeClassName="-mr-3"
          />
        );
      case 'one':
        return (
          <EdgeLabel
            transform={`translate(-150%, -50%) translate(${targetX}px,${targetY}px)`}
            label={'1'}
            icon={<LineIcon className="w-10 h-10 stroke-zinc-400" />}
            fontSize={'10px'}
            className={'flex-row-reverse'}
            iconClassName="-mr-6 rotate-180"
            labeClassName="-mr-3"
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      <BaseEdge
        // @ts-ignore
        id={id}
        path={edgePath}
        style={{
          stroke: shouldHighlight && activeFieldColor ? activeFieldColor : '',
        }}
      />
      {extension === 'graphql' && shouldHighlight && (
        <EdgeLabelRenderer>
          <EdgeLabel
            transform={`translate(-50%, -50%) translate(${centerX}px,${centerY}px)`}
            label={label?.toString() ?? ''}
            fontSize={'8px'}
            zIndex={shouldHighlight ? 100 : 0}
          />
        </EdgeLabelRenderer>
      )}
      {extension === 'yaml' && (
        <EdgeLabelRenderer>
          {/* <EdgeLabel
            transform={`translate(-50%, -50%) translate(${centerX}px,${centerY}px)`}
            label={label?.toString() ?? ''}
            fontSize={'8px'}
            zIndex={shouldHighlight ? 100 : 0}
          /> */}
          <></>
        </EdgeLabelRenderer>
      )}
      {extension === 'prisma' && data && (
        <EdgeLabelRenderer>
          <CardinalSource />
          <EdgeLabel
            transform={`translate(-50%, -50%) translate(${centerX}px,${centerY}px)`}
            label={label?.toString() ?? 'Relation'}
            fontSize={'10px'}
          />
          <CardinalTarget />
        </EdgeLabelRenderer>
      )}
    </>
  );
};
