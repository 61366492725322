interface Props {
  className?: string;
}
export const XmlIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="12"
      viewBox="0 0 29 12"
      fill="none"
      className={className}
    >
      <path
        d="M4.80507 4.3022L6.90022 0H9.5697L6.25307 5.95055L9.6506 12H6.99729L4.83743 7.63187L2.68566 12H0L3.39753 5.95055L0.0889829 0H2.74229L4.80507 4.3022Z"
        fill="white"
      />
      <path
        d="M13.7234 0L14.8398 4.50824L16.1098 0H18.933V12H16.8459V8.4478L16.943 2.83517L15.3979 8.04396H14.2735L12.8902 3.05769L12.9873 8.4478V12H10.9002V0H13.7234Z"
        fill="white"
      />
      <path
        d="M23.4831 10.1291H29V12H21.2019V0H23.4831V10.1291Z"
        fill="white"
      />
    </svg>
  );
};
