import { useMutation } from '@apollo/client';
import { DeleteFileCommentDocument, FileComment } from '@hubql/codegen';
import { useCommentStore } from '../state/stores/comments';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { CommentRender } from './CommentRender';
import { useLiveStore } from '../state/stores/live';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Avatar } from '@hubql/hubqlkit';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

export const CommentItem = ({
  comment,
  userId,
  showThread,
  replyCount,
}: {
  comment: FileComment;
  userId: string;
  showThread: boolean;
  replyCount: number;
}) => {
  const posthog = usePostHog();

  const date = comment.createdAt
    ? new Date(comment.createdAt).toLocaleString()
    : '';
  const { workspace } = useWorkspace();
  const createdBy = comment.createdBy;

  const user = workspace?.members?.find(
    (member) => member.user.id === createdBy
  )?.user;
  const isYou = userId === comment.createdBy;
  const [deleteFileComment] = useMutation(DeleteFileCommentDocument);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const removeCommentById = useCommentStore((state) => state.removeCommentById);
  const threadId = useCommentStore((state) => state.threadId);
  const setThreadId = useCommentStore((state) => state.setThreadId);
  const setCurrentComments = useCommentStore(
    (state) => state.setCurrentComments
  );

  const deletehandler = (fileCommentId: string) => {
    removeCommentById(fileCommentId);
    deleteFileComment({
      variables: {
        id: fileCommentId,
      },
    });
    try {
      posthog?.capture('FILE_COMMENT_DELETED', {
        workspaceId: workspace?.id,
        fileCommentId,
      });
    } catch (error) {}
  };

  const handleThreadClick = () => {
    setThreadId(comment.id);
    setCurrentComments(comment);
  };

  useEffect(
    () => setSelectedFieldId(comment.fieldId ?? null),
    [comment.fieldId, setSelectedFieldId]
  );

  return (
    <div
      key={comment.id}
      className={`comment-item relative pb-1 pt-2 px-0.5 hover:bg-zinc-800 border-zinc-800 ${
        showThread &&
        threadId === comment.id &&
        '  bg-zinc-800/50 hover:bg-zinc-800/50 border-t border-b border-zinc-700 pt-2 pb-2'
      }`}
    >
      {isYou && (
        <TrashIcon
          className="absolute top-2 right-2 cursor-pointer fill-zinc-500 w-3 h-3"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Are you sure to delete this comment?')) {
              deletehandler(comment.id);
            }
          }}
        />
      )}
      <div className="flex flex-col items-start px-0.5">
        <div className="flex flex-row">
          <Avatar
            size="md"
            name={user?.firstName}
            src={user?.picture}
            date={date}
            isYou={isYou && ' (You)'}
          />
        </div>
      </div>
      <div className="my-1">
        <div className="ml-8 my-1">
          <CommentRender comment={comment.comment} />
        </div>
        {replyCount < 1 && showThread && threadId !== comment.id && (
          <span
            className="ml-8 text-zinc-500 text-xs cursor-pointer hover:text-zinc-400"
            onClick={handleThreadClick}
          >
            Reply in thread
          </span>
        )}
        {replyCount > 0 && showThread && (
          <span
            className=" ml-8 text-zinc-500 text-xs cursor-pointer hover:text-zinc-400"
            onClick={handleThreadClick}
          >
            {replyCount} replies
          </span>
        )}
      </div>
    </div>
  );
};
