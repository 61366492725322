import clsx from 'clsx';
import { useState } from 'react';
import { Tab } from './Tab';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';
import { TabPanels } from './TabPanels';

type tabVariant = 'regular' | 'ghost';

interface Tab {
  label: string | any;
  content: string | JSX.Element;
  id: string;
  tooltip?: string;
}

interface Props {
  tabs: Tab[];
  variant?: tabVariant | string;
  tablistClass?: string;
  padding?: string;
  isExportTab?: boolean;
  handleZoomLevel?: (zoomLevel: string) => void;
}

export const Tabs = ({
  tabs,
  variant = 'regular',
  tablistClass,
  padding,
  isExportTab,
  handleZoomLevel,
}: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex: number, tabLabel: string) => {
    setActiveTab(tabIndex);
    if (isExportTab && handleZoomLevel) {
      handleZoomLevel(tabLabel);
    } else {
      return null;
    }
    return;
  };

  return (
    <div className="text-white w-full">
      <TabList tablistClass={tablistClass}>
        {tabs?.map((tab, index) => {
          const tabId = tab.id;
          const tooltip = tab.tooltip;
          return (
            tabId && (
              <Tab
                id={'tab-' + tabId + index}
                key={'tab-' + tabId + index}
                onClick={() => {
                  handleTabClick(index, tab.label);
                }}
                aria-controls={tabId}
                aria-selected={activeTab === index}
                tabIndex={activeTab === index ? 0 : -1}
                variant={variant}
                tooltip={tooltip}
              >
                {tab.label}
              </Tab>
            )
          );
        })}
      </TabList>
      <TabPanels>
        {tabs?.map((tab, index) => {
          const tabId = tab.id;
          return (
            tabId && (
              <TabPanel
                key={'tab-panel-' + tabId + index}
                id={'tab-panel-' + tabId + index}
                className={clsx(activeTab !== index && 'hidden')}
                padding={padding}
              >
                {tab.content}
              </TabPanel>
            )
          );
        })}
      </TabPanels>
    </div>
  );
};
