import { create } from 'zustand';
import { FileComment } from '@hubql/codegen';

interface CommentState {
  comments: FileComment[];
  currentComment: any;
  setCurrentComments: (currentComment: any) => void;
  setComments: (comments: FileComment[]) => void;
  addComment: (comment: FileComment) => void;
  removeCommentById: (commentId: string) => void;
  threadId: null | string;
  setThreadId: (threadId: null | string) => void;
}

export const useCommentStore = create<CommentState>((set) => ({
  comments: [],
  currentComment: null,
  setCurrentComments: (currentComment) =>
    set((state) => ({
      ...state,
      currentComment,
    })),
  setComments: (comments) =>
    set((state) => ({
      ...state,
      comments,
    })),
  addComment: (comment) =>
    set((state) => ({
      ...state,
      comments: [...state.comments, comment],
    })),
  removeCommentById: (commentId) =>
    set((state) => ({
      ...state,
      comments: state.comments.filter((x) => x.id !== commentId),
    })),
  threadId: null,
  setThreadId: (threadId) =>
    set((state) => ({
      ...state,
      threadId: threadId,
    })),
}));
