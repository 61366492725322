import { FileComment, FileField } from '@hubql/codegen';
import { Edge, Node } from 'reactflow';

export function convertFileCommentToReactFlowSchema(
  notes: FileComment[],
  fieldData: FileField[],
  extension: string | null
) {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  notes?.forEach((note) => {
    nodes.push({
      id: note.id,
      type: 'note',
      data: {
        ...note,
        _hasInitPos: !!note?.positionX && !!note?.positionY,
      },
      dragHandle: '.custom-drag-handle',
      position: { x: note.positionX ?? 0, y: note.positionY ?? 0 },
    });

    if (note.fieldId && note.fieldId !== '__NOTE__') {
      const field = fieldData.find((field) => field.id === note.fieldId);
      const isSmoothStep =
        extension === 'prisma' ||
        extension === 'graphql' ||
        extension === 'proto';
      const stepType = isSmoothStep ? 'smoothstep' : 'default';

      edges.push({
        id: 'e' + note.id,
        data: {
          _type: 'note',
        },
        type: stepType,
        source: field?.parentId ?? field?.id ?? '1',
        sourceHandle: field?.parentId ? note.fieldId : null,
        target: note.id,
        style: { strokeDasharray: '5 8', stroke: '#71717a' },
      });
    }
  });

  return { nodes, edges };
}
