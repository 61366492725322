import { useCallback, useEffect, useRef, useState } from 'react';
import { WorkMode, useFileStore } from '../state/stores/file';
import { useLiveStore } from '../state/stores/live';
import { CellGraphQL } from './CellGraphQL';
import { RowGraphQL } from './RowGraphQL';
import { useCommentStore } from '../state/stores/comments';
import { useContextMenuStore } from '../state/stores/contextmenu';
import {
  ContextMenu,
  ContextMenuTrigger,
  Dialog,
  DialogContent,
  Modal,
} from '@hubql/hubqlkit';
import { SuggestAddField } from '../files/SuggestAddField';
import { TableContextMenu } from './TableContextMenu';
import { Handle, Position, WrapNodeProps } from 'reactflow';
import { GroupByTag } from './GroupByTag';
import { Indicators } from './Indicators';
import clsx from 'clsx';

type Props = {
  fields: (JSX.Element | null)[];
  workMode: WorkMode;
  headingTitle: string;
  parentId?: any;
  parentObjectKey?: string;
  getColor: any;
  nodeProps: WrapNodeProps;
};

// inspiration here: https://raw.githubusercontent.com/Ovyerus/prismaliser/dev/components/ModelNode.tsx
export const TableGraphQL = ({
  fields,
  workMode,
  headingTitle,
  parentId,
  parentObjectKey,
  getColor,
  nodeProps,
}: Props) => {
  const [isSuggestDialogOpen, setSuggestDialogOpen] = useState(false);
  const [isLabelDialogOpen, setLabelDialogOpen] = useState(false);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const suggestions = useLiveStore((state) => state.suggestions);
  const fieldData = useLiveStore((state) => state.fields);
  const showFields = useFileStore((state) => state.showFields);
  const hoverFieldId = useFileStore((state) => state.hoverFieldId);
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const showSuggest = useContextMenuStore((state) => state.showSuggest);

  const fieldSuggestions =
    suggestions.filter((item) => item.fileFieldId === selectedFieldId)?.[0]
      ?.action ?? '';
  const suggestionColorMap = (key: string) => {
    switch (key) {
      case 'REMOVE':
        return 'bg-[#361C1C] line-through	text-red-700';
      case 'ADD':
        return 'bg-[#1E2F28]';
      case 'EDIT':
        return 'bg-[#1E252F]';
      default:
        return 'inherit';
    }
  };
  const isNotViewMode =
    workMode !== 'VIEW' && suggestionColorMap(fieldSuggestions);

  const canComment = workMode === 'VIEW' ? false : true;
  const hasComment = useCommentStore(
    useCallback(
      (state) => {
        if (!canComment) return false;
        return state.comments.find((comment) => comment.fieldId === parentId)
          ? true
          : false;
      },
      [canComment, selectedFieldId]
    )
  );

  const handleShowActions = (newFieldId: string | null) => {
    setSelectedFieldId(newFieldId);
    if (showSuggest) {
      setShowSuggest(false);
    }
  };

  const modelSuggestions = suggestions.filter(
    (s) =>
      s.meta?.newFieldParentId === parentId &&
      !s.isResolved &&
      s.type === 'KEY' &&
      s.action === 'ADD'
  );

  const addRow = modelSuggestions.map((item, index) => {
    const newKey = item.value;
    const newType = suggestions.find(
      (s) => s.meta?.newFieldId === item.meta?.newFieldId && s.type === 'TYPE'
    )?.value;
    const newFieldId = item.meta?.newFieldId;
    return (
      <RowGraphQL
        key={'sugRow_' + index}
        isNotViewMode={isNotViewMode}
        showLiveLocation={activeFieldColor}
        isActiveField={newFieldId === selectedFieldId}
        handleShowActions={() => handleShowActions(newFieldId)}
        bg="bg-green-900/20"
        isSelectedSource={false}
      >
        <CellGraphQL key={'key' + item.id + index} className="text-[#9cdcfe]">
          {newKey}
        </CellGraphQL>
        <CellGraphQL key={'type' + item.id + index} className="text-[#569cd6]">
          {newType}
        </CellGraphQL>

        <CellGraphQL colSpan={2} />
      </RowGraphQL>
    );
  });

  const isRelation =
    fieldData
      .find((field) => field.id === hoverFieldId)
      ?.type?.replace(/[^0-9A-Z]+/gi, '') === headingTitle;

  const isActive = selectedFieldId === parentId;
  const isActiveStyle = {
    outline:
      isActive || isRelation
        ? `2px solid ${activeFieldColor}`
        : '2px solid #3f3f46',
  };
  const setObjectKey = useFileStore((state) => state.setObjectKey);

  const handleTheadClick = () => {
    setSelectedFieldId(parentId);
    setObjectKey(null);
  };

  const emptyField = fields.length > 2;
  return (
    <>
      {showFields && (
        <div className="relative rounded-sm" style={isActiveStyle}>
          <table className="relative px-2 py-2 rounded-sm table-fixed customTable bg-zinc-800">
            <ContextMenu>
              <thead
                className="bg-zinc-900 w-full h-[30px]"
                onClick={handleTheadClick}
                style={{
                  background: getColor,
                }}
              >
                <ContextMenuTrigger asChild>
                  <tr>
                    <th
                      scope="col"
                      colSpan={5}
                      className={clsx(
                        'text-center text-[10px] font-semibold text-white',
                        emptyField ? 'rounded-t-lg' : 'rounded-sm'
                      )}
                    >
                      <div className="relative block w-full h-full px-2">
                        {headingTitle}
                        <Handle
                          type="source"
                          position={Position.Right}
                          isConnectable={false}
                          className="absolute right-0 opacity-0"
                        />
                        <Handle
                          type="target"
                          position={Position.Left}
                          isConnectable={false}
                          className="absolute left-0 opacity-0"
                        />
                        <div className="absolute top-0 right-2">
                          <Indicators hasComment={hasComment} single={true} />
                        </div>
                      </div>
                    </th>
                  </tr>
                </ContextMenuTrigger>
              </thead>
              <TableContextMenu
                parentId={parentId}
                nodeProps={nodeProps}
                setIsDialogOpen={setSuggestDialogOpen}
                setLabelDialogOpen={setLabelDialogOpen}
              />
            </ContextMenu>
            <tbody className="divide-y bg-zinc-800 divide-zinc-700 last:rounded-b-lg">
              {fields}
              {addRow}
            </tbody>
          </table>
        </div>
      )}
      {!showFields && (
        <div
          className="w-[70px] h-[70px] bg-zinc-800 outline outline-4 outline-zinc-400 flex items-center justify-center rounded-xl hover:outline-green-200 active:outline-zinc-500"
          style={{ background: getColor }}
        >
          <span className="w-full p-2 text-center truncate">
            {headingTitle}
          </span>

          <Handle
            type="source"
            position={Position.Right}
            isConnectable={false}
            className="absolute right-0 opacity-0"
          />
          <Handle
            type="target"
            position={Position.Left}
            isConnectable={false}
            className="absolute left-0 opacity-0"
          />
        </div>
      )}

      <Dialog onOpenChange={setLabelDialogOpen} open={isLabelDialogOpen}>
        <DialogContent className="max-w-xs p-4">
          <GroupByTag fieldId={parentId} />
        </DialogContent>
      </Dialog>

      <Dialog onOpenChange={setSuggestDialogOpen} open={isSuggestDialogOpen}>
        <DialogContent className="max-w-xl p-4">
          <SuggestAddField
            initialKey={''}
            initialValue={''}
            initialType={''}
            fieldId={selectedFieldId}
            extension={'graphql'}
            parentId={parentId}
            parentObjectKey={parentObjectKey ?? ''}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
