import { useCompletion } from 'ai/react';
import { CreateFile } from '../files/CreateFile';
import {
  Button,
  Input,
  SelectContent,
  SelectItem,
  SelectNew,
  SelectTrigger,
  SelectValue,
  SelectGroup,
} from '@hubql/hubqlkit';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  prismaFirstLines,
  graphqlFirstLines,
  jsonFirstLines,
  protoFirstLines,
  xmlFirstLines,
} from '@hubql/first-lines';
import Editor from '@monaco-editor/react';
import { usePostHog } from 'posthog-js/react';
import { useMutation } from '@apollo/client';
import { IncreaseAiRequestCountDocument } from '@hubql/codegen';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
const slugify = require('slugify');

interface IAskAi {
  projectId: string;
  workspace: any;
}

export const AskAi = ({ projectId, workspace }: IAskAi) => {
  const { workspaceUser } = useWorkspaceUser();
  const [isFileCreationLoading, setIsFileCreationLoading] = useState(false);

  const setShowGuestSignUp = useUIUtilityStore(
    (state) => state.setShowGuestSignUp
  );
  type filetype = 'json' | 'graphql' | 'prisma' | 'proto' | 'xml';
  const fileTypes = ['json', 'graphql', 'prisma', 'proto', 'xml'];
  const [fileType, setFileType] = useState<filetype>('json');
  const posthog = usePostHog();
  const workspaceId = workspace?.id;
  const belowLimit = workspace?.usage?.aiRequestCountLimitReached !== true;
  const [increaseAIRequestCount] = useMutation(IncreaseAiRequestCountDocument);

  const { isLoading, completion, input, handleInputChange, handleSubmit } =
    useCompletion({
      body: {
        fileType,
      },
      onFinish: () => {
        // do something with the completion result
        toast.success('Successfully generated completion!');

        increaseAIRequestCount({
          variables: {
            workspaceId,
          },
        });
        posthog?.capture('AI_FILE_GENERATED', {
          fileType: fileType,
          projectId: projectId,
          workspaceId: workspaceId,
        });
      },
      api: '/rest/chat',
    });
  const [fileContent, setFileContent] = useState<string>();

  useEffect(() => {
    const firstLines = {
      json: jsonFirstLines,
      xml: xmlFirstLines,
      graphql: graphqlFirstLines,
      prisma: prismaFirstLines,
      proto: protoFirstLines,
    };
    setFileContent(firstLines[fileType] + completion);
  }, [completion]);
  const editorRef = useRef(null);
  function handleEditorDidMount(editor) {
    editorRef.current = editor;
  }
  const handleChange = (value: string) => {
    setFileContent(value);
  };

  useEffect(() => {
    if (workspaceUser?.isGuest) {
      setShowGuestSignUp(true);
    }
  }, []);

  if (workspaceUser?.isGuest) {
    return (
      <div className=" w-full py-2 flex flex-col stretch mt-4 max-w-5xl h-full">
        <p>Our GenAI feature is only available to logged in users.</p>
        <Button
          onClick={() => setShowGuestSignUp(true)}
          className="mt-4 w-[200px]"
        >
          Sign up
        </Button>
      </div>
    );
  }

  return (
    <div className=" w-full py-2 flex flex-col stretch mt-4 max-w-5xl h-full">
      <div className="flex gap-2 items-center mt-4">
        {/* @ts-ignore */}
        <SelectNew value={fileType} onValueChange={setFileType}>
          <SelectTrigger className="w-fit" id="new-field-type">
            <SelectValue placeholder="Select a language" aria-label={fileType}>
              {fileType}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {fileTypes?.map((fileType) => {
                return (
                  <SelectItem key={'new-type' + fileType} value={fileType}>
                    {fileType}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </SelectNew>
      </div>
      {!belowLimit ? (
        <p className="text-bold py-2">You have reached the AI request limit</p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="mb-2 mt-2 flex items-center gap-4"
        >
          {isLoading ? (
            <div className="h-7 bg-zinc-700 rounded-sm w-full animate-pulse" />
          ) : (
            <Input
              id="input"
              width="w-full"
              inputSize="lg"
              value={input}
              placeholder="Describe the app you want to build e.g. food ordering"
              onChange={handleInputChange}
            />
          )}

          <Button isLoading={isLoading} type="submit" variant="green" size="lg">
            Generate
          </Button>
        </form>
      )}

      {
        <p className="border border-orange-900 text-zinc-300 rounded-sm p-4 text-xs leading-loose bg-orange-600/20 mt-4">
          <strong className="text-sm text-zinc-50">Disclaimer</strong>
          <br />
          We cannot guarantee the provided templates are complete or valid
          files.
          <br />
          If you fail to load the files reach out to us to further improve.
        </p>
      }
      {completion && (
        <div className="mt-4 flex flex-col items-center w-full h-full">
          <CreateFile
            fileContent={fileContent}
            label={'Use generated code to create file'}
            projectId={projectId}
            workspaceId={workspaceId}
            workspaceSlug={workspace.slug}
            fileName={slugify(input.trim().toLowerCase().substring(0, 30))}
            extension={fileType}
            variant="green"
            className="mx-auto"
            source={'AI'}
            isLoading={isFileCreationLoading}
            setIsLoading={setIsFileCreationLoading}
          />

          <div className="whitespace-pre-wrap mt-4 w-full h-full  border border-zinc-700 p-4 rounded-sm">
            <Editor
              onMount={handleEditorDidMount}
              onChange={handleChange}
              height="400px"
              theme={'vs-dark'}
              defaultLanguage={fileType}
              value={fileContent}
              className=""
            />
          </div>
        </div>
      )}
    </div>
  );
};
