import { useMutation, useQuery } from '@apollo/client';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { WorkspaceInviteMember } from './WorkspaceInviteMember';
import clsx from 'clsx';
import {
  DeleteWorkspaceMemberDocument,
  RevokeInvitationDocument,
  WorkspaceSubscriptionsDocument,
} from '@hubql/codegen';
import { Button } from '@hubql/hubqlkit';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';

export const WorkspaceMembers = () => {
  const { workspace } = useWorkspace();
  const workspaceId = workspace?.id;
  const { data } = useQuery(WorkspaceSubscriptionsDocument, {
    variables: {
      id: workspaceId as string,
    },
    skip: !workspaceId,
  });
  const members = workspace?.members?.map((member) => {
    if (!member) return null;
    return (
      <WorkspaceMember
        workspaceId={workspaceId}
        key={member.user.id}
        member={member}
        invitationId={null}
      />
    );
  });

  const invitations =
    data?.workspace?.invitations?.map((invitation) => {
      if (!invitation) return null;
      return (
        <WorkspaceMember
          workspaceId={workspaceId}
          key={invitation.id}
          member={{
            user: {
              email: invitation.email,
            },
          }}
          invitationId={invitation.id}
        />
      );
    }) ?? [];

  const rowStyle = 'text-left py-2 text-xs';
  return (
    <div className="w-full max-w-4xl flex-1">
      <div className="flex flex-col items-start border border-zinc-700 rounded-sm p-4 ">
        <span className="text-sm text-zinc-50 w-fit whitespace-nowrap">
          Workspace members
        </span>
        <span className="text-xs text-zinc-400 w-fit whitespace-nowrap pb-4">
          Invite team members, working alone ain&apos;t no fun!
        </span>
        <WorkspaceInviteMember />

        <table className="w-full pt-2">
          <thead className="border-b border-zinc-700">
            <tr>
              <th className={clsx(rowStyle)}>Name</th>
              <th className={clsx(rowStyle)}>Email</th>
              <th className={clsx(rowStyle)}>Role</th>
              <th className={clsx(rowStyle)}>Default access</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-zinc-700">
            {members}
            {invitations}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const WorkspaceMember = ({ member, workspaceId, invitationId }) => {
  const rowStyle = 'text-left py-3 text-xs';
  const [deleteWorkspaceMember, deleteState] = useMutation(
    DeleteWorkspaceMemberDocument
  );
  const [revokeInvitation, revokeInvitationState] = useMutation(
    RevokeInvitationDocument
  );
  const posthog = usePostHog();

  const handleDelete = async () => {
    const confirmed = confirm('Are you sure?');
    if (confirmed) {
      if (invitationId) {
        await revokeInvitation({
          variables: {
            id: invitationId,
          },
          refetchQueries: ['workspaceBySlug', 'workspaceSubscriptions'],
        });
      } else {
        await deleteWorkspaceMember({
          variables: {
            id: member.id,
          },
          refetchQueries: ['workspaceBySlug', 'workspaceSubscriptions'],
        });
      }
      toast.success('Done');
      try {
        posthog?.capture('WORKSPACE_MEMBER_DELETED', {
          workspaceId,
        });
      } catch (error) {}
    }
  };
  return (
    <tr>
      <td className={clsx(rowStyle)}>
        {member.user.firstName ?? ''} {member.user.lastName ?? ''}
      </td>
      <td className={clsx(rowStyle)}>
        {member.user.email} {invitationId && '(Invited)'}
      </td>
      <td className={clsx(rowStyle)}>{member.role}</td>
      <td className={clsx(rowStyle)}>
        {member.role !== 'OWNER' && member.defaultAccess}
      </td>
      <td className={clsx(rowStyle)}>
        {member.role !== 'OWNER' && (
          <Button
            isLoading={deleteState.loading}
            variant="red"
            className={'float-right'}
            onClick={handleDelete}
          >
            Remove
          </Button>
        )}
      </td>
    </tr>
  );
};
