import { useState, useRef, useEffect } from 'react';
import { CloudArrowDownIcon } from '@heroicons/react/20/solid';
import { FileExportDetails } from './FileExportDetails';
import { ExportProps } from '../util/exportVisualization';
import { Button, Tooltip, Modal, useModal } from '@hubql/hubqlkit';

export const FileExport = ({ fileName, extension }: ExportProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [bgColor, setBgColor] = useState('#FFF');
  const [isSelected, setIsSelected] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [zoomLevel, setZoomLevel] = useState('Viewport');
  const zoomLevelRef = useRef(null);
  const colorPickerRef = useRef(null);

  const tabs = [
    {
      id: 'tab-viewport',
      label: 'Viewport',
      content: '',
    },
    {
      id: 'tab-all',
      label: 'All',
      content: '',
    },
  ];

  return (
    <>
      <Tooltip message={'Export visualization as image'}>
        <Button
          onClick={openModal}
          variant="regular"
          className="relative h-[32px]"
        >
          <span className="ml-1">Export</span>
          <CloudArrowDownIcon className="w-4 h-4 fill-zinc-50" />
        </Button>
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        className="min-w-[400px] max-w-2xl w-1/3 px-2 py-3"
      >
        <FileExportDetails
          fileName={fileName}
          extension={extension}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onClose={closeModal}
          tabs={tabs}
          colorPickerRef={colorPickerRef}
          zoomLevelRef={zoomLevelRef}
          bgColor={bgColor}
          setBgColor={setBgColor}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          displayColorPicker={displayColorPicker}
          setDisplayColorPicker={setDisplayColorPicker}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
        />
      </Modal>
    </>
  );
};
