import { Tooltip as TooltipStyle } from 'react-tooltip';

type place = 'left' | 'right' | 'top' | 'bottom';

type Props = {
  children?: JSX.Element;
  message?: string;
  place?: place;
  id?: string;
};

export const Tooltip = ({
  message,
  children,
  place,
  id = 'tooltip-id',
}: Props) => {
  return (
    <div
      data-tooltip-id={id}
      data-tooltip-content={message}
      data-tooltip-delay-show={300}
    >
      {children}
      <TooltipStyle
        id={id}
        className={'rounded bg-zinc-800 p-2 text-xs text-white shawdow-lg'}
        place={place}
      />
    </div>
  );
};
