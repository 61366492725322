export const prismaFirstLines = `generator client {
    provider = "prisma-client-js"
}
datasource db {
    provider = "postgresql"
    url      = env("DATABASE_URL")
}
`;

export const protoFirstLines = `syntax = "proto3";
`;

export const jsonFirstLines = `{
    `;

export const xmlFirstLines = `<?xml version="1.0" encoding="UTF-8"?>
    `;

export const graphqlFirstLines = `type Query {
    `;
