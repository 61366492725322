import { useFileStore } from '../state/stores/file';
import { TableGraphQL } from './TableGraphQL';
import { FieldRowGraphQL } from './FieldRowGraphQL';
import { useMemo } from 'react';

// inspiration here: https://raw.githubusercontent.com/Ovyerus/prismaliser/dev/components/ModelNode.tsx
export const ObjectNodeGraphQL = (object: any) => {
  const parentId = object.data['_parentId'];
  const parentKey = object.data['_parentKey'];
  const fieldTags = useFileStore((state) => state.fieldTags);

  const findColor = fieldTags.find((item) =>
    item.fieldIds?.find((i) => i === parentId)
  );
  const getColor = findColor && findColor.hexColor;
  const workMode = useFileStore((state) => state.workMode);

  const fields = useMemo(() => {
    return Object.keys(object.data).map((objectKey) => {
      if (objectKey.startsWith('_')) return null;
      const value = object.data[objectKey];
      return (
        <FieldRowGraphQL
          key={'row-' + parentId + objectKey}
          value={value}
          objectKey={objectKey}
          parentId={parentId}
          nodeProps={object}
        />
      );
    });
  }, [object.data]);

  return (
    parentId !== undefined && (
      <TableGraphQL
        fields={fields}
        workMode={workMode}
        headingTitle={parentKey ?? 'Types'}
        parentId={parentId}
        parentObjectKey={parentKey}
        getColor={getColor}
        nodeProps={object}
      />
    )
  );
};
