import ReactMarkdown from 'react-markdown';
import { CommentMention } from './CommentMention';

// Strip HTML tags from Comment inside Comment Sidebar
const stripHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};

export const CommentRender = ({ comment }) => {
  const strippedComment = stripHtmlTags(comment);

  return (
    <ReactMarkdown
      components={{
        a: ({ node, children, ...props }) => {
          //@ts-ignore
          const isMention = node.properties?.['href']?.includes('user:');
          if (isMention)
            return (
              <CommentMention node={node} children={children} props={props} />
            );
          return <a {...props}></a>;
        },
      }}
      children={strippedComment?.replace(/@\[/g, '[')}
      remarkPlugins={[]}
    />
  );
};
