interface Props {
  className?: string;
}
export const OpenAPIIcon = ({ className }: Props) => {
  return (
    <svg
      className={className}
      height="2452"
      viewBox="-48.58313174 -136.228532 879.49582062 773.98241888"
      width="2500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m158.57 344.93h-158.29c.03.78.05 1.55.09 2.32.06 1.49.17 2.97.26 4.46.04.64.07 1.28.12 1.91.12 1.72.27 3.44.43 5.15.04.39.07.78.11 1.15.18 1.91.39 3.82.61 5.71.01.18.03.37.06.55.25 2.04.51 4.09.81 6.12 0 .03 0 .06.01.08a266.05 266.05 0 0 0 8.18 37.4c.02.05.04.1.05.15.57 1.94 1.17 3.86 1.78 5.77.04.11.08.23.11.34s.07.22.11.34c.56 1.73 1.15 3.45 1.74 5.18.15.43.3.87.46 1.31.53 1.49 1.07 2.98 1.63 4.48.26.67.51 1.34.76 2.02.49 1.24.96 2.47 1.45 3.7.37.93.75 1.86 1.14 2.78.4.97.8 1.92 1.21 2.89.5 1.18 1.03 2.35 1.55 3.53.31.7.63 1.39.94 2.09.64 1.41 1.3 2.82 1.97 4.22.22.45.43.9.65 1.34.78 1.62 1.57 3.21 2.37 4.8.14.24.25.49.38.73.9 1.76 1.82 3.51 2.77 5.25.04.08.09.15.12.24.2.36.42.71.62 1.07l135.19-81.43.5-.3c-5.33-11.24-8.65-23.2-9.89-35.35z"
        fill="#93d500"
      />
      <path
        d="m197.48 417.6-.38.38-111.5 111.49c.57.53 1.14 1.08 1.73 1.59 1.04.95 2.1 1.88 3.15 2.81.55.48 1.08.96 1.63 1.44 1.26 1.09 2.54 2.15 3.81 3.22.35.29.68.57 1.03.86a255.576 255.576 0 0 0 4.91 3.94c1.58 1.25 3.17 2.46 4.79 3.68.07.04.12.08.18.13a266.48 266.48 0 0 0 20.86 14.1c.24.16.49.3.74.46 1.37.81 2.73 1.62 4.11 2.42l2.52 1.44c.8.46 1.59.9 2.39 1.34 1.45.79 2.9 1.58 4.36 2.35.2.11.4.22.61.32 4.05 2.12 8.17 4.09 12.33 5.99l3.96-9.61 56.08-136.17.2-.5c-6.12-3.28-11.99-7.16-17.51-11.68z"
        fill="#4d5a31"
      />
      <path
        d="m185.87 406.49a95.492 95.492 0 0 1 -3.48-4.1c-1-1.23-1.96-2.47-2.89-3.73-1.07-1.44-2.11-2.9-3.1-4.38-.99-1.49-1.97-2.99-2.89-4.51l-135.36 81.53c2.08 3.46 4.24 6.82 6.46 10.14.07.12.14.24.22.35.02.05.05.08.08.13.07.1.14.22.21.33.01.01.02.02.02.04.18.26.36.52.54.79.01.01.02.03.04.05s.01.01.01.02c2.35 3.47 4.77 6.89 7.26 10.23.05.07.11.14.16.21.06.07.11.14.16.21 1.09 1.43 2.17 2.86 3.29 4.28.15.19.29.37.45.55 1.14 1.47 2.31 2.92 3.5 4.35.28.34.57.68.85 1.03 1.07 1.28 2.14 2.55 3.23 3.81.47.54.95 1.09 1.43 1.62.93 1.06 1.85 2.12 2.8 3.16.52.57 1.06 1.15 1.59 1.72.24.27.48.52.73.79.69.75 1.38 1.5 2.08 2.24.28.29.58.58.86.88 1.23 1.28 2.48 2.57 3.74 3.83l111.67-111.65c-1.28-1.28-2.48-2.6-3.66-3.92z"
        fill="#6ba43a"
      />
      <path
        d="m333.88 417.54c-1.23.99-2.47 1.96-3.73 2.89l.27.47 81.42 135.13c3.77-2.46 7.5-5 11.14-7.67 7.88-5.81 15.5-12.11 22.85-18.89l-111.51-111.49z"
        fill="#4d5a31"
      />
      <path
        d="m400.01 557.14-2.76-4.59-76-126.13c-1.54.92-3.11 1.76-4.68 2.6-1.59.86-3.18 1.65-4.8 2.42-14.6 6.92-30.4 10.46-46.23 10.46-10.37 0-20.73-1.5-30.75-4.49-1.71-.51-3.38-1.18-5.06-1.78-1.69-.59-3.4-1.11-5.06-1.79l-56.04 136.08-2.19 5.33-1.88 4.57-.01.02c.14.06.29.11.43.17.16.06.31.11.46.18h.02c.04.02.08.03.11.04 1.32.55 2.65 1.01 3.98 1.53 1.67.65 3.33 1.31 5 1.92.86.31 1.7.67 2.55.97h.01a267.42 267.42 0 0 0 54.19 12.97c.73.09 1.44.19 2.17.29.75.09 1.51.16 2.27.25 1.42.16 2.84.32 4.27.46l1.06.09c1.75.17 3.5.32 5.24.44.6.05 1.2.07 1.79.12 1.53.1 3.06.19 4.59.26.94.06 1.88.08 2.82.12 1.22.04 2.43.09 3.65.13 1.75.04 3.52.05 5.28.06.42 0 .83.01 1.25.02 14.75 0 29.5-1.23 44.09-3.68.24-.04.49-.08.74-.11 1.54-.27 3.08-.56 4.62-.84.88-.17 1.77-.34 2.63-.51.9-.19 1.81-.37 2.71-.57 1.5-.31 3-.64 4.51-.99.27-.06.54-.11.81-.19 22.18-5.16 43.6-13.16 63.72-23.75v-.01c1.32-.69 2.61-1.48 3.92-2.2 1.56-.85 3.11-1.74 4.67-2.63 1.07-.61 2.16-1.18 3.23-1.82.01-.01.02-.02.03-.02h.01c.02-.01.03-.02.05-.03l.02-.02-.2-.33.2.33c.01 0 .02-.01.03-.01.02-.01.03-.02.05-.03.06-.04.12-.07.18-.11.34-.21.69-.41 1.04-.62zm-202.47-306.22c1.23-1 2.48-1.96 3.73-2.9l-.27-.46-81.42-135.14c-3.78 2.47-7.51 5.02-11.16 7.69-7.87 5.8-15.5 12.09-22.83 18.86l111.51 111.5z"
        fill="#93d500"
      />
      <path
        d="m77.86 146.4c-1.27 1.27-2.47 2.58-3.71 3.86-1.23 1.29-2.49 2.57-3.7 3.87a266.156 266.156 0 0 0 -22.56 27.96c-.33.45-.65.93-.98 1.39-.76 1.12-1.52 2.23-2.26 3.35-.78 1.16-1.55 2.34-2.3 3.51-.28.43-.56.87-.83 1.3-25.65 40.23-39.39 85.86-41.24 131.87-.07 1.79-.11 3.58-.15 5.36-.03 1.78-.09 3.57-.09 5.35h157.93c0-1.78.16-3.56.26-5.35.09-1.79.11-3.58.29-5.36 2.04-20.56 9.98-40.64 23.82-57.5 1.12-1.37 2.39-2.66 3.6-3.98 1.19-1.33 2.31-2.7 3.59-3.98zm325.85-39.2c-.21-.13-.44-.26-.65-.39-1.39-.85-2.79-1.67-4.2-2.49-.81-.47-1.63-.93-2.44-1.39-.83-.46-1.64-.92-2.47-1.37a901.4 901.4 0 0 0 -4.29-2.32c-.23-.11-.45-.23-.68-.35-9.26-4.84-18.77-9.1-28.48-12.8-.25-.1-.5-.2-.75-.29-2.12-.79-4.24-1.58-6.38-2.32a267.084 267.084 0 0 0 -53.21-12.63l-2.22-.3c-.75-.09-1.49-.16-2.23-.24-1.43-.17-2.85-.33-4.29-.47-.39-.04-.79-.06-1.2-.11-1.69-.15-3.4-.3-5.1-.43l-1.99-.12c-1.45-.09-2.93-.2-4.38-.26-.78-.04-1.56-.07-2.33-.09v158.29c8.12.84 16.17 2.57 23.98 5.22l116.51-116.49c-4.33-3-8.7-5.92-13.2-8.65z"
        fill="#4d5a31"
      />
      <path
        d="m260.36 68.7c-1.79.04-3.58.04-5.36.11-11.17.45-22.32 1.59-33.38 3.45-.24.04-.48.08-.73.12-1.55.27-3.09.55-4.63.84-.87.17-1.75.33-2.63.5-.91.18-1.81.37-2.72.57-1.5.31-3 .64-4.49.99-.28.05-.55.12-.82.18a267.465 267.465 0 0 0 -63.73 23.76c-1.31.7-2.59 1.49-3.9 2.2-1.56.86-3.12 1.74-4.67 2.63-1.15.67-2.32 1.28-3.47 1.96-.01.01-.03.02-.04.02-.08.05-.16.1-.24.14-.3.19-.61.34-.91.52l.01.02 2.77 4.59 78.75 130.73c1.54-.92 3.11-1.77 4.68-2.61 1.58-.85 3.18-1.65 4.79-2.41a107.25 107.25 0 0 1 35.36-9.91c1.78-.18 3.56-.33 5.35-.42 1.78-.09 3.57-.18 5.36-.18v-157.91c-1.79 0-3.57.08-5.35.11z"
        fill="#6ba43a"
      />
      <path
        d="m531.05 321.17c-.08-1.42-.16-2.83-.26-4.26a75.4 75.4 0 0 0 -.14-2.12c-.11-1.67-.25-3.34-.41-5.01-.03-.43-.07-.86-.11-1.29-.18-1.87-.38-3.73-.6-5.59-.02-.15-.03-.3-.05-.45l-.03-.21c-.24-2.01-.51-4.02-.79-6.02l-.03-.19a266.454 266.454 0 0 0 -8.16-37.33c-.03-.09-.05-.16-.08-.24-.57-1.91-1.16-3.8-1.75-5.69-.09-.25-.17-.5-.25-.75-.56-1.71-1.13-3.41-1.72-5.11l-.48-1.38c-.52-1.47-1.06-2.94-1.6-4.41-.27-.7-.53-1.4-.81-2.09-.46-1.22-.93-2.43-1.42-3.63-.38-.97-.77-1.92-1.16-2.86-.39-.95-.79-1.88-1.18-2.82-.52-1.2-1.05-2.4-1.58-3.59-.3-.67-.6-1.35-.91-2.03-.65-1.42-1.32-2.85-1.99-4.27-.21-.42-.42-.86-.63-1.29-.78-1.62-1.58-3.25-2.4-4.85-.11-.22-.23-.45-.34-.67-.91-1.77-1.85-3.55-2.79-5.3-.04-.06-.07-.13-.11-.19a266.99 266.99 0 0 0 -15.11-24.52l-116.54 116.54c2.65 7.81 4.37 15.86 5.21 23.97h158.32c-.04-.79-.06-1.58-.1-2.35z"
        fill="#4d5a31"
      />
      <path
        d="m373.45 334.22c0 1.79-.17 3.57-.26 5.36s-.12 3.58-.3 5.35c-2.04 20.57-9.97 40.65-23.81 57.52-1.12 1.37-2.39 2.65-3.6 3.98-1.2 1.32-2.31 2.69-3.59 3.97l111.67 111.65c1.27-1.27 2.46-2.58 3.7-3.87s2.5-2.56 3.7-3.87c8.26-8.95 15.82-18.31 22.62-28.04.28-.39.53-.77.8-1.15.83-1.19 1.63-2.4 2.45-3.6.71-1.08 1.42-2.15 2.11-3.24.35-.52.69-1.06 1.02-1.58 25.61-40.21 39.32-85.79 41.16-131.77.07-1.78.11-3.57.14-5.36.04-1.78.09-3.56.09-5.35z"
        fill="#6ba43a"
      />
      <path
        d="m578.27 21.69c-28.89-28.89-75.73-28.89-104.62 0-23.05 23.04-27.66 57.49-13.93 85.15l-161.16 161.15c-27.66-13.72-62.12-9.12-85.17 13.92-28.89 28.89-28.88 75.73 0 104.62 28.9 28.89 75.75 28.88 104.64 0 23.05-23.05 27.65-57.5 13.92-85.16l161.16-161.14c27.67 13.72 62.11 9.12 85.16-13.93 28.89-28.88 28.89-75.72 0-104.61z"
        fill="#424143"
      />
    </svg>
  );
};
