import { useMutation } from '@apollo/client';
import { ArrowUturnLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import { DeleteSuggestionDocument } from '@hubql/codegen';
import { Avatar, Button, Tooltip } from '@hubql/hubqlkit';
import { useLiveStore } from '../state/stores/live';
import { useActiveCode } from '@codesandbox/sandpack-react';
import { useFileStore } from '../state/stores/file';
import { applySuggestion } from '../util/suggestionAction';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { usePostHog } from 'posthog-js/react';

interface IsuggestControl {
  suggestionData: any;
  setShowSuggest: any;
  hasSuggestion: any;
  fieldId: any;
}

export const SuggestNewRowControl = ({
  suggestionData,
  setShowSuggest,
  fieldId,
}: IsuggestControl) => {
  const posthog = usePostHog();

  const { code, updateCode } = useActiveCode();
  const fields = useLiveStore((state) => state.fields);
  const setFields = useLiveStore((state) => state.setFields);
  const suggestions = useLiveStore((state) => state.suggestions);
  const extension = useFileStore((state) => state.extension);
  const setSuggestions = useLiveStore((state) => state.setSuggestions);
  const setRawContent = useLiveStore((state) => state.setRawContent);

  const [deleteSuggestion] = useMutation(DeleteSuggestionDocument);
  const deletehandler = (suggestionId: string) => {
    setSuggestions(
      suggestions?.filter(
        (suggestionNode) => suggestionNode?.id !== suggestionId
      )
    );
    deleteSuggestion({
      variables: {
        id: suggestionId,
      },
      refetchQueries: ['file'],
    });
    try {
      posthog?.capture('SUGGESTION_DELETED', {
        suggestionId,
      });
    } catch (error) {}
  };
  const acceptSuggestion = async (suggestion) => {
    const { newCode, newFields, newSuggestions } = applySuggestion({
      suggestion,
      fields,
      code,
      extension,
      fieldId,
      suggestions,
    });
    setSuggestions(newSuggestions);
    setRawContent(newCode);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setFields(newFields);
    setShowSuggest(false);
  };

  const handleAccept = () => {
    for (let index = 0; index < suggestionData.length; index++) {
      const suggestion = suggestionData[index];
      acceptSuggestion(suggestion);
    }
  };

  const SuggestBy = ({ suggestion }) => {
    const suggestor = suggestion;
    const { workspace } = useWorkspace();
    const user = workspace?.members?.find(
      (member) => member.user.id === suggestor
    )?.user;
    return (
      <Tooltip message={user?.firstName}>
        <Avatar
          src={user?.picture}
          name={user?.firstName}
          size="sm"
          label={false}
        />
      </Tooltip>
    );
  };

  const ActionControl = () => {
    return (
      <div className="w-full flex justify-between items-center">
        <SuggestBy suggestion={suggestionData[0].suggestedBy} />
        <div className="w-full flex justify-end gap-2">
          <Tooltip message="discard change">
            <Button
              variant="ghost"
              className="px-1 py-1 h-[24px] w-[24px]"
              onClick={() => deletehandler(suggestionData[0].id)}
            >
              <ArrowUturnLeftIcon className="w-3 h-3 -rotate-45" />
            </Button>
          </Tooltip>
          <Tooltip message="stage change">
            <Button
              variant="ghost"
              className="px-1 py-1  h-[24px] w-[24px]"
              onClick={handleAccept}
            >
              <PlusIcon className="w-4 h-4" />
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col	items-start h-full gap-1 bg-[#1E252F] px-2 py-2  rounded-md">
      <ActionControl />
      {suggestionData.map((field, index) => (
        <div
          className="flex items-center gap-2 w-full bg-[#1E252F]"
          key={'suggestion-new-' + field.id + index}
        >
          <div>{field.type}:</div>
          <div className="flex-1">{field.value}</div>
        </div>
      ))}
    </div>
  );
};
