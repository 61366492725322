import { useMutation } from '@apollo/client';
import { CreateFileCommentDocument } from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { FileCommentCreateInput } from '@hubql/codegen';
import { useCommentStore } from '../state/stores/comments';
import { CommentInput } from './CommentInput';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { Button } from '@hubql/hubqlkit';
import { usePostHog } from 'posthog-js/react';
import { useFileStore } from '../state/stores/file';

export const AddComment = ({
  fieldId,
  threadId,
}: {
  fieldId?: string | object | null | undefined;
  threadId?: string;
}) => {
  const posthog = usePostHog();
  const router = useRouter();
  const isDemo = router.pathname.startsWith('/demo');
  const fileId = useFileStore((state) => state.fileId);
  const { workspace } = useWorkspace();
  const [newComment, setNewComment] = useState('');
  const { workspaceUser } = useWorkspaceUser();
  const addComment = useCommentStore((state) => state.addComment);
  const [createFileComment, createFileCommentState] = useMutation(
    CreateFileCommentDocument
  );
  const submitComment = async () => {
    if (newComment && workspace && fileId) {
      const uuid = uuidv4().replace(/-/gi, '');
      const file_comment_id = 'flc_' + uuid;

      //@ts-ignore
      const newCommentData: FileCommentCreateInput = {
        comment: newComment,
        workspaceId: workspace.id,
        fileId,
        isNote: false,
        id: file_comment_id,
        ...(fieldId && { fieldId: fieldId }),
        ...(threadId && { parentCommentId: threadId }),
      };
      //@ts-ignore
      addComment({ ...newCommentData, createdBy: workspaceUser?.id });
      setNewComment('');
      createFileComment({
        variables: {
          input: newCommentData,
        },
        refetchQueries: ['fileComments'],
      });
      try {
        posthog?.capture('COMMENT_CREATED', {
          workspaceId: workspace.id,
          fileId,
        });
      } catch (error) {
        console.error('Analyics error', error);
      }
    }
  };

  const returnKeyPressed = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !onclick) {
      submitComment();
    }
  };

  const placeholder = threadId ? 'Reply to thread' : 'Enter comment';

  return (
    <div className="flex flex-col text-xs items-end w-full relative border border-zinc-700 rounded-md  bg-zinc-700 hover:ring-1 hover:ring-zinc-600 p-1">
      <CommentInput
        placeholder={placeholder}
        newComment={newComment}
        setNewComment={setNewComment}
        members={workspace?.members ?? []}
        submitComment={submitComment}
        returnKeyPressed={returnKeyPressed}
      />
      <div className="flex w-full  justify-end p-1">
        <Button
          variant="green"
          size={'md'}
          isLoading={createFileCommentState.loading}
          onClick={() => submitComment()}
          loadingLabel=""
          isDisabled={isDemo || (newComment === '' && true)}
          className="w-7 h-7 rounded-md"
        >
          <PaperAirplaneIcon className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};
