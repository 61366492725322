import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import { Spinner } from '../Spinner/Spinner';

type inputSize = 'md' | 'lg' | 'sm' | 'xs';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  alt?: string;
  src?: string | null;
  name?: string | null;
  date?: string;
  size?: inputSize;
  isYou?: string | false;
  label?: boolean;
  style?: any;
}

export const Avatar = ({
  className,
  name,
  date,
  alt,
  src = null,
  size = 'md',
  isYou,
  label = true,
  style,
}: Props) => {
  const lgClasses = size === 'lg' && 'h-9 w-9';
  const mdClasses = size === 'md' && 'h-6 w-6';
  const smClasses = size === 'sm' && 'h-5 w-5';
  const xsClasses = size === 'xs' && 'h-4 w-4';
  const isXs = size === 'xs';
  const getInitial = () => {
    if (!name) return null;
    return (
      name
        ?.split(' ')
        .map((n) => n[0])
        .join('') ?? <Spinner />
    );
  };
  return (
    <div className="flex items-center">
      {src === null ? (
        <span
          className={clsx(
            lgClasses,
            mdClasses,
            smClasses,
            xsClasses,
            'inline-flex items-center justify-center rounded-full bg-gray-500',
            className
          )}
          style={style}
        >
          <span className="text-[10px] leading-none text-white">
            {getInitial()}
          </span>
        </span>
      ) : (
        <div
          className={clsx(
            lgClasses,
            mdClasses,
            smClasses,
            xsClasses,
            'relative inline-block rounded-full',
            className
          )}
          style={style}
        >
          <img src={src} alt={alt ?? name ?? ''} className="rounded-full" />
        </div>
      )}

      {label && (
        <div className="ml-2">
          {name && (
            <p
              className={clsx(
                isXs ? 'text-[10px]' : 'text-xs ',
                'font-medium text-white group-hover:text-gray-900'
              )}
            >
              {name}
              {isYou}
            </p>
          )}
          {date && (
            <p
              className={clsx(
                isXs ? 'text-[9px] ' : 'text-xs ',
                'font-medium text-zinc-500 group-hover:text-gray-700'
              )}
            >
              {date}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
