import clsx from 'clsx';
import { CommentIndicator } from '../comments/CommentIndicator';
import { SuggestionIndicator } from '../files/SuggestionIndicator';

interface iIndicator {
  hasComment?: boolean;
  fieldSuggestions?: any;
  single?: boolean;
  flex?: boolean;
}

export const Indicators = ({
  hasComment,
  fieldSuggestions,
  single,
  flex = false,
}: iIndicator) => {
  return (
    <div
      className={clsx(
        flex
          ? 'flex flex-row gap-2 items-center justify-center ml-auto h-full'
          : 'grid gap-2 items-end justify-center h-full w-fit ml-auto',
        single ? 'grid-cols-1' : 'grid-cols-2'
      )}
    >
      {hasComment && <CommentIndicator isActive={hasComment} />}
      {fieldSuggestions && (
        <SuggestionIndicator isActive={fieldSuggestions.length > 0} />
      )}
    </div>
  );
};
