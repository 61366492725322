import { IField } from '../types';

export const fieldsToJSON = (
  fields: IField[],
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
) => {
  const newObject = {};
  function exampleToValue(field: IField) {
    return field.type === 'number'
      ? Number(field.exampleValue)
      : field.type === 'object' ||
        // Prisma model
        ((extension === 'prisma' ||
          extension === 'graphql' ||
          extension === 'proto') &&
          field.type &&
          field.type.charAt(0) === field.type.charAt(0).toUpperCase())
      ? {}
      : field.type === 'array'
      ? []
      : field.exampleValue;
  }
  function setDeep(obj: any, path: string | string[], val: any) {
    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    function stringToPath(path: any) {
      // If the path isn't a string, return it
      if (typeof path !== 'string') return path;

      // Create new array
      const output: string[] = [];

      // Split to an array with dot notation
      path.split('.').forEach(function (item) {
        // Split to an array with bracket notation
        item.split(/\[([^}]+)\]/g).forEach(function (key) {
          // Push to the new array
          if (key.length > 0) {
            output.push(key);
          }
        });
      });

      return output;
    }

    // Convert the path to an array if not already
    path = stringToPath(path) as string[];

    // Cache the path length and current spot in the object
    const length = path.length;
    let current = obj;

    // Loop through the path
    path.forEach(function (key: string, index: number) {
      // Check if the assigned key should be an array
      const isArray = key.slice(-2) === '[]';

      // If so, get the true key name by removing the trailing []
      key = isArray ? key.slice(0, -2) : key;

      // If the key should be an array and isn't, create an array
      if (isArray && !Array.isArray(current[key])) {
        current[key] = [];
      }

      // If this is the last item in the loop, assign the value
      if (index === length - 1) {
        // If it's an array, push the value
        // Otherwise, assign it
        if (isArray) {
          current[key].push(val);
        } else {
          current[key] = val !== 'null' ? val : null;
        }
      }

      // Otherwise, update the current place in the object
      else {
        // If the key doesn't exist, create it
        if (!current[key]) {
          current[key] = {};
        }

        // Update the current place in the object
        current = current[key];
      }
    });
  }

  fields.forEach(async (field) => {
    if (field.meta?.isRoot) {
      return;
    }

    let fieldPath = field.key;
    let parentId = field.parentId;
    while (parentId) {
      const parentfield = fields.find((f) => f.id === parentId);
      if (parentfield?.key) {
        fieldPath = parentfield.key + '.' + fieldPath;
      }
      parentId = parentfield?.parentId ?? null;
    }
    setDeep(newObject, fieldPath, exampleToValue(field));
  });

  return newObject;
};
export const fieldsToFileStringJSON = (
  fieldData: IField[],
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
): string => {
  const myObject = fieldsToJSON(fieldData, extension);
  return JSON.stringify(myObject, null, 4);
};
