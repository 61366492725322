import { Handle, Position } from 'reactflow';
import { useContextMenuStore } from '../state/stores/contextmenu';
import { WorkMode, useFileStore } from '../state/stores/file';
import { useLiveStore } from '../state/stores/live';
import clsx from 'clsx';

type Props = {
  fields: (JSX.Element | null)[];
  headingTitle?: string;
  parentId: any;
  workMode: WorkMode;
};

export const TableXML = ({
  fields,
  headingTitle,
  parentId,
  workMode,
}: Props) => {
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const suggestions = useLiveStore((state) => state.suggestions);
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const showSuggest = useContextMenuStore((state) => state.showSuggest);

  const fieldSuggestions =
    suggestions.filter((item) => item.fileFieldId === selectedFieldId)?.[0]
      ?.action ?? '';
  const suggestionColorMap = (key: string) => {
    switch (key) {
      case 'REMOVE':
        return 'bg-[#361C1C] line-through	text-red-700';
      case 'ADD':
        return 'bg-[#1E2F28]';
      case 'EDIT':
        return 'bg-[#1E252F]';
      default:
        return 'inherit';
    }
  };
  const isNotViewMode =
    workMode !== 'VIEW' && suggestionColorMap(fieldSuggestions);

  const handleShowActions = (newFieldId: string | null) => {
    setSelectedFieldId(newFieldId);
    if (showSuggest) {
      setShowSuggest(false);
    }
  };

  const modelSuggestions = suggestions.filter(
    (s) =>
      s.meta?.newFieldParentId === parentId &&
      !s.isResolved &&
      s.type === 'KEY' &&
      s.action === 'ADD'
  );

  const addRow = modelSuggestions.map((item, index) => {
    const newKey = item.value;
    const newValue = suggestions.find(
      (s) => s.meta?.newFieldId === item.meta?.newFieldId && s.type === 'VALUE'
    )?.value;
    const newFieldId = item.meta?.newFieldId;
    return (
      <div
        key={index}
        className={clsx(
          'gap-1 mt-1 px-4 py-1 w-full flex items-center h-8 relative bg-sky-900/20 text-base',
          isNotViewMode
        )}
        style={{
          outline:
            newFieldId === selectedFieldId
              ? `1px solid ${activeFieldColor}`
              : '1px solid transparent',
          zIndex: newFieldId === selectedFieldId ? 100 : 0,
        }}
        onClick={() => handleShowActions(newFieldId)}
      >
        <span
          className="inline-block align-middle text-sky-400   overflow-hidden w-fit truncate"
          key={item.id + index}
        >
          {newKey}:
        </span>
        <span className="inline-block align-middle text-sky-200   truncate w-fit overflow-hidden">
          "{newValue}"
        </span>
        <span className="ml-auto inline-block align-middle text-sky-500   truncate w-fit overflow-hidden">
          suggested
        </span>
      </div>
    );
  });

  return (
    <div className="relative bg-zinc-800 rounded-md shadow-md  outline outline-2 outline-zinc-700 hover:outline-zinc-300">
      {fields}
      {addRow}

      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        className="absolute right-0 opacity-0"
      />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        className="absolute left-0 opacity-0"
      />
    </div>
  );
};
