import { parse } from 'protobufjs';
import { v4 as uuidv4 } from 'uuid';
import { IField } from '../types';

interface IGraphQLToFields {
  protobufSchema: string;
  workspaceId: string;
  fileId: string;
  rawFields: any[];
}

export const protobufToFields = ({
  protobufSchema,
  workspaceId,
  fileId,
  rawFields,
}: IGraphQLToFields) => {
  const fields: IField[] = [];
  let schema;
  try {
    schema = parse(protobufSchema);
  } catch (error) {
    console.error(error);
    return [];
  }

  const rootObject = schema.root.nested;
  const line = 1;
  if (rootObject) {
    Object.keys(rootObject).forEach((rootKey) => {
      const queryField = rootObject[rootKey];
      const queryRawField = rawFields?.find(
        (raw) => raw.key === rootKey && !raw.parentId
      );
      const queryFieldId =
        queryRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
      fields.push({
        id: queryFieldId,
        key: queryField.name,
        type: 'message',
        exampleValue: null,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: null,
        suggestions: queryRawField?.suggestion,
        positionX: queryRawField?.positionX,
        positionY: queryRawField?.positionY,
        line,
        meta: null,
      });
      //@ts-ignore
      const fieldsObject = queryField?.fields ?? queryField?.nested ?? {};

      Object.keys(fieldsObject).forEach((fieldKey) => {
        const field = fieldsObject[fieldKey];
        const rawField = rawFields?.find(
          (raw) => raw.key === fieldKey && raw.parentId === queryFieldId
        );
        const fieldId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
        fields.push({
          id: fieldId,
          key: field.name,
          type: field.type,
          exampleValue: null,
          workspaceId: workspaceId,
          fileId: fileId,
          parentId: queryFieldId,
          suggestions: rawField?.suggestion,
          line,
          positionX: rawField?.positionX,
          positionY: rawField?.positionY,
          meta: {
            id: field.id,
          },
        });
        const fieldsObjectTwo = field?.fields ?? field?.nested ?? {};
        Object.keys(fieldsObjectTwo).forEach((fieldKeyTwo) => {
          const fieldTwo = fieldsObjectTwo[fieldKeyTwo];
          const rawFieldTwo = rawFields?.find(
            (raw) => raw.key === fieldKeyTwo && raw.parentId === fieldId
          );
          const fieldIdTwo =
            rawFieldTwo?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
          fields.push({
            id: fieldIdTwo,
            key: fieldTwo.name,
            type: fieldTwo.type,
            exampleValue: null,
            workspaceId: workspaceId,
            fileId: fileId,
            parentId: fieldId,
            suggestions: rawFieldTwo?.suggestion,
            line,
            positionX: rawFieldTwo?.positionX,
            positionY: rawFieldTwo?.positionY,
            meta: {
              id: fieldTwo.id,
            },
          });
        });
      });
    });
  }

  return fields;
};
