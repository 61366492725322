import { useMutation, useQuery } from '@apollo/client';
import {
  CreateProjectDocument,
  CreateProjectMutation,
  CreateProjectRepositoryDocument,
  CreateProjectRepositoryMutation,
  ImportFilesDocument,
  UserReposDocument,
  UserReposQuery,
} from '@hubql/codegen';
import router, { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { UserProfile, useUser } from '@clerk/nextjs';
import { ArrowPathIcon, CogIcon } from '@heroicons/react/20/solid';
import { gitHubInstallationSettings } from '@hubql/config';
import {
  Button,
  GitHubIcon,
  Input,
  Spinner,
  Tooltip,
  SourceIcon,
  SelectNew,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hubql/hubqlkit';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';

export const ConnectGitHub = ({ hubTitle, defaultAccess }) => {
  const { user } = useUser();

  const checkGitHubConnect = user?.primaryEmailAddress?.linkedTo?.find(
    ({ type }) => type === 'oauth_github'
  );

  const [isConnected, setIsConnected] = useState(
    checkGitHubConnect ? true : false
  );

  useEffect(() => {
    if (checkGitHubConnect !== undefined && isConnected === false) {
      toast.success('Your GitHub account was linked');
      setIsConnected(true);
    }
  }, [checkGitHubConnect]);

  const router = useRouter();
  const projectId = router.query['hub'];
  const { loading, data, refetch, startPolling } = useQuery<UserReposQuery>(
    UserReposDocument,
    {
      variables: {
        projectId: projectId,
      },
    }
  );
  const repos = data?.userRepos ?? [];
  const hasRepos = Number(repos?.length) > 0;

  const appUrl = process.env['NEXT_PUBLIC_INSTALL_GITHUB_APP_URL'];
  const handleOpenGitHubApp = () => {
    startPolling(1000);
    window.open(appUrl, 'sharer', 'toolbar=0,status=0,width=548,height=425');
  };

  const randomRepo = repos?.find((repo) => repo?.appInstallationId);

  const [showSetting, setShowSetting] = useState(false);

  return (
    <div className="w-full flex flex-col gap-4 items-start">
      {!isConnected && (
        <>
          <div className="flex flex-col items-center w-full gap-4 p-4">
            <h3>Connected Hub</h3>
            <span className="text-center text-xs text-zinc-300">
              Your GitHub account is not yet connected to Schema Visualizer
            </span>
            <Button
              onClick={() => {
                setShowSetting(true);
                if (Number(data?.userRepos?.length) < 1) {
                  refetch();
                }
              }}
              isLoading={loading}
              loadingLabel="Connecting to GitHub"
              variant="regular"
            >
              <GitHubIcon className="w-4 h-4 mr-2" />
              Connect with GitHub
            </Button>
          </div>
          {showSetting && (
            <div className="fixed inset-0 w-sreen h-screen overflow-auto ">
              <div className="relativew-full h-full flex justify-center">
                <div
                  className="absolute z-0 w-full h-full"
                  onClick={() => setShowSetting(false)}
                />
                <UserProfile />
              </div>
            </div>
          )}
        </>
      )}
      {hasRepos && isConnected && !loading && (
        <>
          <div className="flex gap-2 justify-center items-center w-full">
            <h3 className="text-md text-left">GitHub</h3>
          </div>
          <GitHubConnector
            projectId={projectId}
            repos={repos}
            hubTitle={hubTitle}
            defaultAccess={defaultAccess}
            randomRepo={randomRepo}
            refetch={refetch}
            handleOpenGitHubApp={handleOpenGitHubApp}
          />
        </>
      )}
      {loading && (
        <div className="flex flex-row justify-start items-center gap-2">
          <Spinner />
          loading
        </div>
      )}
      {!hasRepos && isConnected && !loading && (
        <div className="flex flex-col gap-4 items-start justify-start text-xs">
          <p>
            Install our GitHub app to select and connect your existing GitHub
            repositories{' '}
          </p>
          <Button onClick={handleOpenGitHubApp} variant="regular">
            <GitHubIcon className="w-4 h-4 mr-2" />
            Install GitHub app
          </Button>
        </div>
      )}
    </div>
  );
};

const GitHubConnector = ({
  projectId,
  repos,
  hubTitle,
  defaultAccess,
  randomRepo,
  refetch,
  handleOpenGitHubApp,
}) => {
  const [value, setValue] = useState('');
  const handleChange = (event) => setValue(event.target.value);

  const handleClickAdd = () => {
    org === 'Add organization' && handleOpenGitHubApp();
  };
  const orgName = randomRepo?.fullName?.substring(
    0,
    randomRepo?.fullName?.indexOf('/')
  );
  const [org, setOrg] = useState(orgName);
  const filteredRepos = repos.filter((repo) => repo.fullName.startsWith(org));
  const uniqueOrgs = [
    ...new Set(repos.map((repo) => repo.fullName.split('/')[0])),
  ] as string[];

  const orgs = [
    ...uniqueOrgs
      .sort((a: string, b: string) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      })
      .map((org) => {
        return {
          label: org,
          value: org,
          image: repos.find((repo) => repo.fullName.startsWith(org))?.avatarUrl,
        };
      }),
    {
      label: 'Add organization',
      value: handleClickAdd(),
      image: null,
    },
  ];
  return (
    <>
      <div className="flex w-full items-center justify-between gap-1">
        <div className="w-full">
          <SelectNew value={org} onValueChange={setOrg}>
            <SelectTrigger className="w-fit" id="new-field-type">
              <SelectValue placeholder="Select a language" aria-label={org}>
                {org}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {orgs?.map((org) => {
                  return (
                    <SelectItem key={'org-' + org.label} value={org.label}>
                      <div className="flex items-center gap-2 justify-start">
                        {org.image && (
                          <img
                            src={org.image}
                            alt={org.label?.toString() ?? ''}
                            className="w-4 h-4"
                          />
                        )}
                        {org.label}
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </SelectNew>
        </div>
        <Tooltip message="Reload repositories">
          <Button variant="regular" onClick={() => refetch()}>
            <ArrowPathIcon className={'w-4 h-4'} />
          </Button>
        </Tooltip>
        {randomRepo && (
          <Tooltip message="Configure GitHub app">
            <Button
              variant="regular"
              onClick={() => {
                const configUrl = gitHubInstallationSettings({
                  isOrg: true,
                  installationId: randomRepo?.appInstallationId,
                  orgName,
                });
                window.open(configUrl);
              }}
            >
              <CogIcon className={'w-4 h-4'} />
            </Button>
          </Tooltip>
        )}
      </div>

      <Input
        value={value}
        onChange={handleChange}
        placeholder="Search your repository"
        width="w-full"
      />

      <Repolist
        projectId={projectId}
        repos={filteredRepos}
        hubTitle={hubTitle}
        defaultAccess={defaultAccess}
      />
    </>
  );
};

const Repolist = ({
  projectId,
  repos,
  hubTitle,
  defaultAccess,
}: {
  projectId: string;
  repos: any;
  hubTitle: string;
  defaultAccess: any;
}) => {
  const [importFiles, importFileState] = useMutation(ImportFilesDocument);

  const [isOpen, setIsOpen] = useState(false);

  const ImportLoading = () => {
    return (
      <span className="text-xs flex items-center gap-2">
        <Spinner /> Importing files from GitHub...
      </span>
    );
  };
  const repoList = repos.map((repo: any) => {
    return (
      <Repo
        key={repo.id}
        id={repo.id}
        name={repo.name}
        projectId={projectId}
        repos={repos}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hubTitle={hubTitle}
        defaultAccess={defaultAccess}
        importFiles={importFiles}
      />
    );
  });
  return (
    <div className="w-full h-[200px] overflow-y-auto scrollbar py-1 pr-1">
      <div className="flex flex-col gap-2">
        {importFileState.loading ? <ImportLoading /> : repoList}
      </div>
    </div>
  );
};

interface IRepo {
  name: string;
  id: string;
  projectId: any;
  repos: any;
  isOpen: boolean;
  setIsOpen: any;
  hubTitle?: string;
  defaultAccess: any;
  importFiles: any;
}

const Repo: React.FC<IRepo> = ({
  name,
  id,
  projectId,
  repos,
  isOpen,
  setIsOpen,
  hubTitle,
  defaultAccess,
  importFiles,
}) => {
  const posthog = usePostHog();

  const [repoId, setRepoId] = useState<string | null>();
  const [productionBranch, setProductionBranch] = useState('main');
  const [baseFolder, setBaseFolder] = useState('./');
  const { workspace } = useWorkspace();
  const [createProjectRepo, createProjectRepoState] =
    useMutation<CreateProjectRepositoryMutation>(
      CreateProjectRepositoryDocument
    );
  const selectedRepo = repos.find((repo: { id: string | null | undefined }) => {
    return repo.id === repoId;
  });

  const [createProject, createProjectState] =
    useMutation<CreateProjectMutation>(CreateProjectDocument);
  const createProjectHandler = async (projectId: string) => {
    if (!workspace) return;

    const newProject = await createProject({
      variables: {
        input: {
          id: projectId,
          name: hubTitle ? hubTitle : 'Untitled Hub',
          workspaceId: workspace.id,
          defaultAccess: defaultAccess,
        },
      },
      refetchQueries: ['projectList'],
    });

    if (
      newProject?.data?.createProject?.__typename === 'CreateProjectSuccess' &&
      newProject?.data?.createProject?.data?.id
    ) {
      const newProjectId = newProject?.data?.createProject?.data?.id;
      posthog?.capture('HUB_CREATED', {
        projectId: newProjectId,
        workspaceId: workspace.id,
      });
      return newProjectId;
    } else {
      if (newProject?.data?.createProject?.__typename === 'Error') {
        toast.error(newProject?.data?.createProject?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const createProjectRepoProcess = async (projectId) => {
    if (selectedRepo && productionBranch && baseFolder && projectId) {
      const uuid = uuidv4().replace(/-/gi, '');
      const id = 'prjrep_' + uuid;
      await createProjectRepo({
        variables: {
          input: {
            id,
            projectId: projectId,
            provider: 'GITHUB',
            githubRepositoryId: repoId,
            productionBranch: productionBranch,
            hubqlBaseFolder: baseFolder,
            githubRepositoryFullName: selectedRepo?.name,
            workspaceId: workspace?.id,
            githubAppInstallationId: selectedRepo?.appInstallationId,
          },
        },
      });
      try {
        posthog?.capture('GITHUB_HUB_CONNECTED', {
          workspaceId: workspace?.id,
          projectId: projectId,
          github_repository_full_name: selectedRepo?.name,
        });
      } catch (error) {}

      setIsOpen(false);
    } else {
      alert('Repo not found');
    }
    toast.success('New GitHub connection!');
  };

  const handleImport = async (projectId: string) => {
    if (projectId) {
      await importFiles({
        variables: {
          projectId,
        },
      });
    }
  };

  const handleCreate = async () => {
    const uuid = uuidv4().replace(/-/gi, '');
    const projectId = 'hub_' + uuid;
    const projectResult = await createProjectHandler(projectId);
    if (!projectResult) return;
    await createProjectRepoProcess(projectResult);
    await handleImport(projectResult);
    router.push(`/w/${workspace?.slug}/hub/${projectResult}`);
  };
  const isValid = productionBranch && selectedRepo?.name && baseFolder;

  const hideSettings = () => {
    setRepoId(null);
    setIsOpen(false);
  };

  function showSettings(id: string) {
    setRepoId(id);
    setIsOpen(true);
  }

  return (
    <>
      {!isOpen && (
        <div className="w-full flex-col">
          <div
            className="w-full flex flex-row justify-between items-center border border-zinc-700 p-1 rounded-sm hover:bg-zinc-700/70 cursor-pointer"
            onClick={() => showSettings(id)}
          >
            <span className="text-white px-2 text-xs flex items-center gap-1">
              <SourceIcon className="w-3 h-3 fill-zinc-400" />
              {name}
            </span>
          </div>
        </div>
      )}
      {isOpen && repoId === id && (
        <div className="w-full p-4 bg-zinc-700/40 rounded-sm">
          <span className="text-lg font-medium leading-6 text-white">
            Connect {selectedRepo?.name}
          </span>
          <div className="mt-4 flex flex-col gap-4">
            {/* TODO: enable once configuration ready */}
            {/* <Input
              value={productionBranch}
              label={'Production branch'}
              onChange={(e) => setProductionBranch(e.target.value)}
              placeholder="main"
            />
            <Input
              value={baseFolder}
              label={'Base folder'}
              onChange={(e) => setBaseFolder(e.target.value)}
              placeholder="./"
            /> */}
            <div className="w-full flex flex-row justify-end items-center gap-4">
              <Button variant="ghost" onClick={hideSettings}>
                Cancel
              </Button>
              <Button
                isLoading={
                  createProjectState.loading || createProjectRepoState.loading
                }
                onClick={handleCreate}
                variant="regular"
                isDisabled={!isValid}
                className="mr-3 px-2 py-1 text-sm rounded-sm bg-green-700 hover:bg-green-800 active:bg-green-700 focus:bg-green-800 text-zinc-200 rounded-sm  "
              >
                Import
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
