import ELK, { ElkExtendedEdge, ElkNode } from 'elkjs';
import { Edge, Node } from 'reactflow';

const elk = new ELK();

const defaultOptions = {
  'elk.algorithm': 'layered',
  'elk.alignment': 'CENTER',
  'elk.layered.spacing.nodeNodeBetweenLayers': '120',
  'elk.spacing.nodeNode': '80',
  'elk.spacing.edgeEdge': '80',
  'elk.edgeRouting': 'SPLINES',
  'elk.direction': 'RIGHT',
  'elk.layered.mergeEdges': 'true',
};

export type GetLayoutedElementsProps = {
  nodes: Node[];
  edges: Edge[];
  options?: Record<string, string>;
};

export const getElkLayoutElements = async ({
  nodes,
  edges,
  options = {},
}: GetLayoutedElementsProps) => {
  const graph = {
    id: 'root',
    layoutOptions: {
      ...defaultOptions,
      ...options,
    },
    children: nodes as ElkNode[],
    edges: edges
      ?.filter(
        (edge) =>
          nodes.findIndex((n) => edge.source === n.id) > -1 &&
          nodes.findIndex((n) => edge.target === n.id) > -1
      )
      .map((edge) => ({
        ...edge,
        type: 'smoothstep',
        label: null,
        sources: [edge.source],
        targets: [edge.target],
      })) as ElkExtendedEdge[],
  };

  try {
    const layoutedGraph = await elk.layout(graph);
    return {
      nodes: layoutedGraph?.children?.map((node) => ({
        ...node,
        position: {
          x: node.x,
          y: node.y,
        },
      })),
      edges: layoutedGraph.edges,
    };
  } catch (err) {
    console.error(err);
    return {
      nodes: [],
      edges: [],
    };
  }
};
