import { ConvertRawContentToFieldFn } from '../HubqlEditor';
import { prismaToFields } from '@hubql/file-util';

export const convertRawContentToField: ConvertRawContentToFieldFn = async ({
  code,
  fieldData,
  fileId,
  workspaceId,
}) => {
  const [newFields, prismaErrors] = await prismaToFields({
    fieldData,
    fileId,
    rawContent: code,
    workspaceId,
  });

  const errors = prismaErrors?.errors.map((error) => new Error(error.message));

  return [newFields, errors?.length ? errors : null];
};
