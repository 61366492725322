import {
  Button,
  Select,
  Option,
  TabsNew,
  TabsList,
  TabsTrigger,
  TabsContent,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectNew,
  SelectTrigger,
  SelectGroup,
  DialogClose,
} from '@hubql/hubqlkit';
import { WorkMode } from '../state/stores/file';
import { ReactMultiEmail } from 'react-multi-email';
import { XMarkIcon } from '@heroicons/react/20/solid';

export const FileShareDetails = ({
  tabs,
  fileName,
  defaultAccess,
  value,
  setValue,
  mode,
  handleAccessSelect,
  setNewAccess,
  newAccess,
  handleCancelSend,
  isValidEmail,
  sending,
  handleSend,
  extension,
  allowDefaultAccess,
}) => {
  return (
    <div className="flex flex-col gap-4 items-start">
      <div className="flex align-center items-center py-2 px-2">
        <h2 className="text-sm text-zinc-300">
          Access for &quot;{fileName}
          {extension ? `.${extension}` : ''}&quot;
        </h2>
      </div>
      <div className="w-full px-2">
        {defaultAccess !== 'PUBLIC_VIEW' && (
          <div className="reactEmail first-line:flex flex-col gap-4">
            <ReactMultiEmail
              id={'file-sharing-add-people'}
              placeholder="Add people to let them access"
              emails={value?.map((email) => email.trim())}
              autoFocus={false}
              onChange={(_emails: string[]) => {
                setValue(_emails?.map((email) => email.trim()));
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={'email' + index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      <XMarkIcon className="w-3 h-3" />
                    </span>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
      {mode === 'INVITE' && (
        <>
          {defaultAccess !== 'PUBLIC_VIEW' && (
            <div className="flex flex-col gap-2 w-full">
              <TabsNew defaultValue={tabs?.[0]?.id} className="">
                <TabsList className="max-w-xl">
                  {tabs?.map((tab) => {
                    return (
                      <TabsTrigger
                        key={'file-sharing-' + tab.id}
                        value={tab.id}
                      >
                        {tab.label}
                      </TabsTrigger>
                    );
                  })}
                </TabsList>
                {tabs?.map((tab) => {
                  return (
                    <TabsContent
                      key={'file-sharing-content-' + tab.id}
                      value={tab.id}
                    >
                      {tab.content}
                    </TabsContent>
                  );
                })}
              </TabsNew>
            </div>
          )}
          <div className="flex items-center justify-between w-full pt-4 border-t border-zinc-800">
            <h3 className="text-sm text-zinc-400">Default access:</h3>

            {allowDefaultAccess ? (
              <SelectNew value={newAccess} onValueChange={handleAccessSelect}>
                <SelectTrigger
                  className="w-fit"
                  id="file-sharing-default-access"
                >
                  <SelectValue aria-label={newAccess}>{newAccess}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={'PUBLIC_VIEW'}>
                      Public
                      <br />
                      (anyone can access)
                    </SelectItem>
                    <SelectItem value={'WORKSPACE'}>
                      Workspace
                      <br />
                      (anyone in workspace/project can access)
                    </SelectItem>
                    <SelectItem value={'PRIVATE'}>
                      Private <br />
                      (anyone invited can access)
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </SelectNew>
            ) : (
              <span className="text-xs text-zinc-400">{defaultAccess}</span>
            )}
          </div>

          <div className="flex w-full justify-end px-2">
            <DialogClose asChild>
              <Button size="md" variant={'green'}>
                Done
              </Button>
            </DialogClose>
          </div>
        </>
      )}
      {mode === 'SEND' && (
        <div className="w-full p-3">
          <div className="w-full flex justify-between items-center">
            <span>Access type:</span>

            <SelectNew value={newAccess} onValueChange={setNewAccess}>
              <SelectTrigger className="w-fit" id={'file-sharing-access-type'}>
                <SelectValue aria-label={newAccess}>{newAccess}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value={WorkMode.VIEW}> Read only</SelectItem>
                  <SelectItem value={WorkMode.COMMENT}>
                    Read and comment
                  </SelectItem>
                  <SelectItem value={WorkMode.EDIT}>
                    Read, comment and edit
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </SelectNew>
          </div>
          <div className="w-full flex justify-end mt-4 gap-4">
            <Button
              id={'file-sharing-cancel'}
              size="md"
              variant="ghost"
              onClick={handleCancelSend}
            >
              Cancel
            </Button>
            <Button
              id={'file-sharing-send'}
              isDisabled={!isValidEmail}
              isLoading={sending}
              size="md"
              variant="green"
              onClick={handleSend}
            >
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
