interface Props {
  className?: string;
}
export const PrismaIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.12608 11.4068C-0.0444262 11.6983 -0.0418471 12.0663 0.132933 12.3551L3.29556 17.5835C3.50084 17.9228 3.89645 18.0776 4.26362 17.9621L13.39 15.0927C13.8881 14.9361 14.1386 14.3539 13.9212 13.859L8.05418 0.521101C7.76807 -0.129374 6.90551 -0.182699 6.54829 0.427876L0.12608 11.4068ZM7.89827 3.5703C7.77359 3.2679 7.3513 3.31448 7.28948 3.63743L5.03068 15.4241C4.98301 15.6727 5.20509 15.8849 5.43683 15.8124L11.7424 13.8397C11.9266 13.7822 12.0217 13.5692 11.945 13.3844L7.89827 3.5703Z"
        fill="white"
      />
    </svg>
  );
};
