import { useFileStore } from '../state/stores/file';

export const FielChangeIndicator = () => {
  const hasChanges = useFileStore((state) => state.hasChanges);
  return (
    <div className="absolute right-0 bottom-0">
      {hasChanges && (
        <div className="relative h-3 w-3 flex items-center justify-center">
          <div className="bg-sky-500 h-3 w-3 rounded-full outline outline-1 outline-sky-200  z-20 absolute" />
          <div className="bg-sky-500/60 h-6 w-6 rounded-full animate-pulse z-10 absolute" />
        </div>
      )}
    </div>
  );
};
