import clsx from 'clsx';
interface Props {
  className?: string;
}
export const CommentIcon = ({
  className = 'w-4 h-4 fill-zinc-50 stroke-zinc-50',
}: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1737 1.58669C22.4343 1.91614 24 3.87025 24 6.06581V12.8578C24 15.0534 22.4343 17.0075 20.1737 17.337C17.9566 17.6574 15.6983 17.8503 13.4057 17.9067C13.2917 17.9089 13.183 17.9541 13.1017 18.0331L8.32 22.7525C8.20013 22.8707 8.04747 22.9512 7.8813 22.9838C7.71513 23.0164 7.5429 22.9996 7.38637 22.9356C7.22984 22.8716 7.09603 22.7633 7.00184 22.6243C6.90764 22.4853 6.85729 22.3218 6.85714 22.1546V17.6924C5.84375 17.6008 4.83309 17.4819 3.82629 17.3358C1.56571 17.0086 0 15.0523 0 12.8567V6.06694C0 3.87138 1.56571 1.91501 3.82629 1.58782C6.53272 1.19564 9.26456 0.999178 12 1C14.7771 1 17.5051 1.20083 20.1737 1.58669ZM3.99207 2.70413C2.33503 2.94396 1.14286 4.38792 1.14286 6.06694V12.8567C1.14286 14.5357 2.33503 15.9797 3.99207 16.2195C4.97826 16.3626 5.96865 16.4791 6.96129 16.5688L8 16.6626V21.473L12.2999 17.229C12.5885 16.9486 12.9748 16.7875 13.3796 16.7788C15.6248 16.7234 17.8363 16.5345 20.0074 16.2207C21.666 15.9787 22.8571 14.5357 22.8571 12.8578V6.06581C22.8571 4.3879 21.6659 2.94486 20.0073 2.7029C17.3933 2.32498 14.7209 2.12825 12 2.12825C9.32023 2.12744 6.64344 2.31993 3.99207 2.70413Z"
        fill="current"
      />
    </svg>
  );
};
