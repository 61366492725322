import { useLiveStore } from '../state/stores/live';
import { Button } from '@hubql/hubqlkit';
import { SuggestInputs } from './SuggestInputs';
import { SuggestControl } from './SuggestControl';
import { useContextMenuStore } from '../state/stores/contextmenu';
import { SuggestNewRowControl } from './SuggestNewRowControl';

export const SuggestEditField = ({
  fieldId,
  objectKey,
  type,
  exampleValue,
  extension,
}) => {
  const showSuggest = useContextMenuStore((state) => state.showSuggest);
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const setActiveEdit = useLiveStore((state) => state.setActiveEdit);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const suggestions = useLiveStore((state) => state.suggestions);
  const suggestData = suggestions.filter(
    (item) => item.fileFieldId === fieldId
  );
  const hasSuggestion = suggestData && suggestData.length > 0;
  const handleShowSuggest = (param) => {
    setActiveEdit(param);
    setShowSuggest(true);
  };

  const getSuggestedRow = suggestions.filter(
    (suggestion) =>
      suggestion.action === 'ADD' && suggestion.meta?.newFieldId === fieldId
  );

  const suggestionCount = suggestData?.length + getSuggestedRow.length;
  return (
    <div>
      <div>
        <div className={'flex flex-col'}>
          <h4 className="mb-2">
            Suggestion:{' '}
            {suggestionCount > 0
              ? `(${suggestionCount})`
              : 'None at the moment'}
          </h4>
          {hasSuggestion && getSuggestedRow.length === 0 && (
            <div className="scrollbar w-full overflow-y-auto  max-h-[15vh] scrollbar overflow-x-hidden">
              <SuggestControl
                suggestionData={suggestData}
                setShowSuggest={setShowSuggest}
                hasSuggestion={hasSuggestion}
                fieldId={fieldId}
              />
            </div>
          )}

          {getSuggestedRow.length > 0 && (
            <div className="scrollbar w-full overflow-y-auto  max-h-[15vh] scrollbar">
              <SuggestNewRowControl
                suggestionData={getSuggestedRow}
                setShowSuggest={setShowSuggest}
                hasSuggestion={getSuggestedRow.length > 0}
                fieldId={selectedFieldId}
              />
            </div>
          )}

          {showSuggest && (
            <SuggestInputs
              fieldId={fieldId}
              initialKey={objectKey}
              initialType={type}
              initialValue={exampleValue}
              setShowSuggest={setShowSuggest}
              extension={extension}
            />
          )}
          {!showSuggest && getSuggestedRow.length === 0 && (
            <div className="flex gap-2">
              <Button
                variant="regular"
                onClick={() => handleShowSuggest('KEY')}
                className="my-2 w-full"
              >
                {extension === 'prisma' ||
                extension === 'graphql' ||
                extension === 'proto'
                  ? 'Suggest Key'
                  : 'Suggest new field'}
              </Button>
              {extension === 'prisma' ||
                extension === 'proto' ||
                (extension === 'graphql' && (
                  <Button
                    variant="regular"
                    onClick={() => handleShowSuggest('TYPE')}
                    className="my-2 w-full"
                  >
                    Suggest Type
                  </Button>
                ))}
            </div>
          )}
        </div>
      </div>
      <hr className="my-4 border-zinc-600" />
    </div>
  );
};
