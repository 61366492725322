import { useMutation, useQuery } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/20/solid';

import {
  ApplyDiscountCodeDocument,
  CreateCustomerPortalSessionDocument,
  DeleteWorkspaceDocument,
  UpgradeSubscriptionDocument,
  ValidateDiscountCodeDocument,
  WorkspaceSubscriptionsDocument,
} from '@hubql/codegen';
import {
  Button,
  Input,
  Modal,
  Skeleton,
  Spinner,
  useModal,
} from '@hubql/hubqlkit';

import { useRouter } from 'next/router';
import { WorkspaceSlug } from './WorkspaceSlug';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useEffect, useState } from 'react';
import { freeSeatLimit, stripePlans } from '@hubql/util';
import { usePostHog } from 'posthog-js/react';
import { UpgradeButton } from './UpgradeButton';
import { AccountProfile } from '../header/AccountProfile';
import { toast } from 'react-toastify';

export const WorkspaceSettings = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const posthog = usePostHog();

  const { workspace } = useWorkspace();
  const workspaceId = workspace?.id;
  const { data, loading, refetch } = useQuery(WorkspaceSubscriptionsDocument, {
    variables: {
      id: workspaceId as string,
    },
    skip: !workspaceId,
  });

  const activeSubscription = data?.workspace?.subscriptions?.edges?.filter(
    (s) => s?.node.status === 'ACTIVE' || s?.node.status === 'TRIALING'
  )?.[0]?.node;

  const [createSession, createSessionState] = useMutation(
    CreateCustomerPortalSessionDocument
  );

  const getBillingUrl = async (flow: string) => {
    if (workspaceId) {
      const session = await createSession({
        variables: {
          workspaceId,
          flow,
          subscriptionId: activeSubscription?.stripeSubscriptionId,
        },
      });
      if (
        session?.data?.createCustomerPortalSession?.__typename ===
        'CreateCustomerPortalSessionSuccess'
      ) {
        window.location.assign(
          session?.data?.createCustomerPortalSession?.data
        );
      } else {
        setsessionLoading(false);
      }
    }
  };

  const planTitle =
    stripePlans?.find((plan) => {
      const activePlanId = activeSubscription?.stripePlanId;
      return plan.id === activePlanId;
    })?.name ?? 'Community';

  const hasFreePlan = planTitle === 'Community';
  const isLifetime = planTitle === 'Lifetime';
  const currentUsedSeats = data?.workspace?.seatsUsed ?? 1;
  const currentAvailableSeats = activeSubscription?.seatsTotal ?? freeSeatLimit;
  const [sessionLoading, setsessionLoading] = useState(false);
  const [appsumoCode, setAppsumoCode] = useState('');

  const [applyDiscountCode, applyDiscountCodeState] = useMutation(
    ApplyDiscountCodeDocument
  );
  const submitDiscountCode = async () => {
    if (!workspaceId) return;
    const result = await applyDiscountCode({
      variables: {
        discountCode: appsumoCode,
        workspaceId,
      },
    });
    if (
      result.data?.applyDiscountCode.__typename === 'ApplyDiscountCodeSuccess'
    ) {
      refetch();
      toast.success('Additional seat added');
      setAppsumoCode('');
    } else {
      toast.error(
        'Failed to add seat. Contact us if you have trouble adding a stacked code.'
      );
    }
  };
  const getSeatChangeUrl = async () => {
    setsessionLoading(true);
    await getBillingUrl('subscription_update');
  };
  const getCancelUrl = async () => {
    setsessionLoading(true);
    getBillingUrl('subscription_cancel');
  };
  const getPaymentMethodUrl = async () => {
    setsessionLoading(true);
    getBillingUrl('payment_method_update');
  };

  if (!workspaceId) return null;
  return (
    <div className="w-full max-w-4xl flex-1">
      <div className=" flex flex-col gap-4">
        <WorkspaceSlug />

        <div className="w-full border border-zinc-700 rounded-sm p-4 flex flex-col gap-4 items-start">
          <span className="text-sm text-zinc-50 w-fit whitespace-nowrap">
            Plan & Billing
          </span>
          {/* TODO:
              - Note if plan expires
              - Note if payment failed
              - Note if on trial            
              */}
          {!loading ? (
            <>
              <span className="text-xs text-zinc-400 w-fit whitespace-nowrap">
                Your current plan: {planTitle}{' '}
                {activeSubscription?.status === 'TRIALING' &&
                  '(Trial until ' +
                    new Date(
                      activeSubscription?.trialEndsAt
                    ).toLocaleDateString() +
                    ')'}
              </span>
              {isLifetime && (
                <div>
                  <span className="text-xs text-zinc-400 w-fit whitespace-nowrap pb-2">
                    Add an additional Appsumo code (stacking)
                  </span>
                  <div className="flex">
                    <Input
                      variant="regular"
                      className={`max-w-sm`}
                      width={'w-full'}
                      onChange={(e) => setAppsumoCode(e.target.value)}
                      value={appsumoCode}
                      placeholder=""
                      inputSize="lg"
                    />
                    <Button
                      className="ml-2"
                      isLoading={applyDiscountCodeState.loading}
                      onClick={submitDiscountCode}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}

              {hasFreePlan && <UpgradeButton workspaceId={workspaceId} />}
              <div>
                <span className="text-xs text-zinc-400 w-fit whitespace-nowrap pb-2">
                  Seats: {currentUsedSeats} of {currentAvailableSeats} seat
                  {currentAvailableSeats > 1 ? 's' : ''} used
                </span>
                {!hasFreePlan && (
                  <Button
                    className="ml-2"
                    isLoading={sessionLoading}
                    onClick={getSeatChangeUrl}
                  >
                    Edit
                  </Button>
                )}
              </div>
              {!hasFreePlan && (
                <Button isLoading={sessionLoading} onClick={getCancelUrl}>
                  Cancel Plan
                </Button>
              )}
              <span className="text-xs text-zinc-400 w-fit whitespace-nowrap pb-2">
                Manage your plan and billing details in Stripe
              </span>

              {!hasFreePlan && (
                <Button
                  variant={
                    activeSubscription?.status === 'TRIALING' &&
                    activeSubscription?.hasPaymentMethod === false
                      ? 'green'
                      : undefined
                  }
                  isLoading={sessionLoading}
                  onClick={getPaymentMethodUrl}
                >
                  {activeSubscription?.status === 'TRIALING' &&
                  activeSubscription?.hasPaymentMethod === false
                    ? 'Add payment method'
                    : 'Update payment details'}
                </Button>
              )}
            </>
          ) : (
            <div className="flex flex-col gap-4 items-start w-full">
              <Skeleton className="w-full h-5" />
              <Skeleton className="w-full h-5" />
              <Skeleton className="w-full h-5" />
            </div>
          )}
        </div>

        <AccountProfile />

        <div className="border border-red-700 rounded-lg p-4 bg-red-900/10">
          <h3 className="text-sm text-400">Delete workspace</h3>
          <div className="flex flex-col gap-2 py-2 justify-start items-start">
            <div className="w-max rounded-sm flex flex-row gap-2 items-center">
              <span className="text-zinc-300 text-xs pb-4">
                Once you delete a workspace, there is no going back.
                <br /> Please be certain.
              </span>
            </div>

            {activeSubscription?.id ? (
              <span className="text-zinc-300 text-xs pb-4">
                You cannot delete a workspace with an active subscription.
                Cancel your subscription first.
              </span>
            ) : (
              <Button
                disabled={activeSubscription?.id ? true : false}
                variant="red"
                size="lg"
                onClick={openModal}
              >
                Delete
                <TrashIcon className="w-3 h-3" />
              </Button>
            )}
          </div>
          <Modal isOpen={isOpen} onClose={closeModal} className={'p-8'}>
            <DeleteModal
              posthog={posthog}
              id={workspace?.id}
              workspaceName={workspace?.name}
              closeModal={closeModal}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({ id, workspaceName, closeModal, posthog }) => {
  const [deleteWorkspace, deleteWorkspaceState] = useMutation(
    DeleteWorkspaceDocument
  );
  const router = useRouter();
  const handleDelete = async () => {
    await deleteWorkspace({
      variables: {
        id,
      },
    });
    try {
      posthog?.capture('WORKSPACE_DELETED', {
        workspaceId: id,
      });
    } catch (error) {}
    closeModal();
    router.push('/');
  };
  return (
    <div className="flex flex-col gap-4 itmes-start">
      <h2>
        Do you really want to delete <strong>{workspaceName}</strong> ?
      </h2>
      <div className={'flex gap-2 mt-4 justify-end'}>
        <Button
          isDisabled={deleteWorkspaceState.loading}
          onClick={closeModal}
          variant="ghost"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          isLoading={deleteWorkspaceState.loading}
          variant="red"
        >
          Yes, let&apos;s delete
        </Button>
      </div>
    </div>
  );
};
