import { useMutation } from '@apollo/client';
import { DeleteProjectAccessDocument } from '@hubql/codegen';
import { Avatar } from '@hubql/hubqlkit';
import { usePostHog } from 'posthog-js/react';

export const ProjectMemberItem = ({ picture, firstName, id, allowChange }) => {
  const posthog = usePostHog();

  const [deleteProjectAccess] = useMutation(DeleteProjectAccessDocument);
  const handleDelete = () => {
    const confirmed = confirm('Are you sure?');
    if (confirmed) {
      deleteProjectAccess({
        variables: {
          id,
        },
        refetchQueries: ['fileAccess'],
      });
      try {
        posthog?.capture('HUB_ACCESS_DELETED', {});
      } catch (error) {}
    }
  };
  return (
    <div className="flex">
      <Avatar src={picture} name={firstName}></Avatar>
      {allowChange && (
        <div className="opacity-[0.5] ml-4" onClick={handleDelete}>
          x
        </div>
      )}
    </div>
  );
};
