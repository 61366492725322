import {
  CompletionSource,
  CompletionResult,
  CompletionContext,
} from '@codemirror/autocomplete';

const prismaKeywords = [
  'model',
  'enum',
  'type',
  'id',
  'default',
  'unique',
  'index',
  'createdAt',
  'updatedAt',
];

const prismaTypes = [
  'String',
  'Boolean',
  'Int',
  'Float',
  'DateTime',
  'Json',
  'Bytes',
  'BigInt',
  'Decimal',
];

const prismaDirectives = [
  '@id',
  '@default',
  '@unique',
  '@index',
  '@relation',
  '@map',
  '@updatedAt',
];

function extractModelNames(docContent: string): string[] {
  const modelNames = [];
  const modelRegex = /model\s+(\w+)/g;
  let match;

  while ((match = modelRegex.exec(docContent)) !== null) {
    modelNames.push(match[1]);
  }

  return modelNames;
}

function extractEnumNames(docContent: string): string[] {
  const enumNames = [];
  const enumRegex = /enum\s+(\w+)/g;
  let match;

  while ((match = enumRegex.exec(docContent)) !== null) {
    enumNames.push(match[1]);
  }

  return enumNames;
}

const combinedSuggestions = [
  ...prismaKeywords.map((keyword) => ({
    label: keyword,
    type: 'keyword',
  })),
  ...prismaDirectives.map((directive) => ({
    label: directive,
    type: 'directive',
  })),
  ...prismaTypes.map((type) => ({ label: type, type: 'type' })),
];

export const prismaCompletion: CompletionSource = (
  context: CompletionContext
): CompletionResult | null => {
  const cursor = context.state.selection.main.head;
  const line = context.state.doc.lineAt(cursor);

  let start = line.from;
  const end = cursor;
  const word = context.matchBefore(/\w*/);
  if (word) {
    start = word.from;
  }

  const modelSuggestions = extractModelNames(context.state.doc.toString());
  const enumSuggestions = extractEnumNames(context.state.doc.toString());
  combinedSuggestions.push(
    ...modelSuggestions.map((model) => ({ label: model, type: 'type' })),
    ...enumSuggestions.map((model) => ({ label: model, type: 'enum' }))
  );

  return {
    from: start,
    to: end,
    options: combinedSuggestions,
  };
};
