//https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/
import fetch from 'isomorphic-unfetch';
import { setContext } from '@apollo/client/link/context';

import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { getMainDefinition } from '@apollo/client/utilities';
import type { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import type { GetToken } from '@clerk/types';

let accessToken: string | null = null;

const requestAccessToken = async () => {
  if (accessToken) return;

  const res = await fetch(`/api/session`);
  if (res.ok) {
    const json = await res.json();
    accessToken = json.accessToken;
  } else {
    accessToken = 'public';
  }
};

// remove cached token on 401 from the server
const errorLink = onError(({ networkError, operation }) => {
  if (networkError && networkError.name === 'ServerError') {
    console.error(
      'Apollo.Error.' + operation.operationName,
      networkError.message
    );
    accessToken = null;
  }
});

const GQL_ENDPOINT = process.env['NEXT_PUBLIC_AWS_GQL_ENDPOINT'];

const createHttpLink = (headers?: Record<string, string>) => {
  return new HttpLink({
    uri: GQL_ENDPOINT,
    // credentials: 'include',
    headers: {
      ...headers,
      'accept-encoding': 'gzip, deflate, br',
      'content-type': 'application/json',
    },
    fetch,
  });
};

const ssrMode = typeof window === 'undefined';

const wsLink = ssrMode
  ? null
  : new GraphQLWsLink(
      createClient({
        lazy: true,
        retryAttempts: 3,
        url: GQL_ENDPOINT?.replace('https', 'wss') ?? '',
        connectionParams: async () => {
          await requestAccessToken(); // happens on the client
          return {
            headers: {
              authorization: accessToken ? `Bearer ${accessToken}` : '',
            },
          };
        },
      })
    );

export const createApolloClient = ({
  initialState = {},
  headers,
  getToken,
  skipTokenCache = false,
  guestId,
}: {
  initialState?: NormalizedCacheObject;
  headers?: Record<string, string>;
  getToken: GetToken;
  skipTokenCache?: boolean;
  guestId?: string | null;
}) => {
  const httpLink = createHttpLink(headers);
  const authMiddleware = setContext(async (req, { headers }) => {
    const token = guestId
      ? null
      : await getToken({ template: 'hasura', skipCache: skipTokenCache });
    return {
      headers: {
        ...headers,
        ...(guestId && { 'x-hubql-guest-id': guestId }),
        ...(token && { authorization: `Bearer ${token}` }),
      },
    };
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    !ssrMode && wsLink ? wsLink : httpLink,
    httpLink
  );
  const conditionalSkipLink = new ApolloLink((operation, forward) => {
    // for demo route we wont allow any modifications
    const isDemo =
      typeof window !== 'undefined' &&
      window.location.pathname.startsWith('/demo');

    if (
      isDemo &&
      //@ts-expect-error
      operation.query.definitions?.find((d) => d.operation === 'mutation')
    ) {
      return null; // Skip the mutation
    }

    return forward(operation);
  });
  return new ApolloClient({
    ssrMode,
    link: from([conditionalSkipLink, authMiddleware, errorLink, splitLink]),
    cache: new InMemoryCache().restore(initialState),
  });
};
