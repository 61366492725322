/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import {
  ChevronRightIcon,
  LockClosedIcon,
  StarIcon,
} from '@heroicons/react/20/solid';
import Link from 'next/link';
import { ProjectTitle } from './ProjectTitle';
import { Button } from '@hubql/hubqlkit';
import { ProjectSharing } from './ProjectSharing';
import {
  CreateUserBookmarkDocument,
  DeleteUserBookmarkDocument,
} from '@hubql/codegen';
import { useBookmarkStore } from '../state/stores/bookmarks';
import { toast } from 'react-toastify';
import { useWorkspace } from '@hubql/app-core';
import { v4 as uuidv4 } from 'uuid';
import { usePostHog } from 'posthog-js/react';

interface iTitleContrainer {
  workspaceSlug: string | string[] | undefined;
  isPrivateProject: boolean;
  projectId: string;
  projectName: string | undefined;
  workspaceUser: any;
}

export const TitleContainer = ({
  workspaceSlug,
  isPrivateProject,
  projectId,
  projectName,
  workspaceUser,
}: iTitleContrainer) => {
  const { workspace } = useWorkspace();
  const posthog = usePostHog();

  //removeBookmark
  const [removeBookmark, removeBookmarkState] = useMutation(
    DeleteUserBookmarkDocument
  );
  const removeBookmarkById = useBookmarkStore(
    (state) => state.removeBookmarkById
  );
  const handleUnmark = (id: string) => {
    if (id) {
      removeBookmarkById(id);
      removeBookmark({
        variables: {
          id,
        },
      });
      try {
        posthog?.capture('HUB_BOOKMARK_DELETED', {
          workspaceId: workspace?.id,
          projectId: projectId,
        });
      } catch (error) {}
    }
    toast.success('Removed from your favorites');
  };

  //addBookMark
  const bookmarks = useBookmarkStore((state) => state.bookmarks);
  const addBookmarkToState = useBookmarkStore((state) => state.addBookmark);
  const bookmark = bookmarks.find(
    (bookmark) => bookmark.project?.id === projectId
  );
  const [addBookmark, addBookmarkState] = useMutation(
    CreateUserBookmarkDocument
  );
  const handleBookmark = () => {
    if (!workspace?.id || !workspaceUser?.id) return;
    const uuid = uuidv4().replace(/-/gi, '');
    const id = 'bookm_' + uuid;
    const newObject = {
      id,
      projectId: projectId,
      workspaceId: workspace?.id,
      userId: workspaceUser?.id,
    };
    addBookmarkToState({
      ...newObject,
      //@ts-ignore
      project: {
        id: projectId,
      },
    });
    addBookmark({
      variables: {
        input: newObject,
      },
    });
    try {
      posthog?.capture('HUB_BOOKMARK_CREATED', {
        workspaceId: workspace?.id,
        projectId: projectId,
      });
    } catch (error) {}
    toast.success('Added to your favorites');
  };

  return (
    <div className="flex flex-row items-center gap-2 justify-start">
      <div className="flex items-center">
        <Link href={`/w/${workspaceSlug}`}>
          <div className="flex flex-row items-center gap-1">
            <span className="text-sm cursor-pointer hover:underline hover:underline-offset-8 text-zinc-300">
              Hubs
            </span>
            <ChevronRightIcon className="w-4 h-4 fill-zinc-500" />
          </div>
        </Link>
        <ProjectTitle projectId={projectId} defaultTitle={projectName} />
      </div>

      {isPrivateProject && <LockClosedIcon className=" w-4 h-4" />}
      {!bookmark && (
        <Button
          isDisabled={removeBookmarkState?.loading || addBookmarkState?.loading}
          onClick={handleBookmark}
          variant="ghost"
          size="md"
          className="px-1"
        >
          <StarIcon className="w-5 h-5 fill-none stroke-1 stroke-zinc-400" />
        </Button>
      )}
      {bookmark && (
        <Button
          isDisabled={removeBookmarkState?.loading || addBookmarkState?.loading}
          onClick={() => handleUnmark(bookmark.id)}
          variant="ghost"
          size="md"
          className="px-1"
        >
          <StarIcon className="w-5 h-5 fill-yellow-500" />
        </Button>
      )}
      <ProjectSharing />
    </div>
  );
};
