import clsx from 'clsx';
interface Props {
  className?: string;
  variant?: 'show' | 'hide';
}
export const SidebarIcon = ({ className, variant }: Props) => {
  return (
    <>
      {variant === 'show' && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M24 21C24 22.6569 22.6569 24 21 24H16L16 0L21 4.37114e-07C22.6569 5.81961e-07 24 1.34315 24 3L24 21Z"
            fill="current"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 22L14 22L14 2L3 2C2.44772 2 2 2.44772 2 3L2 21C2 21.5523 2.44771 22 3 22ZM16 24L16 0L3 7.70852e-07C1.34315 6.26005e-07 1.71846e-06 1.34315 1.57361e-06 3L0 21C-1.44845e-07 22.6569 1.34314 24 3 24L16 24Z"
            fill="current"
          />
        </svg>
      )}
      {variant === 'hide' && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 22H21C21.5523 22 22 21.5523 22 21L22 3C22 2.44772 21.5523 2 21 2L16 2L16 22ZM21 24C22.6569 24 24 22.6569 24 21L24 3C24 1.34315 22.6569 1.71846e-06 21 1.57361e-06L14 9.61651e-07L14 24H21Z"
            fill="current"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 22L14 22L14 2L3 2C2.44772 2 2 2.44772 2 3L2 21C2 21.5523 2.44771 22 3 22ZM16 24L16 1.1365e-06L3 0C1.34315 -1.44847e-07 1.71846e-06 1.34315 1.57361e-06 3L0 21C-1.44845e-07 22.6569 1.34315 24 3 24L16 24Z"
            fill="current"
          />
        </svg>
      )}
    </>
  );
};
