import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  tablistClass?: string;
}

export const TabList = ({ children, tablistClass }: Props) => {
  return (
    <ul
      className={clsx(
        'flex items-center justify-start gap-1 rounded-sm bg-zinc-800 p-1',
        tablistClass
      )}
    >
      {children}
    </ul>
  );
};
