export type Object = {
  [k in string]: Object | string;
};
export const flatObject = (source: Object) => {
  const target: Record<string, unknown> = {};

  Object.keys(source).forEach((key) => {
    const value = source[key];

    if (Array.isArray(value)) {
      // If the value is an array, set an empty array in the target object
      target[key] = [];
    } else if (value !== null && typeof value === 'object') {
      // If the value is a non-null object, set an empty object in the target object
      target[key] = {};
    } else {
      // Otherwise, copy the value as is
      target[key] = value;
    }
  });

  return target;
};
