import { SignIn } from '@clerk/nextjs';

export const LoginForm = () => {
  return (
    <div className="flex flex-col w-fill h-[80vh] items-center justify-center gap-4">
      <h1 className="text-lg">Schema Visualizer by Hubql</h1>
      <div>
        <SignIn />
      </div>
      <p
        style={{
          fontSize: '12px',
          maxWidth: '100%',
          width: '450px',
        }}
      >
        By clicking “Continue with Google/GitHub/Email above, you acknowledge
        that you have read and understood, and agree to Hubql&apos;s{' '}
        <a
          style={{
            textDecoration: 'underline',
          }}
          href="https://www.hubql.com/terms-of-use"
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          style={{
            textDecoration: 'underline',
          }}
          href="https://www.hubql.com/privacy-policy"
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );
};
