import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  children: string | ReactNode;
  className?: string;
  id: string;
  padding?: string;
}
export const TabPanel = ({
  children,
  className,
  id,
  padding = 'pt-4 px-2',
}: Props) => {
  return (
    <div id={id} className={clsx(className, padding)}>
      {children}
    </div>
  );
};
