import { PencilSquareIcon, PlusIcon, TagIcon } from '@heroicons/react/20/solid';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { useLiveStore } from '../state/stores/live';
import { useFileStore } from '../state/stores/file';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { toast } from 'react-toastify';
import { WrapNodeProps, useReactFlow } from 'reactflow';
import { createNote } from '../comments/Note';
import { useMutation } from '@apollo/client';
import { CreateFileCommentDocument } from '@hubql/codegen';
import { usePostHog } from 'posthog-js/react';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { Point } from './types/common';
import { ContextMenuContent, ContextMenuItem } from '@hubql/hubqlkit';

interface IProps {
  parentId?: string;
  nodeProps: WrapNodeProps;
  setIsDialogOpen: any;
  setLabelDialogOpen: any;
}

export const TableContextMenu = ({
  parentId,
  nodeProps,
  setIsDialogOpen,
  setLabelDialogOpen,
}: IProps) => {
  const { setCenter } = useReactFlow();
  const posthog = usePostHog();

  const notes = useLiveStore((state) => state.notes);
  const setNotes = useLiveStore((state) => state.setNotes);
  const [createFileComment, createFileCommentState] = useMutation(
    CreateFileCommentDocument
  );

  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();
  const userId = workspaceUser?.id;
  const fileId = useFileStore((state) => state.fileId);

  const handleAddNote = () => {
    const workspaceId = workspace?.id;

    if (!workspaceId || !userId || !fileId) {
      toast.error('Unexpected error');
      console.error('workspaceId or userId is null');
      return;
    }

    const noteXPos = nodeProps.xPos + 400;
    const noteYPos = nodeProps.yPos;

    const { newNotes, newNote } = createNote({
      notes,
      fileId,
      workspaceId,
      userId,
      x: noteXPos,
      y: noteYPos,
      fieldId: parentId ?? '__NOTE__',
      createFileComment,
      posthog,
    });

    setCenter(noteXPos, noteYPos, {
      zoom: 1,
      duration: 300,
    });

    setNotes(newNotes);
  };

  const setShowComment = useUIUtilityStore((state) => state.setShowComment);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setObjectKey = useFileStore((state) => state.setObjectKey);

  const openComment = () => {
    setShowComment(true);
    setSelectedFieldId(parentId ?? null);
    setObjectKey(null);
  };

  return (
    <ContextMenuContent>
      <ContextMenuItem onClick={handleAddNote}>
        <PlusIcon className="w-4 h-4 fill-zinc-200 " />
        Create note
      </ContextMenuItem>
      <ContextMenuItem onClick={openComment}>
        <PlusIcon className="w-4 h-4" /> Add comment
      </ContextMenuItem>
      <hr className="py-1 border-zinc-600" />
      <ContextMenuItem onClick={setLabelDialogOpen}>
        <TagIcon className="w-4 h-4" /> Group by tag
      </ContextMenuItem>
      <hr className="py-1 border-zinc-600" />
      <ContextMenuItem onClick={setIsDialogOpen}>
        <PencilSquareIcon className="w-4 h-4" /> Suggest New Field
      </ContextMenuItem>
    </ContextMenuContent>
  );
};
