import { Button, Input } from '@hubql/hubqlkit';

export const CreatePR = ({
  prTitle,
  setPRTitle,
  canSave,
  isSaving,
  handleSave,
  isGitHubProject,
  prURL,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <Input
        className="bg-zinc-800 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1 focus:ring-1 focus:outline-none focus:ring-zinc-200"
        id="input-value"
        placeholder="PR Title"
        value={prTitle}
        onChange={(e) => setPRTitle(e.target.value)}
        inputSize="sm"
      />
      <Button
        isDisabled={!canSave}
        isLoading={isSaving}
        onClick={handleSave}
        variant="green"
      >
        {isGitHubProject && prURL === '' && <>Commit</>}
      </Button>
    </div>
  );
};
