import { QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { LayoutIcon, RightClickIcon } from '@hubql/hubqlkit';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';

export const Helper = () => {
  const setShowHelper = useUIUtilityStore((state) => state.setShowHelper);
  const listStyle = 'py-1 pl-0 pr-2 flex items-center flex-nowrap';
  return (
    <div className="bg-zinc-500/20 backdrop-blur-sm rounded-sm p-4 m-2 flex flex-col w-[340px] text-zinc-50">
      <div className="flex w-full items-center gap-2 text-[18px] font-bold">
        <QuestionMarkCircleIcon className="fill-zinc-100 w-6 h-6" /> How to get
        started?
      </div>
      <div className="mt-4">
        <ul>
          <li className={listStyle}>
            <RightClickIcon className="w-10 h-10 fill-zinc-50 mr-3" /> Right
            Click anywhere on the visualization and get to see hidden actions
          </li>
          <li className={listStyle}>
            <LayoutIcon
              className="w-9 h-10 fill-zinc-50 mr-3"
              variant="light"
            />{' '}
            Hide panel you don't use or reset your workspace layout
          </li>
        </ul>
      </div>
      <button
        className="absolute top-4 right-4 rounded-md bg-zinc-50/10 border border-zinc-50/30 p-1 hover:bg-zinc-50/30"
        onClick={() => setShowHelper(false)}
      >
        <XMarkIcon className="w-4 h-4" />
      </button>
    </div>
  );
};
