import { Button } from '@hubql/hubqlkit';
import { useRef, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { ArrowUpOnSquareIcon } from '@heroicons/react/20/solid';
import { usePostHog } from 'posthog-js/react';
import { FileData, getFileContent } from '../util/getFileContent';

export const UploadFiles = ({ projectId, source, setFiles }) => {
  const [isDragging, setIsDragging] = useState(false);
  const { workspace } = useWorkspace();
  const posthog = usePostHog();

  const inputFileRef = useRef(null);
  const [content, setContent] = useState<string>();

  const handleSetFiles = (files: FileList) => {
    Array.from(files).forEach((file) => {
      getFileContent(file, (fileData) =>
        setFiles((prev: FileData[]) => [...prev, fileData])
      );
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleSetFiles(e.target.files);
    }
  };

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const [fileName, setFileName] = useState<string>();
  const [extension, setExtension] = useState<
    'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml'
  >();

  // Dragging Functions
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files.length > 0) {
      handleSetFiles(e.dataTransfer.files);
    }
  };

  if (!workspace?.id) return <></>;

  return (
    <div
      className={`flex flex-col gap-2 items-center justify-center rounded-md  mt-4 max-w-5xl border border-zinc-700 
      ${isDragging ? 'border-dashed border-green-700' : ''}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        ref={inputFileRef}
        hidden={true}
        type="file"
        accept=".json,.xml,.prisma,.graphql,.proto,.yaml"
        onChange={handleFileChange}
        multiple
      />

      <div className="flex flex-col items-center gap-2 py-8">
        <div className="flex flex-col items-center gap-1">
          {isDragging ? (
            <ArrowUpOnSquareIcon className={'w-6 h-6 fill-zinc-400'} />
          ) : null}
          <h3 className="text-md">
            {isDragging
              ? 'Drop your file(s) here'
              : 'Upload or Drag your file(s) here'}
          </h3>
          <span className="text-xs text-zinc-400">
            {!isDragging
              ? 'We currently only support JSON, XML, Prisma, Protobuf, OpenAPI (.yaml) or GraphQL files'
              : ''}
          </span>
        </div>
        {!isDragging && (
          <Button onClick={handleClick} size={'md'} variant="green">
            Upload
          </Button>
        )}
      </div>
    </div>
  );
};
