import React, { useCallback, useEffect, useState } from 'react';
import { useFileStore } from '../../state/stores/file';
import { useUIUtilityStore } from '../../state/stores/toggleSidebarStore';
import { useWorkspace } from '../../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../../context/user/WorkspaceUserContext';
import { EditorView } from 'codemirror';
import { useCenterView } from '../../util/useCenterView';
import { useActiveCode } from '@codesandbox/sandpack-react';
import { LiveField, useLiveStore } from '../../state/stores/live';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { lineToOffset } from './prisma/diagnostics';
import * as Y from 'yjs';
import LiveblocksProvider from '@liveblocks/yjs';
import { customDebouncer } from '../../util/custom-debouncer';
import { yCollab } from 'y-codemirror.next';
import { IField } from '@hubql/file-util';
import { createCodeMirrorView } from './cm-view';
import { Extension } from '@codemirror/state';
import { ViewUpdate } from '@codemirror/view';
import { toast } from 'react-toastify';
import { Cross1Icon } from '@radix-ui/react-icons';

export type FileExtension =
  | 'prisma'
  | 'graphql'
  | 'proto'
  | 'xml'
  | 'json'
  | 'yaml'
  | 'text';

export type GetPositionByFieldIdFn = (input: {
  selectedFieldId: string;
  fieldData: LiveField[];
  code: string;
}) => { lineNumber: number; columnNumber: number } | null;

export type GetFieldByLineNumberFn = (input: {
  code: string;
  lineNumber: number;
  fieldData: LiveField[];
}) => LiveField | null;

export type ConvertRawContentToFieldFn = (input: {
  code: string;
  fileId: string;
  workspaceId: string;
  fieldData: LiveField[];
}) =>
  | Promise<[IField[] | null, Error[] | null]>
  | [IField[] | null, Error[] | null];

export type LanguageConfig = {
  language: FileExtension;
  extensions?: Extension[];
  getEditorPositionByFieldId?: GetPositionByFieldIdFn;
  getFieldByLineNumber?: GetFieldByLineNumberFn;
  convertRawContentToField?: ConvertRawContentToFieldFn;
  onContentChange?: (v: ViewUpdate) => void;
};

type HubqlEditorProps = {
  fileId: string;
  workspaceId: string;
  languageConfig?: LanguageConfig;
  isReadOnly: boolean;
};

export const HubqlEditor: React.FC<HubqlEditorProps> = (props) => {
  const { workspaceUser } = useWorkspaceUser();
  const { workspace } = useWorkspace();
  const [view, setView] = useState<EditorView | null>(null);
  const [element, setElement] = useState<HTMLElement>();
  const [latestSelectedFieldIdByEditor, setLatestSelectedFieldIdByEditor] =
    useState<string | null>(null);
  const [
    latestSelectedFieldIdByOtherComponents,
    setLatestSelectedFieldIdByOtherComponents,
  ] = useState<string | null>(null);
  const { handleCenterView } = useCenterView();
  const [hasSyntaxError, setHasSyntaxError] = useState<any[] | null>(null);
  const { code: activeCode, updateCode } = useActiveCode();
  const setShowGuestSignUp = useUIUtilityStore(
    (state) => state.setShowGuestSignUp
  );
  const setReachedPlanLimit = useUIUtilityStore(
    (state) => state.setReachedPlanLimit
  );
  const setReachedGuestLimit = useUIUtilityStore(
    (state) => state.setReachedGuestLimit
  );
  const setShowUpgradeModal = useUIUtilityStore(
    (state) => state.setShowUpgradeModal
  );
  const rawContent = useLiveStore((state) => state.rawContent);
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const fieldData = useLiveStore((state) => state.fields);
  const setFields = useLiveStore((state) => state.setFields);

  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const room = useLiveStore((state) => state.liveblocks.room);

  const languageConfig = props.languageConfig;

  const ref = useCallback(function setupCodeMirrorHTMLElement(
    node: HTMLElement | null
  ) {
    !!node && setElement(node);
  },
  []);

  useEffect(
    function handleFieldSelectedByOtherComponents() {
      if (!selectedFieldId || !view || !activeCode || !fieldData) {
        return;
      }

      // If the selected field is the same as the latest selected field by the editor
      // This implies that the user has selected the field from the editor so nothing to do here
      if (latestSelectedFieldIdByEditor === selectedFieldId) {
        return;
      }
      setLatestSelectedFieldIdByOtherComponents(selectedFieldId);

      if (!languageConfig?.getEditorPositionByFieldId) {
        return;
      }
      const latestFields = useLiveStore.getState().fields;

      const editorPos = languageConfig.getEditorPositionByFieldId({
        fieldData: latestFields,
        selectedFieldId,
        code: activeCode,
      });

      if (!editorPos) {
        return;
      }

      try {
        const { from } = lineToOffset(activeCode, editorPos.lineNumber);
        view.dispatch({
          selection: { anchor: from + editorPos.columnNumber },
          scrollIntoView: true,
          effects: EditorView.scrollIntoView(from + editorPos.columnNumber, {
            y: 'center',
            yMargin: 300,
          }),
        });
      } catch (e) {
        console.error(e);
      }
    },
    [selectedFieldId]
  );

  useEffect(
    function setupCodeMirrorView() {
      const isInitialized = !element || !room || rawContent === '';
      if (isInitialized) {
        return;
      }

      const ydoc = new Y.Doc();
      const provider = new LiveblocksProvider(room as any, ydoc);
      const ytext = ydoc.getText('codemirror');
      const undoManager = new Y.UndoManager(ytext);

      provider.awareness.setLocalStateField('user', {
        name: workspaceUser?.name,
        color: activeFieldColor,
        colorLight: activeFieldColor,
      });

      const onSyncedWithLiveBlockServer = () => {
        const others = room.getOthers();
        if (others.length === 0) {
          try {
            ytext.delete(0, ytext.length);
          } catch (e) {
            ytext.delete(0, rawContent.length);
          }

          ytext.insert(0, rawContent);

          return;
        }
      };

      provider.synced
        ? onSyncedWithLiveBlockServer()
        : provider.once('synced', onSyncedWithLiveBlockServer);

      const convertFileDebounceFn = customDebouncer(async (content: string) => {
        if (!languageConfig?.convertRawContentToField) {
          return;
        }

        if (content?.trim() === '') {
          setFields([]);
          setHasSyntaxError(null);
          return;
        }

        const liveFields = useLiveStore.getState().fields;
        const initialFields = useFileStore.getState().initialFields ?? [];

        // If there are live fields, use them, otherwise use the initial fields
        // Use `initialFields` to initialize LiveBlock state for newly created files
        const fieldData = liveFields.length > 0 ? liveFields : initialFields;

        const [newFields, errors] =
          await languageConfig?.convertRawContentToField({
            fieldData,
            fileId: props.fileId,
            code: content,
            workspaceId: props.workspaceId,
          });

        newFields?.length && setFields(newFields as unknown as LiveField[]);
        setHasSyntaxError(errors);
      }, 200);

      const view = createCodeMirrorView({
        element,
        doc: ytext.toString(),
        isReadOnly: props.isReadOnly,
        extensions: [
          ...(languageConfig?.extensions ?? []),
          yCollab(ytext, provider.awareness, { undoManager }),
        ],
        onEditorChange: async (v) => {
          languageConfig?.onContentChange?.(v);

          switch (true) {
            case v.docChanged: {
              const newContent = v.state.doc.toString();

              // Update the active code in the editor
              // This is needed for the codesandbox and diff view
              newContent !== '' && updateCode(newContent);
              convertFileDebounceFn(newContent);

              const fileLines = newContent.split('\n');
              const lineCount = fileLines.length;
              const lineLimit = workspaceUser?.isGuest
                ? 100
                : workspace?.activeSubscription
                ? 10000
                : 150;

              if (lineCount > lineLimit) {
                if (workspaceUser?.isGuest) {
                  setShowGuestSignUp(true);
                  setReachedGuestLimit(true);
                  toast.info(
                    'You have reached the file size limit. Sign up for larger files.'
                  );
                } else {
                  setShowUpgradeModal(true);
                  setReachedPlanLimit(true);
                  toast.info(
                    'You have reached the file size limit for your current plan.'
                  );
                }
              } else {
                setReachedPlanLimit(false);
                setReachedGuestLimit(false);
              }

              return;
            }

            case v.selectionSet: {
              const state = v.state;
              const lineHead = state.selection.main.head;
              if (!lineHead) {
                return;
              }
              // If there is only 1 line highlighted
              const ifOnlyOneLineHighlighted =
                state.selection.main.from === state.selection.main.to;
              if (!ifOnlyOneLineHighlighted) {
                return;
              }

              const line = state.doc.lineAt(lineHead);
              const currentLineNumber = line.number;
              const lineContent = line.text;
              if (lineContent && lineContent.length > 0) {
                const latestFields = useLiveStore.getState().fields;

                if (!languageConfig?.getFieldByLineNumber) {
                  return;
                }

                const currentField = languageConfig.getFieldByLineNumber({
                  fieldData: latestFields,
                  code: v.state.doc.toString(),
                  lineNumber: currentLineNumber,
                });

                if (
                  latestSelectedFieldIdByOtherComponents === currentField?.id
                ) {
                  return;
                }

                if (currentField && currentField.id !== selectedFieldId) {
                  setLatestSelectedFieldIdByEditor(currentField.id);
                  setSelectedFieldId(currentField.id);
                  handleCenterView(
                    currentField.id,
                    currentField.parentId ?? ''
                  );
                }
              }

              return;
            }
          }
        },
      });

      setView(view);

      return () => {
        ydoc?.destroy();
        provider?.destroy();
        view?.destroy();
      };
    },
    [element, room, rawContent]
  );

  const closeWarning = () => {
    setHasSyntaxError(null);
  };
  return (
    <div
      id="file-editor"
      ref={ref}
      className="text-xs w-full h-full overflow-y-auto scrollbar"
    >
      {hasSyntaxError?.length && (
        <div className="absolute top-4 right-4 z-10 clamp-line-3 w-[200px] flex items-center gap-2 border border-amber-400 rounded-sm p-2 bg-zinc-900/70 backdrop-blur-sm">
          <ExclamationTriangleIcon className="fill-amber-500 min-w-[24px] min-h-[24px] h-6 w-6" />
          {hasSyntaxError?.length} error(s) found. Please check the syntax.
          <Cross1Icon cursor={'pointer'} onClick={closeWarning} />
        </div>
      )}
    </div>
  );
};
