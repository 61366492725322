export const Checkbox = ({
  id,
  label,
  onClick,
  defaultChecked = false,
}: {
  id?: string;
  label?: string;
  onClick?: any;
  defaultChecked?: boolean;
}) => {
  return (
    <div className={'checkbox flex gap-2 py-1 px-1 w-fit'}>
      <input
        defaultChecked={defaultChecked}
        type="checkbox"
        id={id}
        value={label}
        onClick={onClick}
      />
      <span className="checkmark"></span>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
