import { useMutation } from '@apollo/client';
import {
  CreateFieldTagDocument,
  CreateFileFieldTagDocument,
  DeleteFileFieldTagDocument,
  FieldTagCreateInput,
  FileFieldTagCreateInput,
} from '@hubql/codegen';
import { Input } from '@hubql/hubqlkit';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { FieldTag, useFileStore } from '../state/stores/file';
import { useRouter } from 'next/router';
import { CheckIcon } from '@heroicons/react/20/solid';
import { usePostHog } from 'posthog-js/react';

export const GroupByTag = ({ fieldId }: { fieldId: any }) => {
  const posthog = usePostHog();

  const [createFieldTag] = useMutation(CreateFieldTagDocument);
  const [createFileFieldTag] = useMutation(CreateFileFieldTagDocument);
  const [deleteFileFieldTag] = useMutation(DeleteFileFieldTagDocument);
  const fieldTags = useFileStore((state) => state.fieldTags);
  const setFieldTags = useFileStore((state) => state.setFieldTags);
  const fileId = useFileStore((state) => state.fileId);
  const commitHash = useFileStore((state) => state.commitHash);
  const [tagName, setTagName] = useState('');
  const { workspace } = useWorkspace();
  const handleCreateTag = async (
    tagName: string,
    hexColor: string,
    tagId: string
  ) => {
    if (workspace) {
      const newTag: FieldTagCreateInput = {
        workspaceId: workspace?.id,
        id: tagId,
        name: tagName,
        hexColor,
      };
      fieldTags.push(newTag as FieldTag); // force type, removing `workspaceId` might have a side effect
      setFieldTags(fieldTags);
    }
    setTagName('');
  };

  const handleSelectTag = (
    tagId: string,
    tagName?: string,
    hexColor?: string
  ) => {
    const tag = fieldTags.find((item) => item.id === tagId);
    if (tag?.fieldIds?.includes(fieldId)) {
      setFieldTags(
        fieldTags.map((item) => {
          if (item.id === tagId) {
            item.fieldIds = item.fieldIds?.filter((i) => i !== fieldId);
          }
          return item;
        })
      );
      deleteFileFieldTag({
        variables: {
          commitHash,
          fieldId,
          tagId,
        },
      });
      try {
        posthog?.capture('FILE_FIELD_TAG_DELETED', {
          commitHash,
          fieldId,
          tagId,
          fileId,
        });
      } catch (error) {}

      return;
    }
    const uuid = uuidv4().replace(/-/gi, '');
    const id = 'fft_' + uuid;
    if (workspace && fileId && tagId) {
      const newTag: FileFieldTagCreateInput = {
        workspaceId: workspace?.id,
        tagId: tagId,
        commitHash,
        fieldId: fieldId,
        fileId: fileId.toString(),
        id,
      };
      setFieldTags(
        fieldTags.map((item) => {
          if (item.id === tagId) {
            if (!item.fieldIds) item.fieldIds = [];
            item.fieldIds?.push(fieldId);
          }

          return item;
        })
      );
      createFileFieldTag({
        variables: {
          input: {
            ...newTag,
            hexColor,
            tagName,
          },
        },
      });
      try {
        posthog?.capture('FILE_FIELD_TAG_CREATED', {
          commitHash,
          fieldId,
          tagId,
          fileId,
          hexColor,
        });
      } catch (error) {}
    }
  };

  const hasValue = tagName !== '';
  const handleNewTag = async (hexColor: string) => {
    const uuid = uuidv4().replace(/-/gi, '');
    const tagId = 'fta_' + uuid;
    handleCreateTag(tagName, hexColor, tagId);
    handleSelectTag(tagId, tagName, hexColor);
  };
  return (
    <div className="flex flex-col gap-1">
      <Input
        placeholder="Add label..."
        value={tagName}
        inputSize={'sm'}
        variant="ghost"
        onChange={(e) => setTagName(e.target.value)}
      />
      <hr className="border-zinc-700" />

      {hasValue ? (
        <ColorList handleNewTag={handleNewTag} />
      ) : (
        <TagList handleSelectTag={handleSelectTag} fieldId={fieldId} />
      )}
    </div>
  );
};
const colors = [
  { name: 'Rose', hexColor: '#e11d48' },
  { name: 'Violet', hexColor: '#7c3aed' },
  { name: 'Blue', hexColor: '#2563eb' },
  { name: 'Amber', hexColor: '#ca8a04' },
  { name: 'Lime', hexColor: '#65a30d' },
  { name: 'Fushia', hexColor: '#c026d3' },
  { name: 'Cyan', hexColor: '#0891b2' },
  { name: 'Teal', hexColor: '#0d9488' },
  { name: 'Red', hexColor: '#dc2626' },
];
const listClass =
  'flex items-center text-xs hover:bg-zinc-700/30 cursor-pointer p-1 rounded-md gap-2';
const chipClass = 'w-3 h-3 rounded-md';

type TagListProps = {
  handleSelectTag: (tagId: string, tagName?: string, hexColor?: string) => void;
  fieldId: string;
};
const TagList = ({ handleSelectTag, fieldId }: TagListProps) => {
  const fieldTags = useFileStore((state) => state.fieldTags);
  const hasTags = fieldTags.length > 0;

  return (
    <ul className="w-full">
      {hasTags &&
        fieldTags.map((tag) => (
          <li
            key={'group-tag-' + tag.id}
            className={listClass}
            onClick={() => handleSelectTag(tag.id as string)}
          >
            <div className="w-4 h-4 rounded-md bg-zinc-700 flex items-center justify-center">
              {tag.fieldIds?.includes(fieldId) ? (
                <CheckIcon className="w-3 h-3 fill-zinc-50 stroke-zinc-50 stroke-width-3" />
              ) : null}
            </div>
            <div className={chipClass} style={{ background: tag.hexColor }} />
            {tag.name}
          </li>
        ))}
      {!hasTags && (
        <div className="w-full text-xs text-center text-zinc-300">no tags</div>
      )}
    </ul>
  );
};

type ColorListProps = {
  handleNewTag: (hexColor: string) => void;
};
const ColorList = ({ handleNewTag }: ColorListProps) => {
  return (
    <ul>
      {colors.map((color) => (
        <li
          key={color.hexColor}
          className={listClass}
          onClick={() => handleNewTag(color.hexColor)}
        >
          <div className={chipClass} style={{ background: color.hexColor }} />
          {color.name}
        </li>
      ))}
    </ul>
  );
};
