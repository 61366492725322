import { GetFieldByLineNumberFn, GetPositionByFieldIdFn } from '../HubqlEditor';

export const getFieldByLineNumber: GetFieldByLineNumberFn = ({
  code,
  lineNumber,
  fieldData,
}) => {
  const lines = code.split('\n');
  const currentLine = lines[lineNumber - 1];
  if (currentLine?.startsWith('message ')) {
    const modelName = currentLine.split(' ')[1];
    const model = fieldData.find(
      (field) => field.key === modelName && field.type === 'message'
    );
    if (model) {
      return model;
    }
  }

  for (let i = lineNumber - 1; i > 0; i--) {
    const line = lines[i]?.trim();
    if (line?.startsWith('message ')) {
      const modelName = line.split(' ')[1];
      const model = fieldData.find(
        (field) => field.key === modelName && field.type === 'message'
      );
      if (currentLine && model) {
        const field = fieldData.find(
          (field) =>
            currentLine.trim().includes(field.key + ' = ') &&
            field.parentId === model.id
        );
        if (field) return field;
      }
    }
  }

  return null;
};

export const getEditorPositionByFieldId: GetPositionByFieldIdFn = (_) => {
  return null;
};
