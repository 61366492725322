import { LanguageConfig } from '../HubqlEditor';
import { prisma } from '@hubql/prisma-language-support';
import { diagnosticExtension } from './diagnostics';
import { getFieldByLineNumber, getEditorPositionByFieldId } from './line';
import { convertRawContentToField } from './converter';

export const languageConfig: LanguageConfig = {
  language: 'prisma',
  extensions: [prisma(), diagnosticExtension],
  getFieldByLineNumber,
  getEditorPositionByFieldId,
  convertRawContentToField,
};
