import { LogoChip } from '@hubql/hubqlkit';
import { renderToString } from 'react-dom/server';
import { toPng } from 'html-to-image';

export interface ExportProps {
  fileName: string;
  extension: string;
}

type ActionOptions = {
  dataUrl: string;
  fileName: string;
  extension: string;
};

export type ActionFn = (options: ActionOptions) => void;

type SaveVisualizationProps = {
  fileName: string;
  extension: string;
  action: ActionFn;
  bgColor?: string;
  zoomLevel?: string;
  onSucceed?: () => void;
  onFailed?: (e: Error) => void;
};

export const downloadImage: ActionFn = (options) => {
  const link = document.createElement('a');
  link.setAttribute('download', `${options.fileName}.png`);
  link.setAttribute('href', options.dataUrl);
  link.click();
};

export const copyToClipboard: ActionFn = async (options) => {
  const data = await fetch(options.dataUrl);
  const blob = await data.blob();

  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);
    console.log('Success');
  } catch (error) {
    console.log(error);
  }
};

// Change Logo to Base64
export const svgToBase64 = (svgString: string) => {
  return 'data:image/svg+xml;base64,' + btoa(svgString);
};

export const loadImage = (base64Image: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = base64Image;
  });
};

// Drawing Logo and Visualization to a Canvas
export const drawImagesOnCanvas = (
  canvas: HTMLCanvasElement,
  visualization: HTMLImageElement,
  logo: HTMLImageElement,
  color?: string,
  scaleFactor = 2 // Add this parameter
) => {
  const ctx = canvas.getContext('2d');
  const margin = 10 * scaleFactor;
  if (ctx) {
    canvas.width =
      (Math.max(visualization.width, logo.width) + 2 * margin) * scaleFactor;
    canvas.height = (visualization.height + logo.height + margin) * scaleFactor;
    if (color) {
      ctx.fillStyle = color;
    }

    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      visualization,
      0,
      0,
      visualization.width * scaleFactor,
      visualization.height * scaleFactor
    );
    ctx.drawImage(
      logo,
      canvas.width - logo.width * scaleFactor - margin,
      visualization.height * scaleFactor + margin,
      logo.width * scaleFactor,
      logo.height * scaleFactor
    );
  }
};

export const exportVisualization = async ({
  action,
  bgColor,
  extension,
  fileName,
  onFailed,
  onSucceed,
}: SaveVisualizationProps) => {
  const canvas = document.createElement('canvas');

  try {
    const hubqlLogo = LogoChip({
      className: 'w-10 h-10',
    });
    const svg = renderToString(hubqlLogo);
    const base64Image = svgToBase64(svg);

    const renderer = document.querySelector('.react-flow__renderer');

    const dataUrl = await toPng(renderer as HTMLElement, {
      quality: 1,
      pixelRatio: 9,
    });

    const [visualization, logo] = await Promise.all([
      loadImage(dataUrl),
      loadImage(base64Image),
    ]);

    drawImagesOnCanvas(canvas, visualization, logo, bgColor);
    action({
      dataUrl: canvas.toDataURL('image/png'),
      extension,
      fileName,
    });

    onSucceed && onSucceed();
  } catch (error) {
    console.error('Failed:', error);
    onFailed && onFailed(error as Error);
  }
};
