export { CreateProject } from './lib/projects/CreateProject';
export { ProjectFileList } from './lib/projects/ProjectFileList';
export { ProjectHeader } from './lib/projects/ProjectHeader';
export { FileCollaboration } from './lib/files/FileCollaboration';
export { VisualizationEngine } from './lib/files/rendering/VisualizationEngine';
export {
  WorkspaceProvider,
  useWorkspace,
} from './lib/context/workspace/WorkspaceContext';
export { WorkspaceList } from './lib/workspaces/WorkspaceList';
export { WorkspaceName } from './lib/workspaces/WorkspaceName';
export { WorkspaceSlug } from './lib/workspaces/WorkspaceSlug';
export { WorkspaceMembers } from './lib/workspaces/WorkspaceMembers';
export { WorkspaceSettings } from './lib/workspaces/WorkspaceSettings';
export { WorkspaceWrapper } from './lib/workspaces/WorkspaceWrapper';

export { useCommentStore } from './lib/state/stores/comments';
export { useFileStore } from './lib/state/stores/file';
export {
  WorkspaceUserProvider,
  useWorkspaceUser,
} from './lib/context/user/WorkspaceUserContext';
export { FilePresence } from './lib/files/FilePresence';
export { FileComments } from './lib/comments/FileComments';
export { FileWorkMode } from './lib/files/FileWorkMode';
export { Notifications } from './lib/notifications/Notifications';
export { isDemo, isDemoWorkspace } from './lib/util/isDemo';
export { useUIUtilityStore } from './lib/state/stores/toggleSidebarStore';
export { useToggleFileViewModeStore } from './lib/state/stores/toggleFileViewMode';

export { Header } from './lib/header/Header';
export { Nav } from './lib/header/Nav';
export { Sidebar } from './lib/sidebar/Sidebar';
export { CommentSidebar } from './lib/sidebar/CommentSidebar';
export { CommentSidebarAnimation } from './lib/sidebar/CommentSidebarAnimation';
export { MenuHoverAnimation } from './lib/sidebar/MenuHoverAnimation';
export { MenuSidebar } from './lib/sidebar/MenuSidebar';
export { MenuSidebarAnimation } from './lib/sidebar/MenuSidebarAnimation';
export { ProjectDetail } from './lib/projects/ProjectDetail';
export { Layout, LayoutOuter } from './lib/layout/Layout';
export { LoginForm } from './lib/user/LoginForm';
export {
  getGuestIdFromCookie,
  generateGuestUser,
  saveUserCookie,
  STORAGE_INDEX,
} from './lib/util/user';
export { SessionConfirms } from './lib/user/SessionConfirms';
export { Home } from './lib/layout/Home';
export { WorkspaceHome } from './lib/workspaces/WorkspaceHome';
export { FileChanges } from './lib/files/FileChanges';
export { SidebarProjectFile } from './lib/projects/SidebarProjectFile';
export { WelcomeMessage } from './lib/layout/WelcomeMessage';
export { ProjectListCards } from './lib/projects/ProjectListCards';
export { ProjectListCard } from './lib/projects/ProjectListCard';
export { Onboard } from './lib/onboard/Onboard';
export { Helper } from './lib/visualization/Helper';
export { useFilePage } from './lib/hooks/useFilePage';
export { GuestSignUpModal } from './lib/onboard/GuestSignUpModal';
export type { FileDetailPageProps } from './lib/hooks/useFilePage';
