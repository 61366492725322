import { ContextMenuContent, ContextMenuItem, Spinner } from '@hubql/hubqlkit';
import Link from 'next/link';
import { createPortal } from 'react-dom';

interface IProps {
  link: string;
  handleDelete?: any;
  canDelete?: boolean;
  onRenaming?: any;
  canRename?: boolean;
  deleteFileState: any;
}

export const FileContextMenu = ({
  link,
  handleDelete,
  canDelete,
  onRenaming,
  canRename,
  deleteFileState,
}: IProps) => {
  return (
    <ContextMenuContent>
      <Link href={link} passHref>
        <ContextMenuItem>Open</ContextMenuItem>
      </Link>
      <Link href={link} target={'_blank'} passHref>
        <ContextMenuItem> Open in new tab</ContextMenuItem>
      </Link>
      {canRename && (
        <ContextMenuItem onClick={onRenaming}>Rename</ContextMenuItem>
      )}
      {canDelete && (
        <ContextMenuItem onClick={handleDelete}>
          Delete {deleteFileState && <Spinner />}
        </ContextMenuItem>
      )}
    </ContextMenuContent>
  );
};
