import clsx from 'clsx';
interface Props {
  className?: string;
}
export const StarsIcon = ({ className }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className)}
    >
      <path
        d="M3.875 9.33333C6.875 9.33333 8.20833 8 8.20833 5C8.20833 8 9.875 9.33333 12.875 9.33333C9.875 9.33333 8.20833 11 8.20833 14C8.20833 11 6.875 9.33333 3.875 9.33333Z"
        fill="current"
      />
      <path
        d="M3.125 4.94444C4.125 4.94444 4.56944 4.5 4.56944 3.5C4.56944 4.5 5.125 4.94444 6.125 4.94444C5.125 4.94444 4.56944 5.5 4.56944 6.5C4.56944 5.5 4.125 4.94444 3.125 4.94444Z"
        fill="current"
      />
      <path
        d="M6.125 2.72222C6.625 2.72222 6.84722 2.5 6.84722 2C6.84722 2.5 7.125 2.72222 7.625 2.72222C7.125 2.72222 6.84722 3 6.84722 3.5C6.84722 3 6.625 2.72222 6.125 2.72222Z"
        fill="current"
      />
    </svg>
  );
};
