import { ConvertRawContentToFieldFn } from '../HubqlEditor';
import { yamlToFields } from '@hubql/file-util';

export const convertRawContentToField: ConvertRawContentToFieldFn = async ({
  fileId,
  workspaceId,
  fieldData,
  code,
}) => {
  const newFields = yamlToFields({
    fileId,
    workspaceId,
    yamlData: code,
    rawFields: fieldData,
  });

  const errors = newFields.length > 0 ? null : [new Error('Invalid YAML')];

  return [newFields, errors];
};
