import React, { Dispatch, SetStateAction, useState } from 'react';
import { useViewport, useReactFlow } from 'reactflow';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  ActionFn,
  copyToClipboard,
  downloadImage,
  exportVisualization,
} from '../util/exportVisualization';
import { Button, Checkbox, Input, LogoChip, Tabs } from '@hubql/hubqlkit';
import { BlockPicker } from 'react-color';
import { useOutsideClick } from '../util/useOutsideClick';

type FileExportDetailsProps = {
  fileName: string;
  extension: string;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  onClose: () => void;
  tabs: any;
  zoomLevelRef: any;
  colorPickerRef: any;
  bgColor: string;
  setBgColor: (value: string) => void;
  isSelected: boolean;
  setIsSelected: Dispatch<SetStateAction<boolean>>;
  displayColorPicker: boolean;
  setDisplayColorPicker: Dispatch<SetStateAction<boolean>>;
  zoomLevel: string;
  setZoomLevel: (value: string) => void;
};
export const FileExportDetails: React.FC<FileExportDetailsProps> = ({
  fileName,
  extension,
  isLoading = false,
  setIsLoading,
  onClose,
  tabs,
  zoomLevelRef,
  colorPickerRef,
  bgColor,
  setBgColor,
  isSelected,
  setIsSelected,
  displayColorPicker,
  setDisplayColorPicker,
  zoomLevel = 'Viewport',
  setZoomLevel,
}) => {
  const { fitView } = useReactFlow();
  const [name, setFileName] = useState(fileName);

  const handleActions = async (action: ActionFn, successMessage: string) => {
    if (zoomLevel !== 'Viewport') {
      fitView({ padding: 0.1, includeHiddenNodes: true, duration: 300 });
    }

    await exportVisualization({
      action,
      fileName: name,
      extension,
      zoomLevel,
      bgColor,
      onSucceed: () => toast.success(successMessage),
      onFailed: () => toast.error('Something went wrong!'),
    });
  };

  // Checkbox Toggle
  const handleBgStatus = () => {
    setIsSelected((isSelected) => {
      const currentState = !isSelected;
      setBgColor(currentState ? 'transparent' : '#FFFFFF');

      return !currentState;
    });

    setIsSelected(!isSelected);
  };

  // Color Picker Display
  const handleColorPicker = () => {
    setDisplayColorPicker((prevState) => !prevState);
  };

  useOutsideClick(colorPickerRef, () => {
    setDisplayColorPicker(false);
  });

  // Zoom Level
  const handleZoomLevel = (state: string) => {
    setZoomLevel(state);
  };

  useOutsideClick(zoomLevelRef, () => {
    setZoomLevel('Viewport');
    onClose();
  });

  return (
    <div ref={zoomLevelRef} className="modal p-3 w-full text-sm">
      <div className="flex justify-between mb-4 items-center">
        <h2 className="title">Export Image</h2>
        <Button
          onClick={() => {
            setZoomLevel('Viewport');
            onClose();
          }}
          variant={'ghost'}
        >
          <XMarkIcon className="icon w-5 h-5 fill-white" />
        </Button>
      </div>
      <div className="body">
        <div className="field mb-3">
          <Input
            label={'File Name'}
            placeholder={fileName}
            onChange={(e) => setFileName(e.target.value)}
            value={name}
          />
        </div>
        <div className="field">
          <label>Selection</label>
          <Tabs
            tabs={tabs}
            tablistClass="max-w-xl"
            padding="px-0 pt-4"
            handleZoomLevel={handleZoomLevel}
            isExportTab={true}
          />
        </div>
        <div
          className={`${
            !isSelected ? 'flex justify-between items-center' : ''
          }`}
        >
          <div className="mb-5">
            <h2>Background</h2>
            <div className="flex items-center mt-1">
              <Checkbox onClick={handleBgStatus} />
              <h2 className="text-sm">Transparent</h2>
            </div>
          </div>
          {!isSelected && (
            <div
              className="color-picker h-fit flex items-center relative"
              ref={colorPickerRef}
            >
              <Button
                className="h-[23px] w-[23px] relative mr-[5px]"
                onClick={handleColorPicker}
                style={{ backgroundColor: bgColor }}
              />
              <Input
                className="w-fit"
                placeholder={bgColor}
                onChange={(e) => setBgColor(e.target.value)}
                value={bgColor}
              />
              {displayColorPicker && (
                <div className="absolute top-full right-1/2 mt-2">
                  <BlockPicker
                    color={bgColor}
                    onChange={(color) => setBgColor(color.hex)}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="footer flex justify-center gap-3">
        <Button
          variant="regular"
          className="w-full"
          disabled={isLoading}
          onClick={() => handleActions(copyToClipboard, 'Copied to Clipboard!')}
        >
          Clipboard
        </Button>
        <Button
          className="w-full"
          onClick={async () => {
            setIsLoading(true);
            await handleActions(downloadImage, 'Export Successful!');
            setIsLoading(false);
          }}
          disabled={isLoading}
          isLoading={isLoading}
          loadingLabel={'Exporting'}
          variant={'green'}
        >
          Export
        </Button>
      </div>
    </div>
  );
};
