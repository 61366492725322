import { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Radio } from '@hubql/hubqlkit';
import StepWizard from 'react-step-wizard';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useMutation, useQuery } from '@apollo/client';
import {
  CompleteUserOnboardingDocument,
  FindLastOrCreateHubDocument,
  InviteUsersToWorkspaceDocument,
  UpdateUserOnboardingDocument,
  UpdateUserSubscriptionsDocument,
  UpdateWorkspaceNameDocument,
  UpdateWorkspaceNameMutation,
  WorkspaceMembershipsDocument,
  WorkspaceMembershipsQuery,
} from '@hubql/codegen';
import { toast } from 'react-toastify';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { ReactMultiEmail } from 'react-multi-email';
import { useRouter } from 'next/router';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { usePostHog } from 'posthog-js/react';

export const Onboard = () => {
  const resetTransition = {
    enterRight: '',
    enterLeft: '',
    exitRight: '',
    exitLeft: '',
    intro: '',
  };
  const [companyName, setCompanyName] = useState();
  const { workspaceUser } = useWorkspaceUser();
  const { createWorkspace } = useWorkspace();
  const userId = workspaceUser?.id;
  const [workspaceName, setWorkspaceName] = useState(
    `${workspaceUser?.name ?? ''}'s workspace`
  );
  const { data } = useQuery<WorkspaceMembershipsQuery>(
    WorkspaceMembershipsDocument,
    {
      skip: !userId,
      variables: {
        userId,
      },
    }
  );
  const edges = data?.workspaceMembers?.edges;
  const workspaceId = edges?.[edges?.length - 1]?.node?.workspace?.id;
  const workspaceSlug = edges?.[edges?.length - 1]?.node?.workspace?.slug;
  const posthog = usePostHog();

  // check if user already owns a workspace
  const needNewWorkspace = edges?.find(
    (wp) => wp?.node?.userId === userId && wp?.node?.role === 'OWNER'
  )
    ? false
    : true;

  useEffect(() => {
    try {
      posthog?.capture('ONBOARDING_STARTED', {
        workspaceId,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className="px-0 py-8 lg:py-8 lg:px-8">
      <StepWizard
        isHashEnabled={false}
        transitions={resetTransition}
        initialStep={1}
      >
        <Consent userId={userId} posthog={posthog} />
        <Company
          posthog={posthog}
          companyName={companyName}
          setCompanyName={setCompanyName}
          userId={userId}
        />
        <DescribeRole userId={userId} posthog={posthog} />
        <UseCase userId={userId} posthog={posthog} />
        <CreateWorkspaceStep
          posthog={posthog}
          companyName={companyName}
          workspaceName={workspaceName}
          setWorkspaceName={setWorkspaceName}
          createWorkspace={createWorkspace}
          workspaceId={workspaceId}
          needNewWorkspace={needNewWorkspace}
        />
        <InviteTeammate
          posthog={posthog}
          userId={userId}
          workspaceId={workspaceId}
          workspaceSlug={workspaceSlug}
        />
      </StepWizard>
    </div>
  );
};

const Nav = ({
  nextStep,
  previousStep,
  totalSteps,
  step,
  checked,
  isLoading = false,
}) => (
  <div className="w-full flex gap-4">
    {step > 1 && (
      <Button
        onClick={previousStep}
        variant="regular"
        className="w-full flex-1"
        size="lg"
        disabled={isLoading}
      >
        Go Back
      </Button>
    )}
    {step < totalSteps ? (
      <Button
        isLoading={isLoading}
        onClick={nextStep}
        variant="green"
        className="w-full flex-1"
        size="lg"
        isDisabled={!checked}
      >
        Continue
      </Button>
    ) : (
      <Button
        isLoading={isLoading}
        onClick={nextStep}
        variant="purple"
        className="w-full flex-1"
        size="lg"
      >
        Finish
      </Button>
    )}
  </div>
);

/** Steps */

const Consent = (props) => {
  const { userId } = props;
  const [productUpdatesChecked, setProductUpdatesChecked] = useState(false);
  const [newsChecked, setNewsChecked] = useState(false);
  const [updateUserSubscriptions] = useMutation(
    UpdateUserSubscriptionsDocument
  );

  const validate = () => {
    updateUserSubscriptions({
      variables: {
        id: userId,
        subscribedToNews: newsChecked,
        subscribedToProductUpdates: productUpdatesChecked,
      },
    });
    try {
      props?.posthog?.capture('ONBOARDING_STEP_CONSENT_COMPLETED', {
        subscribedToNews: newsChecked,
        subscribedToProductUpdates: productUpdatesChecked,
      });
    } catch (error) {}
    props.nextStep();
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl">News and Updates</h2>
        <p>Please select the news and updates you would like to receive:</p>
      </div>
      <div>
        <form className="grid grid-cols-1 gap-4 lg:gap-2">
          <Checkbox
            defaultChecked={false}
            label={'News'}
            id="news"
            onClick={() => setNewsChecked(!newsChecked)}
          />
          <Checkbox
            onClick={() => setProductUpdatesChecked(!productUpdatesChecked)}
            defaultChecked={false}
            label={'Product updates'}
            id="product-updates"
          />
        </form>
      </div>
      <div className="text-zinc-400">
        <p className="py-2 text-xs">
          You can unsubscribe from these communications at any time. For more
          information on how to unsubscribe, our privacy practices, and how we
          are committed to protecting and respecting your privacy, please review
          our{' '}
          <a
            href="https://www.hubql.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Privacy Policy
          </a>
          .
        </p>
        <p className="py-2 text-xs">
          By clicking submit below, you consent to allow Schema Visualizer by
          Hubql to store and process the personal information submitted above to
          provide you the content requested.
        </p>
      </div>
      <Nav step={1} {...props} checked={true} nextStep={validate} />
    </div>
  );
};

const DescribeRole = (props) => {
  const { userId } = props;
  const [updateUserOnboarding] = useMutation(UpdateUserOnboardingDocument);

  const [role, setRole] = useState<null | string>(null);
  const validate = () => {
    if (!role) {
      return;
    } else {
      updateUserOnboarding({
        variables: {
          id: userId,
          //@ts-expect-error types
          role,
        },
      });
      try {
        props?.posthog?.capture('ONBOARDING_STEP_ROLE_COMPLETED', { role });
      } catch (error) {}
      props.nextStep();
    }
  };
  const roles = {
    SOFTWARE_ENGINEER: 'Software Engineer',
    IT_ARCHITECT: 'IT Architect',
    PROJECT_MANAGER: 'Project Manager',
    PRODUCT_MANAGER: 'Product Manager',
    BUSINESS_ANALYST: 'Business Analyst',
    STUDENT: 'Student',
    OTHER: 'Other',
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl">What best describes your role?</h2>
        <form>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-2">
            {Object.keys(roles)?.map((role) => (
              <Radio
                id={role}
                key={'role' + role}
                label={roles[role]}
                name={'role'}
                onClick={() => {
                  setRole(role);
                }}
              />
            ))}
          </div>
        </form>
      </div>
      <Nav step={3} {...props} nextStep={validate} checked={role} />
    </div>
  );
};

const Company = (props) => {
  const { companyName, setCompanyName, userId } = props;
  const [updateUserOnboarding] = useMutation(UpdateUserOnboardingDocument);

  const saveOnboarding = () => {
    if (companyName)
      updateUserOnboarding({
        variables: {
          id: userId,
          companyName,
        },
      });

    try {
      props?.posthog?.capture('ONBOARDING_STEP_COMPANY_COMPLETED', {
        companyName,
      });
    } catch (error) {}
    props.nextStep();
  };
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl">What is the name of your company?</h2>
        <div className="w-full">
          <Input
            placeholder="Enter company name"
            inputSize={'lg'}
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
          />
        </div>
      </div>
      <Nav step={2} {...props} checked={true} nextStep={saveOnboarding} />
    </div>
  );
};

const UseCase = (props) => {
  const { userId } = props;
  const [checked, setChecked] = useState<string[]>([]);
  const [updateUserOnboarding] = useMutation(UpdateUserOnboardingDocument);

  const validate = () => {
    if (checked.length < 1) {
      toast.error('You did not choose');
    } else {
      updateUserOnboarding({
        variables: {
          id: userId,
          useCases: checked,
        },
      });
      try {
        props?.posthog?.capture('ONBOARDING_STEP_USECASE_COMPLETED', {
          useCases: checked,
        });
      } catch (error) {}
      props.nextStep();
    }
  };

  const toggleCheckbox = (id: string) => {
    if (checked.includes(id)) {
      setChecked(checked.filter((item) => item !== id));
    } else {
      setChecked([...checked, id]);
    }
  };
  const useCases = {
    DATA_MODEL_VISUALIZATION: 'Data model visualization',
    API_SCHEMA_VISUALIZATION: 'API schema visualization',
    DOCUMENTATION_SYNC: 'Sync documentation to GitHub (GitOps)',
    TEAM_COLLABORATION: 'Team collaboration',
  };
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl">How do you plan to use Schema Visualizer?</h2>
        <form>
          <div className="grid grid-cols-1 gap-4 lg:gap-2">
            {Object.keys(useCases).map((useCase) => (
              <Checkbox
                id={useCase}
                label={useCases[useCase]}
                key={'useCase' + useCase}
                onClick={() => toggleCheckbox(useCase)}
              />
            ))}
          </div>
        </form>
      </div>
      <Nav
        step={4}
        {...props}
        nextStep={validate}
        checked={checked.length > 0}
      />
    </div>
  );
};

const CreateWorkspaceStep = (props) => {
  const {
    companyName,
    workspaceName,
    setWorkspaceName,
    createWorkspace,
    workspaceId,
    needNewWorkspace,
  } = props;
  const [updateWorkspaceName] = useMutation<UpdateWorkspaceNameMutation>(
    UpdateWorkspaceNameDocument
  );
  const validate = async () => {
    if (workspaceName.length < 1) {
      toast.error('You did not choose');
    } else {
      if (workspaceId && !needNewWorkspace) {
        await updateWorkspaceName({
          variables: {
            id: workspaceId,
            name: workspaceName,
          },
        });
      } else {
        await createWorkspace();
      }
      try {
        props?.posthog?.capture('ONBOARDING_STEP_WORKSPACE_COMPLETED', {});
      } catch (error) {}
      props.nextStep();
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl">Name your workspace:</h2>
        <Input
          placeholder="Enter workspace name"
          inputSize="lg"
          onChange={(e) => setWorkspaceName(e.target.value)}
          value={workspaceName}
        />
      </div>
      <Nav step={5} {...props} checked={true} nextStep={validate} />
    </div>
  );
};

const InviteTeammate = (props) => {
  const { workspaceId, workspaceSlug, userId } = props;
  const { push } = useRouter();
  const [emails, setEmails] = useState<string[]>([]);
  const [focused, setFocused] = useState(false);
  const [findLastOrCreateHubMutation] = useMutation(
    FindLastOrCreateHubDocument
  );

  const [inviteUserToWorkspace, invitationState] = useMutation(
    InviteUsersToWorkspaceDocument
  );
  const [completeUserOnboarding, completeUserOnboardingState] = useMutation(
    CompleteUserOnboardingDocument
  );
  const validate = async () => {
    const promises = [];
    const fetchHub = findLastOrCreateHubMutation({
      variables: {},
    });
    const fetchOnboarding = completeUserOnboarding({
      variables: {
        userId,
      },
    });
    if (emails.length > 0) {
      promises.push(
        inviteUserToWorkspace({
          variables: {
            input: {
              userEmails: emails,
              workspaceId,
              workspaceRole: 'MEMBER',
              workspaceAccess: 'VIEW',
            },
          },
        })
      );

      try {
        props?.posthog?.capture('ONBOARDING_MEMBER_INVITED', {
          noMembersInvited: emails?.length ?? 0,
          workspaceId,
        });
      } catch (error) {}
    }
    const [hubResult] = await Promise.all([
      fetchHub,
      fetchOnboarding,
      ...promises,
    ]);
    try {
      props?.posthog?.capture('ONBOARDING_COMPLETED', { workspaceId });
    } catch (error) {}
    if (
      hubResult?.data?.findLastOrCreateHub?.__typename ===
        'FindLastOrCreateHubSuccess' &&
      hubResult?.data?.findLastOrCreateHub?.data?.id
    ) {
      push(
        `/?hub=${hubResult?.data?.findLastOrCreateHub?.data?.id}&product_tour_id=431399`
      );
    } else {
      push('/?product_tour_id=431399');
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl">Unlock Collaboration!</h2>
        <p className="mb-2">Invite team members, working alone is no fun!</p>

        <div className="reactEmail first-line:flex flex-col gap-4">
          <ReactMultiEmail
            placeholder="Enter your team mate's email"
            emails={emails}
            autoFocus={false}
            onChange={(_emails: string[]) => {
              setEmails(_emails);
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={'email' + index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    <XMarkIcon className="w-3 h-3" />
                  </span>
                </div>
              );
            }}
          />
        </div>
        <Nav
          step={6}
          {...props}
          checked={true}
          nextStep={validate}
          isLoading={
            invitationState.loading || completeUserOnboardingState.loading
          }
        />
      </div>
    </div>
  );
};
