interface IFileProps {
  workspaceId: string;
  fileId: string;
  fullFileName: string;
  file: File | string;
  commitHash: string;
  accessToken?: string;
  url?: string;
  guestId?: string;
}

export const uploadFile = async ({
  workspaceId,
  fileId,
  fullFileName,
  file,
  commitHash,
  accessToken,
  url,
  guestId,
}: IFileProps) => {
  const uploadUrl = url ?? '/rest/upload-file';
  let response = await fetch(uploadUrl, {
    method: 'POST',
    headers: {
      'accept-encoding': 'gzip, deflate, br',
      'content-type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(guestId && { 'x-hubql-guest-id': guestId }),
    },
    body: JSON.stringify({
      workspaceId: workspaceId,
      fileId: fileId,
      fileName: fullFileName,
      commitHash: commitHash,
    }),
  });

  if (!response.ok) {
    throw new Error(`Upload failed, ${response.statusText}`);
  }

  const signedUrlResult = await response.json();

  const signedUrl = signedUrlResult.url;
  const headers = {
    'Content-Type': signedUrlResult.contentType,
  };
  if (signedUrlResult.acl) {
    //@ts-ignore
    headers['x-amz-acl'] = signedUrlResult.acl;
  }
  if (signedUrlResult.tagging) {
    //@ts-ignore
    headers['x-amz-tagging'] = signedUrlResult.tagging;
  }

  response = await fetch(signedUrl, {
    method: 'PUT',
    headers: {},
    body: file,
  });

  if (!response.ok) {
    throw new Error(`Upload failed, ${response.statusText}`);
  }
};
