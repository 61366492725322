import {
  fieldsToFileStringJSON,
  fieldsToFileStringXML,
  uploadFile,
} from '@hubql/file-util';

type GetFileParams = {
  workspaceId: string;
  fileId: string;
  fileName: string;
  extension: string;
  commitHash: string;
  fieldData: any[];
  signedUrl?: string;
};
export const getFile = async ({
  workspaceId,
  fileId,
  fileName,
  extension,
  commitHash,
  fieldData = [],
  signedUrl,
}: GetFileParams) => {
  try {
    const fullFileName = fileName + '.' + extension;
    let fileSignedUrl = signedUrl;
    if (!fileSignedUrl) {
      const response = await fetch('/rest/get-file', {
        method: 'POST',
        headers: {
          'accept-encoding': 'gzip, deflate, br',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          workspaceId: workspaceId,
          fileId: fileId,
          fileName: fullFileName,
          commitHash: commitHash,
        }),
      });
      const signedUrlResult = await response.json();
      fileSignedUrl = signedUrlResult.url;
    }
    if (!fileSignedUrl) return;
    const fileResponse = await fetch(fileSignedUrl, {
      method: 'GET',
    });
    const fileString = await fileResponse.text();
    //TODO: remove this in future as we have files that may not exist yet
    if (
      fileString?.includes(
        '<Error><Code>NoSuchKey</Code><Message>The specified key does not exist.</Message><Key>'
      )
    ) {
      const nonRootFields = fieldData.filter(
        (f) => f.key !== '__root__' && f.exampleValue !== '__root__'
      );
      const contentToUpload: string =
        extension === 'json'
          ? fieldsToFileStringJSON(nonRootFields, 'json')
          : (fieldsToFileStringXML(nonRootFields, 'xml') as string);
      const file = new File([contentToUpload], fullFileName, {
        type: 'text/plain',
      });
      await uploadFile({
        workspaceId,
        fileId: fileId,
        fullFileName,
        file,
        commitHash,
      });
      return contentToUpload;
    }

    return fileString;
  } catch (error) {
    console.error(error);

    return null;
  }
};
