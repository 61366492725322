import { EditorState, Extension } from '@codemirror/state';
import { EditorView } from 'codemirror';
import { ViewUpdate } from '@codemirror/view';
import { baseExtensions } from './constant';

type CreateCodeMirrorInput = {
  element: HTMLElement;
  doc?: string;
  extensions?: Extension[];
  onEditorChange?: (v: ViewUpdate) => void;
  isReadOnly?: boolean;
};

export function createCodeMirrorView(props: CreateCodeMirrorInput): EditorView {
  const state = EditorState.create({
    doc: props.doc,
    extensions: [
      ...baseExtensions,
      EditorState.readOnly.of(props.isReadOnly ?? false),
      EditorView.updateListener.of((v) => {
        props.onEditorChange?.call(null, v);
      }),
      ...(props?.extensions ?? []),
    ],
  });

  return new EditorView({
    state,
    parent: props.element,
  });
}
