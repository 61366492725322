import React from 'react';

type Props = {
  name?: string;
  color: string;
  x: number;
  y: number;
};

export function Cursor({ color, x, y, name }: Props) {
  return (
    <>
      <p
        style={{
          position: 'absolute',
          left: x,
          top: y + 20,
          color: color,
          fontSize: '10px',
          fontWeight: 700,
          zIndex: 1000,
        }}
      >
        {name}
      </p>
      <svg
        id="cursor"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          transform: `translateX(${x}px) translateY(${y}px)`,
          zIndex: 1000,
        }}
        width="24"
        height="36"
        viewBox="0 0 24 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
          fill={color}
        />
      </svg>
    </>
  );
}
