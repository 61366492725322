import { DocumentIcon, UserPlusIcon } from '@heroicons/react/20/solid';
import { ProjectListCard } from '@hubql/app-core';
import { Spinner } from '@hubql/shared-design-system';

type Props = {
  workspace: any;
  hubsQuery: any;
  sharedProjectsFromAccess: any;
  sharedFilesFromAccess: any;
};

export const SharedProject = ({
  workspace,
  sharedProjectsFromAccess = [],
  sharedFilesFromAccess = [],
  hubsQuery,
}: Props) => {
  const sharedProjects = [
    ...sharedProjectsFromAccess,
    ...sharedFilesFromAccess,
  ]?.map((project, index) => {
    if (!project) return null;
    const repoName = project?.repositories?.[0]?.githubRepositoryFullName;
    const isFile = project.__typename === 'File';

    return (
      <ProjectListCard
        isFile={isFile}
        workspaceSlug={workspace.slug}
        key={'shared-project-list' + project.id + index}
        id={project.id}
        name={
          isFile
            ? `${project.fileName}.${project.extension}`
            : `${project.name} ${repoName ? `(${repoName})` : ''}`
        }
        createdAt={project.createdAt}
        access={project.access}
        owner={project.owner}
        icon={
          isFile ? (
            <DocumentIcon className="w-5 h-5" />
          ) : (
            <UserPlusIcon className="w-5 h-5" />
          )
        }
      />
    );
  });
  return (
    <div>
      {hubsQuery.loading ? (
        <Spinner />
      ) : (
        sharedProjects?.length === 0 && (
          <div className="flex flex-col items-center p-8 gap-2 mt-8">
            <div className="flex flex-col items-center mb-2">
              <span className="text-xs text-zinc-100">No Hub</span>
              <p className="text-xs text-zinc-500">No shared Hubs yet</p>
            </div>
          </div>
        )
      )}
      <div className={'grid grid-cols-5 gap-4 mt-8'}>{sharedProjects}</div>
    </div>
  );
};
