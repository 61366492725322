export const renameProtobufType = ({
  protobufFile,
  typeName,
  fieldName,
  oldType,
  newType,
}: {
  protobufFile: string;
  typeName: string;
  fieldName: string;
  oldType: string;
  newType: string;
}) => {
  const pattern = new RegExp(
    `(message\\s+${typeName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = protobufFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      const modifiedModelDefinition = modelDefinition.replace(
        new RegExp(`\\b${fieldName}\\b\\s+${oldType}\\b`),
        `${fieldName}   ${newType}`
      );
      return `${modifiedModelDefinition}${emptyLine}`;
    }
  );
  return modifiedFile;
};

export const renameProtobufField = ({
  protobufFile,
  typeName,
  oldFieldName,
  newFieldName,
}: {
  protobufFile: string;
  typeName: string;
  oldFieldName: string;
  newFieldName: string;
}) => {
  const pattern = new RegExp(
    `(message\\s+${typeName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = protobufFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      const modifiedModelDefinition = modelDefinition.replace(
        new RegExp(`\\b${oldFieldName}\\b`),
        newFieldName
      );
      return `${modifiedModelDefinition}${emptyLine}`;
    }
  );
  return modifiedFile;
};

export const addProtobufField = ({
  protobufFile,
  typeName,
  newType,
  newFieldName,
}: {
  protobufFile: string;
  typeName: string;
  newType: string;
  newFieldName: string;
}) => {
  const pattern = new RegExp(
    `(message\\s+${typeName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = protobufFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      return `${modelDefinition}  ${newFieldName}   ${newType}\n${emptyLine}`;
    }
  );
  return modifiedFile;
};
