import { Skeleton } from '@hubql/hubqlkit';

export const ProjectFileListLoading = () => {
  return (
    <div className="grid grid-cols-12 text-xs w-full justify-between items-center gap-2 h-[34px] py-2">
      <Skeleton className="h-full col-span-10 pl-2 rounded-full bg-zinc-800" />
      <Skeleton className="h-full col-span-1 rounded-full bg-zinc-800" />
    </div>
  );
};
