import clsx from 'clsx';
import { ChooseHubType } from './ChooseHubType';

export const ProjectTabHeader = ({ access }) => {
  return (
    <div className="w-full flex justify-between">
      <ChooseHubType defaultAccess={access} />
    </div>
  );
};
