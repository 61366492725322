import { Button, Input } from '@hubql/hubqlkit';

export const CommitChanges = ({
  commitMessage,
  setCommitMessage,
  canSave,
  isSaving,
  handleSave,
  isGitHubProject,
  prURL,
  openModal,
}) => {
  const filedchanged = ['filed changed 1', 'filed changed 2'];
  return (
    <div className="flex flex-col gap-2">
      <Input
        className="bg-zinc-800 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1 focus:ring-1 focus:outline-none focus:ring-zinc-200"
        id="input-value"
        placeholder="Commit message"
        value={commitMessage}
        onChange={(e) => setCommitMessage(e.target.value)}
        inputSize="sm"
      />
      {/* 
      TODO - being able to commit to the current branch without PR
      <Button
        isDisabled={!canSave}
        isLoading={isSaving}
        onClick={handleSave}
        variant="green"
      >
        {isGitHubProject && <>Commit</>}
      </Button> */}

      {/* 
      // TODO - show all file that have changes 
                        - being able to move changes to be staged
                        - being able to discard changes
                        
      <div className="flex flex-col gap-1 mt-2">
        <div className="flex gap-2 items-center">
          <span className="break-normal text-zinc-500">Staged file</span>
          <div className="bg-zinc-600 h-[1px] flex-1" />
        </div>
        <ul>   
          {filedchanged.map((listItem) => (
            <li
              key={listItem}
              onClick={openModal}
              className="cursor-pointer hover:bg-zinc-800 py-1 px-1 text-zinc-300 hover:text-white"
            >
              {listItem}
            </li>
          ))}
        </ul>
      </div>

      // TODO - show all file that have changes
                        - being able to move staged back to changes
                        - being able to discard staged changes
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <span className="break-normal text-zinc-500">Changes</span>
          <div className="bg-zinc-600 h-[1px] w-full" />
        </div>
        <ul>
             
          {filedchanged.map((listItem) => (
            <li
              key={listItem}
              onClick={openModal}
              className="cursor-pointer hover:bg-zinc-800 py-1 px-1 text-zinc-300 hover:text-white"
            >
              {listItem}
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};
