import { useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { Avatar } from '@hubql/hubqlkit';

export const CommentMention = ({ node, children, props }) => {
  const { workspace } = useWorkspace();
  const userId = node.properties.href.split('user:').pop();
  const [open, setOpen] = useState(false);
  const userInfo = workspace?.members?.find((member) => {
    return member.user.id === userId;
  })?.user;
  return (
    <div className="relative">
      <span
        className="px-0.5 py-0.5 bg-[#009E6520] text-[#009E65] text-xs mr-0.2 cursor-pointer"
        onMouseOver={() => setOpen(true)}
      >
        {children.toString()}
      </span>
      {open && (
        <div className="absolute bottom-[100%] bg-zinc-900 border border-zinc-600 px-2 py-2 mb-2 rounded-sm">
          <Avatar
            name={userInfo?.firstName ?? ''}
            src={userInfo?.picture ?? ''}
          />
        </div>
      )}
    </div>
  );
};
