interface Props {
  className?: string;
}
export const SaveIcon = ({ className = 'fill-zinc-400' }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.36111 0C0.609158 0 0 0.609395 0 1.36111V12.6389C0 13.3906 0.609158 14 1.36111 14H12.6389C13.3908 14 14 13.3906 14 12.6389V3.62772C14 3.26672 13.8572 2.92054 13.602 2.66528L11.334 0.398668C11.0788 0.143412 10.7339 0 10.3724 0H1.36111ZM2.33333 2.72222C2.33333 2.60086 2.38954 2.49251 2.47613 2.42118C2.54297 2.36628 2.62956 2.33333 2.72222 2.33333H8.55556C8.65278 2.33333 8.74089 2.36875 8.80925 2.42735C8.89128 2.49865 8.94444 2.6043 8.94444 2.72222V4.27778C8.94444 4.49256 8.76975 4.66667 8.55556 4.66667H2.72222C2.50803 4.66667 2.33333 4.49256 2.33333 4.27778V2.72222ZM7 11.6667C8.18186 11.6667 9.13889 10.7091 9.13889 9.52778C9.13889 8.3465 8.18186 7.38889 7 7.38889C5.81814 7.38889 4.86111 8.3465 4.86111 9.52778C4.86111 10.7091 5.81814 11.6667 7 11.6667Z"
        fill="current"
      />
    </svg>
  );
};
