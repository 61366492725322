import { Avatar } from '@hubql/hubqlkit';
import { MentionsInput, Mention } from 'react-mentions';

export const CommentInput = ({
  newComment,
  setNewComment,
  members,
  submitComment,
  returnKeyPressed,
  placeholder = 'Enter comment',
}) => {
  return (
    <div className={'w-full scrollbar'}>
      <MentionsInput
        className={'mentionInput'}
        placeholder={placeholder}
        onKeyUp={returnKeyPressed}
        value={newComment}
        onChange={(e) => {
          setNewComment(e.target.value);
        }}
        a11ySuggestionsListLabel={'Suggested mentions'}
      >
        <Mention
          trigger="@"
          markup="[__display__](user:__id__)"
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused
          ) => {
            const member = members?.find(
              (member) => member?.user?.id === suggestion.id
            )?.user;
            return (
              <div className="flex text-xs py-0.5 px-2 bg-zinc-800 hover:bg-zinc-700">
                <Avatar name={suggestion.display ?? ''} src={member?.picture} />
              </div>
            );
          }}
          style={{
            backgroundColor: '#009E6520',
            color: '#009E65',
            zIndex: 100,
            fontSize: '12px',
          }}
          data={members?.map((member) => {
            return {
              id: member.user.id,
              display: '@' + member.user.firstName + ' ' + member.user.lastName,
            };
          })}
        />
      </MentionsInput>
    </div>
  );
};
