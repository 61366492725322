export const renameGraphQLType = ({
  graphQLFile,
  typeName,
  fieldName,
  oldType,
  newType,
}: {
  graphQLFile: string;
  typeName: string;
  fieldName: string;
  oldType: string;
  newType: string;
}) => {
  const pattern = new RegExp(
    `(type\\s+${typeName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = graphQLFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      const modifiedModelDefinition = modelDefinition.replace(
        new RegExp(`\\b${fieldName}\\b\\s+${oldType}\\b`),
        `${fieldName}   ${newType}`
      );
      return `${modifiedModelDefinition}${emptyLine}`;
    }
  );
  return modifiedFile;
};

export const renameGraphQLField = ({
  graphQLFile,
  typeName,
  oldFieldName,
  newFieldName,
}: {
  graphQLFile: string;
  typeName: string;
  oldFieldName: string;
  newFieldName: string;
}) => {
  const pattern = new RegExp(
    `(type\\s+${typeName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = graphQLFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      const modifiedModelDefinition = modelDefinition.replace(
        new RegExp(`\\b${oldFieldName}\\b`),
        newFieldName
      );
      return `${modifiedModelDefinition}${emptyLine}`;
    }
  );
  return modifiedFile;
};

export const addGraphQLField = ({
  graphQLFile,
  typeName,
  newType,
  newFieldName,
}: {
  graphQLFile: string;
  typeName: string;
  newType: string;
  newFieldName: string;
}) => {
  const pattern = new RegExp(
    `(type\\s+${typeName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = graphQLFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      return `${modelDefinition}  ${newFieldName}   ${newType}\n${emptyLine}`;
    }
  );
  return modifiedFile;
};
