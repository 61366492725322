import { StarIcon } from '@heroicons/react/20/solid';
import { ProjectListCard } from './ProjectListCard';

type Props = {
  bookmarkedProjects: any;
  workspace: any;
};

export const BookmarkedProject = ({ bookmarkedProjects, workspace }: Props) => {
  const bookmarkItems = bookmarkedProjects.map((project, index) => {
    if (!project) return null;
    const repoName = project?.repositories?.[0]?.github_repository_full_name;
    return (
      <ProjectListCard
        isFile={false}
        workspaceSlug={workspace.slug}
        key={'b' + project.id}
        id={project.id}
        name={project.name}
        createdAt={project.createdAt}
        access={[]}
        owner={null}
        icon={
          <StarIcon className="w-5 h-5 fill-transparent stroke-2 stroke-yellow-500" />
        }
      />
    );
  });
  return bookmarkItems.length === 0 ? (
    <div className="flex flex-col items-center p-8 gap-2 mt-8">
      <div className="flex flex-col items-center mb-2">
        <span className="text-xs text-zinc-100">No bookmark</span>
        <p className="text-xs text-zinc-500">No bookmarked projects yet</p>
      </div>
    </div>
  ) : (
    <div className={'grid grid-cols-5 gap-4 mt-8'}>{bookmarkItems}</div>
  );
};
