interface Props {
  className?: string;
}
export const ProtoIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="11"
      viewBox="0 0 41 11"
      fill="none"
      className={className}
    >
      <path
        d="M1.93308 6.92647V10.8529H0V0.147059H3.42589C3.97165 0.147059 4.46467 0.232843 4.90494 0.404412C5.34522 0.571078 5.71899 0.806373 6.02627 1.11029C6.33813 1.40931 6.57661 1.76961 6.74171 2.19118C6.9114 2.61275 6.99625 3.08088 6.99625 3.59559C6.99625 4.07598 6.9114 4.51961 6.74171 4.92647C6.57661 5.33333 6.33813 5.68627 6.02627 5.98529C5.71899 6.27941 5.34522 6.5098 4.90494 6.67647C4.46467 6.84314 3.97165 6.92647 3.42589 6.92647H1.93308ZM1.93308 5.25H3.42589C3.70106 5.25 3.93955 5.20588 4.14134 5.11765C4.34313 5.02941 4.51053 4.90931 4.64353 4.75735C4.78111 4.60539 4.88201 4.43137 4.94622 4.23529C5.01501 4.03431 5.04941 3.82598 5.04941 3.61029C5.04941 3.36029 5.01501 3.12745 4.94622 2.91176C4.88201 2.69608 4.78111 2.50735 4.64353 2.34559C4.51053 2.18382 4.34313 2.05637 4.14134 1.96324C3.93955 1.8701 3.70106 1.82353 3.42589 1.82353H1.93308V5.25Z"
        fill="white"
      />
      <path
        d="M11.7361 6.85294H10.2708V10.8529H8.33771V0.147059H11.6948C12.2497 0.147059 12.7519 0.218137 13.2014 0.360294C13.6508 0.497549 14.0338 0.70098 14.3502 0.970588C14.6713 1.2402 14.9166 1.57598 15.0863 1.97794C15.2606 2.3799 15.3477 2.84559 15.3477 3.375C15.3477 3.75735 15.3041 4.10294 15.217 4.41177C15.1345 4.71569 15.0129 4.9902 14.8524 5.23529C14.6965 5.47549 14.5062 5.68627 14.2814 5.86765C14.0613 6.04902 13.8136 6.20588 13.5385 6.33824L15.6917 10.7574V10.8529H13.621L11.7361 6.85294ZM10.2708 5.17647H11.7017C12.2474 5.17647 12.6671 5.02941 12.9606 4.73529C13.2587 4.43627 13.4078 4.02941 13.4078 3.51471C13.4078 2.98039 13.2633 2.56618 12.9744 2.27206C12.6854 1.97304 12.2589 1.82353 11.6948 1.82353H10.2708V5.17647Z"
        fill="white"
      />
      <path
        d="M23.7886 6.28677C23.7886 6.97304 23.7038 7.60539 23.5341 8.18382C23.3644 8.76225 23.119 9.2598 22.798 9.67647C22.477 10.0931 22.0848 10.4191 21.6216 10.6544C21.163 10.8848 20.6448 11 20.0669 11C19.4891 11 18.9754 10.8848 18.526 10.6544C18.0765 10.4191 17.6981 10.0931 17.3909 9.67647C17.0836 9.2549 16.8497 8.75735 16.6892 8.18382C16.5287 7.60539 16.4484 6.97304 16.4484 6.28677V4.72794C16.4484 4.04167 16.5287 3.40931 16.6892 2.83088C16.8497 2.25245 17.0836 1.7549 17.3909 1.33824C17.6981 0.916667 18.0742 0.588235 18.5191 0.352941C18.9685 0.117647 19.4822 0 20.06 0C20.6425 0 21.163 0.117647 21.6216 0.352941C22.0848 0.588235 22.477 0.916667 22.798 1.33824C23.119 1.7549 23.3644 2.25245 23.5341 2.83088C23.7038 3.40931 23.7886 4.04167 23.7886 4.72794V6.28677ZM21.828 4.71324C21.828 4.27696 21.7936 3.875 21.7248 3.50735C21.6606 3.13971 21.5574 2.82353 21.4153 2.55882C21.2731 2.29412 21.0896 2.08824 20.8649 1.94118C20.6448 1.78922 20.3765 1.71324 20.06 1.71324C19.7482 1.71324 19.4868 1.78922 19.2758 1.94118C19.0648 2.08824 18.8951 2.29412 18.7667 2.55882C18.6383 2.82353 18.5466 3.13971 18.4916 3.50735C18.4365 3.875 18.409 4.27696 18.409 4.71324V6.28677C18.409 6.71814 18.4365 7.11765 18.4916 7.48529C18.5466 7.85294 18.6383 8.17157 18.7667 8.44118C18.8951 8.71078 19.0648 8.92157 19.2758 9.07353C19.4868 9.22549 19.7505 9.30147 20.0669 9.30147C20.3788 9.30147 20.6448 9.22549 20.8649 9.07353C21.0896 8.92157 21.2708 8.71324 21.4084 8.44853C21.5506 8.17892 21.656 7.86029 21.7248 7.49265C21.7936 7.125 21.828 6.72304 21.828 6.28677V4.71324Z"
        fill="white"
      />
      <path
        d="M32.6216 1.83088H29.5328V10.8529H27.5929V1.83088H24.5453V0.147059H32.6216V1.83088Z"
        fill="white"
      />
      <path
        d="M40.7117 6.28677C40.7117 6.97304 40.6269 7.60539 40.4572 8.18382C40.2875 8.76225 40.0421 9.2598 39.7211 9.67647C39.4 10.0931 39.0079 10.4191 38.5447 10.6544C38.0861 10.8848 37.5679 11 36.99 11C36.4121 11 35.8985 10.8848 35.449 10.6544C34.9996 10.4191 34.6212 10.0931 34.3139 9.67647C34.0067 9.2549 33.7728 8.75735 33.6123 8.18382C33.4517 7.60539 33.3715 6.97304 33.3715 6.28677V4.72794C33.3715 4.04167 33.4517 3.40931 33.6123 2.83088C33.7728 2.25245 34.0067 1.7549 34.3139 1.33824C34.6212 0.916667 34.9973 0.588235 35.4422 0.352941C35.8916 0.117647 36.4053 0 36.9831 0C37.5656 0 38.0861 0.117647 38.5447 0.352941C39.0079 0.588235 39.4 0.916667 39.7211 1.33824C40.0421 1.7549 40.2875 2.25245 40.4572 2.83088C40.6269 3.40931 40.7117 4.04167 40.7117 4.72794V6.28677ZM38.7511 4.71324C38.7511 4.27696 38.7167 3.875 38.6479 3.50735C38.5837 3.13971 38.4805 2.82353 38.3383 2.55882C38.1962 2.29412 38.0127 2.08824 37.788 1.94118C37.5679 1.78922 37.2996 1.71324 36.9831 1.71324C36.6713 1.71324 36.4098 1.78922 36.1989 1.94118C35.9879 2.08824 35.8182 2.29412 35.6898 2.55882C35.5614 2.82353 35.4697 3.13971 35.4146 3.50735C35.3596 3.875 35.3321 4.27696 35.3321 4.71324V6.28677C35.3321 6.71814 35.3596 7.11765 35.4146 7.48529C35.4697 7.85294 35.5614 8.17157 35.6898 8.44118C35.8182 8.71078 35.9879 8.92157 36.1989 9.07353C36.4098 9.22549 36.6735 9.30147 36.99 9.30147C37.3019 9.30147 37.5679 9.22549 37.788 9.07353C38.0127 8.92157 38.1939 8.71324 38.3315 8.44853C38.4736 8.17892 38.5791 7.86029 38.6479 7.49265C38.7167 7.125 38.7511 6.72304 38.7511 6.28677V4.71324Z"
        fill="white"
      />
    </svg>
  );
};
