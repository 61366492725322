import { create } from 'zustand';

interface ContextMenuState {
  showSuggest: boolean;
  setShowSuggest: (showSuggest: boolean) => void;
}

export const useContextMenuStore = create<ContextMenuState>((set) => ({
  showSuggest: false,
  setShowSuggest: (showSuggest) =>
    set((state) => ({
      ...state,
      showSuggest,
    })),
}));
