export function guessFileType(
  fileContent: string
): 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml' {
  return fileContent.includes('datasource db {')
    ? 'prisma'
    : fileContent.includes('type Query')
    ? 'graphql'
    : fileContent.includes('message ') && fileContent.includes(' = 1')
    ? 'proto'
    : fileContent.includes('<')
    ? 'xml'
    : fileContent.includes('{')
    ? 'json'
    : 'yaml';
}
