import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Button,
} from '@hubql/hubqlkit';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { UpgradeButton } from '../workspaces/UpgradeButton';
const communityPlan = {
  title: 'Pro',
  price: '5€',
  subtitle:
    'Perfect for small teams, solo freelancers, developers, architects, and analysts.',
  header: 'Upgrade to Pro',
  content:
    'You have reached the limit of the free plan. \n Please upgrade your plan to continue.',
  details: [
    '250 files',
    'Unlimited Hubs',
    'Export PNG',
    '5 GitHub repo connections',
  ],
};
export const UpgradeModal = () => {
  const showUpgradeModal = useUIUtilityStore((state) => state.showUpgradeModal);
  const setShowUpgradeModal = useUIUtilityStore(
    (state) => state.setShowUpgradeModal
  );
  const { workspace } = useWorkspace();

  if (!workspace) return null;
  return (
    <Dialog open={showUpgradeModal} onOpenChange={setShowUpgradeModal}>
      <DialogContent className="max-w-md  w-fit border border-zinc-700 bg-zinc-800 ">
        <div className="flex flex-col w-fit pb-4">
          <DialogHeader className=" flex flex-col pt-10 pb-4  items-start justify-center">
            <DialogTitle className="text-2xl text-center font-bold leading-relaxed w-full">
              {communityPlan.header}
            </DialogTitle>
            <DialogDescription className="leading-relaxed whitespace-pre-line text-center w-full text-zinc-300 text-sm px-3 ">
              {communityPlan.content}
            </DialogDescription>
          </DialogHeader>
          <UpgradeDetails workspaceId={workspace.id} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const UpgradeFullContent = ({ workspaceId }) => {
  return (
    <div className="mt-[20vh]">
      <h2 className="text-2xl text-center font-bold leading-relaxed w-full">
        {communityPlan.header}
      </h2>
      <p className="leading-relaxed whitespace-pre-line text-center w-full text-zinc-300 text-sm px-3 ">
        {communityPlan.content}
      </p>
      <UpgradeDetails workspaceId={workspaceId} />
    </div>
  );
};

export const UpgradeDetails = ({ workspaceId }) => {
  return (
    <div className=" flex flex-col gap-2 px-6 rounded-r-lg py-4 items-center">
      <h2 className="text-4xl text-center w-full font-black">
        {communityPlan.price}
        <span className="text-lg">/user/month</span>
      </h2>
      <div className="max-w-sm mx-auto py-8">
        <ul>
          {communityPlan?.details?.map((item, index) => (
            <li
              key={'upgrade-modal-' + index}
              className="list-disc ml-4 text-md py-1.5"
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      <UpgradeButton workspaceId={workspaceId} />
    </div>
  );
};
