import { useMutation } from '@apollo/client';
import {
  CreateFileFieldsDocument,
  UpdateFileHashDocument,
} from '@hubql/codegen';
import { DiffEditor } from '@monaco-editor/react';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useFileStore } from '../state/stores/file';
import { useLiveStore } from '../state/stores/live';
import { uploadFile } from '@hubql/file-util';
import { v4 as uuidv4 } from 'uuid';
import { gitHashObject } from '@hubql/file-util';
import { LinkIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import { Button } from '@hubql/hubqlkit';
import { CreateFileRevisionDocument } from '@hubql/codegen';
import { CreatePrDocument } from '@hubql/codegen';
import { GitChanges } from './GitChanges';
import { useActiveCode } from '@codesandbox/sandpack-react';
import { usePostHog } from 'posthog-js/react';

export const FileChanges = () => {
  const router = useRouter();
  const isDemo = router.pathname.startsWith('/demo');

  const fileId = useFileStore((state) => state.fileId);
  const posthog = usePostHog();

  const editorRef = useRef(null);
  const { workspace } = useWorkspace();
  const fieldData = useLiveStore((state) => state.fields);
  const fileName = useFileStore((state) => state.fileName);
  const extension = useFileStore((state) => state.extension);
  const initialFields = useFileStore((state) => state.initialFields);
  const initialContent = useFileStore((state) => state.initialContent);
  const isGitHubProject = useFileStore((state) => state.isGitHubProject);
  const setOpenDiffEditor = useFileStore((state) => state.setOpenDiffEditor);
  const openDiffEditor = useFileStore((state) => state.openDiffEditor);
  const { updateCode, code } = useActiveCode();

  const formatFile = async () => {
    if (extension === 'prisma') {
      const formatResponse = await fetch('/rest/prisma-format', {
        method: 'POST',
        headers: {
          'accept-encoding': 'gzip, deflate, br',
          'content-type': 'application/json',
        },
        body: JSON.stringify({ prismaSchema: code }),
      });
      const { prismaSchema } = await formatResponse.json();
      if (prismaSchema) {
        setRawContent(prismaSchema);
        // updateCode(prismaSchema);
      } else {
        toast.error('Your file is invalid. Please check the synthax.', {
          position: 'bottom-left',
        });
      }
    }
  };
  const openModal = () => {
    formatFile();
    setOpenDiffEditor(true);
  };

  const closeModal = () => {
    setOpenDiffEditor(false);
  };

  const compareFields = (previous, current) => {
    try {
      let hasChange = false;
      if (previous.length !== current.length) return true;
      previous.forEach((previousField) => {
        const currentField = current.find(
          (item) => item.id === previousField.id
        );
        if (currentField) {
          if (
            currentField.exampleValue?.toString() !==
            previousField.exampleValue?.toString()
          ) {
            hasChange = true;
          }
          if (currentField.key !== previousField.key) {
            hasChange = true;
          }
        } else {
          hasChange = true;
        }
      });
      return hasChange;
    } catch (error) {
      return false;
    }
  };
  const [isSaving, setIsSaving] = useState(false);
  const isChanged = compareFields(initialFields, fieldData);
  const setHasChanges = useFileStore((state) => state.setHasChanges);

  useEffect(() => {
    setHasChanges(isChanged);
  }, [isChanged]);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }
  const [insertFields] = useMutation(CreateFileFieldsDocument);
  const [setCommitHash] = useMutation(UpdateFileHashDocument);
  const [addFileRevision] = useMutation(CreateFileRevisionDocument);
  const [createPR] = useMutation(CreatePrDocument);
  const [prTitle, setPRTitle] = useState<string>('');
  const [commitMessage, setCommitMessage] = useState<string>('');
  const [prURL, setPRURL] = useState<string>('');
  const setRawContent = useLiveStore((state) => state.setRawContent);
  const previousRevision = useFileStore((state) => state.commitHash);

  const canSave = isGitHubProject
    ? commitMessage && commitMessage !== '' && prTitle && prTitle !== ''
      ? true
      : false
    : true;

  const createNewRevision = async (
    commitHash: string,
    redirect: boolean,
    pullRequestNumber?: number
  ) => {
    if (!fileId) return;
    const fileRevisionId = 'filr_' + uuidv4().replace(/-/gi, '');
    const lastCommitHash = previousRevision ?? 'HEAD';
    await addFileRevision({
      variables: {
        input: {
          fileId: fileId,
          revision: commitHash,
          workspaceId: workspace?.id as string,
          id: fileRevisionId,
          previousRevision: lastCommitHash,
          pullRequestNumber: pullRequestNumber,
        },
      },
    });
    closeModal();
    setIsSaving(false);
    if (redirect) {
      router.query['ref'] = commitHash;
      router.push(router);
    }
  };
  const fullFileName = `${fileName}.${extension}`;
  const handleSave = async () => {
    if (!fileId) return;
    setIsSaving(true);
    // insert new fields
    const fileContent = code;

    const commitHash = gitHashObject(fileContent);
    const newFields = fieldData?.map((field) => {
      // TODO: centralize this logic
      const isArray = Array.isArray(field.exampleValue);
      const valueType = isArray ? 'array' : typeof field.exampleValue;
      const isObject = field.exampleValue && valueType === 'object';
      return {
        exampleValue:
          isObject || isArray
            ? JSON.stringify(field.exampleValue)
            : field.exampleValue?.toString(),
        id: field.id,
        key: field.key,
        // TODO: we should extract this logic - fields to fieldinput
        type:
          extension === 'prisma' ||
          extension === 'graphql' ||
          extension === 'proto'
            ? field.type
            : valueType,
        workspaceId: workspace?.id,
        positionX: field.positionX,
        positionY: field.positionY,
        commitHash: commitHash,
        fileId: fileId,
        line: field.line,
        parentId: field.parentId,
      };
    });

    await insertFields({
      variables: {
        input: newFields,
      },
    });

    const file = new File([code], fileName, {
      type: 'text/plain',
    });
    await uploadFile({
      workspaceId: workspace?.id as string,
      fileId,
      fullFileName,
      file,
      commitHash,
    });

    try {
      posthog?.capture('FILE_SAVED', {
        workspaceId: workspace?.id,
        fileId: fileId,
      });

      toast.success('File saved');
    } catch (error) {}

    if (isGitHubProject) {
      // TODO: find out why local commit hash and remote commit hash are different and decide if we maintain both or keep only remote event fields
      // for now we maintain both field hashes and link the revision to the PR

      if (!prTitle || !commitMessage) {
        alert('Missing PR title and commit message');
        toast.error('Missing PR title and commit message');
        return;
      }
      // createPR mutation
      // prompt to open PR url
      const prResult = await createPR({
        variables: {
          input: {
            commitHash: commitHash,
            fileId: fileId,
            prTitle: prTitle,
            commitMessage: commitMessage,
            workspaceId: workspace?.id as string,
          },
        },
      });
      if (prResult.data?.createPr?.__typename === 'CreatePrSuccess') {
        await createNewRevision(
          commitHash,
          false,
          //@ts-ignore
          prResult.data?.createPr?.data.pullRequestNumber
        );
        toast.success('Pull request created');
        try {
          posthog?.capture('PULL_REQUEST_CREATED', {
            workspaceId: workspace?.id,
            fileId: fileId,
          });
        } catch (error) {}
        setPRURL(prResult.data.createPr.data.url);
      }
    } else {
      await setCommitHash({
        variables: {
          lastCommitHash: commitHash,
          id: fileId,
        },
      });
      await createNewRevision(commitHash, true);
    }
  };

  return (
    <div className="fixed top-[44px] left-0 w-screen h-[calc(100vh-44px)] z-50 bg-zinc-900 grid grid-cols-[280px_minmax(900px,_1fr)] ">
      <div className="w-full flex flex-col gap-4   px-2 pt-4 pb-4">
        <h3>Source Control</h3>
        {prURL !== '' && (
          <a target="_blank" href={prURL} rel="noreferrer">
            <Button variant="green">
              Check PR <LinkIcon className="w-3.5 h-3.5 ml-1" />
            </Button>
          </a>
        )}
        {isGitHubProject && prURL === '' && (
          <GitChanges
            commitMessage={commitMessage}
            setCommitMessage={setCommitMessage}
            prTitle={prTitle}
            setPRTitle={setPRTitle}
            canSave={canSave}
            isSaving={isSaving}
            handleSave={handleSave}
            isGitHubProject={isGitHubProject}
            prURL={prURL}
            closeModal={closeModal}
            openModal={openModal}
            isChanged={isChanged}
            openDiffEditor={openDiffEditor}
          />
        )}
        {!isGitHubProject && prURL === '' && (
          <div className="w-full flex flex-col gap-2 justify-start ">
            <Button
              isLoading={isSaving}
              isDisabled={isDemo || !isChanged}
              onClick={() => setOpenDiffEditor(true)}
            >
              {!isChanged ? 'No changes' : 'View changes'}
            </Button>

            <div className="flex gap-1">
              <Button onClick={closeModal} className="flex-1">
                Cancel
              </Button>
              <Button
                isDisabled={isDemo || !isChanged}
                isLoading={isSaving}
                onClick={handleSave}
                variant="green"
                className="flex-1"
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="relative w-full h-full border-l border-zinc-700">
        <DiffEditor
          onMount={handleEditorDidMount}
          width={'100%'}
          height={'calc(100vh - 44px)'}
          theme={'vs-dark'}
          original={initialContent}
          modified={code}
          options={{
            readOnly: true,
            automaticLayout: true,
            minimap: {
              enabled: true,
            },
            fontSize: 16,
            cursorStyle: 'block',
            wordWrap: 'on',
          }}
        />
      </div>
    </div>
  );
};
