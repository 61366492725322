import { EditorContent, useCurrentEditor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  ToolButton,
  OrderedListIcon,
  Avatar,
  Tooltip,
  Option,
  Menu,
  useModal,
  DeleteConfirmationModal,
} from '@hubql/hubqlkit';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  DeleteFileCommentDocument,
  UpdateFileCommentContentDocument,
} from '@hubql/codegen';
import clsx from 'clsx';
import {
  ChatBubbleBottomCenterTextIcon,
  CodeBracketSquareIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { useLiveStore } from '../state/stores/live';
import { useCommentStore } from '../state/stores/comments';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { usePostHog } from 'posthog-js/react';
import { Handle, Position, WrapNodeProps } from 'reactflow';

type MenuBarProps = {
  handleDelete: () => void;
};

const MenuBar = ({ handleDelete }: MenuBarProps) => {
  const { editor } = useCurrentEditor();
  const [openOption, setOpenOption] = useState(false);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex border-b rounded-t-lg border-zinc-700">
      <ToolButton
        className={'relative rounded-tl-md'}
        onClick={() => setOpenOption(!openOption)}
        tooltip="Font style"
      >
        <span className="font-bold text-[10px]">Tt</span>
        {openOption && (
          <div className="absolute top-[100%] left-0 bg-zinc-800 z-30 border border-zinc-600 rounded-sm shadow-lg overflow-hidden">
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              variant={editor.isActive('heading', { level: 1 }) ? 'active' : ''}
              size={'full'}
            >
              Heading 1
            </ToolButton>
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              variant={editor.isActive('heading', { level: 2 }) ? 'active' : ''}
              size={'full'}
            >
              Heading 2
            </ToolButton>
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              variant={editor.isActive('heading', { level: 3 }) ? 'active' : ''}
              size={'full'}
            >
              Heading 3
            </ToolButton>
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 4 }).run()
              }
              variant={editor.isActive('heading', { level: 4 }) ? 'active' : ''}
              size={'full'}
            >
              Heading 4
            </ToolButton>
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 5 }).run()
              }
              variant={editor.isActive('heading', { level: 5 }) ? 'active' : ''}
              size={'full'}
            >
              Heading 5
            </ToolButton>
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 6 }).run()
              }
              variant={editor.isActive('heading', { level: 6 }) ? 'active' : ''}
              size={'full'}
            >
              Heading 6
            </ToolButton>
            <ToolButton
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              variant={editor.isActive('paragraph') ? 'active' : ''}
              size={'full'}
            >
              Paragraph
            </ToolButton>
          </div>
        )}
      </ToolButton>
      <ToolButton
        tooltip="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        variant={editor.isActive('bold') ? 'active' : ''}
      >
        <span className="font-bold text-[10px]">B</span>
      </ToolButton>
      <ToolButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        variant={editor.isActive('bulletList') ? 'active' : ''}
        tooltip="Bullet list"
      >
        <ListBulletIcon className="w-3 h-3 fill-zinc-400" />
      </ToolButton>
      <ToolButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        variant={editor.isActive('orderedList') ? 'active' : ''}
        tooltip="Numbered list"
      >
        <OrderedListIcon className=" fill-zinc-400 w-3.5 h-3.5" />
      </ToolButton>
      <ToolButton
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        variant={editor.isActive('codeBlock') ? 'active' : ''}
        tooltip="Code block"
      >
        <CodeBracketSquareIcon className="w-3 h-3" />
      </ToolButton>
      <div className="flex-1 min-w-[24px]" />

      <Menu
        label={<EllipsisVerticalIcon className="w-3 h-3" />}
        tooltipLabel="Options"
        id="note-option"
        className="rounded-none rounded-tr-md h-[24px] w-[24px]"
        optionsClassname="-right-[100px] -top-[9px]"
      >
        <Option
          className="flex items-center text-[10px]"
          onClick={handleDelete}
        >
          <TrashIcon className="w-3 h-3" />
          Delete note
        </Option>
      </Menu>
    </div>
  );
};

type FloatingMenuProps = {
  noteId: string;
  setEditing: (value: boolean) => void;
  editing: boolean;
};
const FloatingMenu = ({ noteId, setEditing, editing }: FloatingMenuProps) => {
  const setThreadId = useCommentStore((state) => state.setThreadId);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setShowComment = useUIUtilityStore((state) => state.setShowComment);

  const clickComment = () => {
    setShowComment(true);
    setThreadId(noteId);
    setSelectedFieldId(noteId);
  };

  const floatingButtonStyle = 'p-1 rounded-md hover:bg-zinc-700 cursor-pointer';
  return (
    <div className="absolute top-0 grid grid-cols-1 -right-6 ">
      {!editing && (
        <div onClick={() => setEditing(true)} className={floatingButtonStyle}>
          <Tooltip message="Edit note" id="edit-note">
            <PencilSquareIcon className="w-3 h-3 fill-zinc-400" />
          </Tooltip>
        </div>
      )}
      {editing && (
        <div onClick={() => setEditing(false)} className={floatingButtonStyle}>
          <Tooltip message="Close edit mode" id="close-note">
            <XMarkIcon className="w-3 h-3 stroke-1 fill-zinc-400 stroke-zinc-400" />
          </Tooltip>
        </div>
      )}
      <div onClick={() => clickComment()} className={floatingButtonStyle}>
        <Tooltip message="Comment on note" id="comment-note">
          <ChatBubbleBottomCenterTextIcon className="w-3 h-3 fill-zinc-400 " />
        </Tooltip>
      </div>
    </div>
  );
};
const extensions = [StarterKit];

export const NoteNode = (object: WrapNodeProps) => {
  const posthog = usePostHog();
  const { isOpen, openModal, closeModal } = useModal();
  const [content, setContent] = useState(object.data?.comment);

  const editor = useEditor({
    extensions: extensions,
    content: object.data?.comment,
    editorProps: {
      attributes: {
        class:
          'prose prose-[10px] prose-p:leading-[6px] prose-p:mb-[0px] prose-p:text-[10px] prose-p:text-zinc-300 prose-headings:text-zinc-300 prose-li:text-zinc-300 prose-a:text-green-500 prose-strong:text-zinc-300 prose-ol:ml-0 prose-ol:text-zinc-300  prose-ul:text-[10px] prose-ol:text-[10px] prose-ol:leading-[8px] prose-ul:leading-[8px] prose-ul:mt-[2px] prose-ol:mt-[2px] prose-li:m-[2px]',
      },
    },
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML());
    },
  });

  useEffect(() => {
    setContent(object.data?.comment);
    editor && editor.commands.setContent(object.data?.comment);
  }, [object.data?.comment]);

  const [editing, setEditing] = useState(false);
  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false);

  const notes = useLiveStore((state) => state.notes);
  const setNotes = useLiveStore((state) => state.setNotes);

  const [updateFileCommentContent] = useMutation(
    UpdateFileCommentContentDocument
  );
  const id = object.data?.id;
  const [deleteFileComment] = useMutation(DeleteFileCommentDocument);

  const handleDelete = () => {
    setNotes(notes.filter((note) => note.id !== id));
    deleteFileComment({
      variables: {
        id,
      },
    });
    try {
      posthog?.capture('NOTE_DELETED', {
        noteId: id,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!object?.selected && content && id) {
      setEditing(false);
      let isNoteUpdated = false;
      const updatedNotes = notes.map((note) => {
        if (note.id === id) {
          isNoteUpdated = true;
          return { ...note, comment: content };
        }

        return { ...note };
      });

      if (!isNoteUpdated) {
        return;
      }

      setNotes(updatedNotes);
      updateFileCommentContent({
        variables: {
          comment: content,
          id,
        },
      });
    }
  }, [object?.selected]);
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);

  const border = object?.selected
    ? `border-[${activeFieldColor}]`
    : 'border-zinc-500';

  const NoteHeader = () => {
    const { workspace } = useWorkspace();
    const { workspaceUser } = useWorkspaceUser();
    const author = workspace?.members?.find(
      (member) => member.user.id === object.data.createdBy
    )?.user;
    const yourNote = workspaceUser?.id === object.data.createdBy;
    const [hoverAvatar, setHoverAvatar] = useState(false);
    const isoString = object?.data?.createdAt?.substring(0, 10);
    return (
      <div>
        {editing ? <MenuBar handleDelete={openModal} /> : null}
        <div
          className="px-1.5 py-1.5 w-full border-b-1 border-zinc-600 cursor-pointer"
          onClick={() => setHoverAvatar(!hoverAvatar)}
        >
          <Avatar
            name={author?.firstName}
            size="xs"
            src={author?.picture}
            date={isoString ?? ''}
            isYou={yourNote && ' (You)'}
            label={hoverAvatar || editing}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="relative">
      <DeleteConfirmationModal
        onDelete={handleDelete}
        onCancel={closeModal}
        isOpen={isOpen}
      />
      <div
        className={clsx(
          !editing && 'custom-drag-handle cursor-grab',
          border,
          ' border-2 rounded-sm bg-zinc-800 relative'
        )}
        onDoubleClick={() => setEditing(true)}
      >
        <NoteHeader />
        {editor && <EditorContent editor={editor} />}
      </div>
      <Handle
        id={id}
        type="source"
        position={Position.Right}
        isConnectable={false}
        className="absolute right-0 opacity-0"
      />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        className="absolute left-0 opacity-0"
      />
      <FloatingMenu noteId={id} setEditing={setEditing} editing={editing} />
    </div>
  );
};
