import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
  colSpan?: number;
  onClick?: () => void;
}

export const CellProtobuf = ({
  children,
  className,
  colSpan = 1,
  onClick,
}: Props) => {
  return (
    <td
      colSpan={colSpan}
      className={clsx(
        'whitespace-nowrap py-2 pl-3 pr-2 text-[9px] font-medium',
        className
      )}
      onClick={onClick}
    >
      {children}
    </td>
  );
};
