import {
  Avatar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@hubql/hubqlkit';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useLiveStore } from '../state/stores/live';
import { useEffect } from 'react';
import { COLORS } from '../const/presence-colors';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import Link from 'next/link';

export const FilePresence = () => {
  const { workspaceUser } = useWorkspaceUser();
  const others = useLiveStore((state) => state.liveblocks.others);
  const room = useLiveStore((state) => state.liveblocks.room);
  const setActiveFieldColor = useLiveStore(
    (state) => state.setActiveFieldColor
  );
  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);
  const connectionId = room?.getSelf()?.connectionId ?? 1;

  useEffect(() => {
    if (connectionId) {
      const activeFieldColor = COLORS[connectionId % COLORS.length];
      setActiveFieldColor(activeFieldColor);
    }
  }, [connectionId]);

  const { workspace } = useWorkspace();

  const user = workspace?.members?.find(
    (member) => member.user.id === workspaceUser?.id
  );

  return (
    <div className="w-fit mr-1 flex items-center gap-2">
      <Popover>
        <PopoverTrigger>
          <Avatar
            key={'current-user'}
            src={user?.user.picture ?? null}
            name={
              workspaceUser?.name
                ?.split(' ')
                .map((n) => n[0])
                .join('') ?? ''
            }
            alt=""
            style={{
              outline: `${activeFieldColor} solid 2px`,
            }}
            label={false}
          />
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-col gap-1 items-start text-xs">
            <span>{user?.user?.email} (You)</span>
            <span>
              {user?.user?.firstName} {user?.user?.lastName}
            </span>
            <span>{user?.role}</span>
            <Link
              href={`/w/${workspace?.id}/settings`}
              className="mt-2 underline"
            >
              view account settings
            </Link>
          </div>
        </PopoverContent>
      </Popover>

      {others.length > 0 && (
        <div className="flex -space-x-1 items-center border-l border-zinc-700 pl-2">
          {others?.map((other) => (
            <Tooltip message={other.info?.name} key={other?.connectionId}>
              <Avatar
                //@ts-ignore
                src={other?.info?.avatar ?? null}
                name={
                  //@ts-ignore
                  other.info?.name
                    ?.split(' ')
                    ?.map((n) => n[0])
                    ?.join('') ?? ''
                }
                alt=""
                style={{
                  outline: `${
                    COLORS[other?.connectionId % COLORS.length]
                  } solid 2px`,
                }}
                label={false}
              />
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};
