import { FileExtension, LanguageConfig } from './HubqlEditor';
import { languageConfig as prismaLanguageConfig } from './prisma/config';
import { languageConfig as jsonLanguageConfig } from './json/config';
import { languageConfig as graphqlLanguageConfig } from './graphql/config';
import { languageConfig as protobufLanguageConfig } from './protobuf/config';
import { languageConfig as xmlLanguageConfig } from './xml/config';
import { languageConfig as yamlLanguageConfig } from './yaml/config';
import { languageConfig as simpleTextLanguageConfig } from './simple-text/config';
export function getLanguageConfig(ext: FileExtension): LanguageConfig {
  switch (ext) {
    case 'prisma':
      return prismaLanguageConfig;
    case 'json':
      return jsonLanguageConfig;
    case 'graphql':
      return graphqlLanguageConfig;
    case 'proto':
      return protobufLanguageConfig;
    case 'xml':
      return xmlLanguageConfig;
    case 'yaml':
      return yamlLanguageConfig;
    default:
      return simpleTextLanguageConfig;
  }
}
