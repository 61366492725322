import { useQuery } from '@apollo/client';
import { ProjectDocument, ProjectQuery } from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useProjectStore } from '../state/stores/project';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsNew,
  TabsTrigger,
  useModal,
} from '@hubql/hubqlkit';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { ProjectFileList } from './ProjectFileList';
import { TitleContainer } from './TitleContainer';
import { ProjectListSettings } from './ProjectListSettings';

export const ProjectHeader = () => {
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();
  const router = useRouter();
  const projectId = router?.query['hub'] as string;
  const setProject = useProjectStore((state) => state.setProject);
  const project = useProjectStore((state) => state.project);
  const { isOpen, openModal, closeModal } = useModal();
  const { loading, data } = useQuery<ProjectQuery>(ProjectDocument, {
    skip: !projectId,
    variables: {
      id: projectId,
    },
    onCompleted: (data) => {
      if (data.project) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setProject(data.project);
      }
    },
  });
  const hasRepo = Number(project?.repositories?.edges?.length) > 0;
  const repo = project?.repositories?.edges?.[0]?.node;
  if (loading || !workspace) return null;
  if (!project) return <p>Access denied</p>;
  const isPrivateProject = project.defaultAccess === 'PRIVATE';
  const projectName = data?.project?.name;
  const allowDelete = project?.ownedBy === workspaceUser?.id;

  const tabs = [
    {
      label: 'Files',
      content: <ProjectFileList />,
      id: 'files',
    },
    {
      label: 'Settings',
      content: (
        <ProjectListSettings
          allowDelete={allowDelete}
          openModal={openModal}
          isOpen={isOpen}
          closeModal={closeModal}
          projectId={projectId}
          projectName={projectName}
          workspaceSlug={workspace?.slug}
          hasRepo={hasRepo}
          repo={repo}
        />
      ),
      id: 'settings',
    },
  ];
  return (
    <div className="mb-4 flex flex-col gap-4">
      <TitleContainer
        workspaceSlug={router.query['workspaceSlug']}
        isPrivateProject={isPrivateProject}
        projectId={projectId}
        projectName={projectName}
        workspaceUser={workspaceUser}
      />
      <div className="flex flex-row items-start justify-between">
        <div className="w-full">
          <TabsNew defaultValue={tabs?.[0]?.id} className="">
            <TabsList className="max-w-xs">
              {tabs?.map((tab) => {
                return (
                  <TabsTrigger key={'project-header-' + tab.id} value={tab.id}>
                    {tab.label}
                  </TabsTrigger>
                );
              })}
            </TabsList>
            {tabs?.map((tab) => {
              return (
                <TabsContent
                  key={'project-header-content-' + tab.id}
                  value={tab.id}
                >
                  {tab.content}
                </TabsContent>
              );
            })}
          </TabsNew>
        </div>
      </div>
    </div>
  );
};
