import { useQuery } from '@apollo/client';
import {
  WorkspaceMembershipsQuery,
  WorkspaceMembershipsDocument,
} from '@hubql/codegen';
import { Spinner } from '@hubql/shared-design-system';
import Link from 'next/link';
import { useEffect } from 'react';

export const WorkspaceList = ({ refetch, userId }) => {
  const { loading, error, data, stopPolling } =
    useQuery<WorkspaceMembershipsQuery>(WorkspaceMembershipsDocument, {
      skip: !userId,
      variables: {
        userId,
      },
      pollInterval: refetch ? 2000 : undefined,
    });

  useEffect(() => {
    if (Number(data?.workspaceMembers?.edges?.length) > 0) {
      stopPolling();
    }
  }, [data]);

  if (loading) return <Spinner />;

  const workspaces = data?.workspaceMembers?.edges?.map((workspaceNode) => {
    const workspace = workspaceNode?.node?.workspace;
    if (!workspace) return 'No workspace';
    return (
      <WorkspaceListItem
        key={workspace.id}
        name={workspace?.name}
        slug={workspace?.slug}
      />
    );
  });
  return <ul className="list-none">{workspaces}</ul>;
};

const WorkspaceListItem = ({ name, slug }) => {
  return (
    <Link href={'/w/' + slug}>
      <li className="w-full cursor-pointer py-1 px-1 text-zinc-400 hover:bg-zinc-800">
        {name}
      </li>
    </Link>
  );
};
