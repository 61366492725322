import React, { useMemo } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { VisualizationEngineDetail } from './VisualizationEngineDetail';
import { useAuth } from '@clerk/nextjs';
import { createApolloClient } from '@hubql/apollo';
import { ApolloProvider } from '@apollo/client';

/**
 * The visualization engine is the component that renders the graph visualization of fields
 * This component is used to export the image from the command line(check `cli` app)
 */
export const VisualizationEngine: React.FC = () => {
  const { getToken } = useAuth();
  const client = useMemo(() => createApolloClient({ getToken }), []);

  return (
    <div className="w-screen h-screen">
      <ReactFlowProvider>
        <ApolloProvider client={client}>
          <VisualizationEngineDetail />
        </ApolloProvider>
      </ReactFlowProvider>
    </div>
  );
};
