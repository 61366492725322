import { GraphQLSchema, printSchema, buildSchema } from 'graphql';
import { v4 as uuidv4 } from 'uuid';
import { IField } from '../types';

interface IGraphQLToFields {
  graphqlSchema: string;
  workspaceId: string;
  fileId: string;
  rawFields: any[];
}

export const schemaToString = (parsedSchema: GraphQLSchema) => {
  const schemaSDL = printSchema(stitchedSchema(parsedSchema));
  return schemaSDL;
};
const stitchedSchema = (parsedSchema: GraphQLSchema) => {
  return new GraphQLSchema({
    query: parsedSchema.getQueryType(),
    mutation: parsedSchema.getMutationType(),
    subscription: parsedSchema.getSubscriptionType(),
    //@ts-expect-error types
    types: parsedSchema.getTypeMap(),
    directives: parsedSchema.getDirectives(),
  });
};

export const graphQLToFields = ({
  graphqlSchema,
  workspaceId,
  fileId,
  rawFields,
}: IGraphQLToFields) => {
  const fields: IField[] = [];
  let schema;
  try {
    schema = buildSchema(graphqlSchema);
  } catch (error) {
    return [];
  }

  const query = schema.getQueryType();
  const line = 1;
  if (query) {
    const rawField = rawFields?.find(
      (raw) => raw.key === 'Query' && !raw.parentId
    );
    const queryId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    fields.push({
      id: queryId,
      key: 'Query',
      type: 'Query',
      exampleValue: null,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      suggestions: rawField?.suggestion,
      line,
      meta: null,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
    });

    const queryFields = query.getFields();
    Object.keys(queryFields).forEach((queryKey) => {
      const queryField = queryFields[queryKey];
      const queryRawField = rawFields?.find(
        (raw) => raw.key === queryKey && raw.parentId === queryId
      );
      const queryFieldId =
        queryRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
      fields.push({
        id: queryFieldId,
        key: queryField.name,
        type: queryField.type.toString(),
        exampleValue: null,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: queryId,
        suggestions: queryRawField?.suggestion,
        line,
        meta: null,
        positionX: queryRawField?.positionX,
        positionY: queryRawField?.positionY,
      });
    });
  }
  const mutation = schema.getMutationType();
  if (mutation) {
    const rawField = rawFields?.find(
      (raw) => raw.key === 'Mutation' && !raw.parentId
    );
    const mutationId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    fields.push({
      id: mutationId,
      key: 'Mutation',
      type: 'Mutation',
      exampleValue: null,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      suggestions: rawField?.suggestion,
      line,
      meta: null,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
    });
    const mutationFields = mutation.getFields();
    Object.keys(mutationFields).forEach((mutationKey) => {
      const queryField = mutationFields[mutationKey];
      const queryRawField = rawFields?.find(
        (raw) => raw.key === mutationKey && raw.parentId === mutationId
      );
      const queryFieldId =
        queryRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
      fields.push({
        id: queryFieldId,
        key: queryField.name,
        type: queryField.type.toString(),
        exampleValue: null,
        workspaceId: workspaceId,
        fileId: fileId,
        parentId: mutationId,
        suggestions: queryRawField?.suggestion,
        line,
        meta: null,
        positionX: queryRawField?.positionX,
        positionY: queryRawField?.positionY,
      });
    });
  }

  const types = schema.getTypeMap();
  Object.keys(types).forEach((typeKey) => {
    const type = types[typeKey];
    if (type.name.startsWith('__')) {
      return;
    }
    if (type.name === 'Query' || type.name === 'Mutation') {
      return;
    }
    const rawField = rawFields?.find(
      (raw) => raw.key === typeKey && !raw.parentId
    );
    const typeId = rawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
    fields.push({
      id: typeId,
      key: type.name,
      type: type.name,
      exampleValue: null,
      workspaceId: workspaceId,
      fileId: fileId,
      parentId: null,
      suggestions: rawField?.suggestion,
      line,
      meta: null,
      positionX: rawField?.positionX,
      positionY: rawField?.positionY,
    });
    try {
      //@ts-ignore
      const typeFields = type.getFields();
      Object.keys(typeFields).forEach((mutationKey) => {
        const queryField = typeFields[mutationKey];
        const queryRawField = rawFields?.find(
          (raw) => raw.key === mutationKey && raw.parentId === typeId
        );
        const queryFieldId =
          queryRawField?.id ?? 'ff_' + uuidv4().replace(/-/gi, '');
        fields.push({
          id: queryFieldId,
          key: queryField.name,
          type: queryField.type.toString(),
          exampleValue: null,
          workspaceId: workspaceId,
          fileId: fileId,
          parentId: typeId,
          suggestions: queryRawField?.suggestion,
          line,
          meta: null,
          positionX: queryRawField?.positionX,
          positionY: queryRawField?.positionY,
        });
      });
    } catch (error) {
      // console.log('error', error);
    }
  });
  return fields;
};
