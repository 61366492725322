export const LIFETIME_PRICE_ID =
  process.env.DOPPLER_ENVIRONMENT === 'development' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'development'
    ? 'price_1OtCc2GIx8y1kBbyuibuVXmp'
    : 'price_1PLsBHGIx8y1kBbyLXjXIRg9';

// TODO: we might export this from stripe as static value?
export const stripePlans =
  process.env.DOPPLER_ENVIRONMENT === 'development' ||
  process.env.NEXT_PUBLIC_APP_ENV === 'development'
    ? [
        {
          name: 'Lifetime',
          id: LIFETIME_PRICE_ID,
        },
        {
          name: 'Pro (Yearly)',
          id: 'price_1OqZfGGIx8y1kBbyOc4spB1i',
        },
        {
          name: 'Pro',
          id: 'price_1OPjDzGIx8y1kBbyhVmg1a3q',
        },
        {
          name: 'Enterprise',
          id: 'TBD',
        },
      ]
    : [
        {
          name: 'Lifetime',
          id: LIFETIME_PRICE_ID,
        },
        {
          name: 'Pro',
          id: 'price_1PYk5VGIx8y1kBby0mBhpXAC',
        },
        {
          name: 'Pro (Yearly)',
          id: 'price_1PYk6HGIx8y1kBbyEBbit3Zf',
        },
        {
          name: 'Pro Legacy',
          id: 'price_1OhTTqGIx8y1kBbyFuXT6j3g',
        },
        {
          name: 'Pro Lgeacy (Yearly)',
          id: 'price_1OjmhJGIx8y1kBby4hk6HXdT',
        },
        {
          name: 'Enterprise',
          id: 'TBD',
        },
      ];

export const freeSeatLimit = 1;

// Set the paths that don't require the user to be signed in
export const publicPaths = [
  '/',
  '/demo',
  '/sign-in(.*)',
  '/sign-up(.*)',
  '/session-confirms(.*)',
  '/visualizations',
  '/rest/live-auth',
  '/rest/analytics-auth',
  '/rest/get-file',
  '/rest/templates',
  '/rest/upload-file',
  '/rest/prisma-dmmf',
  '/rest/prisma-format',
];

export const isPublicPage = (path: string) => {
  return publicPaths.find((x) =>
    path.match(new RegExp(`^${x}$`.replace('*$', '($|/)')))
  );
};
