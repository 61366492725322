import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  overlay?: boolean;
  position?: string;
  style?: any;
  bg?: string;
};

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return { isOpen, openModal, closeModal };
};

const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  className,
  overlay = true,
  position = 'relative',
  style,
  bg = 'bg-zinc-900',
}: ModalProps) => {
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, onClose);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className="fixed inset-0 z-30 overflow-auto h-full w-full flex justify-center">
      {overlay && (
        <div
          id="blur"
          className="w-screen h-screen top-0 left-0 z-10 fixed bg-[#0A0A0B] backdrop-blur-md opacity-70"
        />
      )}

      <div
        className={clsx(
          position,
          bg,
          'absolute top-[140px] z-20 h-fit border border-1 border-zinc-800 rounded-md   shadow-xl',
          className
        )}
        style={style}
        ref={modalRef}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};
