import { fieldsToFileStringXML } from './fieldsToXml';

export const updateXMLKey = ({
  newKey,
  fields,
  fieldId,
}: {
  newKey: string;
  fields: any;
  fieldId: string;
}) => {
  const newFields = fields.map((field: any) => {
    if (field.id === fieldId) {
      return {
        ...field,
        key: newKey,
      };
    }
    return field;
  });
  return fieldsToFileStringXML(newFields, 'xml');
};

export const addXMLValue = ({
  fields,
  newField,
}: {
  fields: any;
  newField: any;
}) => {
  return fieldsToFileStringXML([...fields, newField], 'xml');
};

export const updateXMLValue = ({
  newValue,
  fields,
  fieldId,
}: {
  newValue: any;
  fields: any;
  fieldId: string;
}) => {
  const newFields = fields.map((field: any) => {
    if (field.id === fieldId) {
      return {
        ...field,
        exampleValue: newValue,
      };
    }
    return field;
  });
  return fieldsToFileStringXML(newFields, 'xml');
};
