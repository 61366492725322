import { useUIUtilityStore } from '@hubql/app-core';
import { AnimatePresence, motion } from 'framer-motion';
import { CommentSidebar } from './CommentSidebar';

export const CommentSidebarAnimation = () => {
  const showComment = useUIUtilityStore((state) => state.showComment);
  const hoverComment = useUIUtilityStore((state) => state.hoverComment);
  const setHoverComment = useUIUtilityStore((state) => state.setHoverComment);

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };

  return (
    <AnimatePresence>
      {showComment ? (
        <motion.aside
          initial={{
            width: '280px',
            x: '0px',
            position: 'relative',
            top: 0,
            right: 0,
            height: 'calc(100vh - 40px)',
            zIndex: 100,
            marginLeft: '0px',
          }}
          animate={{
            width: '280px',
            x: '0px',
            zIndex: 100,
            position: 'relative',
            transition: { duration: 0.3 },
          }}
          exit={{
            width: '0px',
            x: '280px',
            zIndex: 100,
            position: 'relative',
            transition: { duration: 0.3 },
          }}
        >
          <motion.div
            className="container"
            initial="open"
            animate="closed"
            exit="closed"
            variants={sideVariants}
          >
            <CommentSidebar />
          </motion.div>
        </motion.aside>
      ) : (
        <motion.aside
          initial={{
            width: '0xp',
            x: '280px',
            position: 'relative',
            top: 0,
            right: 0,
            height: 'calc(100vh - 40px)',
            zIndex: 100,
            marginLeft: '0px',
          }}
          animate={{
            width: '0px',
            x: '280px',
            zIndex: 100,
            position: 'relative',
            transition: { duration: 0.3 },
          }}
          exit={{
            width: '280px',
            x: '0px',
            zIndex: 100,
            position: 'relative',
            transition: { duration: 0.3 },
          }}
        >
          <motion.div
            className="container"
            initial="open"
            animate="closed"
            exit="closed"
            variants={sideVariants}
          >
            <CommentSidebar />
          </motion.div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};
