import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Button,
} from '@hubql/hubqlkit';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';

export const GuestSignUpModal = () => {
  const showGuestSignUp = useUIUtilityStore((state) => state.showGuestSignUp);
  const setShowGuestSignUp = useUIUtilityStore(
    (state) => state.setShowGuestSignUp
  );
  const communityPlan = {
    title: 'Community plan',
    price: 'Free',
    header: 'Not signed up yet?',
    content:
      'You like what you see and want to access more powerful features? \n \n Sign up now for Schema Visualizer for FREE and join our community to power-up your application development.',
    details: [
      'Unlimited visitors',
      '1 workspace editor',
      '3 Hubs',
      '1 GitHub repo connection',
    ],
  };
  return (
    <Dialog open={showGuestSignUp} onOpenChange={setShowGuestSignUp}>
      <DialogContent className="max-w-3xl  w-fit  ">
        <div className="flex flex-row w-fit">
          <DialogHeader className="flex flex-col p-4 items-start justify-center max-w-[360px]">
            <DialogTitle className="text-2xl text-left font-bold leading-relaxed">
              {communityPlan.header}
            </DialogTitle>
            <DialogDescription className="leading-relaxed whitespace-pre-line ">
              {communityPlan.content}
            </DialogDescription>
          </DialogHeader>
          <div className="bg-[#008A27] flex flex-col gap-2 px-4 rounded-r-lg max-w-[280px] py-4">
            <h1 className="text-xl text-center font-bold">
              {communityPlan.title}
            </h1>
            <h2 className="text-3xl text-center w-full font-black uppercase ">
              {communityPlan.price}
            </h2>
            <div>
              <ul>
                {communityPlan?.details?.map((item, index) => (
                  <li
                    key={'guest-modal-' + index}
                    className="list-disc ml-4 text-md"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <Button className="bg-white text-black w-full border-white hover:bg-zinc-200 focus:bg-zinc-200 active:bg-zinc-200 mt-4">
              <a href="https://accounts.schemavisualizer.dev/sign-up">
                Sign up
              </a>
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
