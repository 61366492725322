import type { LiveField } from '@hubql/app-core/lib/state/stores/live';
import { EdgeProps } from 'reactflow';
import { Object, makeNodesEdges as legacyMakeNodesEdges } from './makeNodes';
import { makeNodesEdgesForJson } from './json';
export const makeNodesEdges = (
  object: Object,
  fieldData: LiveField[],
  extension: string | null,
  edgeStatus?: EdgeProps
) => {
  // If the file is a JSON file and has a root field, use the new JSON node/edge creation
  // TODO: we can remove in 1-2 months
  const hasRoot = fieldData?.find((field) => field?.key === '__root__');
  if (extension === 'json' && hasRoot) {
    return makeNodesEdgesForJson(object, fieldData);
  }

  return legacyMakeNodesEdges(object, fieldData, extension, edgeStatus);
};
