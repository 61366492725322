import {
  CheckIcon,
  DocumentIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';
import {
  Avatar,
  Button,
  Input,
  Tooltip,
  Menu,
  Option,
  Spinner,
  JsonIcon,
  GraphqlIcon,
  PrismaIcon,
  ProtoIcon,
  XmlIcon,
  ContextMenu,
  ContextMenuTrigger,
  OpenAPIIcon,
} from '@hubql/hubqlkit';
import Link from 'next/link';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FileContextMenu } from './FileContextMenu';
import { useMutation } from '@apollo/client';
import { DeleteFileDocument, UpdateFileNameDocument } from '@hubql/codegen';
import { usePostHog } from 'posthog-js/react';

export const ProjectFile = ({
  file,
  hasRepo,
}: {
  file?: any;
  hasRepo: boolean;
}) => {
  const posthog = usePostHog();

  const fileAccess = file.access?.edges
    ?.map((node: { node: any }) => node?.node)
    ?.map(
      (access: {
        user: {
          id: string;
          picture: string | null | undefined;
          firstName: any;
        };
      }) => {
        return (
          <Avatar
            key={'avatar' + access.user.id + file.id}
            src={access?.user?.picture}
            alt={access.user?.firstName ?? ''}
            title={access.user?.firstName ?? ''}
          />
        );
      }
    );

  const canDelete = !hasRepo;
  const canRename = !hasRepo;
  const accessList = fileAccess.length > 0 ? fileAccess : '';
  const fileName = file.fileName;
  const link = `/?file=${file.id}&ref=${file.lastCommitHash}`;
  const [deleteFile, deleteFileState] = useMutation(DeleteFileDocument);
  const [updateFileName] = useMutation(UpdateFileNameDocument);
  const [renameView, setRenameView] = useState(false);
  const [name, setFilename] = useState(fileName);
  const onSaveChanges = () => {
    setRenameView(false);
    updateFileName({
      variables: {
        id: file.id,
        fileName: name,
      },
      refetchQueries: ['projectFileList'],
    });
    try {
      posthog?.capture('FILE_NAME_CHANGED', {
        fileId: file.id,
        fileName: name,
      });
    } catch (error) {}
    toast.success('File name saved');
  };
  const onCancel = () => {
    setRenameView(false);
  };
  const onRenaming = () => {
    setRenameView(true);
  };

  const handleDelete = () => {
    deleteFile({
      variables: {
        id: file.id,
      },
      refetchQueries: ['projectFileList'],
    });
    try {
      posthog?.capture('FILE_DELETED', {
        fileId: file.id,
      });
    } catch (error) {}
  };
  const extensionIcon = (extension: string) => {
    switch (extension) {
      case 'json':
        return <JsonIcon className="w-5 h-5 ml-2" />;
      case 'graphql':
        return <GraphqlIcon className="w-5 h-5 ml-2" />;
      case 'prisma':
        return <PrismaIcon className="w-5 h-5 ml-2" />;
      case 'proto':
        return <ProtoIcon className="w-5 h-5 ml-2" />;
      case 'xml':
        return <XmlIcon className="w-5 h-5 ml-2" />;
      case 'yaml':
        return <OpenAPIIcon className="w-5 h-5 ml-2" />;
      default:
        return <DocumentIcon className="w-5 h-5 ml-2" />;
    }
  };

  if (renameView) {
    return (
      <div
        key={file.id}
        className="relative grid grid-cols-12 text-xs w-full justify-between items-center gap-2 cursor-pointer h-[34px] text-zinc-400 hover:bg-zinc-800"
      >
        <div className="flex items-center  h-[34px] col-span-12 gap-2 pl-2">
          {extensionIcon(file.extension)}

          <Input
            placeholder="Untitled"
            value={name}
            onChange={(e) => setFilename(e.target.value)}
            className="h-[24px] w-full"
            width="w-full"
          />
          <div className="flex">
            <Tooltip message="Save changes">
              <Button variant="ghost" onClick={onSaveChanges}>
                <CheckIcon className="w-4 h-4 fill-green-400" />
              </Button>
            </Tooltip>
            <Tooltip message="Cancel change">
              <Button variant="ghost" onClick={onCancel}>
                <XMarkIcon className="w-4 h-4 fill-red-400" />
              </Button>
            </Tooltip>
          </div>
        </div>

        <div>
          <div className="flex justify-start col-span-2">{accessList}</div>
        </div>
      </div>
    );
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div
          key={file.id}
          className="w-full relative flex items-center h-[34px] "
        >
          <Link
            href={`/?file=${file.id}&ref=${file.lastCommitHash}`}
            className=" grid grid-cols-12 text-xs w-full justify-between items-center gap-2 cursor-pointer h-[34px] text-zinc-400 hover:bg-zinc-800"
          >
            <div className="flex items-center col-span-10 gap-2 pl-2">
              {deleteFileState.loading ? (
                <Spinner />
              ) : (
                extensionIcon(file.extension)
              )}

              {deleteFileState.loading ? (
                'Deleting file...'
              ) : (
                <span className="w-full pr-4 truncate">{`${
                  file.path ?? '.'
                }/${fileName}.${file.extension}`}</span>
              )}
            </div>

            <div>
              <div className="flex justify-start col-span-1">{accessList}</div>
            </div>
          </Link>
          <div className="absolute right-0">
            {deleteFileState.loading && <Spinner />}
            {!deleteFileState.loading && (
              <Menu
                label={
                  <EllipsisVerticalIcon className="w-4 h-4 fill-zinc-300" />
                }
                tooltipLabel={'actions'}
                className="rounded-none"
              >
                <Link href={link} className="w-full h-full">
                  <Option>Open</Option>
                </Link>
                <Link href={link} target="_blank">
                  <Option>Open new tab</Option>
                </Link>

                {canRename && <Option onClick={onRenaming}>Rename</Option>}

                {canDelete && <Option onClick={handleDelete}>Delete</Option>}
              </Menu>
            )}
          </div>

          <FileContextMenu
            link={link}
            canDelete={canDelete}
            handleDelete={handleDelete}
            onRenaming={onRenaming}
            canRename={canRename}
            deleteFileState={deleteFileState.loading}
          />
        </div>
      </ContextMenuTrigger>
    </ContextMenu>
  );
};
