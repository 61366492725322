import { GetFieldByLineNumberFn, GetPositionByFieldIdFn } from '../HubqlEditor';

export const getFieldByLineNumber: GetFieldByLineNumberFn = ({
  code,
  fieldData,
  lineNumber,
}) => {
  const lines = code.split('\n');
  const lineContent = lines[lineNumber - 1];
  if (!lineContent) {
    return null;
  }

  const lineContents = lineContent.split('"');
  const contentTabs = lineContents[0];
  const contentKey = lineContents[1];
  let contentValue: string | null = null;
  if (lineContents.length === 3) {
    // in case of a comma at the end of the line (which is valid JSON)
    contentValue = lineContents[2].substring(2).replace(/,/g, '');
  } else {
    contentValue = lineContents[3];
  }
  const isNestedField = contentTabs.length > 4;
  const isNestedArray = contentTabs.length > 8;

  const matchedFields = fieldData.filter(
    (f) => f.key === contentKey && String(f.exampleValue) === contentValue
  );
  const isUnique = matchedFields.length === 1;

  if (isUnique) {
    return matchedFields[0];
  }

  const matchedField =
    fieldData.find((field) => {
      const fieldString = JSON.stringify(field.exampleValue);

      if (lineContents.length === 5) {
        const keyValueMatch =
          field.key === contentKey && field.exampleValue === contentValue;
        const currentNestedField = field.key !== field.id;
        const nestedMatch =
          !isNestedArray &&
          currentNestedField &&
          field.exampleValue === contentValue &&
          field.key === contentKey;
        const nestedArray =
          isNestedArray &&
          currentNestedField &&
          fieldString.includes(contentValue as string) &&
          fieldString.includes(contentKey);

        return nestedArray || nestedMatch || (!isNestedField && keyValueMatch);
      }
      if (lineContents.length === 3) {
        return (
          field.key === contentKey &&
          String(field.exampleValue) === contentValue
        );
      }

      return false;
    }) ?? null;

  return matchedField;
};

// TODO: Implement this
export const getEditorPositionByFieldId: GetPositionByFieldIdFn = (_params) => {
  return null;
};
