import { CreateProject } from './CreateProject';
import { Spinner } from '@hubql/shared-design-system';
import { FolderIcon, FolderPlusIcon } from '@heroicons/react/20/solid';
import { ProjectListCard } from './ProjectListCard';
import { useState } from 'react';
import clsx from 'clsx';
import { ProjectTabHeader } from './ProjectTabHeader';
import { ChooseHubType } from './ChooseHubType';

type Props = {
  hubsQuery: any;
  workspace: any;
};

export const PrivateProject = ({ hubsQuery, workspace }: Props) => {
  const privateProjects = hubsQuery?.data?.privateProjects?.edges?.map(
    (projectNode, index) => {
      const project = projectNode?.node;
      if (!project) return null;
      const repoName =
        project?.repositories?.edges?.[0]?.node?.github_repository_full_name;
      return (
        <ProjectListCard
          isFile={false}
          workspaceSlug={workspace.slug}
          key={project.id + index}
          id={project.id}
          name={project.name}
          createdAt={project.createdAt}
          access={project.access}
          owner={null}
          icon={<FolderIcon className="w-5 h-5" />}
        />
      );
    }
  );
  return (
    <div>
      {hubsQuery.loading ? (
        <Spinner />
      ) : (
        privateProjects?.length === 0 && (
          <div className="flex flex-col items-center p-8 gap-2 mt-8 w-max">
            <FolderPlusIcon className="w-8 h-8" />
            <div className="flex flex-col items-center mb-2">
              <span className="text-xs text-zinc-100">No Hub</span>
              <p className="text-xs text-zinc-500">No private Hubs yet</p>
            </div>
            <ChooseHubType defaultAccess={'PRIVATE'} />
          </div>
        )
      )}
      {privateProjects?.length > 0 && <ProjectTabHeader access={'PRIVATE'} />}
      <div
        className={clsx('gap-4 grid mt-4')}
        style={{
          gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
        }}
      >
        {privateProjects}
      </div>
    </div>
  );
};
