import { useMutation } from '@apollo/client';
import { CreateSuggestionDocument } from '@hubql/codegen';
import { Button, Spinner } from '@hubql/hubqlkit';
import clsx from 'clsx';
import router from 'next/router';
import { useEffect, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { v4 as uuidv4 } from 'uuid';
import { useLiveStore } from '../state/stores/live';
import { SelectType } from './SelectType';
import { usePostHog } from 'posthog-js/react';
import { useFileStore } from '../state/stores/file';

interface IsuggestInputs {
  initialKey: string;
  initialValue: string;
  initialType: string;
  setShowSuggest: (arg0: any) => void;
  fieldId: string;
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml';
}

export const SuggestInputs = ({
  initialKey,
  initialValue,
  initialType,
  setShowSuggest,
  fieldId,
  extension,
}: IsuggestInputs) => {
  const posthog = usePostHog();

  const [key, setKey] = useState<string>(initialKey);
  const [exampleValue, setExampleValue] = useState<string>(initialValue);
  const [type, setType] = useState<string>(initialType);
  const [createSuggestion, createSuggestionState] = useMutation(
    CreateSuggestionDocument
  );
  const file_id = useFileStore((state) => state.fileId);
  const commit_hash = useFileStore((state) => state.commitHash);
  const suggestion = key ? 'CHANGE_KEY' : 'CHANGE_VALUE';
  const fields = useLiveStore((state) => state.fields);

  const parentFields = fields?.filter((field) => !field.parentId);

  const suggestions = useLiveStore((state) => state.suggestions);
  const setSuggestions = useLiveStore((state) => state.setSuggestions);
  const activeEdit = useLiveStore((state) => state.activeEdit);
  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();

  const handleClick = async () => {
    if (!suggestion || !workspace || !workspaceUser) {
      return;
    }
    const uuid = uuidv4().replace(/-/gi, '');
    const id = 'sug_' + uuid;
    const suggestionType =
      initialKey !== key ? 'KEY' : initialType !== type ? 'TYPE' : 'VALUE';
    const value =
      suggestionType === 'KEY'
        ? key
        : suggestionType === 'TYPE'
        ? type
        : exampleValue;
    const newSuggestion = {
      fileFieldFileId: file_id,
      fileFieldId: fieldId,
      id: id,
      action: 'EDIT',
      value,
      fileFieldCommitHash: commit_hash,
      workspaceId: workspace.id,
      suggestedBy: workspaceUser.id,
      type: suggestionType,
    };

    //@ts-expect-error types
    setSuggestions([...suggestions, newSuggestion]);
    await createSuggestion({
      variables: {
        input: newSuggestion,
      },
      refetchQueries: ['file'],
    });
    try {
      posthog?.capture('SUGGESTION_CREATED', {
        fileFieldFileId: file_id,
        fileFieldId: fieldId,

        action: 'EDIT',
        fileFieldCommitHash: commit_hash,
        workspaceId: workspace.id,
        type: suggestionType,
      });
    } catch (error) {}
    setShowSuggest(false);
  };

  useEffect(() => {
    setKey(initialKey);
    setExampleValue(initialValue);
    setType(initialType);
  }, [fieldId, initialKey, initialType, initialValue]);

  const isInvalid =
    initialKey + initialType + initialValue === key + type + exampleValue ||
    key === '' ||
    key === undefined ||
    extension === 'prisma' ||
    extension === 'graphql' ||
    extension === 'proto'
      ? type === '' || type === undefined
      : exampleValue === '' || exampleValue === undefined
      ? true
      : false;

  const isPrisma =
    (extension === 'prisma' ||
      extension === 'graphql' ||
      extension === 'proto') &&
    'flex-row';
  const isJson = extension === 'json' && 'flex-col';
  const isXml = extension === 'xml' && 'flex-col';
  const isYAML = extension === 'yaml' && 'flex-col';

  return (
    <div className={clsx('py-2 flex gap-1', isPrisma, isXml, isJson, isYAML)}>
      <div className="grid gap-2 w-full grid-cols-1 justify-center">
        {activeEdit === 'KEY' && (
          <input
            className={clsx(
              `w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 px-2 py-1  focus:border-zinc-400 focus:outline-none invalid:ring-1`
            )}
            id="input-key"
            placeholder={initialKey}
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        )}
        {extension === 'json' && (
          <input
            className="w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1  focus:border-zinc-400 focus:outline-none ring-zinc-200 invalid:ring-1"
            id="input-value"
            placeholder={type}
            value={exampleValue}
            onChange={(e) => setExampleValue(e.target.value)}
          />
        )}
        {extension === 'xml' && (
          <input
            className="w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1  focus:border-zinc-400 focus:outline-none ring-zinc-200 invalid:ring-1"
            id="input-value"
            placeholder={type}
            value={exampleValue}
            onChange={(e) => setExampleValue(e.target.value)}
          />
        )}
        {extension === 'yaml' && (
          <input
            className="w-full rounded-md bg-zinc-700 border border-zinc-700 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1  focus:border-zinc-400 focus:outline-none ring-zinc-200 invalid:ring-1"
            id="input-value"
            placeholder={type}
            value={exampleValue}
            onChange={(e) => setExampleValue(e.target.value)}
          />
        )}
        {/* here */}
        {(extension === 'prisma' ||
          extension === 'graphql' ||
          extension === 'proto') &&
          activeEdit === 'TYPE' && (
            <SelectType
              extension={extension}
              setType={setType}
              parentFields={parentFields}
            />
          )}
      </div>
      <div className=" w-full flex justify-end gap-1">
        <Button variant="green" isDisabled={isInvalid} onClick={handleClick}>
          {createSuggestionState.loading ? (
            <div className="flex flex-row gap-2">
              <Spinner /> <span>loading</span>
            </div>
          ) : (
            'Commit'
          )}
        </Button>

        <Button
          variant="ghost"
          className="px-2 py-1"
          onClick={() => setShowSuggest(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
