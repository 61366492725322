import { LanguageConfig } from '../HubqlEditor';

import { getFieldByLineNumber, getEditorPositionByFieldId } from './line';
import { convertRawContentToField } from './converter';
import { graphql, updateSchema } from 'cm6-graphql';
import { syntaxHighlighting } from '@codemirror/language';
import { oneDarkHighlightStyle } from '@codemirror/theme-one-dark';
import { theme } from './theme';
import { buildSchema } from 'graphql';

export const languageConfig: LanguageConfig = {
  language: 'graphql',
  extensions: [
    graphql(undefined, { showErrorOnInvalidSchema: true }),
    theme,
    syntaxHighlighting(oneDarkHighlightStyle),
  ],
  getFieldByLineNumber,
  getEditorPositionByFieldId,
  convertRawContentToField,
  onContentChange: (view) => {
    const raw = view.state.doc.toString();
    if (!view.docChanged || !raw.trim().length) {
      return;
    }

    try {
      updateSchema(view.view, buildSchema(raw, { assumeValid: false }));
    } catch (e) {}
  },
};
