import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface siderbarTypes {
  showNewFile: boolean;
  setShowNewFile: (showNewFile: boolean) => void;
  reachedGuestLimit: boolean;
  setReachedGuestLimit: (reachedGuestLimit: boolean) => void;
  reachedPlanLimit: boolean;
  setReachedPlanLimit: (reachedPlanLimit: boolean) => void;
  showGuestSignUp: boolean;
  setShowGuestSignUp: (showGuestSignUp: boolean) => void;
  showUpgradeModal: boolean;
  setShowUpgradeModal: (showUpgradeModal: boolean) => void;
  showComment: boolean;
  setShowComment: (showComment: boolean) => void;
  showModel: boolean;
  setShowModel: (showModel: boolean) => void;
  showHeader: boolean;
  setShowHeader: (showHeader: boolean) => void;
  showCode: boolean;
  setShowCode: (showCode: boolean) => void;
  showVisualizationMenu: boolean;
  setshowVisualizationMenu: (showVisualizationMenu: boolean) => void;
  zen: boolean;
  setZen: (zen: boolean) => void;
  showHelper: boolean;
  setShowHelper: (showHelper: boolean) => void;
  paneLeft: number;
  setPaneLeft: (paneLeft: number) => void;
  paneCode: number;
  setPaneCode: (paneCode: number) => void;
  paneRight: number;
  setPaneRight: (paneRight: number) => void;

  isSidebarActive: boolean;
  setIsSidebarActive: (isSidebarActive: boolean) => void;
  hoverComment: boolean;
  showSidebar: boolean;
  hoverSidebar: boolean;
  toggleShowSidebar: (state: void) => void;
  toggleHoverSidebar: (state: void) => void;
  setHoverComment: (state: void) => void;
  fullSidebarWidth: number;
  setFullSidebarWidth: (width: number) => void;
}
export const useUIUtilityStore = create<siderbarTypes>()(
  devtools(
    persist(
      (set) => ({
        reachedGuestLimit: false,
        setReachedGuestLimit: (reachedGuestLimit: boolean) =>
          set((state) => ({
            ...state,
            reachedGuestLimit,
          })),
        reachedPlanLimit: false,
        setReachedPlanLimit: (reachedPlanLimit: boolean) =>
          set((state) => ({
            ...state,
            reachedPlanLimit,
          })),
        showNewFile: false,
        setShowNewFile: (showNewFile: boolean) =>
          set((state) => ({
            ...state,
            showNewFile,
          })),
        showSidebar: true,
        hoverSidebar: false,
        showComment: false,
        showGuestSignUp: false,
        setShowGuestSignUp: (showGuestSignUp: boolean) =>
          set((state) => ({
            ...state,
            showGuestSignUp,
          })),
        showUpgradeModal: false,
        setShowUpgradeModal: (showUpgradeModal: boolean) =>
          set((state) => ({
            ...state,
            showUpgradeModal,
          })),
        hoverComment: false,
        setShowComment: (showComment: boolean) =>
          set((state) => ({
            ...state,
            showComment,
          })),
        setHoverComment: () =>
          set((state) => ({
            hoverComment: !state.hoverComment,
          })),
        toggleShowSidebar: () =>
          set((state) => ({
            showSidebar: !state.showSidebar,
            hoverSidebar: false,
          })),
        toggleHoverSidebar: () =>
          set((state) => ({ hoverSidebar: !state.hoverSidebar })),
        showModel: true,
        setShowModel: (showModel: boolean) =>
          set((state) => ({
            ...state,
            showModel,
          })),
        showHeader: true,
        setShowHeader: (showHeader: boolean) =>
          set((state) => ({
            ...state,
            showHeader,
          })),
        showCode: true,
        setShowCode: (showCode: boolean) =>
          set((state) => ({
            ...state,
            showCode,
          })),
        showVisualizationMenu: true,
        setshowVisualizationMenu: (showVisualizationMenu: boolean) =>
          set((state) => ({
            ...state,
            showVisualizationMenu,
          })),
        zen: false,
        setZen: (zen: boolean) =>
          set((state) => ({
            ...state,
            zen,
          })),
        showHelper: true,
        setShowHelper: (showHelper: boolean) =>
          set((state) => ({
            ...state,
            showHelper,
          })),
        paneLeft: 340,
        setPaneLeft: (paneLeft: number) =>
          set((state) => ({
            ...state,
            paneLeft,
          })),
        paneCode: 400,
        setPaneCode: (paneCode: number) =>
          set((state) => ({
            ...state,
            paneCode,
          })),
        paneRight: 280,
        setPaneRight: (paneRight: number) =>
          set((state) => ({
            ...state,
            paneRight,
          })),
        isSidebarActive: true,
        setIsSidebarActive: (isSidebarActive: boolean) =>
          set((state) => ({
            ...state,
            isSidebarActive,
          })),
        fullSidebarWidth: 280,
        setFullSidebarWidth: (width: number) =>
          set((state) => ({
            ...state,
            fullSidebarWidth: width,
          })),
      }),
      { name: 'sidebar-store' }
    )
  )
);
