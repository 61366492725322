import { useUser } from '@clerk/nextjs';
import { LoginForm } from '../user/LoginForm';
import { Spinner } from '@hubql/shared-design-system';
import { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  WorkspaceMembershipsDocument,
  WorkspaceMembershipsQuery,
} from '@hubql/codegen';
import { useRouter } from 'next/router';
import { WorkspaceList } from '../workspaces/WorkspaceList';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { CreateWorkspace } from '../workspaces/CreateWorkspace';
import { Loading } from '@hubql/hubqlkit';

export const Home = () => {
  const { isSignedIn, isLoaded } = useUser();
  const { workspaceUser } = useWorkspaceUser();
  const router = useRouter();

  const [query, queryState] = useLazyQuery<WorkspaceMembershipsQuery>(
    WorkspaceMembershipsDocument,
    {
      // skip: !isSignedIn || !workspaceUser?.id,
      variables: {
        userId: workspaceUser?.id,
      },
      pollInterval: 2000,
    }
  );
  const { loading } = queryState;

  const routeUser = async () => {
    const result = await query();
    const slug =
      result?.data?.workspaceMembers?.edges?.[0]?.node?.workspace?.slug;
    const userOnboarded = workspaceUser?.onboardingCompletedAt;

    if (slug && userOnboarded) {
      router.push('/w/' + slug);
    } else {
      router.push('/onboard');
    }
  };
  useEffect(() => {
    if (workspaceUser && !loading) {
      routeUser();
    }
  }, [workspaceUser, loading]);

  return (
    <div className="wrapper flex-1 ml-0 overflow-auto h-screen scrollbar">
      <div className="container max-w-4xl pt-8 pb-16 mx-auto px-4">
        <div id="welcome">
          {!isLoaded ||
            (loading && (
              <div className="flex w-full justify-center flex-col items-center">
                <Loading />
              </div>
            ))}
          {!loading && isLoaded && isSignedIn && (
            <div className="flex w-full justify-center flex-col items-center">
              <CreateWorkspace label={'Create workspace'} variant="green" />

              <div className="w-full mt-4 border border-zinc-700 p-4 rounded-sm text-sm">
                <span>Your workspaces</span>
                <WorkspaceList userId={workspaceUser?.id} refetch={true} />
              </div>
            </div>
          )}
          {isLoaded && !isSignedIn && <LoginForm />}
        </div>
      </div>
    </div>
  );
};
