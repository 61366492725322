import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';

type inputSize = 'md' | 'lg' | 'sm';
type inputVariant = 'regular' | 'ghost' | 'search';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  onChange?: (e: any) => any;
  className?: string;
  inputSize?: inputSize;
  variant?: inputVariant;
  isInvalid?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  width?: string;
  id?: string;
  autocomplete?: string;
}

export const Input = ({
  label,
  value = '',
  onChange,
  placeholder,
  className,
  type,
  variant = 'regular',
  inputSize = 'md',
  isRequired = false,
  isInvalid = false,
  isDisabled = false,
  width,
  id,
  autocomplete,
  ...props
}: Props) => {
  const lgClasses = inputSize === 'lg' && 'px-2 py-1 text-sm';
  const mdClasses = inputSize === 'md' && ' px-2 py-1 text-xs';
  const smClasses = inputSize === 'sm' && ' px-1 py-1 text-xs';

  const regularClasses =
    variant === 'regular' &&
    'w-full rounded-md border border-zinc-600 bg-zinc-800 text-zinc-100 placeholder:text-zinc-400  focus:ring-1 focus:outline-none ring-zinc-200 invalid:ring-1 rounded-sm';

  const searchClasses =
    variant === 'search' &&
    'w-full rounded-sm bg-transparent text-zinc-100 placeholder:text-zinc-400 focus:outline-none ';

  const ghostClasses =
    variant === 'ghost' &&
    'w-full rounded-sm border border-transparent bg-transparent hover:bg-zinc-800 focus:bg-zinc-800 text-zinc-100 placeholder:text-zinc-500 focus:ring-1 focus:outline-none ring-zinc-200 invalid:ring-1 rounded-sm';

  const invalidClasses =
    isInvalid &&
    'w-full rounded-sm border border-red-600 bg-zinc-800 placeholder:text-red-500 text-zinc-200 focus:ring-1 focus:outline-none ring-red-600';

  const disabledClasses =
    isDisabled &&
    'w-full rounded-sm border border-zinc-700 bg-zinc-800 placeholder:text-zinc-700 text-zinc-700 focus:ring-0 focus:outline-none cursor-not-allowed';

  return (
    <div className={clsx(width)}>
      {label && (
        <div className="flex gap-1 items-center">
          <label className=" text-zinc-200">{label}</label>
          {isRequired && <span className=" text-zinc-200">*</span>}
        </div>
      )}
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={autocomplete}
        className={clsx(
          smClasses,
          mdClasses,
          lgClasses,
          label && 'mt-1',
          regularClasses,
          ghostClasses,
          searchClasses,
          invalidClasses,
          disabledClasses,
          className
        )}
        disabled={isDisabled}
        {...props}
      />
    </div>
  );
};
