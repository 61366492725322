import {
  graphQLToFields,
  jsonToFields,
  protobufToFields,
  prismaToFields,
  xmlToFields,
  yamlToFields,
} from '../index';

export const contentToFields = async (
  content: string,
  extension: string,
  restEndpoint?: string
) => {
  if (extension === 'prisma') {
    const [fields, errors] = await prismaToFields({
      fieldData: [],
      rawContent: content,
      fileId: 'test',
      workspaceId: 'test',
      url: restEndpoint,
    });

    if (errors?.fullMessage) {
      throw new Error(errors.fullMessage);
    }

    return fields;
  }
  if (extension === 'json') {
    return jsonToFields({
      jsonData: JSON.parse(content),
      fileId: 'test',
      workspaceId: 'test',
      rawFields: [],
    });
  }
  if (extension === 'xml') {
    return xmlToFields({
      xmlData: content,
      fileId: 'test',
      workspaceId: 'test',
      rawFields: [],
    });
  }
  if (extension === 'yaml') {
    return yamlToFields({
      yamlData: content,
      fileId: 'test',
      workspaceId: 'test',
      rawFields: [],
    });
  }
  if (extension === 'graphql') {
    return graphQLToFields({
      graphqlSchema: content,
      fileId: 'test',
      workspaceId: 'test',
      rawFields: [],
    });
  }
  if (extension === 'proto') {
    return protobufToFields({
      protobufSchema: content,
      fileId: 'test',
      workspaceId: 'test',
      rawFields: [],
    });
  }

  return [];
};
