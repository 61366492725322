import { useMutation } from '@apollo/client';
import { ImportFilesDocument } from '@hubql/codegen';
import { Button, Spinner } from '@hubql/hubqlkit';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const EmptyRepo = ({ productionBranch }) => {
  const router = useRouter();
  const projectId = router.query['hub'] as string;
  const [importFiles, importFileState] = useMutation(ImportFilesDocument);
  const handleClick = async () => {
    if (projectId) {
      await importFiles({
        variables: {
          projectId,
        },
      });
    }
  };
  const ListClass = 'ml-8';
  return (
    <div className="bg-zinc-800 p-8 rounded-sm w-max flex flex-col items-center gap-4 mt-8 text-xs max-w-xl">
      <p className="text-center">
        Oops, we did not find any files in this repository
      </p>
      <p className="text-center">
        We only support certain file types at the moment (JSON, GraphQL, Prisma,
        Protobuf)
      </p>
      <p className="text-center">
        If your repository was just created a few minutes ago, it might take a
        while for the files to be indexed in GitHub for Schema Visualizer to be
        able to access them.
      </p>
      <ol className="list-disc">
        <li className={ListClass}>
          Try pushing to your &quot;{productionBranch}&quot; GitHub branch
        </li>
        - or -<li className={ListClass}>Try hit refresh below</li>
      </ol>
      <Button
        className=" px-2 py-1 text-sm rounded-sm bg-green-700 hover:bg-green-800 active:bg-green-700 focus:bg-green-800 text-zinc-200 rounded-sm  "
        isLoading={importFileState.loading}
        onClick={handleClick}
      >
        Refresh
      </Button>
    </div>
  );
};
