import { useCommentStore } from '../state/stores/comments';
import { CommentRender } from './CommentRender';
import { Avatar } from '@hubql/hubqlkit';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useLiveStore } from '../state/stores/live';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useMutation } from '@apollo/client';
import {
  DeleteFileCommentDocument,
  DeleteFileCommentThreadDocument,
} from '@hubql/codegen';
import { usePostHog } from 'posthog-js/react';

export const CommentThread = () => {
  const posthog = usePostHog();

  const { workspaceUser } = useWorkspaceUser();
  const comment = useCommentStore((state) => state.currentComment);

  const { workspace } = useWorkspace();

  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setThreadId = useCommentStore((state) => state.setThreadId);
  const [deleteFileComment] = useMutation(DeleteFileCommentDocument);
  const [deleteFileCommentThread] = useMutation(
    DeleteFileCommentThreadDocument
  );
  const removeCommentById = useCommentStore((state) => state.removeCommentById);
  const deletehandler = (fileCommentId: string) => {
    removeCommentById(fileCommentId);
    deleteFileComment({
      variables: {
        id: fileCommentId,
      },
    });
    deleteFileCommentThread({
      variables: {
        id: fileCommentId,
      },
      refetchQueries: ['fileComments'],
    });
    try {
      posthog?.capture('FILE_COMMENT_DELETED', {
        workspaceId: workspace?.id,
        fileCommentId,
      });
    } catch (error) {}
    setThreadId(null);
    setSelectedFieldId(null);
  };
  if (!comment) return null;
  const date = new Date(comment.createdAt).toLocaleString();
  const isYou = workspaceUser?.id === comment.createdBy;
  const createdBy = comment.createdBy;
  const user = workspace?.members?.find(
    (member) => member.user.id === createdBy
  )?.user;

  return (
    <div
      key={comment.id}
      className={`comment-item relative pt-2 px-0.5 hover:bg-zinc-800 border-zinc-800 border-b pb-2 mb-2`}
    >
      {isYou && (
        <TrashIcon
          className="absolute top-2 right-2 cursor-pointer fill-zinc-500 w-3 h-3"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Are you sure to delete this comment?')) {
              deletehandler(comment.id);
            }
          }}
        />
      )}
      <div className="flex flex-col items-start px-0.5">
        <div className="flex flex-row">
          <Avatar
            size="md"
            name={user?.firstName}
            src={user?.picture}
            date={date}
            isYou={isYou && ' (You)'}
          />
        </div>
      </div>
      <div className="my-1">
        <div className="ml-8 my-1 max-h-[200px] h-fit overflow-x-hidden overflow-y-auto scrollbar">
          <CommentRender comment={comment.comment} />
        </div>
      </div>
    </div>
  );
};
