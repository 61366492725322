import { FC, useEffect } from 'react';
import { useReactFlow } from 'reactflow';
import { useLiveStore } from '@hubql/app-core/lib/state/stores/live';
import { Cursor } from '@hubql/shared-design-system';
interface LiveCursorProps {
  cursorPos: { x: number; y: number };
}
export const LiveCursor: FC<LiveCursorProps> = ({ cursorPos }) => {
  const reactFlowInstance = useReactFlow();
  const {
    setCursor,
    liveblocks: { others },
  } = useLiveStore();

  useEffect(() => {
    const position = reactFlowInstance.screenToFlowPosition({
      x: cursorPos.x,
      y: cursorPos.y,
    });

    setCursor({ x: Math.round(position.x), y: Math.round(position.y) });
  }, [cursorPos]);

  return (
    <>
      {others.map((other) => {
        if (other.presence.cursor == null) {
          return null;
        }
        const otherViewPort = reactFlowInstance.getViewport();

        let xPos =
          other.presence.cursor.x * otherViewPort.zoom + otherViewPort.x;

        let yPos =
          other.presence.cursor.y * otherViewPort.zoom + otherViewPort.y;

        return (
          <Cursor
            name={other?.info?.name}
            color={other?.presence?.activeFieldColor ?? 'white'}
            key={other.id}
            x={xPos}
            y={yPos}
          />
        );
      })}
    </>
  );
};
