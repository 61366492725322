export const Loading = () => {
  return (
    <div className="relative w-screen h-screen bg-zinc-900 ">
      <div className="nprogressClass">
        <div className="splash">
          <div className="logoWrapper">
            <div className="logoContainer">
              <div className="logoRelative">
                <div className="logo" />
                <div className="ping" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
