import {
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';
import {
  ContextMenuContent,
  ContextMenuItem,
  SidebarIcon,
  ZenIcon,
} from '@hubql/hubqlkit';
import { useLiveStore } from '../state/stores/live';
import { useFileStore } from '../state/stores/file';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { createNote } from '../comments/Note';
import { useReactFlow } from 'reactflow';
import { useMutation } from '@apollo/client';
import { CreateFileCommentDocument } from '@hubql/codegen';
import { usePostHog } from 'posthog-js/react';

interface IProps {
  location: any;
}

export const CanvaContextMenu = ({ location }: IProps) => {
  const posthog = usePostHog();
  const setShowHeader = useUIUtilityStore((state) => state.setShowHeader);
  const showHeader = useUIUtilityStore((state) => state.showHeader);

  const setShowCode = useUIUtilityStore((state) => state.setShowCode);
  const showCode = useUIUtilityStore((state) => state.showCode);

  const setShowModel = useUIUtilityStore((state) => state.setShowModel);
  const showModel = useUIUtilityStore((state) => state.showModel);

  const setshowVisualizationMenu = useUIUtilityStore(
    (state) => state.setshowVisualizationMenu
  );
  const showVisualizationMenu = useUIUtilityStore(
    (state) => state.showVisualizationMenu
  );

  const setShowComment = useUIUtilityStore((state) => state.setShowComment);
  const showComment = useUIUtilityStore((state) => state.showComment);

  const zen = useUIUtilityStore((state) => state.zen);
  const setZen = useUIUtilityStore((state) => state.setZen);

  const showHelper = useUIUtilityStore((state) => state.showHelper);
  const setShowHelper = useUIUtilityStore((state) => state.setShowHelper);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setObjectKey = useFileStore((state) => state.setObjectKey);

  const showAllToggle = () => {
    setZen(!zen);
    setShowHeader(zen);
    setShowCode(zen);
    setShowModel(zen);
    setShowComment(zen);
    setshowVisualizationMenu(zen);
    try {
      posthog?.capture('TOGGLE_SHOW_ALL', {});
    } catch (error) {}
  };

  const handleShowHeader = () => {
    try {
      posthog?.capture('TOGGLE_SHOW_HEADER', {
        showHeader: !showHeader,
      });
    } catch (error) {}
    setShowHeader(!showHeader);
  };

  const handleShowVisualizationMenu = () => {
    try {
      posthog?.capture('TOGGLE_SHOW_VISUALIZATION_MENU', {
        showVisualizationMenu: !showVisualizationMenu,
      });
    } catch (error) {}
    setshowVisualizationMenu(!showVisualizationMenu);
  };

  const handleShowCode = () => {
    try {
      posthog?.capture('TOGGLE_SHOW_CODE', {
        showCode: !showCode,
      });
    } catch (error) {}
    setShowCode(!showCode);
  };

  const handleLeftMenu = () => {
    try {
      posthog?.capture('TOGGLE_SHOW_MODEL', {
        showModel: !showModel,
      });
    } catch (error) {}
    setShowModel(!showModel);
  };

  const handleShowComment = () => {
    try {
      posthog?.capture('TOGGLE_SHOW_COMMENT', {
        showComment: !showComment,
      });
    } catch (error) {}
    setShowComment(!showComment);
  };

  const handleShowHelper = () => {
    try {
      posthog?.capture('TOGGLE_SHOW_HELPER', {
        showHelper: !showHelper,
      });
    } catch (error) {}
    setShowHelper(!showHelper);
  };

  const openComment = () => {
    setShowComment(true);
    setSelectedFieldId('__GENERAL__');
    setObjectKey(null);
  };
  const notes = useLiveStore((state) => state.notes);
  const setNotes = useLiveStore((state) => state.setNotes);
  const fileId = useFileStore((state) => state.fileId);
  const { workspace } = useWorkspace();
  const workspaceId = workspace?.id;
  const { workspaceUser } = useWorkspaceUser();
  const userId = workspaceUser?.id;
  const { project } = useReactFlow();
  const [createFileComment, createFileCommentState] = useMutation(
    CreateFileCommentDocument
  );
  const addNote = () => {
    const reactFlowWrapper = document.getElementById('file-visualization');
    const reactFlowBounds = reactFlowWrapper?.getBoundingClientRect();
    // use `project` to get the right coordinates
    const position = project({
      x: location.x - (reactFlowBounds?.left ?? 0),
      y: location.y - (reactFlowBounds?.top ?? 0),
    });

    console.log('position', position);
    console.log('reactFlowBounds', reactFlowBounds);
    console.log('location', location);
    if (!workspaceId || !userId || !fileId) {
      return;
    }

    const { newNotes } = createNote({
      notes,
      fileId,
      workspaceId,
      userId,
      x: position.x,
      y: position.y,
      createFileComment,
      posthog,
    });

    setNotes(newNotes);
  };
  return (
    <ContextMenuContent>
      <ContextMenuItem onClick={openComment}>
        <PlusIcon className="w-4 h-4 fill-zinc-200" /> Add general comment
      </ContextMenuItem>
      <ContextMenuItem onClick={addNote}>
        <PlusIcon className="w-4 h-4 fill-zinc-200" /> Add note
      </ContextMenuItem>
      <ContextMenuItem onClick={handleShowCode}>
        {showCode ? (
          <EyeIcon className="w-4 h-4 fill-zinc-200" />
        ) : (
          <EyeSlashIcon className="w-4 h-4 fill-zinc-400" />
        )}
        Show code
      </ContextMenuItem>
      <ContextMenuItem onClick={handleShowVisualizationMenu}>
        {showVisualizationMenu ? (
          <EyeIcon className="w-4 h-4 fill-zinc-200" />
        ) : (
          <EyeSlashIcon className="w-4 h-4 fill-zinc-400" />
        )}{' '}
        Show visualization bar
      </ContextMenuItem>
      <ContextMenuItem onClick={handleShowHeader}>
        {showHeader ? (
          <SidebarIcon
            className="w-4 h-4 fill-zinc-200 -rotate-90"
            variant="show"
          />
        ) : (
          <SidebarIcon
            className="w-4 h-4 fill-zinc-200  -rotate-90"
            variant="hide"
          />
        )}
        Show header
      </ContextMenuItem>
      <ContextMenuItem onClick={handleLeftMenu}>
        {showModel ? (
          <SidebarIcon
            className="w-4 h-4 fill-zinc-200 rotate-180"
            variant="show"
          />
        ) : (
          <SidebarIcon
            className="w-4 h-4 fill-zinc-200 rotate-180"
            variant="hide"
          />
        )}
        Show left menu
      </ContextMenuItem>
      <ContextMenuItem onClick={handleShowComment}>
        {showComment ? (
          <SidebarIcon className="w-4 h-4 fill-zinc-200" variant="show" />
        ) : (
          <SidebarIcon className="w-4 h-4 fill-zinc-200" variant="hide" />
        )}
        Show comment
      </ContextMenuItem>
      <ContextMenuItem onClick={showAllToggle}>
        {' '}
        {zen ? (
          <ZenIcon className="w-4 h-4 fill-zinc-200" variant="show" />
        ) : (
          <ZenIcon className="w-4 h-4 fill-zinc-200" variant="hide" />
        )}
        Show ui
      </ContextMenuItem>
      <ContextMenuItem onClick={handleShowHelper}>
        {showHelper ? (
          <QuestionMarkCircleIcon className="w-4 h-4 fill-zinc-200" />
        ) : (
          <QuestionMarkCircleIcon className="w-4 h-4 fill-zinc-300" />
        )}
        Show Helper
      </ContextMenuItem>
    </ContextMenuContent>
  );
};
