import { useSandpack } from '@codesandbox/sandpack-react';

import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { FileVisualization } from './FileVisualization';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useFileStore } from '../state/stores/file';
import { LiveField, useLiveStore } from '../state/stores/live';
import { getFile } from '../util/getFile';
import { SplitPane } from './SplitPane';
import React from 'react';
import { Sidebar } from '../sidebar/Sidebar';
import { CommentSidebar } from '../sidebar/CommentSidebar';
import { FileChanges } from './FileChanges';
import { FieldTag, FileComment, FileQuery, Suggestion } from '@hubql/codegen';
import { FileExtension, HubqlEditor } from './editors/HubqlEditor';
import { getLanguageConfig } from './editors';
import { useProjectStore } from '../state/stores/project';
import { Loading } from '@hubql/hubqlkit';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import type { SandpackFile } from '@codesandbox/sandpack-react/types';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';

type FileCollaborationProps = {
  data: FileQuery;
};
export const FileCollaboration: React.FC<FileCollaborationProps> = ({
  data,
}) => {
  const router = useRouter();
  const { workspaceUser } = useWorkspaceUser();
  const [loading, setLoading] = useState(true);
  const [hasInit, setHasInit] = useState(false);
  const hubId = router.query['hub'] as string;
  const commitHash =
    data?.file?.fields?.edges?.[0]?.node?.commitHash ??
    (router.query['ref'] as string) ??
    'HEAD';
  const setFileId = useFileStore((state) => state.setFileId);
  const setCommitHash = useFileStore((state) => state.setCommitHash);
  const fileId =
    // useFileStore((state) => state.fileId) ??
    data?.file?.id ?? (router.query['file'] as string);
  const roomId = fileId + '_' + commitHash;

  const setFileName = useFileStore((state) => state.setFileName);
  const setExtension = useFileStore((state) => state.setExtension);
  const extension =
    useFileStore((state) => state.extension) ??
    (data?.file?.extension as FileExtension);
  const fileName = useFileStore((state) => state.fileName);
  const setInitialFields = useFileStore((state) => state.setInitialFields);
  const setIsGitHubProject = useFileStore((state) => state.setIsGitHubProject);
  const setProjectId = useFileStore((state) => state.setProjectId);
  const setProjectName = useFileStore((state) => state.setProjectName);
  const setHasProjectAccess = useFileStore(
    (state) => state.setHasProjectAccess
  );
  const setSuggestions = useLiveStore((state) => state.setSuggestions);
  const setRawContent = useLiveStore((state) => state.setRawContent);
  const setNotes = useLiveStore((state) => state.setNotes);
  const setInitialContent = useFileStore((state) => state.setInitialContent);
  const setFieldTags = useFileStore((state) => state.setFieldTags);
  const rawContent = useLiveStore((state) => state.rawContent);
  const { sandpack } = useSandpack();
  const openDiffEditor = useFileStore((state) => state.openDiffEditor);
  const setProject = useProjectStore((state) => state.setProject);
  const resetAllStates = useLiveStore((state) => state.reset);
  const setShowNewFile = useUIUtilityStore((state) => state.setShowNewFile);
  const {
    liveblocks: { enterRoom, leaveRoom, isStorageLoading, status },
  } = useLiveStore();

  useEffect(() => {
    if (roomId) {
      resetAllStates(); // before entering a new room, reset all states
      enterRoom(roomId);
      return () => {
        // @ts-expect-error
        leaveRoom(roomId);
      };
    }

    return;
  }, [roomId, enterRoom, leaveRoom]);

  const { workspace } = useWorkspace();
  const workspaceId = workspace?.id;

  const init = async () => {
    const project = data?.file?.projects?.edges?.[0]?.node?.project;
    const projectId = project?.id;
    const projectName = project?.name;

    if (projectId) {
      setProjectId(projectId);
    }
    if (projectName) {
      setProjectName(projectName);
    }

    const memberHasAccessToProject = project?.access?.edges?.find(
      (member) => member?.node?.userId === workspaceUser?.id
    );
    const memberHasAccessToWorkspace = workspace?.members?.find(
      (member) => member?.user?.id === workspaceUser?.id
    );

    const hasProjectAccess =
      memberHasAccessToProject || memberHasAccessToWorkspace;

    setHasProjectAccess(!!hasProjectAccess);

    const isGitHubProject =
      !!data?.file?.projects?.edges?.[0]?.node?.project?.repositories
        ?.edges?.[0]?.node?.githubRepositoryId;
    setIsGitHubProject(isGitHubProject);

    const fileExtension = data?.file?.extension;
    const fileName = data?.file?.fileName;
    const fileId = data?.file?.id;

    if (!fileExtension || !fileName || !workspaceId || !fileId) {
      return;
    }

    const fieldData =
      data?.file?.fields?.edges?.map((fieldNode) => {
        const field = fieldNode?.node;
        if (!field) return null;

        return {
          id: field.id,
          key: field.key,
          type: field.type,
          exampleValue: field.exampleValue,
          parentId: field.parentId,
          workspaceId,
          fileId: fileId,
          line: field.line,
          meta: field.meta,
          positionX: field.positionX,
          positionY: field.positionY,
        };
      }) ?? [];

    const fileString = await getFile({
      workspaceId,
      fileId,
      fileName,
      extension: fileExtension,
      commitHash,
      fieldData,
      signedUrl: data?.file?.signedUrl,
    });

    if (!fileString) {
      return;
    }

    const newFiles: Record<string, SandpackFile> = {
      [fileName + '.' + extension]: {
        code: fileString,
        active: true,
      },
    };

    // TODO: we might want to reset files here
    sandpack.addFile(newFiles);
    sandpack.setActiveFile(data?.file?.fileName + '.' + fileExtension);
    sandpack.updateCurrentFile(fileString);

    setFileName(data?.file?.fileName ?? '');
    if (data?.file?.extension) {
      setExtension(
        data.file.extension as
          | 'json'
          | 'prisma'
          | 'graphql'
          | 'proto'
          | 'xml'
          | 'yaml'
      );
    }

    const notes = data?.file?.notes?.edges?.map((edge) => edge?.node) ?? [];

    setCommitHash(commitHash);
    setFileId(fileId);
    setRawContent(fileString);
    setInitialContent(fileString);
    setInitialFields(fieldData as unknown as LiveField[]);
    setNotes(notes as unknown as FileComment[]);

    const suggestions: Suggestion[] = data?.file?.suggestions?.edges
      ?.map((edge) => edge?.node)
      .filter(Boolean) as Suggestion[];
    setSuggestions(suggestions);

    const fieldTags =
      data?.file?.tags?.edges?.map((edge) => edge?.node as FieldTag) ?? [];
    setFieldTags(fieldTags);
    setLoading(false);
  };

  useEffect(() => {
    if (workspaceId && status === 'connected' && !hasInit) {
      setHasInit(true);
      if (data) {
        init();
      }
      if (hubId && !data) {
        //@ts-expect-error
        setProject({ id: hubId });
        setShowNewFile(true);
      }
      setLoading(false);
    }
  }, [data, workspaceId, status]);

  const editor = useMemo(() => {
    if (!extension) {
      return (
        <HubqlEditor
          fileId={fileId}
          workspaceId={workspace?.id as string}
          isReadOnly={data?.file?.userAccess !== 'EDIT'}
        />
      );
    }

    const workspaceId = workspace?.id as string;
    const languageConfig = getLanguageConfig(extension);

    return (
      <HubqlEditor
        fileId={fileId}
        workspaceId={workspaceId}
        languageConfig={languageConfig}
        isReadOnly={data?.file?.userAccess !== 'EDIT'}
      />
    );
  }, [extension]);

  if (!data?.file && !loading && !hubId) return <p>Access denied</p>;
  if (loading || isStorageLoading || !workspaceId || !hasInit)
    return <Loading />;

  const noFile = !extension;
  const hasContent = rawContent && rawContent !== '';

  return (
    <div className="relative w-full h-full bg-zinc-900">
      <SplitPane
        pane1={<Sidebar />}
        pane2={hasContent && editor}
        pane3={
          noFile ? (
            <div></div>
          ) : (
            <FileVisualization
              fileId={fileId}
              commitHash={commitHash}
              workspaceId={workspace?.id}
            />
          )
        }
        pane4={<CommentSidebar />}
      />
      {openDiffEditor && <FileChanges />}
    </div>
  );
};
