import { MutationResult, useMutation, useQuery } from '@apollo/client';
import {
  CreateWorkspaceDocument,
  CreateWorkspaceMutation,
  UserBookmarksDocument,
  UserBookmarksQuery,
  WorkspaceBySlugDocument,
  WorkspaceBySlugQuery,
} from '@hubql/codegen';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { useBookmarkStore } from '../../state/stores/bookmarks';
import { useWorkspaceUser } from '../user/WorkspaceUserContext';
import { v4 as uuidv4 } from 'uuid';
import { usePostHog } from 'posthog-js/react';

export type Workspace = {
  id: string;
  name: string;
  slug: string;
  usage: WorkspaceBySlugQuery['workspaceBySlug']['usage'];
  members: WorkspaceBySlugQuery['workspaceBySlug']['members']['edges'][number]['node'][];
  activeSubscription: WorkspaceBySlugQuery['workspaceBySlug']['subscriptions']['edges'][number]['node'];
  subscriptions: WorkspaceBySlugQuery['workspaceBySlug']['subscriptions']['edges'][number]['node'][];
};

export const WorkspaceProvider = ({
  children,
  slugProp,
  workspaceData,
}: {
  children: JSX.Element;
  slugProp?: string;
  workspaceData?: any;
}) => {
  const posthog = usePostHog();
  const router = useRouter();
  const slug = router.query['workspaceSlug'] ?? slugProp;
  const [workspace, setWorkspace] = useState<Workspace | undefined>(undefined);
  const { workspaceUser } = useWorkspaceUser();
  const [createWorkspaceMutation, createWorkspaceState] = useMutation(
    CreateWorkspaceDocument
  );

  const createWorkspace = async (name?: string) => {
    const uuid = uuidv4().replace(/-/gi, '');
    const workspaceId = 'wsp_' + uuid;
    const memberUuid = uuidv4().replace(/-/gi, '');
    const memberId = 'wspm_' + memberUuid;
    const result = await createWorkspaceMutation({
      variables: {
        input: {
          id: workspaceId,
          name: name ?? 'Untitled',
          slug: workspaceId,
          createdBy: workspaceUser?.id as string,
          members: {
            data: [
              {
                workspaceId,
                id: memberId,
                role: 'OWNER',
                defaultAccess: 'EDIT',
                userId: workspaceUser?.id as string,
              },
            ],
          },
        },
      },
      refetchQueries: ['workspaces', 'workspaceMemberships'],
    });
    if (
      result?.data?.createWorkspace.__typename === 'CreateWorkspaceSuccess' &&
      result?.data?.createWorkspace?.data?.id
    ) {
      const newId = result?.data?.createWorkspace?.data?.id;
      try {
        posthog?.capture('WORKSPACE_CREATED', {
          workspaceId: newId,
        });
      } catch (error) {}
      return newId;
    } else {
      return;
    }
  };
  const { data } = useQuery<WorkspaceBySlugQuery>(WorkspaceBySlugDocument, {
    skip: workspaceData || !slug || slug === 'undefined',
    variables: {
      slug,
    },
  });
  const bookmarkQuery = useQuery<UserBookmarksQuery>(UserBookmarksDocument, {
    skip: !workspace?.id || !workspaceUser?.id,
    variables: {
      workspaceId: workspace?.id,
      userId: workspaceUser?.id,
    },
  });
  const setBookmarks = useBookmarkStore((state) => state.setBookmarks);

  useEffect(() => {
    if (!bookmarkQuery?.loading && bookmarkQuery?.data) {
      setBookmarks(bookmarkQuery.data.userBookmarks.edges.map((e) => e?.node));
    }
  }, [bookmarkQuery?.loading]);
  useEffect(() => {
    if (slug === 'undefined') {
      router.push('/');
    }
  }, [slug]);
  useEffect(() => {
    if (!data && !workspaceData) return;
    const workspace = workspaceData ?? data?.workspaceBySlug;
    if (workspace) {
      setWorkspace({
        ...workspace,
        usage: workspace?.usage,
        subscriptions: workspace.subscriptions.edges.map((e) => e?.node),
        activeSubscription: workspace.subscriptions.edges.find(
          (e) => e.node.status === 'ACTIVE' || e.node.status === 'TRIALING'
        )?.node,
        members: workspace.members.edges.map((e) => e?.node),
      });
      try {
        posthog?.group('company', workspace.id);
      } catch (error) {
        console.error('Failed to init analytics', error);
      }
    } else {
      if (router.pathname !== '/' && !router.pathname.includes('/file/')) {
        router.push('/');
      }
    }
  }, [data, workspaceData]);

  return (
    <WorkspaceContext.Provider
      value={{ workspace, createWorkspace, createWorkspaceState }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

const WorkspaceContext = createContext<{
  workspace: Workspace | undefined;
  createWorkspace: (name?: string) => Promise<string | undefined>;
  createWorkspaceState: MutationResult<CreateWorkspaceMutation> | undefined;
}>({
  workspace: undefined,
  createWorkspace: async (name?: string) => undefined,
  createWorkspaceState: undefined,
});

export const useWorkspace = () => useContext(WorkspaceContext);
