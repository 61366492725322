import { Input, SourceIcon } from '@hubql/hubqlkit';

export const BranchSwitch = () => {
  const branches = [
    'hubql-a66asq-testing-url-set',
    'hubql-aasdjh-build-fix-improvement',
  ];
  return (
    // TODO - list all branchs, search branches and create branch.
    <div>
      <Input
        className="bg-zinc-800 text-zinc-100 placeholder:text-zinc-400 pl-2 py-1 focus:ring-1 focus:outline-none focus:ring-zinc-200"
        id="input-value"
        placeholder="Search branch"
        inputSize="sm"
      />
      <ul className="mt-2">
        {branches.map((item) => (
          <li
            key={item}
            className="px-1 py-1 cursor-pointer hover:bg-zinc-800  flex gap-1 items-center"
          >
            <SourceIcon /> <span className="truncate">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
