import { useMutation, useQuery } from '@apollo/client';
import {
  CreateProjectFileDocument,
  ProjectFileListDocument,
  ProjectFileListQuery,
} from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { EmptyProjectFileList } from './EmptyProjectFileList';
import { ProjectFile } from './ProjectFile';
import { useProjectStore } from '../state/stores/project';
import { EmptyRepo } from './EmptyRepo';
import { Spinner } from '@hubql/shared-design-system';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Button, Dialog, DialogContent, DialogTrigger } from '@hubql/hubqlkit';
import { useFileStore } from '../state/stores/file';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import { DragEventHandler, useEffect, useState } from 'react';
import { ProjectFileListLoading } from './ProjectFileLoading';
import { createFileProcess } from '../files/CreateFile';
import { usePostHog } from 'posthog-js/react';
import { toast } from 'react-toastify';
import { FileData, getFileContent } from '../util/getFileContent';
import { useUIUtilityStore } from '../state/stores/toggleSidebarStore';

type ProjectFileListProps = {
  projectIdProp?: string;
};

export const ProjectFileList = (props: ProjectFileListProps) => {
  const router = useRouter();

  const project = useProjectStore((state) => state.project);
  const fileProjectId = useFileStore((state) => state.projectId);
  const posthog = usePostHog();
  const projectId =
    (router.query['hub'] as string) ?? fileProjectId ?? project?.id;

  const [newFiles, setNewFiles] = useState<FileData[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [fileContainerHeight, setFileContainerHeight] = useState(0);
  const [createProjectFile, createProjectFileState] = useMutation(
    CreateProjectFileDocument
  );
  const setShowGuestSignUp = useUIUtilityStore(
    (state) => state.setShowGuestSignUp
  );
  const setShowUpgradeModal = useUIUtilityStore(
    (state) => state.setShowUpgradeModal
  );

  const { workspace } = useWorkspace();
  const { workspaceUser } = useWorkspaceUser();
  const [isLoading, setIsLoading] = useState(false);

  const { loading, error, data, startPolling, stopPolling } =
    useQuery<ProjectFileListQuery>(ProjectFileListDocument, {
      skip: !projectId,
      variables: {
        projectId: projectId,
      },
    });

  const hasRepo = project && project?.repositories?.edges?.length > 0;
  const hasFiles = data && data?.projectFiles?.edges?.length > 0;

  useEffect(() => {
    if (!loading) {
      const height = document?.getElementById('files')?.clientHeight;
      if (height && height > fileContainerHeight)
        setFileContainerHeight(height);
    }
  }, [loading, newFiles]);

  useEffect(() => {
    if (data && hasRepo && !hasFiles) {
      startPolling(1000);
    }
    if (data && hasRepo && hasFiles) {
      stopPolling();
    }
  }, [hasRepo, hasFiles, data]);

  useEffect(() => {
    async function createFiles() {
      if (newFiles.length > 0) {
        setIsDragging(false);
        setIsDialogOpen(false);
        setIsFilesUploading(true);
        const errorFiles: string[] = [];

        try {
          await Promise.all(
            newFiles.map(async (file) => {
              try {
                const result = await createFileProcess({
                  fileContent: file.fileContent,
                  fullFileName: file.fileName + '.' + file.extension,
                  extension: file.extension,
                  workspaceId: workspace?.id ?? '',
                  projectId,
                  createProjectFile,
                  fileName: file.fileName,
                  posthog,
                  source: 'UPLOAD',
                  showToast: false,
                  isRedirect: false,
                  setShowGuestSignUp,
                  setShowUpgradeModal,
                  workspaceUser,
                  setIsLoading,
                });

                if (!result) {
                  errorFiles.push(`${file.fileName}.${file.extension}`);
                }
              } catch (error) {
                // Handle specific errors if needed
                console.error(
                  `Error uploading file ${file.fileName}.${file.extension}:`,
                  error
                );
                errorFiles.push(`${file.fileName}.${file.extension}`);
              }
            })
          );
        } finally {
          setIsFilesUploading(false);
          setNewFiles([]);
          if (errorFiles.length === 0) {
            toast.success('New files created');
          } else {
            toast.error(`File(s) ${errorFiles.join(', ')} failed to upload`);
          }
          setIsLoading(false);
        }
      }
    }

    createFiles();
  }, [newFiles]);

  if (loading || !workspace || isLoading) return <Spinner />;

  const files = data?.projectFiles?.edges?.map((fileNode) => {
    const file = fileNode?.node?.file;
    return (
      file && <ProjectFile key={'f' + file.id} hasRepo={hasRepo} file={file} />
    );
  });

  const isEmpty = !hasRepo && !hasFiles;
  const defaultRepo = project?.repositories?.edges?.[0]?.node;

  const handleDragging: DragEventHandler = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  function handleDragLeave() {
    setIsDragging(false);
  }

  const handleDrop: DragEventHandler = (e) => {
    if (!isDialogOpen) {
      e.preventDefault();
      setIsDragging(false);

      if (e.dataTransfer.files.length > 0) {
        Array.from(e.dataTransfer.files as FileList).forEach((file) => {
          getFileContent(file, (fileData) =>
            setNewFiles((prev: FileData[]) => [...prev, fileData])
          );
        });
      }
    }
  };

  if (isEmpty)
    return (
      <EmptyProjectFileList projectId={projectId} setFiles={setNewFiles} />
    );
  if (hasRepo && !hasFiles)
    return <EmptyRepo productionBranch={defaultRepo?.productionBranch} />;

  return (
    <div
      id="files"
      className={`flex flex-col items-start w-full gap-4 pb-8 overflow-auto scrollbar ${
        isDragging
          ? `border-dashed border-green-700 border rounded-md h-[200px] items-center justify-center mt-8`
          : ''
      }`}
      onDragEnter={handleDragging}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragging}
      onDrop={handleDrop}
    >
      {isDragging && !isFilesUploading && !isDialogOpen ? (
        <>
          <h3 className="text-md">Drop your file(s) here</h3>
          <span className="text-xs text-zinc-400">
            We currently only support JSON, XML, Prisma, Protobuf,
            OpenAPI(.yaml) or GraphQL files
          </span>
        </>
      ) : (
        <>
          {defaultRepo && (
            <div className="flex flex-col w-full p-4 mt-4 text-xs border rounded-sm border-zinc-700">
              <span className="text-zinc-50">This Hub is connected</span>
              <span className="text-zinc-400">
                Provider:
                {defaultRepo.provider}
              </span>
              <span className="text-zinc-400">
                Repository:
                {defaultRepo.githubRepositoryFullName}
              </span>
              <span className="text-zinc-400">
                Branch:{defaultRepo.productionBranch}
              </span>
            </div>
          )}
          <div className="flex flex-col w-full px-4 pb-4 text-xs">
            <div className="relative grid items-center justify-between w-full grid-cols-12 gap-2 py-2 mt-2 text-xs font-bold border-b text-zinc-300 border-zinc-700">
              <div className="flex items-center w-3/12 col-span-11 gap-2 pl-2">
                <span className="truncate">Name</span>
              </div>
              <div className="flex justify-end col-span-1">
                {!hasRepo && (
                  <>
                    <Button
                      variant="green"
                      onClick={() => setIsDialogOpen((state) => !state)}
                    >
                      New
                      <PlusIcon className="w-4 h-4" />
                    </Button>
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <DialogContent
                        className={
                          'w-11/12 h-5/6 p-0 bg-zinc-900 font-mono max-w-none overflow-y-auto scrollbar'
                        }
                      >
                        <EmptyProjectFileList
                          projectId={projectId}
                          setFiles={setNewFiles}
                        />
                      </DialogContent>
                    </Dialog>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full mb-4 divide-y divide-solid divide-zinc-700 ">
              {isFilesUploading &&
                newFiles.map((_, i) => <ProjectFileListLoading key={i} />)}
              {files}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
