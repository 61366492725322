import { Tooltip } from '@hubql/hubqlkit';
import clsx from 'clsx';

export const ToolButton = ({
  children,
  onClick,
  variant,
  className,
  size,
  tooltip,
}: {
  children?: any;
  onClick?: any;
  variant?: any;
  className?: any;
  size?: any;
  tooltip?: string;
}) => {
  const variantStyle = variant === 'active' && 'bg-zinc-600';

  return (
    <Tooltip message={tooltip} id={`tooltip-${tooltip}`}>
      <button
        onClick={onClick}
        className={clsx(
          className,
          variantStyle,
          'h-[24px] min-w-[24px] flex items-center justify-center px-1 text-zinc-400 hover:bg-zinc-700 rounded-none text-left w-full '
        )}
      >
        {children}
      </button>
    </Tooltip>
  );
};
