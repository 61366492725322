import { CreateProject } from './CreateProject';
import { Spinner } from '@hubql/shared-design-system';
import { BuildingOfficeIcon, FolderPlusIcon } from '@heroicons/react/20/solid';
import { ProjectListCard } from '@hubql/app-core';
import { ProjectTabHeader } from './ProjectTabHeader';
import { useState } from 'react';
import clsx from 'clsx';

type Props = {
  hubsQuery: any;
  workspace: any;
};

export const TeamspaceProject = ({ hubsQuery, workspace }: Props) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const projects = hubsQuery?.data?.workspaceProjects?.edges?.map(
    (projectNode, index) => {
      const project = projectNode?.node;
      if (!project) return null;
      const repoName =
        project?.repositories?.edges?.[0]?.node?.githubRepositoryFullName;
      return (
        <ProjectListCard
          isFile={false}
          workspaceSlug={workspace.slug}
          key={project.id + index}
          id={project.id}
          name={project.name}
          createdAt={project.createdAt}
          access={[]}
          owner={null}
          icon={<BuildingOfficeIcon className="w-5 h-5" />}
        />
      );
    }
  );
  return (
    <div>
      {hubsQuery.loading ? (
        <Spinner />
      ) : (
        projects?.length === 0 && (
          <div className="flex flex-col items-center p-8 gap-2 mt-8">
            <FolderPlusIcon className="w-8 h-8" />
            <div className="flex flex-col items-center mb-2">
              <span className="text-xs text-zinc-100">No Hub</span>
              <p className="text-xs text-zinc-500">No teamspace Hubs yet</p>
            </div>
            <CreateProject
              size={'md'}
              justify={'center'}
              defaultAccess={'WORKSPACE'}
              label={'New Hub'}
              variant="green"
            />
          </div>
        )
      )}
      {projects?.length > 0 && <ProjectTabHeader access={'WORKSPACE'} />}
      <div
        className={clsx('gap-4 grid mt-8')}
        style={{
          gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
        }}
      >
        {projects}
      </div>
    </div>
  );
};
