import { CustomEvents } from '../projects/CustomEvents';
import { useProjectStore } from '../state/stores/project';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useRouter } from 'next/router';
import { useFileStore } from '../state/stores/file';

export const APITab = () => {
  const { workspace } = useWorkspace();
  const project = useProjectStore((state) => state.project);
  const fileProjectId = useFileStore((state) => state.projectId);
  const router = useRouter();
  const hubId = (router.query['hub'] as string) ?? fileProjectId ?? project?.id;
  if (!workspace) return null;
  return (
    <div className="mt-0 text-zinc-50 pl-0 pr-10">
      <CustomEvents
        projectId={hubId}
        workspace={workspace}
        showSendPayload={false}
      />
    </div>
  );
};
