import MagicBell, {
  FloatingNotificationInbox,
} from '@magicbell/magicbell-react';
import { useEffect, useState } from 'react';
import { useWorkspaceUser } from '../context/user/WorkspaceUserContext';
import useSWR from 'swr';
import { Button, Tooltip } from '@hubql/hubqlkit';
import { BellIcon } from '@heroicons/react/20/solid';

const MAGICBELL_APIKEY = process.env['NEXT_PUBLIC_MAGICBELL_PUBLIC_KEY'];

export const Notifications = () => {
  const { workspaceUser } = useWorkspaceUser();
  const [magicBellUserHMAC, setMagicBellUserHMAC] = useState();
  const shouldFetch =
    typeof window !== 'undefined' &&
    MAGICBELL_APIKEY &&
    workspaceUser?.id &&
    workspaceUser?.isGuest === false;
  const { data, isLoading } = useSWR(
    shouldFetch ? '/rest/notification-auth' : null
  );

  useEffect(() => {
    try {
      if (data) {
        setMagicBellUserHMAC(data?.hmac);
      }
    } catch (error) {
      console.error(error);
    }
  }, [data]);
  if (isLoading) return null;
  if (!magicBellUserHMAC) return null;

  return (
    <Tooltip message={'check your update'}>
      <Button variant="regular" className="h-8">
        <MagicBell
          BellIcon={<BellIcon className="w-4 h-4 fill-zinc-400" />}
          theme={{
            icon: { width: '18px' },
            header: {
              fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
              backgroundColor: '#202124',
              textColor: 'white',
              borderRadius: '0px',
            },
            container: {
              fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
              backgroundColor: '#202124',
              textColor: 'white',
            },
            footer: {
              fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
              backgroundColor: '#202124',
              borderRadius: '0px',
            },
            tabs: {
              color: 'white',
              activeColor: '#009E65',
            },
            banner: {},
            notification: {
              default: {
                fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
                backgroundColor: '#292d3e',
                borderRadius: '4px',
                textColor: 'white',
              },
              unread: {
                fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
                backgroundColor: '#212328',
                backgroundOpacity: 0.75,
              },
              unseen: {
                fontFamily: '"Roboto","Helvetica Neue","Arial",sans-serif',
                backgroundColor: '#867555',
                backgroundOpacity: 0.2,
              },
            },
          }}
          apiKey={MAGICBELL_APIKEY}
          userExternalId={workspaceUser?.id}
          userKey={magicBellUserHMAC}
          //https://www.magicbell.com/docs/react/inbox-tabs#stores
          stores={[
            { id: 'Inbox', defaultQueryParams: { read: false } },
            {
              id: 'Mentions',
              defaultQueryParams: { categories: ['Mentions'] },
            },
            {
              id: 'Comments',
              defaultQueryParams: { categories: ['Comments'] },
            },
            {
              id: 'Announcements',
              defaultQueryParams: { categories: ['Announcements'] },
            },
          ]}
        >
          {(props) => (
            <FloatingNotificationInbox
              height={500}
              placement="bottom-end"
              {...props}
              tabs={[
                { storeId: 'Inbox', label: 'Inbox' },
                { storeId: 'Mentions', label: 'Mentions' },
                { storeId: 'Comments', label: 'Comments' },
                { storeId: 'Announcements', label: 'Announcements' },
              ]}
            />
          )}
        </MagicBell>
      </Button>
    </Tooltip>
  );
};
