import { PlusIcon } from '@heroicons/react/20/solid';
import { Button, Dialog, DialogContent, Input } from '@hubql/hubqlkit';
import { CreateProject } from './CreateProject';
import { useState } from 'react';
import { ConnectGitHub } from './ConnectGitHub';
import clsx from 'clsx';

export const ChooseHubType = ({ defaultAccess }) => {
  const [hubTitle, setHubTitle] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const colClass = 'col-span-1 flex flex-col gap-2 items-center rounded-sm p-3';
  return (
    <>
      <Button variant={'green'} onClick={setIsDialogOpen} id="create-hub">
        New Hub
        <PlusIcon className="w-3 h-3" />
      </Button>
      <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
        <DialogContent
          className={
            'max-w-2xl p-4 bg-zinc-900 font-mono overflow-y-auto scrollbar border-zinc-700'
          }
        >
          <div className="flex flex-col gap-4 items-center w-full">
            <span>Create a new Hub</span>
            <div className="max-w-xs w-full">
              <Input
                placeholder="Enter Hub name"
                value={hubTitle}
                onChange={(e) => setHubTitle(e.target.value)}
                onBlur={(e) => setHubTitle(e.target.value)}
                inputSize="md"
              />
            </div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className={clsx(colClass, 'bg-zinc-800/70')}>
                <h3 className="text-md text-left">Static Hub</h3>
                <div className={clsx(colClass, 'justify-center h-full ')}>
                  <p className="text-xs text-zinc-300 text-center px-2">
                    Not synced with your Git provider. You can get your files
                    into Schema Visualizer in 3 ways
                  </p>
                  <ul className="list-disc text-zinc-300 ml-3 text-xs mb-3">
                    <li>Start with our templates</li>
                    <li>Create file by sending a payload</li>
                    <li>Drag & Drop your local files</li>
                  </ul>
                  <CreateProject
                    defaultAccess={defaultAccess}
                    justify={'justify-center'}
                    label={'New Static Hub'}
                    variant="regular"
                    hubTitle={hubTitle}
                  />
                </div>
              </div>

              <div className={clsx(colClass, 'bg-zinc-800/70')}>
                <ConnectGitHub
                  hubTitle={hubTitle}
                  defaultAccess={defaultAccess}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
