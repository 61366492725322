export const STORAGE_INDEX = 'hubql_guest';

export const getGuestIdFromCookie = (cookies: any) =>
  cookies?.['hubql_guest']
    ? typeof cookies?.['hubql_guest'] === 'string'
      ? JSON.parse(cookies?.['hubql_guest'])?.id
      : cookies?.['hubql_guest']?.id
    : null;

export type WorkspaceUser = {
  id: string;
  isGuest: boolean;
  name?: string | null;
  email?: string | null;
  picture?: string | null;
  onboardingCompletedAt?: string;
};

const catNames = [
  'Fluffy Paws',
  'Bella Socks',
  'Whiskers Beans',
  'Luna Whiskers',
  'Shadow Bandit',
  'Tigger Beans',
  'Jasper Smudge',
  'Chloe Tail',
  'Midnight Spot',
  'Fluffy Tail',
  'Bella Mittens',
  'Shadow Mittens',
  'Fluffy Spot',
  'Tigger Spot',
  'Bella Bandit',
  'Tigger Smudge',
  'Jasper Paws',
  'Oliver Mittens',
  'Whiskers Fuzz',
  'Oliver Fuzz',
  'Jasper Tail',
  'Fluffy Whiskers',
  'Chloe Mittens',
  'Midnight Socks',
  'Jasper Beans',
  'Midnight Fuzz',
  'Tigger Whiskers',
  'Chloe Socks',
  'Luna Fuzz',
  'Chloe Paws',
  'Whispers Shadow',
  'Fluffy Bandit',
  'Luna Spot',
  'Chloe Smudge',
  'Luna Beans',
  'Fluffy Oliver',
  'Chloe Mittens',
  'Luna Tail',
  'Midnight Smudge',
  'Oliver Beans',
  'Chloe Whiskers',
  'Whispers Tail',
  'Midnight Paws',
  'Oliver Shadow',
  'Luna Bandit',
  'Midnight Mittens',
  'Shadow Socks',
  'Bella Spot',
  'Luna Paws',
  'Tigger Bandit',
  'Shadow Spot',
  'Luna Smudge',
  'Bella Whiskers',
  'Whispers Bandit',
  'Midnight Spot',
  'Shadow Beans',
  'Whiskers Midnight',
  'Midnight Beans',
  'Whiskers Socks',
  'Luna Jasper',
  'Midnight Socks',
  'Bella Paws',
  'Jasper Fuzz',
  'Bella Tail',
  'Shadow Oliver',
  'Whispers Bella',
  'Tigger Chloe',
  'Fluffy Luna',
  'Bella Socks',
  'Whispers Spot',
  'Tigger Oliver',
  'Fluffy Mittens',
  'Bella Fuzz',
  'Whispers Socks',
  'Tigger Spot',
  'Bella Mittens',
  'Whispers Socks',
  'Whiskers Jasper',
  'Tigger Bandit',
  'Whiskers Socks',
  'Shadow Luna',
  'Fluffy Tail',
  'Tigger Shadow',
  'Fluffy Smudge',
  'Oliver Luna',
  'Shadow Chloe',
  'Whispers Socks',
  'Chloe Mittens',
  'Shadow Smudge',
  'Shadow Spot',
  'Oliver Fuzz',
  'Fluffy Paws',
  'Shadow Jasper',
  'Fluffy Bandit',
  'Tigger Beans',
];

export const generateGuestUser = (): WorkspaceUser => {
  const randomIndex = Math.floor(Math.random() * catNames.length);
  const newName =
    catNames[randomIndex] + ' ' + Math.floor(Math.random() * 10000);

  const newId = 'guest_' + newName.replace(/\s+/g, '_').toLowerCase();
  return {
    id: newId,
    isGuest: true,
    name: newName,
  };
};

export const saveUserCookie = (setCookie: any, newGuest: WorkspaceUser) => {
  setCookie(STORAGE_INDEX, JSON.stringify(newGuest), { path: '/' });
};
