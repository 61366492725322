import { Edge, Node } from 'reactflow';
import { flatObject } from '../flatObject';
import { LiveField } from '../../state/stores/live';

const position = { x: 0, y: 0 };

export type Object = {
  [k in string]: Object | string;
};

type ProcessObjectParams = {
  obj: Object;
  parentId?: string;
  parentKey?: string;
};

const hasInitPosition = (field?: LiveField): boolean => {
  return !!field?.positionX && !!field?.positionY;
};

function findFieldDataByKeyAndParentId(
  fieldData: LiveField[],
  key: string,
  parentId?: string
) {
  if (!parentId) {
    return fieldData.find((field) => field.key === key && !field.parentId);
  }

  return fieldData.find(
    (field) => field.key === key && field.parentId === parentId
  );
}

export const makeNodesEdgesForJson = (
  object: Object,
  fieldData: LiveField[]
) => {
  const edgeType = 'smoothstep';
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  const firstLevelObject = flatObject(object);
  const rootField = findFieldDataByKeyAndParentId(fieldData, '__root__');

  if (!rootField) {
    console.error('Root field not found');

    return { nodes, edges };
  }

  const rootId = rootField.id;
  nodes.push({
    id: rootId,
    type: 'object',
    data: {
      ...firstLevelObject,
      _parentId: null,
      _parentKey: '__root__',
      _hasInitPos: hasInitPosition(rootField),
    },
    position: {
      x: rootField.positionX ?? position.x,
      y: rootField.positionY ?? position.y,
    },
  });

  function processObject({ obj, parentId, parentKey }: ProcessObjectParams) {
    for (const key in obj) {
      const value = obj[key];

      const isValidObject = value !== null && typeof value === 'object';
      if (!isValidObject) {
        continue;
      }

      const isEmptyObject = Object.keys(value ?? {})?.length === 0;
      if (isEmptyObject) {
        continue;
      }

      const field = findFieldDataByKeyAndParentId(fieldData, key, parentId);
      const fieldId = field?.id ?? '';

      edges.push({
        id: 'e' + fieldId,
        type: edgeType,
        source: parentId ?? rootId,
        target: fieldId,
        sourceHandle: fieldId,
        hidden: field?.hideChildren ?? false,
        zIndex: 0,
      });

      nodes.push({
        id: field?.id ?? '',
        type: 'object',
        hidden: field?.hideChildren ?? false,
        data: {
          ...flatObject(value as Object),
          _parentId: field?.id ?? parentId,
          _parentKey: field?.key ?? key,
          _hasInitPos: hasInitPosition(field),
        },
        position: {
          x: field?.positionX ?? position.x,
          y: field?.positionY ?? position.y,
        },
      });

      processObject({
        obj: value as Object,
        parentId: fieldId, // next parent
        parentKey: key, // next parent key
      });
    }
  }

  processObject({
    obj: object,
  });

  return { nodes, edges };
};
