import { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { Tooltip } from '../Tooltip/Tooltip';

interface iMenu {
  options?: any[];
  label?: any;
  tooltipLabel?: string;
  positionX?: 'right' | 'left';
  className?: string;
  children?: JSX.Element | ReactNode;
  size?: string;
  id?: string;
  optionsClassname?: string;
}

export const Menu: React.FC<iMenu> = ({
  label,
  tooltipLabel,
  positionX = 'right',
  className,
  children,
  size = 'w-fit',
  id,
  optionsClassname,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const posRight = positionX === 'right' && 'right-0';
  const posLeft = positionX === 'left' && 'left-0';

  const contextRef = useRef<HTMLDivElement>(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(contextRef);

  return (
    <div className="dropdown relative">
      <Tooltip message={tooltipLabel} id={'context-menu'}>
        <Button
          id={id}
          onClick={() => setIsOpen(true)}
          className={clsx(
            'text-xs justify-between focus:ring-0 h-[34px]',
            className
          )}
          variant="ghost"
          justify="justify-between"
          size="md"
        >
          <span className={clsx('w-full truncate text-left flex')}>
            {label}
          </span>
        </Button>
      </Tooltip>
      {isOpen && (
        <div
          className={clsx(
            'menu absolute inset-auto top-[100%] max-h-[140px] overflow-x-hidden overflow-y-auto scrollbar  bg-zinc-700  mt-2 z-30 cursor-pointer shadow-xl rounded-md',
            posRight,
            posLeft,
            size,
            optionsClassname
          )}
          onClick={() => setIsOpen(false)}
          ref={contextRef}
          onContextMenu={(e) => e.preventDefault}
        >
          {children}
        </div>
      )}
    </div>
  );
};
