import Link from 'next/link';
import { Avatar } from '@hubql/hubqlkit';
import { format } from 'date-fns';
import { Card } from '@hubql/hubqlkit';
import clsx from 'clsx';

export const ProjectListCard = ({
  id,
  name,
  workspaceSlug,
  createdAt,
  access,
  owner,
  icon,
  isFile,
}) => {
  const formatedDate = createdAt
    ? format(new Date(createdAt), 'dd MMM yyyy HH:mm')
    : '';
  const projectMembers = access?.edges?.map((node) => {
    const access = node?.node;
    if (access?.user?.picture === null) return null;
    return (
      <Avatar
        key={'avatar' + access?.user?.id}
        src={access?.user?.picture}
        alt={access.user?.firstName ?? ''}
        title={access.user?.firstName ?? ''}
        name={access.user?.firstName ?? ''}
        label={false}
      />
    );
  });
  if (owner?.picture) {
    projectMembers.unshift(
      <Avatar
        key={'avatar' + owner?.id}
        src={owner?.picture}
        alt={owner?.firstName ?? ''}
        title={owner?.firstName ?? ''}
        name={owner?.firstName ?? ''}
        label={false}
      />
    );
  } else {
    <Avatar
      key={'avatar' + owner?.id}
      alt={owner?.firstName ?? ''}
      title={owner?.firstName ?? ''}
      name={owner?.firstName ?? ''}
      label={false}
    />;
  }

  return (
    <Link
      href={isFile ? '/?file=' + id : '/w/' + workspaceSlug + '/hub/' + id}
      shallow={true}
      passHref
    >
      <Card
        variant={'shiny'}
        className={clsx('flex-col h-24 justify-between p-2')}
      >
        <div className="flex justify-start w-full gap-2 items-center">
          {icon}
          <span className="w-full truncate">{name}</span>
        </div>

        <div className="flex justify-start w-full no-wrap overflow-hidden">
          {projectMembers}
        </div>

        <div className="flex justify-end w-full">
          <span className="w-full truncate text-right text-xs text-zinc-400">
            Created on {formatedDate}
          </span>
        </div>
      </Card>
    </Link>
  );
};
