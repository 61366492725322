import clsx from 'clsx';
import { useState } from 'react';

interface Props {
  className?: string;
  onClick?: (status: boolean) => void;
}

export const NodeToggleIcon = ({
  className = 'w-5 h-5 stroke-zinc-300',
  onClick,
}: Props) => {
  const [showChildren, setShowChildren] = useState(true);

  /* Function to hide all children of a Node */
  const handleToggler = async () => {
    setShowChildren((prevShowChildren) => {
      return !prevShowChildren;
    });

    onClick && onClick(showChildren);

    return;
  };

  return (
    // @ts-ignore
    <div className={'w-fit ml-3'} onClick={handleToggler}>
      {showChildren ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(className)}
        >
          <path
            d="M17.5 7H13V6H17.5C21.0899 6 24 8.91015 24 12.5C24 16.0899 21.0899 19 17.5 19H13V18H17.5C20.5376 18 23 15.5376 23 12.5C23 9.46243 20.5376 7 17.5 7Z"
            fill="black"
          />
          <path
            d="M11 6V7H6.5C3.46243 7 1 9.46243 1 12.5C1 15.5376 3.46243 18 6.5 18H11V19H6.5C2.91015 19 0 16.0899 0 12.5C0 8.91015 2.91015 6 6.5 6H11Z"
            fill="black"
          />
          <path d="M6 13H18V12H6V13Z" fill="black" />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(className)}
        >
          <path
            d="M21.2167 23L17.9161 19.1254C17.7785 19.1341 17.6398 19.1385 17.5 19.1385H13V18.1385H17.0754L12.6981 13H6V12H11.8462L7.70488 7.13855H6.5C3.46243 7.13855 1 9.60098 1 12.6385C1 15.6761 3.46243 18.1385 6.5 18.1385H11V19.1385H6.5C2.91015 19.1385 0 16.2284 0 12.6385C0 9.0487 2.91015 6.13855 6.5 6.13855H6.85302L3 1.61552L3.78328 1L22 22.3845L21.2167 23Z"
            fill="black"
          />
          <path
            d="M24 12.6385C24 15.2647 22.4426 17.5271 20.201 18.5525L19.5349 17.7498C21.5652 16.9408 23 14.9573 23 12.6385C23 9.60098 20.5376 7.13855 17.5 7.13855H13V6.13855H17.5C21.0899 6.13855 24 9.0487 24 12.6385Z"
            fill="black"
          />
          <path d="M18 13H15.5844L14.7546 12H18V13Z" fill="black" />
        </svg>
      )}
    </div>
  );
};
