import { useQuery } from '@apollo/client';
import { guessFileType } from '@hubql/file-util';
import { EventsDocument, EventsQuery } from '@hubql/codegen';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { CreateFile } from '../files/CreateFile';
import { useProjectStore } from '../state/stores/project';
import { Button } from '@hubql/hubqlkit';

import { useFileStore } from '../state/stores/file';

export const EventList = () => {
  const { workspace } = useWorkspace();
  const router = useRouter();
  const project = useProjectStore((state) => state.project);
  const fileProjectId = useFileStore((state) => state.projectId);
  const projectId =
    (router.query['hub'] as string) ?? fileProjectId ?? project?.id;
  const setProjectEvents = useProjectStore((state) => state.setProjectEvent);
  const { error, data, refetch, networkStatus } = useQuery<EventsQuery>(
    EventsDocument,
    {
      pollInterval: 5000,
      variables: {
        projectId: projectId,
      },
    }
  );

  const [loadingFile, setFileLoading] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setProjectEvents(data?.events?.edges?.map((e) => e?.node));
  }, [data]);

  const loadNewEvents = () => {
    refetch({
      projectId: projectId,
    });
  };
  useEffect(() => {
    // subscribeToMore({
    //     document: EventsSubscriptionDocument,
    //     variables: { projectId },
    //     updateQuery: (prev, { subscriptionData }) => {
    //         console.log('prev',prev)
    //         console.log('subscriptionData',subscriptionData)
    //       if (!subscriptionData.data) return prev;
    //       const newEvent = subscriptionData.data.events;
    //       return Object.assign({}, prev, {
    //         events: [newEvent, ...prev.events]
    //       });
    //     }
    //   })
  }, []);

  if (error) return <p>Error</p>;
  const events = data?.events?.edges
    ?.map((eventNode) => {
      const event = eventNode?.node;
      if (!event || !workspace?.id) return;
      const date = new Date(event.createdAt).toLocaleString();
      const eventString =
        typeof event.payload === 'object'
          ? JSON.stringify(event.payload)
          : event.payload;
      const extension = guessFileType(eventString);
      return (
        <div
          className="flex flex-col p-4 rounded-md border border-zinc-700 gap-2"
          key={event.id}
        >
          <div className={'w-full flex justify-between items-center'}>
            <span className="text-xs text-zinc-400">{date}</span>
            <CreateFile
              fileName={'test'}
              workspaceId={workspace?.id}
              workspaceSlug={workspace?.slug}
              fileContent={eventString}
              label="Use as file"
              projectId={projectId}
              extension={extension}
              isLoading={loadingFile}
              setIsLoading={setFileLoading}
            />
          </div>
          <pre className="pt-4 text-zinc-400">
            <code>{eventString}</code>
          </pre>
        </div>
      );
    })
    .reverse();

  if (Number(data?.events?.edges?.length) < 1)
    return (
      <div className="flex flex-col items-center">
        <Button
          isLoading={networkStatus === 4}
          className="mt-2 mb-2"
          onClick={loadNewEvents}
        >
          Reload
        </Button>
        <span> No events yet</span>{' '}
      </div>
    );
  return (
    <div>
      <Button
        className=" mb-2"
        onClick={loadNewEvents}
        isLoading={networkStatus === 4}
      >
        Reload
      </Button>
      <div className="py-0.5 flex flex-col gap-4 overflow-y-auto max-h-[45vh]">
        {events}
      </div>
    </div>
  );
};
