import { useMutation } from '@apollo/client';
import {
  UpdateWorkspaceSlugDocument,
  UpdateWorkspaceSlugMutation,
} from '@hubql/codegen';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Input } from '@hubql/hubqlkit';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';

export const WorkspaceSlug = () => {
  const posthog = usePostHog();

  const { workspace } = useWorkspace();
  const router = useRouter();
  const [slug, setSlug] = useState(workspace?.slug);

  useEffect(() => {
    if (workspace?.slug) setSlug(workspace?.slug);
  }, [workspace]);

  const [updateWorkspaceSlug] = useMutation<UpdateWorkspaceSlugMutation>(
    UpdateWorkspaceSlugDocument
  );
  if (!workspace) return null;
  const updateSlug = async (newValue: string) => {
    const newSlug = newValue === '' || !newValue ? workspace.id : newValue;
    await updateWorkspaceSlug({
      variables: {
        id: workspace.id,
        slug: newSlug,
      },
    });
    try {
      posthog?.capture('WORKSPACE_SLUG_CHANGED', {
        workspaceId: workspace.id,
      });
    } catch (error) {}
    toast.success('Workspace slug changed!');
    router.push(`/w/${newSlug}`);
  };
  const handleOnBlur = (newValue: string) => {
    if (newValue !== workspace?.slug) updateSlug(newValue);
  };
  return (
    <div className="border border-zinc-700 rounded-sm p-4">
      <div className="flex flex-1 flex-col justify-start items-start gap-1">
        <span className="text-sm text-zinc-50 w-fit whitespace-nowrap">
          Workspace slug (URL)
        </span>
        <span className="text-xs text-zinc-400 w-fit whitespace-nowrap pb-4">
          Change your slug url and make sharing your workspace more personal.
        </span>
        <Input
          variant="regular"
          className={`max-w-sm`}
          width={'w-full'}
          onChange={(e) => setSlug(e.target.value)}
          onBlur={(e) => {
            handleOnBlur(e.target.value);
          }}
          value={slug}
          placeholder=""
          inputSize="lg"
        />
      </div>
    </div>
  );
};
