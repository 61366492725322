import { ContextMenuTrigger } from '@hubql/hubqlkit';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  isNotViewMode?: string | boolean;
  handleShowActions?: () => void;
  handleDoubleClick?: () => void;
  showLiveLocation?: string | null;
  onContextMenu?: (event: {
    clientX: number;
    clientY: number;
    preventDefault: () => void;
  }) => void;
  children: ReactNode;
  isActiveField?: any;
  handleMouseEnter?: any;
  handleMouseLeave?: any;
  bg?: string;
  isSelectedSource: boolean;
}

export const RowGraphQL = ({
  isNotViewMode,
  handleShowActions,
  handleDoubleClick,
  showLiveLocation,
  onContextMenu,
  children,
  isActiveField,
  bg = 'bg-zinc-800',
  handleMouseEnter,
  handleMouseLeave,
  isSelectedSource,
}: Props) => {
  return (
    <ContextMenuTrigger asChild>
      <tr
        className={clsx(
          'grid grid-cols-[1fr,1fr,auto] relative w-full',
          isNotViewMode,
          bg
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleShowActions}
        onDoubleClick={handleDoubleClick}
        style={{
          zIndex: isActiveField ? 100 : 0,
          background:
            isActiveField || isSelectedSource
              ? `${showLiveLocation}30`
              : 'transparent',
        }}
        onContextMenu={onContextMenu}
      >
        {children}
      </tr>
    </ContextMenuTrigger>
  );
};
