import { useState } from 'react';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useMutation } from '@apollo/client';
import { InviteUsersToWorkspaceDocument } from '@hubql/codegen';
import { Button } from '@hubql/hubqlkit';
import { toast } from 'react-toastify';
import { ReactMultiEmail } from 'react-multi-email';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { usePostHog } from 'posthog-js/react';
export const WorkspaceInviteMember = () => {
  const [userEmails, setInput] = useState<string[]>([]);
  const { workspace } = useWorkspace();
  const posthog = usePostHog();

  const handleInputChange = (value: string[]) => setInput(value);
  const [inviteUserToWorkspace, invitationState] = useMutation(
    InviteUsersToWorkspaceDocument
  );

  const isError = userEmails?.length === 0;
  const handleClick = async () => {
    if (!workspace?.id) return;

    const result = await inviteUserToWorkspace({
      variables: {
        input: {
          userEmails: userEmails,
          workspaceId: workspace?.id,
          workspaceRole: 'MEMBER',
          workspaceAccess: 'VIEW',
        },
      },
      refetchQueries: ['workspaceBySlug', 'workspaceSubscriptions'],
    });
    if (
      result?.data?.inviteUsersToWorkspace?.__typename ===
      'InviteUsersToWorkspaceSuccess'
    ) {
      toast.success('Done');
      setInput([]);

      try {
        posthog?.capture('WORKSPACE_MEMBER_CREATED', {
          workspaceId: workspace?.id,
        });
      } catch (error) {}
    } else {
      console.error(result?.data?.inviteUsersToWorkspace?.message);
      alert('Failed');
    }
  };
  return (
    <div className="mt-2 mb-4  w-full">
      <div className="flex items-center gap-4 w-full justify-start">
        <div className="reactEmail w-full first-line:flex flex-col gap-4">
          <ReactMultiEmail
            id={'file-sharing-add-people'}
            className=""
            placeholder="Invite new member by email"
            emails={userEmails?.map((email) => email.trim())}
            autoFocus={false}
            onChange={(_emails: string[]) => {
              handleInputChange(_emails?.map((email) => email.trim()));
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={'email' + index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    <XMarkIcon className="w-3 h-3" />
                  </span>
                </div>
              );
            }}
          />
        </div>

        <Button
          isLoading={invitationState.loading}
          variant="green"
          size="lg"
          isDisabled={isError}
          onClick={handleClick}
        >
          Invite
        </Button>
      </div>
    </div>
  );
};
