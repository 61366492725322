import { usePostHog, PostHog } from 'posthog-js/react';
import { v4 as uuidv4 } from 'uuid';
import { FileComment } from '@hubql/codegen';

type CreateNoteProps = {
  createFileComment: any;
  notes: FileComment[];
  fileId: string;
  workspaceId: string;
  userId: string;
  x: number;
  y: number;
  fieldId?: string;
  posthog: PostHog;
};

export const createNote = ({
  createFileComment,
  notes,
  fileId,
  workspaceId,
  userId,
  x,
  y,
  fieldId = '__NOTE__',
  posthog,
}: CreateNoteProps): { newNotes: FileComment[]; newNote: FileComment } => {
  const uuid = uuidv4().replace(/-/gi, '');
  const noteId = 'note_' + uuid;
  const newNote = {
    id: noteId,
    fileId,
    workspaceId,
    comment: 'This is a note',
    positionX: x,
    positionY: y,
    fieldId: fieldId,
    isNote: true,
  } as unknown as FileComment;
  try {
    posthog?.capture('NOTE_CREATED', { noteId, fileId, workspaceId });
  } catch (error) {}
  const newNotes = [
    ...notes,
    { ...newNote, createdBy: userId },
  ] as unknown as FileComment[];
  createFileComment({
    variables: {
      input: newNote,
    },
  });

  return { newNotes, newNote };
};
