import { useMutation } from '@apollo/client';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { UpdateFileNameDocument } from '@hubql/codegen';
import { Tooltip, Input, Button, Skeleton } from '@hubql/hubqlkit';
import clsx from 'clsx';
import Link from 'next/link';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  isWorkspace: any;
  workspaceSlug: string;
  isProject: boolean;
  projectId?: string;
  projectName?: string;
  isFile: boolean;
  fileName: string;
  extension: 'json' | 'prisma' | 'graphql' | 'proto' | 'xml' | 'yaml' | null;
  fileId: string;
  isWorkspaceMember: boolean;
  onFileNameChanged?: (fileName: string) => void;
}

export const BreadcrumNav = ({
  workspaceSlug,
  projectId,
  projectName,
  isFile,
  fileName,
  extension,
  fileId,
  isWorkspaceMember,
  onFileNameChanged,
}: Props) => {
  const posthog = usePostHog();

  const [renameView, setRenameView] = useState(false);
  const [name, setFilename] = useState('');
  const [updateFileName] = useMutation(UpdateFileNameDocument);

  const onSaveChanges = async () => {
    setRenameView(false);
    const prvName = fileName;
    onFileNameChanged?.(name);
    const result = await updateFileName({
      variables: {
        id: fileId,
        fileName: name,
      },
    }).catch((error) => {
      return { errors: [error] };
    });

    if (result.errors?.length) {
      toast.error('Error renaming file');
      onFileNameChanged?.(prvName);
      return;
    }

    toast.success('File name changed');
    try {
      posthog?.capture('FILE_NAME_CHANGED', {
        fileId,
        fileName: name,
      });
    } catch (error) {}
  };
  const onCancel = () => {
    setRenameView(false);
  };
  useEffect(() => {
    setFilename(fileName);
  }, [fileName]);
  const breadClass =
    'truncate w-fit max-w-[240px] h-[32px] px-2 py-1 focus:ring-0 border-0 cursor-pointer hover:bg-zinc-800 bg-zinc-900 rounded-sm text-zinc-50 flex items-center justify-start text-xs';
  return (
    <div className="flex gap-1 items-center text-xs">
      {isFile &&
        (projectName ? (
          <>
            <span className="text-zinc-50 inline ">/</span>

            <Tooltip message={isWorkspaceMember ? 'Go to Hub' : ''}>
              <div className={breadClass}>
                {isWorkspaceMember ? (
                  <Link
                    href={`/w/${
                      workspaceSlug !== '' ? workspaceSlug : '-'
                    }/hub/${projectId !== '' ? projectId : '-'}`}
                  >
                    {projectName}
                  </Link>
                ) : (
                  <span>{projectName}</span>
                )}
              </div>
            </Tooltip>
          </>
        ) : (
          <Skeleton className="h-[32px] w-[80px]" />
        ))}
      {isFile &&
        (renameView ? (
          <>
            <span className="text-zinc-50 inline ">/</span>
            <Input
              placeholder="untitled"
              value={name}
              onChange={(e) => setFilename(e.target.value)}
              width="w-full"
              className="h-[28px] w-full"
            />
            <div className="flex">
              <Tooltip message="Save changes">
                <Button variant="ghost" onClick={onSaveChanges}>
                  <CheckIcon className="w-4 h-4 fill-green-400" />
                </Button>
              </Tooltip>
              <Tooltip message="Cancel change">
                <Button variant="ghost" onClick={onCancel}>
                  <XMarkIcon className="w-4 h-4 fill-red-400" />
                </Button>
              </Tooltip>
            </div>
          </>
        ) : fileName ? (
          <>
            <span className="text-zinc-50 inline ">/</span>

            <Tooltip message={'Current file'}>
              <div
                className={clsx(
                  breadClass,
                  'relative bg-zinc-900 hover:bg-zinc-900 select-none !cursor-default'
                )}
                onDoubleClick={() => setRenameView(!renameView)}
              >
                <span>
                  {fileName}.{extension}
                </span>
              </div>
            </Tooltip>
          </>
        ) : (
          <Skeleton className="h-[32px] w-[80px]" />
        ))}
    </div>
  );
};
