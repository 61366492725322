import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useCommentStore, useFileStore } from '@hubql/app-core';
import { AddComment } from 'libs/app-core/src/lib/comments/AddComment';
import { CommentList } from 'libs/app-core/src/lib/comments/CommentList';
import { FileCommentFilter } from 'libs/app-core/src/lib/comments/FileCommentFilter';
import { useLiveStore } from 'libs/app-core/src/lib/state/stores/live';
import { CommentThread } from '../comments/CommentThread';
import { SuggestEditField } from '../files/SuggestEditField';
import { Button } from '@hubql/hubqlkit';
import { useReactFlow } from 'reactflow';
import { useState } from 'react';
import { SearchComment } from './SearchComment';

export const CommentSidebar = () => {
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const threadId = useCommentStore((state) => state.threadId);
  const setThreadId = useCommentStore((state) => state.setThreadId);
  const currentComment = useCommentStore((state) => state.currentComment);
  const workMode = useFileStore((state) => state.workMode);
  const {
    liveblocks: { isStorageLoading },
  } = useLiveStore();
  const { getNodes } = useReactFlow();

  const objectKey = useFileStore((state) => state.objectKey);
  const type = useFileStore((state) => state.type);
  const extension = useFileStore((state) => state.extension);
  const exampleValue = useFileStore((state) => state.exampleValue);
  const commentData = useCommentStore((state) => state.comments);
  const notes = useLiveStore((state) => state.notes);
  const commentsAndNotes = [...commentData, ...notes];
  const fields = useLiveStore((state) => state.fields);

  const selectedNoteId = notes.find(
    (note) => getNodes()?.find((node) => node?.selected)?.id
  )?.id;

  const channelNull = !selectedFieldId;
  const channelNotNull = selectedFieldId;

  const field = fields?.find((field) => field.id === selectedFieldId);

  const channelName = field?.key ?? 'General';

  const channelWithoutThread = channelNotNull && !threadId;

  const channelWithThread = channelNotNull && threadId;

  const handleBack = () => {
    if (channelNotNull && threadId) {
      setThreadId(null);
    }
    if (channelNotNull && !threadId) {
      setSelectedFieldId(null);
    }

    if (threadId?.includes('note')) {
      // TODO: this probably has performance implications at some point
      setThreadId(null);
      setSelectedFieldId(null);
    }
  };
  const [keyword, setKeyword] = useState('');
  if (isStorageLoading) return null;
  if (workMode === 'VIEW') return null;

  const findKeywordInField = commentsAndNotes?.filter((field) =>
    field?.comment?.toLowerCase()?.includes(keyword.toLowerCase())
  );
  const removeTags = (str: string) => {
    if (!str || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  const hasObjectKey = objectKey && objectKey !== '__GENERAL__';

  const currentCommentContent = currentComment?.comment;
  return (
    <div
      id="comment-sidebar"
      className="relative text-xs flex flex-col pr-2 w-full h-[calc(100vh-45px)] justify-start  text-zinc-50 bg-zinc-900 z-100 overflow-x-hidden"
    >
      <div className="flex flex-col gap-2 mt-2">
        <div className="relative flex flex-row items-center gap-2 px-2 bg-zinc-800 rounded-sm py-2 justify-center">
          {channelNotNull && (
            <Button
              variant={'ghost'}
              onClick={handleBack}
              size={'sm'}
              className="w-6 h-6 absolute left-2 flex justify-center items-center"
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </Button>
          )}
          <span className="text-[14px] flex-1 text-center truncate pl-6 pr-2">
            {channelNull && 'Channels'}
            {channelNotNull && !threadId && channelName}
            {channelNotNull && threadId && removeTags(currentCommentContent)}
          </span>
        </div>
        <div>
          {channelNull && (
            <SearchComment keyword={keyword} setKeyword={setKeyword} />
          )}
        </div>
        <FileCommentFilter commentData={findKeywordInField} />
      </div>
      {channelWithoutThread && (
        <>
          <div className="scrollbar px-2  w-full overflow-x-hidden max-h-[calc(100%-90px)] mt-2">
            {hasObjectKey && (
              <SuggestEditField
                fieldId={selectedFieldId}
                objectKey={objectKey}
                type={type}
                extension={extension}
                exampleValue={exampleValue}
              />
            )}

            <div className="scrollbar w-full overflow-y-auto overflow-x-hidden max-h-[28vh]">
              <CommentList fieldId={selectedFieldId} threadId={null} />
            </div>
            <div id="comment-sidebar-add-comment" className=" w-full py-2 ">
              <AddComment
                fieldId={
                  typeof selectedFieldId === 'string'
                    ? selectedFieldId
                    : undefined
                }
              />
            </div>
          </div>
        </>
      )}
      {channelWithThread && (
        <>
          <div className="scrollbar px-2 overflow-y-auto w-full overflow-x-hidden max-h-[calc(100%-90px)] mt-2">
            <CommentThread />
            <CommentList fieldId={selectedFieldId} threadId={threadId} />
          </div>
          <div id="comment-sidebar-add-comment" className="px-2 w-full py-2 ">
            <AddComment fieldId={selectedFieldId} threadId={threadId} />
          </div>
        </>
      )}
    </div>
  );
};
