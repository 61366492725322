import clsx from 'clsx';

interface iOption {
  children?: any;
  onClick?: (e: any | object) => void;

  className?: string;
}

export const Option: React.FC<iOption> = ({ className, children, onClick }) => {
  return (
    <div
      className={clsx(
        'text-xs w-full text-zinc-50 hover:bg-zinc-600 hover:text-zinc-50 cursor-pointer py-2 px-2 whitespace-nowrap truncate text-left',
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
