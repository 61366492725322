export const renamePrismaType = ({
  prismaFile,
  modelName,
  fieldName,
  oldType,
  newType,
}: {
  prismaFile: string;
  modelName: string;
  fieldName: string;
  oldType: string;
  newType: string;
}) => {
  const pattern = new RegExp(
    `(model\\s+${modelName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = prismaFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      const modifiedModelDefinition = modelDefinition.replace(
        new RegExp(`\\b${fieldName}\\b\\s+${oldType}\\b`),
        `${fieldName}   ${newType}`
      );
      return `${modifiedModelDefinition}${emptyLine}`;
    }
  );
  return modifiedFile;
};

export const renamePrismaField = ({
  prismaFile,
  modelName,
  oldFieldName,
  newFieldName,
}: {
  prismaFile: string;
  modelName: string;
  oldFieldName: string;
  newFieldName: string;
}) => {
  const pattern = new RegExp(
    `(model\\s+${modelName}\\b[\\s\\S]*?)(\\n\\s*\\n)`,
    'g'
  );
  const modifiedFile = prismaFile.replace(
    pattern,
    (match, modelDefinition, emptyLine) => {
      const modifiedModelDefinition = modelDefinition.replace(
        new RegExp(`\\b${oldFieldName}\\b`),
        newFieldName
      );
      return `${modifiedModelDefinition}${emptyLine}`;
    }
  );
  return modifiedFile;
};

export const addPrismaField = ({
  prismaFile,
  modelName,
  newType,
  newFieldName,
}: {
  prismaFile: string;
  modelName: string;
  newType: string;
  newFieldName: string;
}) => {
  const pattern = new RegExp(
    `(model\\s+${modelName}\\b[\\s\\S]*?)(\\n\\s*})`,
    'g'
  );

  const modifiedFile = prismaFile.replace(
    pattern,
    (match, modelDefinition, closingBrace) => {
      const indentedNewField = `  ${newFieldName} ${newType}`;
      const updatedModelDefinition = `${modelDefinition}\n${indentedNewField}${closingBrace}`;
      return updatedModelDefinition;
    }
  );
  return modifiedFile;
};
