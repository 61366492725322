import { TrashIcon } from '@heroicons/react/20/solid';
import { Button, Modal } from '@hubql/hubqlkit';
import { ProjectDeleteModal } from './ProjectDeleteModal';
import { ProjectRepository } from '@hubql/codegen';

interface IProps {
  allowDelete: boolean;
  openModal: () => void;
  isOpen: boolean;
  closeModal: () => void;
  projectId: string;
  projectName: string | undefined;
  workspaceSlug: string;
  hasRepo: boolean;
  repo: ProjectRepository | undefined;
}

export const ProjectListSettings = ({
  allowDelete,
  openModal,
  isOpen,
  closeModal,
  projectId,
  projectName,
  workspaceSlug,
  hasRepo,
  repo,
}: IProps) => {
  return (
    <div className="w-full max-w-4xl gap-2 flex flex-col pt-2">
      {hasRepo && (
        <div className="w-full flex flex-col text-xs p-4 border border-zinc-700 rounded-sm">
          <span className="text-zinc-50">This Hub is connected</span>
          <span className="text-zinc-400">
            Provider:
            {repo?.provider}
          </span>
          <span className="text-zinc-400">
            Repository:
            {repo?.githubRepositoryFullName}
          </span>
          <span className="text-zinc-400">Branch:{repo?.productionBranch}</span>
        </div>
      )}
      <div className="w-full flex flex-col gap-2 py-2 justify-start items-start">
        <div className="w-full border border-1 border-red-500 p-4 rounded-sm flex flex-col gap-1 bg-red-900/10">
          <h3 className="text-sm text-400">Delete Hub</h3>
          <div className="flex flex-col gap-2 py-2 justify-start items-start">
            <div className="w-max rounded-sm flex flex-row gap-2 items-center">
              <span className="text-zinc-300 text-xs pb-4">
                Once you delete a Hub, there is no going back.
                <br /> Please be certain.
              </span>
            </div>
            {allowDelete && (
              <Button variant="red" size="lg" onClick={openModal}>
                Delete
                <TrashIcon className="w-3 h-3" />
              </Button>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={closeModal} className={'p-8'}>
        <ProjectDeleteModal
          id={projectId}
          name={projectName}
          closeModal={closeModal}
          workspaceSlug={workspaceSlug}
        />
      </Modal>
    </div>
  );
};
