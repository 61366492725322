import { FitViewOptions, NodeTypes } from 'reactflow';
import { ObjectEdge } from '../files/ObjectEdge';
import { ObjectNodePRISMA } from '../visualization/ObjectNodePRISMA';
import { ObjectNodeGraphQL } from '../visualization/ObjectNodeGraphQL';
import { ObjectNodeProtobuf } from '../visualization/ObjectNodeProtobuf';

import { SystemNode } from '../files/SystemNode';
import { ObjectNodeJson } from '../visualization/ObjectNodeJson';
import { ObjectNodeOpenAPI } from '../visualization/ObjectNodeOpenAPI';
import { ObjectNodeXml } from '../visualization/ObjectNodeXml';
import { NoteNode } from '../visualization/NoteNode';

export const fitViewOptions: FitViewOptions = {
  padding: 0.2,
  minZoom: 0.5,
  maxZoom: 1,
};

export const nodeTypes = {
  object: ObjectNodePRISMA,
  system: SystemNode,
  note: NoteNode,
};
export const nodeTypesJson = { object: ObjectNodeJson, note: NoteNode };
export const nodeTypesOpenAPI = { object: ObjectNodeOpenAPI, note: NoteNode };
export const nodeTypesXml = { object: ObjectNodeXml, note: NoteNode };
export const nodeTypesGraphQL = {
  object: ObjectNodeGraphQL,
  note: NoteNode,
  system: SystemNode,
};
export const nodeTypesProtobuf = {
  object: ObjectNodeProtobuf,
  note: NoteNode,
  system: SystemNode,
};
export const edgeTypes = { relation: ObjectEdge, enum: ObjectEdge };

export const reactFlowStyle = {
  zIndex: 0,
};

export const getNodeTypes = (extension: string): NodeTypes => {
  if (extension === 'json') {
    return nodeTypesJson as unknown as NodeTypes;
  }
  if (extension === 'xml') {
    return nodeTypesXml as unknown as NodeTypes;
  }
  if (extension === 'graphql') {
    return nodeTypesGraphQL as unknown as NodeTypes;
  }
  if (extension === 'proto') {
    return nodeTypesProtobuf as unknown as NodeTypes;
  }
  if (extension === 'yaml') {
    return nodeTypesOpenAPI as unknown as NodeTypes;
  }

  return nodeTypes as unknown as NodeTypes;
};
