import { LanguageConfig } from '../HubqlEditor';

import { getFieldByLineNumber, getEditorPositionByFieldId } from './line';
import { convertRawContentToField } from './converter';
import { langs } from '@uiw/codemirror-extensions-langs';
import { syntaxHighlighting } from '@codemirror/language';
import { oneDarkHighlightStyle } from '@codemirror/theme-one-dark';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { theme } from './theme';

export const languageConfig: LanguageConfig = {
  language: 'proto',
  extensions: [
    langs.protobuf(),
    theme,
    syntaxHighlighting(oneDarkHighlightStyle),
    vscodeDark,
  ],
  getFieldByLineNumber,
  getEditorPositionByFieldId,
  convertRawContentToField,
};
