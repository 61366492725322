import { ProjectFileList, ProjectHeader } from '@hubql/app-core';

export const ProjectDetail = () => {
  return (
    <div className="w-full h-[calc(100vh-45px)] overflow-auto scrollbar">
      <div className="w-full pt-4 pb-16 px-8">
        <div id="welcome">
          <ProjectHeader />
        </div>
      </div>
    </div>
  );
};
