import { useCallback, useRef, useState } from 'react';
import { useFileStore } from '../state/stores/file';
import { useCommentStore } from '../state/stores/comments';
import { Handle, Position, WrapNodeProps } from 'reactflow';
import { PresenceIndicator } from '../files/PresenceIndicator';
import { useLiveStore } from '../state/stores/live';
import { FieldContextMenu } from './FieldContextMenu';
import { CellPRISMA } from './CellPRISMA';
import { RowPRISMA } from './RowPRISMA';
import { useContextMenuStore } from '../state/stores/contextmenu';
import {
  ContextMenu,
  ContextMenuTrigger,
  Dialog,
  DialogContent,
  Modal,
  useModal,
} from '@hubql/hubqlkit';
import { SuggestAddField } from '../files/SuggestAddField';
import { Indicators } from './Indicators';
import { useCenterView } from '../util/useCenterView';

interface Props {
  objectKey: string;
  value: string;
  parentId: string;
  nodeProps: WrapNodeProps;
  rowNumber: number;
}

export const FieldRowPRISMA = ({
  objectKey,
  value,
  parentId,
  nodeProps,
  rowNumber,
}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const showFields = useFileStore((state) => state.showFields);
  const selectedFieldId = useLiveStore((state) => state.selectedFieldId);
  const workMode = useFileStore((state) => state.workMode);
  const setObjectKey = useFileStore((state) => state.setObjectKey);
  const setExampleValue = useFileStore((state) => state.setExampleValue);
  const setType = useFileStore((state) => state.setType);
  const fields = useLiveStore((state) => state.fields);
  const suggestions = useLiveStore((state) => state.suggestions);
  const fieldData = fields?.find(
    (field) => field.key === objectKey && field.parentId == parentId
  );
  const parentObjectKey =
    fields?.find((field) => field.id == parentId)?.key ?? '';
  const fieldId = fieldData?.id;
  const fieldSuggestions =
    suggestions.filter((item) => item.fileFieldId === fieldId)?.[0]?.action ??
    '';
  const meta = fieldData?.meta ?? '';
  const type = fieldData?.type
    ? fieldData?.type
    : Array.isArray(value)
    ? 'array'
    : typeof value;

  const primaryHighlight = 'text-[#4ade80]';
  const secondaryHighlight = 'text-[#c4b5fd]';
  const thirdHighlight = 'text-[#B55ABE]';

  const hasKind = meta.kind === 'object' && (
    <div>
      <span className={primaryHighlight}>{type}</span>
    </div>
  );
  const hasDefaultValue = meta.hasDefaultValue &&
    meta.default === undefined && (
      <span className={thirdHighlight}>@default</span>
    );
  const hasAutoincrement = meta.hasDefaultValue &&
    meta.default.name === 'autoincrement' && (
      <div>
        <span className={thirdHighlight}>@id @default</span>
        <span>(</span>
        <span className={primaryHighlight}>autoincrement</span>
        <span>())</span>
      </div>
    );
  const hasNow = meta.hasDefaultValue && meta.default.name === 'now' && (
    <div>
      <span className={thirdHighlight}>@default</span>
      <span>(</span>
      <span className={primaryHighlight}>now</span>
      <span>())</span>
    </div>
  );
  const hasFalse = meta.hasDefaultValue && meta.default === false && (
    <div>
      <span className={thirdHighlight}>@default</span>
      <span>(</span>
      <span className="text-[#569cd6]">false</span>
      <span>)</span>
    </div>
  );
  const hasRelation = meta.relationFromFields &&
    meta.relationFromFields.length > 0 && (
      <div>
        <span className={thirdHighlight}>@relation</span>
        <span>{`(`}</span>
        <span className={primaryHighlight}>fields</span>
        <span>{`:[`}</span>
        <span className={primaryHighlight}>{meta.relationFromFields?.[0]}</span>
        <span>{`])`}</span>
      </div>
    );
  const isUnique = meta.isUnique && (
    <span className={thirdHighlight}>@unique</span>
  );
  const isUpdatedAt = meta.isUpdatedAt && (
    <span className={thirdHighlight}>@updatedAt</span>
  );

  const canComment = workMode !== 'VIEW';
  const fileUsers = useLiveStore((state) => state.liveblocks.others);
  const setSelectedFieldId = useLiveStore((state) => state.setSelectedFieldId);
  const setThreadId = useCommentStore((state) => state.setThreadId);

  const activeFieldColor = useLiveStore((state) => state.activeFieldColor);

  const others = fileUsers.filter(
    (other) => other.presence['selectedFieldId'] === fieldId
  );

  const isActiveField = selectedFieldId === fieldId;
  const hasComment = useCommentStore(
    useCallback(
      (state) => {
        if (!canComment) return false;
        return !!state.comments.find((comment) => comment.fieldId === fieldId);
      },
      [canComment, fieldId]
    )
  );

  const suggestionColorMap = (key: string) => {
    switch (key) {
      case 'REMOVE':
        return 'bg-[#361C1C] line-through	text-red-700';
      case 'ADD':
        return 'bg-[#1E2F28]';
      case 'EDIT':
        return 'bg-[#1E252F]';
      default:
        return 'inherit';
    }
  };
  const setShowSuggest = useContextMenuStore((state) => state.setShowSuggest);
  const showSuggest = useContextMenuStore((state) => state.showSuggest);

  const handleShowActions = () => {
    if (!fieldId) return;
    setSelectedFieldId(fieldId);
    setThreadId(null);
    setObjectKey(objectKey);
    setType(type);
    setExampleValue(value);
    if (showSuggest) {
      setShowSuggest(false);
    }
  };

  const { handleCenterView } = useCenterView();

  const handleDoubleClick = () => {
    if (!fieldId) return;
    setSelectedFieldId(fieldId);
    if (selectedFieldId) {
      handleCenterView(selectedFieldId, parentId);
    }
  };

  const isNotViewMode =
    workMode !== 'VIEW' && suggestionColorMap(fieldSuggestions);

  const selectedSourceId = useLiveStore((state) => state.selectedSourceId);
  const setHoverFieldId = useFileStore((state) => state.setHoverFieldId);
  const handleMouseEnter = () => {
    if (fieldId) setHoverFieldId(fieldId);
  };
  const handleMouseLeave = () => {
    setHoverFieldId(null);
  };
  const isSelectedSource = selectedSourceId === fieldId;
  if (!fieldId) return null;

  return (
    <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
      <ContextMenu>
        {showFields && (
          <RowPRISMA
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            key={rowNumber}
            isNotViewMode={isNotViewMode}
            handleShowActions={handleShowActions}
            handleDoubleClick={handleDoubleClick}
            showLiveLocation={activeFieldColor}
            isActiveField={isActiveField}
            isSelectedSource={isSelectedSource}
            onContextMenu={() => {
              setSelectedFieldId(fieldId);
              setThreadId(null);
            }}
          >
            <CellPRISMA className={primaryHighlight}>{objectKey}</CellPRISMA>
            <CellPRISMA>
              <div className="flex">
                {hasKind ? (
                  hasKind
                ) : (
                  <span className={secondaryHighlight}>{type}</span>
                )}
                {meta.isList && <span className="inline">[]</span>}
                {!meta.isRequired && <span className="inline">?</span>}
              </div>
            </CellPRISMA>
            {hasRelation && <CellPRISMA>{hasRelation}</CellPRISMA>}
            {hasFalse && <CellPRISMA>{hasFalse}</CellPRISMA>}
            {hasNow && <CellPRISMA>{hasNow}</CellPRISMA>}
            {hasAutoincrement && <CellPRISMA>{hasAutoincrement}</CellPRISMA>}
            {isUnique && <CellPRISMA>{isUnique}</CellPRISMA>}
            {hasDefaultValue && <CellPRISMA>{hasDefaultValue}</CellPRISMA>}
            {isUpdatedAt && <CellPRISMA>{isUpdatedAt}</CellPRISMA>}
            <CellPRISMA colSpan={meta === '' ? 1 : 2}>
              <Indicators
                hasComment={hasComment}
                fieldSuggestions={fieldSuggestions}
              />
              <div className="relative right-[20px]">
                <PresenceIndicator others={others} />
              </div>
              <Handle
                id={fieldId}
                type="source"
                position={Position.Right}
                isConnectable={false}
                className="absolute right-0 opacity-0"
              />
              <Handle
                type="target"
                position={Position.Left}
                isConnectable={false}
                className="absolute left-0 opacity-0"
              />

              <FieldContextMenu
                fieldId={fieldId}
                extension="prisma"
                parentNodeProps={nodeProps}
                setIsDialogOpen={setIsDialogOpen}
              />
            </CellPRISMA>
          </RowPRISMA>
        )}

        <DialogContent className="max-w-xl p-4">
          <SuggestAddField
            initialKey={''}
            initialValue={''}
            initialType={''}
            fieldId={fieldId}
            extension={'prisma'}
            parentId={parentId}
            parentObjectKey={parentObjectKey}
            setIsDialogOpen={setIsDialogOpen}
          />
        </DialogContent>
      </ContextMenu>
    </Dialog>
  );
};
