import { Input } from '@hubql/hubqlkit';

export const SearchComment = ({ keyword, setKeyword }) => {
  return (
    <Input
      placeholder="Search..."
      type="search"
      onChange={(e) => setKeyword(e.target.value)}
      value={keyword}
    />
  );
};
