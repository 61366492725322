import { useMutation } from '@apollo/client';
import { CreateProjectDocument, CreateProjectMutation } from '@hubql/codegen';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Button } from '@hubql/hubqlkit';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { usePostHog } from 'posthog-js/react';

interface CreateProjectType {
  label?: string;
  size?: string;
  justify?: string | any;
  variant?: string;
  className?: string;
  defaultAccess: 'WORKSPACE' | 'PRIVATE';
  hubTitle?: string;
}

export const CreateProject: React.FC<CreateProjectType> = ({
  label,
  size,
  justify,
  variant = 'ghost',
  defaultAccess,
  className,
  hubTitle,
}) => {
  const posthog = usePostHog();

  const router = useRouter();
  const { workspace } = useWorkspace();
  const [createProject, createProjectState] =
    useMutation<CreateProjectMutation>(CreateProjectDocument);
  const createProjectHandler = async () => {
    if (!workspace) return;
    const uuid = uuidv4().replace(/-/gi, '');
    const newProject = await createProject({
      variables: {
        input: {
          id: 'hub_' + uuid,
          name: hubTitle ? hubTitle : 'Untitled Hub',
          workspaceId: workspace.id,
          defaultAccess: defaultAccess,
        },
      },
      refetchQueries: ['projectList'],
    });

    if (
      newProject?.data?.createProject?.__typename === 'CreateProjectSuccess'
    ) {
      const newProjectId = newProject?.data?.createProject?.data?.id;
      posthog?.capture('HUB_CREATED', {
        workspaceId: workspace?.id,
        projectId: newProjectId,
      });

      router.push(`/w/${workspace.slug}/hub/${newProjectId}`);
      toast.success('New Hub created!');
    } else {
      if (newProject?.data?.createProject?.__typename === 'Error') {
        toast.error(newProject?.data?.createProject?.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };
  return (
    <Button
      variant={variant}
      loadingLabel=""
      size={size}
      isLoading={createProjectState.loading}
      onClick={() => createProjectHandler()}
      className={className}
      justify={justify}
      id={'create-project'}
    >
      {label && label}
      <PlusIcon className={clsx(label && 'mr-2', 'w-3 h-3 mr-0')} />
    </Button>
  );
};
