import { Button, Modal } from '@hubql/hubqlkit';

export type DeleteConfirmationModallProps = {
  nodeType?: string;
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

export const DeleteConfirmationModal = (
  props: DeleteConfirmationModallProps
) => {
  const { onCancel, onDelete, isOpen, nodeType = 'note' } = props;
  return (
    <Modal isOpen={isOpen} onClose={onCancel} className="p-8">
      <div className="flex flex-col gap-2 itmes-start">
        <h2 className="text-xs">
          Are you sure you want to delete this {nodeType}?
        </h2>
        <div className={'flex gap-2 mt-1 justify-end'}>
          <Button onClick={onCancel} variant="ghost" size="lg">
            Cancel
          </Button>
          <Button onClick={onDelete} variant="red" size="lg">
            Yes, let&apos;s delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
