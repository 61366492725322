import Link from 'next/link';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Spinner } from '@hubql/shared-design-system';
import {
  DeleteFileDocument,
  ProjectFileListDocument,
  ProjectFileListQuery,
} from '@hubql/codegen';
import { useMutation, useQuery } from '@apollo/client';
import { useFileStore } from '../state/stores/file';
import { useWorkspace } from '../context/workspace/WorkspaceContext';
import { useEffect, useState } from 'react';
import { Button, useModal, Modal, Avatar } from '@hubql/hubqlkit';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';

type Props = {
  workspaceSlug?: string;
  id?: string;
  allowDelete?: boolean;
};

export const SidebarProjectFile = ({
  workspaceSlug,
  id,
  allowDelete,
}: Props) => {
  const { workspace } = useWorkspace();

  const { loading, error, data } = useQuery<ProjectFileListQuery>(
    ProjectFileListDocument,
    {
      skip: !id,
      variables: {
        projectId: id,
      },
    }
  );

  if (loading || !workspace) return <Spinner />;

  const files = data?.projectFiles?.edges?.map((fileNode, index) => {
    const file = fileNode?.node?.file;
    if (!file) return null;
    return (
      <Content
        file={file}
        workspaceSlug={workspaceSlug}
        key={'f' + file?.id + index}
        allowDelete={allowDelete}
      />
    );
  });

  return (
    <>
      {files && files?.length > 0 ? (
        <div className="pb-1">{files}</div>
      ) : (
        <div className=" pl-7 w-full py-1 px-1 text-xs text-zinc-500">
          <span>Empty</span>
        </div>
      )}
    </>
  );
};

const Content = ({ file, workspaceSlug, allowDelete }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [currentFile, setCurrentFile] = useState(false);
  const router = useRouter();
  const fileSlug = useFileStore((state) => state.fileId);
  const handleMouseEnter = () => {
    setShowDetails(true);
  };

  const handleMouseLeave = () => {
    setShowDetails(false);
  };

  const fileId = file && file.id;

  useEffect(() => {
    if (fileSlug === fileId) {
      setCurrentFile(true);
    } else {
      setCurrentFile(false);
    }
  }, [router]);

  const fullFileName = file && `${file.fileName}.${file.extension}`;
  const highlightBgClass = currentFile && 'bg-zinc-800';
  const highlightTextClass = currentFile && 'text-white fill-white';
  const highlightAccentClass =
    currentFile && 'bg-brand-800 w-0.5 h-6 rounded-full';
  const AccentClass = !currentFile && 'bg-transparent w-[1px] h-6 rounded-full';
  const fileAccess = file?.access.edges ?? [];
  const showNoAccess =
    !showDetails && fileAccess && fileAccess.length === 0 && 'col-span-12';
  const showWithAccess =
    !showDetails && fileAccess && fileAccess.length > 0 && 'col-span-9';
  const showDeleteNoAccess =
    showDetails && fileAccess && fileAccess.length === 0 && 'col-span-11';
  const showDeleteWithAccess =
    showDetails && fileAccess && fileAccess.length > 0 && 'col-span-8';

  const invitedUsers = fileAccess.slice(0, 2).map((userNode, index) => {
    if (!userNode) return null;
    const users = userNode.node;
    return (
      <Avatar
        key={users.user.id + '-' + index}
        src={users.user.picture}
        label={false}
        size="xs"
        className="-ml-1 relative z-10"
      />
    );
  });

  return (
    file && (
      <div
        className="w-full pl-6 flex items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={clsx(
            'grid grid-cols-12 justify-items-stretch items-center w-full rounded-md hover:bg-zinc-800 ',
            highlightBgClass
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={clsx(
              showDeleteNoAccess,
              showDeleteWithAccess,
              showNoAccess,
              showWithAccess
            )}
          >
            <Link
              title={fullFileName}
              href={`/?file=${file.id}&ref=${file.lastCommitHash}`}
              key={file.id}
            >
              <div className=" w-full cursor-pointer py-1 px-3 text-xs text-zinc-400 flex flex-row items-center gap-2">
                <span
                  className={clsx(highlightTextClass, 'truncate w-full flex-1')}
                >
                  {fullFileName}
                </span>
              </div>
            </Link>
          </div>
          {fileAccess.length > 0 && (
            <div className="col-span-3 flex bg-zinc-600 w-fit items-center justify-self-end mr-1 rounded-md h-5 pl-2 pr-0.5">
              {invitedUsers}
              {fileAccess.length > 2 && (
                <div className="flex   text-[9px] items-center justify-center bg-zinc-500 border border-zinc-600 rounded-full w-[18px] h-[18px] relative z-10 -ml-1">
                  +{fileAccess.length - 2}
                </div>
              )}
            </div>
          )}

          {showDetails && allowDelete && (
            <div className={clsx('col-span-1')}>
              <DeleteModal
                id={file.id}
                name={fullFileName}
                highlightTextClass={highlightTextClass}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

const DeleteModal = ({ id, name, highlightTextClass }) => {
  const posthog = usePostHog();

  const { isOpen, openModal, closeModal } = useModal();
  const [deleteFile, deleteFileState] = useMutation(DeleteFileDocument);
  const handleDelete = async () => {
    await deleteFile({
      variables: {
        id,
      },
      refetchQueries: ['projectFileList'],
    });
    try {
      posthog?.capture('FILE_DELETED', {
        fileId: id,
      });
    } catch (error) {}
    closeModal();
  };
  return (
    <>
      <div className="w-full flex justify-end">
        <Button
          size="sm"
          variant="ghost"
          onClick={openModal}
          className="rounded-sm"
        >
          <TrashIcon
            className={clsx(
              'h-3 w-3',
              highlightTextClass,
              !highlightTextClass && 'fill-zinc-400'
            )}
          />
        </Button>
      </div>
      <Modal isOpen={isOpen} onClose={closeModal} className={'p-8'}>
        <div className="flex flex-col gap-4 itmes-start">
          <h2>
            Do you really want to delete <strong>{name}</strong> ?
          </h2>
          <div className={'flex gap-2 mt-4 justify-end'}>
            <Button
              isDisabled={deleteFileState.loading}
              onClick={closeModal}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              isLoading={deleteFileState.loading}
              variant="red"
            >
              Yes, let&apos;s delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
