import { Avatar } from '@hubql/hubqlkit';

export const PresenceIndicator = ({ others }) => {
  return (
    others && (
      <div className="absolute flex h-full top-0 left-full z-10 items-center">
        {others?.map((other) => (
          <Avatar
            key={other.connectionId}
            name={other.info?.name ?? 'Unknown'}
            src={other.info.picture}
            label={false}
            style={{
              outline: `${other?.presence['activeFieldColor']} solid 2px`,
            }}
          ></Avatar>
        ))}
      </div>
    )
  );
};
