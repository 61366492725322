import { useMutation } from '@apollo/client';
import { DeleteProjectAccessDocument } from '@hubql/codegen';
import {
  Avatar,
  SelectNew,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
} from '@hubql/hubqlkit';
import { useState } from 'react';
import { WorkMode, useFileStore } from '../state/stores/file';
import { usePostHog } from 'posthog-js/react';

export const ProjectAccessItem = ({
  id,
  picture,
  firstName,
  accessType,
  allowChange,
  invitationId,
}) => {
  const posthog = usePostHog();

  const [deleteProjectAccess] = useMutation(DeleteProjectAccessDocument);
  const [newAccess, setNewAccess] = useState(accessType);
  const setWorkMode = useFileStore((state) => state.setWorkMode);

  const updateAccess = (WorkMode) => {
    setWorkMode(WorkMode);

    // TODO for tobias to be able to change access right
  };

  const setValueChange = (value) => {
    if (value === 'deleting...') {
      handleDelete();
    } else {
      setNewAccess(value);
      updateAccess(value);
    }
  };

  const handleDelete = () => {
    const confirmed = confirm('Are you sure?');
    if (confirmed) {
      deleteProjectAccess({
        variables: {
          id,
        },
        refetchQueries: ['projectAccess', 'project'],
      });
      try {
        posthog?.capture('HUB_ACCESS_DELETED', {});
      } catch (error) {}
    }
  };
  return (
    <div className="flex items-center justify-between w-full">
      <Avatar
        src={picture}
        name={firstName + `${invitationId ? ' (Invited)' : ''}`}
      ></Avatar>
      {allowChange ? (
        <SelectNew value={newAccess} onValueChange={setValueChange}>
          <SelectTrigger className="w-fit" id={'file-sharing-access-type'}>
            <SelectValue aria-label={newAccess}>{newAccess}</SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value={WorkMode.VIEW}>Read only</SelectItem>
              <SelectItem value={WorkMode.COMMENT}>Read and comment</SelectItem>
              <SelectItem value={WorkMode.EDIT}>
                Read, comment and edit
              </SelectItem>
              <SelectItem value={'deleting...'}>Remove</SelectItem>
            </SelectGroup>
          </SelectContent>
        </SelectNew>
      ) : (
        <span className="text-xs text-zinc-400">{accessType}</span>
      )}
    </div>
  );
};
